import React, { useEffect, useState } from 'react'

import ProjectSearchBar from '../../Header/joclassified/ProjectSearchBar'
import axios from 'axios'
import ProjectListingCard from './ProjectListingCard'
import Pagination from 'react-js-pagination'
import Select from 'react-select'

import { API_URL, jsonHeader } from '../../../Constants/global'

function ProjectListings () {
  //api/projects_search

  const sortbyArray = [
    { label: 'Default', value: 0 },
    { label: 'Price low to high', value: 1 },
    { label: 'Price high to low', value: 2 }
  ]
  const [isLoading, setisLoading] = useState(false)

  const property_thumbnail =
    'https://cdn.jagahonline.com/properties/275906/front_image-61b98b34e402a-thumb.jpeg'

  const [propertyData, setPropertyData] = useState([])
  const [total_records, settotal_records] = useState(0)
  const [pageNumber, setpageNumber] = useState(0)
  const [recordNotfound, setRecordNotFound] = useState('')
  const [sortby, setsortby] = useState(0)

  function handlePageChange (pageNumber) {
    console.log(`active page is ${pageNumber}`)
    setpageNumber(pageNumber)
    this.setState({ activePage: pageNumber })
  }

  var searchQueryGet = {}

  if (
    localStorage.getItem('searchQueryProject') !== undefined &&
    localStorage.getItem('searchQueryProject') !== ''
  ) {
    searchQueryGet = JSON.parse(localStorage.getItem('searchQueryProject')) || {
      city_id: 0,
      area_id: 0,
      min_area: 0,
      max_area: 0,
      price_min: 0,
      price_max: 0,
      beds: 0,
      keywords: '',
      sortby: 1
    }
  } else {
    searchQueryGet = {
      city_id: 0,
      area_id: 0,
      min_area: 0,
      max_area: 0,
      price_min: 0,
      price_max: 0,
      beds: 0,
      keywords: '',
      sortby: 1
    }
  }

  console.log(localStorage.getItem('searchQueryProject'))

  const user_id = localStorage.getItem('user_id')
    ? localStorage.getItem('user_id')
    : 0
  const [searchQuery, setSearchQuery] = useState(searchQueryGet)

  const data_m = {
    pageNumber: pageNumber,
    nPerPage: 20,
    isActive: 1,
    isDeleted: 0,
    showAll: false,
    id: 0,
    city_id: searchQueryGet.city_id,
    builder_id: searchQueryGet.builder_id,
    min_area: searchQueryGet.min_area,
    max_area: searchQueryGet.max_area,
    price_min: searchQueryGet.price_min,
    price_max: searchQueryGet.price_max,
    beds: searchQueryGet.beds,
    keywords: searchQueryGet.keywords,
    user_id: user_id,
    sortby: sortby
  }

  useEffect(() => {
    async function fetchProperties () {
      setisLoading(true)
      axios({
        method: 'POST',
        url: API_URL + 'api/projects_search',
        data: data_m,
        headers: jsonHeader
      }).then(response => {
        if (response.status === 200) {
          if (response.data.total_records === 0) {
            setisLoading(false)
            setRecordNotFound('Record not found. Try again')
          } else {
            settotal_records(response.data.total_records)
            // console.log('total records ' + response.data.total_records)
            setPropertyData(response.data?.data)
            setisLoading(false)
          }
        } else {
          setisLoading(false)
          setRecordNotFound(
            'Something went wrong with the requested data. Try again'
          )
        }
      })
    }

    fetchProperties()
  }, [pageNumber, sortby])

  const resetSeach = () => {
    localStorage.setItem('searchQueryProject', '')
    window.location.reload()
  }

  const onSortChange = value => {
    // setSearchQuery({ ...searchQuery, sortby: value.value });
    setsortby(value.value)
    // localStorage.setItem("searchQueryProject", JSON.stringify(searchQuery));
    // console.log(searchQuery);

    // window.location = window.location.href
  }

  return (
    <>
      <div className='no-banner-padding'>
        <ProjectSearchBar />
      </div>
      <section className='space-ptb'>
        <div className='container'>
          {/*Title and Results*/}
          <div className='row'>
            <div className='col-md-6'>
              <div className='section-title mb-3 mb-lg-4'>
                {isLoading ? (
                  ''
                ) : (
                  <h2>
                    <span className='text-primary'>{total_records}</span>{' '}
                    Results
                  </h2>
                )}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='property-filter-tag'>
                <ul className='list-unstyled'>
                  <li>
                    <a href='#'>
                      Projects <i className='fas fa-times-circle' />{' '}
                    </a>
                  </li>
                  <li>
                    <a
                      className='filter-clear'
                      href='javascript:void(0);'
                      onClick={resetSeach}
                    >
                      Reset Search <i className='fas fa-redo-alt' />{' '}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/*Title and Results*/}

          <div className='row'>
            <div className='col-lg-9'>
              {/*Project Filter*/}
              <div className='property-filter d-sm-flex'>
                <ul className='property-short list-unstyled d-sm-flex mb-0'>
                  <li>
                    <form className='form-inline'>
                      <div className='form-group d-lg-flex d-block'>
                        <label className='justify-content-start'>
                          Sort by:
                        </label>
                        <div className='short-by'>
                          <Select
                            className='form-control'
                            styles={{
                              // Fixes the overlapping problem of the component
                              menu: provided => ({ ...provided, zIndex: 9999 })
                            }}
                            //   isLoading={isloading}
                            options={sortbyArray}
                            isMulti={false}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            onChange={onSortChange}
                            value={sortbyArray.find(
                              c => c.value === parseInt(sortby)
                            )}
                          />
                        </div>
                      </div>
                    </form>
                  </li>
                </ul>
              </div>
              {/*Project Filter*/}

              {/*Project Card*/}

              {isLoading ? (
                <div className='row my-4'>
                  <div className='col-md-4 text-center'>
                    <box className='shine property-box-shimmer w-100'></box>
                  </div>
                  <div className='col-md-8 text-center'>
                    <div>
                      <lines className='mt-3 my-1 shine property-box-shimmerLines d-block w-100'></lines>
                      <lines className='shine property-box-shimmerLines d-block w-100'></lines>
                      <lines className='shine property-box-shimmerLines d-block w-100'></lines>
                      <lines className='my-1 shine property-box-shimmerLines d-block w-100'></lines>
                      <lines className='shine property-box-shimmerLines d-block w-100'></lines>
                      <lines className='shine property-box-shimmerLines d-block w-100'></lines>
                      <lines className='shine property-box-shimmerLines d-block w-100'></lines>
                      <lines className='shine property-box-shimmerLines d-block w-100'></lines>
                      <lines className='my-1 shine property-box-shimmerLines d-block w-100'></lines>
                      <lines className='my-1 shine property-box-shimmerLines d-block w-100'></lines>
                      <lines className='shine property-box-shimmerLines d-block w-100'></lines>
                      <lines className='shine property-box-shimmerLines d-block w-100'></lines>
                      <lines className='shine property-box-shimmerLines d-block w-100'></lines>
                      <lines className='shine property-box-shimmerLines d-block w-100'></lines>
                      <lines className='my-1 shine property-box-shimmerLines d-block w-100'></lines>
                    </div>
                  </div>
                </div>
              ) : recordNotfound ? (
                <div className='row my-4'>
                  <div> {recordNotfound} </div>
                </div>
              ) : (
                <ProjectListingCard propertyData={propertyData} />
              )}

              {/*Project Card*/}

              {/*Project Pagination*/}
              <div className='col-md-12'>
                <Pagination
                  activePage={pageNumber}
                  itemsCountPerPage={20}
                  totalItemsCount={total_records}
                  pageRangeDisplayed={3}
                  onChange={handlePageChange}
                  activeLinkClass='active'
                  innerClass='pagination'
                  itemClass='page-item'
                  linkClass='page-link'
                />
              </div>
              {/*Project Pagination*/}
            </div>
          </div>

          {/*Project Card*/}
        </div>
      </section>
    </>
  )
}

export default ProjectListings
