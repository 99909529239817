import React from 'react'

import PropertySearchbar from '../../../Components/Header/joclassified/PropertySearchbar'
import PropertiesListing from '../../../Components/ClassifiedComponents/Properties/PropertiesListing'
import { useParams, Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

function Properties () {
  const property_params = useParams()

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>
          Pakistan Best Property Portal – Sell, Buy, Rent Properties &amp; Homes
          - JagahOnline.com
        </title>
        <link rel='canonical' href={window.location.href} />
      </Helmet>
      <div className='no-banner-padding'>
        <PropertySearchbar />
      </div>
      <PropertiesListing prop_params={property_params} />
    </>
  )
}

export default Properties
