import React from 'react'

function TermsConditions () {
  return (
    <>
      <div className='bg-light'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <ol className='breadcrumb mb-0'>
                <li className='breadcrumb-item'>
                  <a href='/'>
                    {' '}
                    <i className='fas fa-home' />{' '}
                  </a>
                </li>
                <li className='breadcrumb-item active'>
                  <i className='fas fa-chevron-right' />{' '}
                  <span>Terms Conditions</span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {/*=================================
      breadcrumb */}

      <section className='space-ptb'>
        <div className='container'>
          <div className='row'>
            <div
              className='col-md-12 tc-align'
              style={{ textAlign: 'left!important', direction: 'ltr' }}
            >
              <h2>TERMS &amp; CONDITIONS</h2>
              <h4 className='text-center'>User Agreement</h4>
              <p>
                This documents is referred as terms and condition under User
                Agreement for all types of users for
                <a href='/' target='_blank'>
                  {' '}
                  www.jagahonline.com
                </a>
                . Jagahonline.com hereunder this agreement is referred as a
                platform as website, Application or App as iOS and Android
                versions, its social media pages and any other kind of article
                in any form or shape and is as a business entity as Hundred
                Alpha Pvt Ltd which is a subsidiary of Hatch Tech Pvt Ltd’
                Pakistan.{' '}
                <a href='/' target='_blank'>
                  www.jagahonline.com
                </a>{' '}
                is merely a marketplace platform facilitating sellers selling
                their products and services to potential buyers. Jagahonline
                does not offer, sell, solicit any product or any service or any
                business directly to anyone individual or business as its
                offering except its website/apps as a software as a service
                under its SAAS Model on subscription model.
              </p>
              <h5 className='h-size'>1. Introduction</h5>
              <p>
                These Standard Terms and Conditions are (these “Terms” or these
                “Website &amp; Apps and other means of digital business’
                Standard Terms and Conditions”) contained herein on this
                webpage, shall govern your use of this website/apps and any
                other digital mean of Business of JagahOnline, including all
                pages within this website (collectively referred to herein below
                as this
                <a href='/' target='_blank'>
                  “https://www.jagahonline.com”)
                </a>
                . These Terms apply in full force and effect to your use of this
                Website and by using this Website, you expressly accept all
                terms and conditions contained herein in full. You must not use
                this Website, if you have any objection to any of these Website
                Standard Terms and Conditions.
              </p>
              <p>
                This Website is not for use by any minors (defined as those who
                are not at least 18 years of age), and you must not use this
                Website if you are a minor. The general users of this Website
                are required not to use this website for any commercial activity
                whatsoever unless they are subscribers of as customer of{' '}
                <a href='/' target='_blank'>
                  jagahonline.com.
                </a>
              </p>
              <p>
                This Privacy Policy explains how we collect, use and (under
                certain conditions) disclose your personal information. This
                Privacy Policy also explains the steps we have taken to secure
                your personal information. Finally, this Privacy Policy explains
                your options regarding the collection, use and disclosure of
                your personal information. By visiting the Site directly or
                through another site, you accept the practices described in this
                Policy.
              </p>
              <p>
                Data protection is a matter of trust and your privacy is
                important to us. We shall therefore only use your name and other
                information which relates to you in the manner set out in this
                Privacy Policy. We will only collect information where it is
                necessary for us to do so and we will only collect information
                if it is relevant to our dealings with you. We will only keep
                your information for as long as we are either required to by law
                or as is relevant for the purposes for which it was collected.
                You can visit the Site and browse without having to provide
                personal details. During your visit to the Site you remain
                anonymous and at no time can we identify you unless you have an
                account on the Site and log on with your user name and password.
              </p>
              <h5 className='h-size'>2. Intellectual Property Rights</h5>
              <p>
                Other than content you own, which you may have opted to include
                on this Website, under these Terms, [Hundred Alpha Pvt Ltd]
                and/or its licensors own all rights to the intellectual property
                and material contained in this Website, and all such rights are
                reserved. You are granted a limited license only, subject to the
                restrictions provided in these Terms, for purposes of viewing
                the material contained on this Website,
              </p>
              <h5 className='h-size'>3. Restrictions</h5>
              <p>
                You are expressly and emphatically restricted from all of the
                following:
              </p>
              <ol>
                <li>
                  Publishing any Website material in any other media of any
                  kind;
                </li>
                <li>
                  Selling, sublicensing and/or otherwise commercializing any
                  Website material;
                </li>
                <li>
                  Publicly performing and/or showing any Website material;
                </li>
                <li>
                  Using this Website in any way that is, or may be, damaging to
                  this Website;
                </li>
                <li>
                  Using this Website in any way that impacts user access to this
                  Website;
                </li>
                <li>
                  Using this Website contrary to applicable laws and
                  regulations, or in a way that causes, or may cause, harm to
                  the Website, or to any person or business entity;
                </li>
                <li>
                  Engaging in any data mining, data harvesting, data extracting
                  or any other similar activity in relation to this Website, or
                  while using this Website;
                </li>
                <li>
                  Using this Website to engage in any advertising or marketing;
                </li>
              </ol>
              <h5 className='h-size'>4. Data Collection</h5>
              <p>
                We may collect various pieces of information if you seek to
                place an order for a product or service with us on the Site. We
                collect, store and process your data for processing your
                purchase on the Site and any possible later claims, and to
                provide you with our services. We may collect personal
                information including, but not limited to, your title, name,
                gender, date of birth, email address, postal address, delivery
                address (if different), telephone number, mobile number, fax
                number, payment details, payment card details or bank account
                details.
              </p>
              <p>
                {' '}
                We will use the information you provide to enable us to process
                your orders and to provide you with the services and information
                offered through our website and which you request. Further, we
                will use the information you provide to administer your account
                with us; verify and carry out financial transactions in relation
                to payments you make; audit the downloading of data from our
                website; improve the layout and/or content of the pages of our
                website and customize them for users; identify visitors on our
                website; carry out research on our users' demographics; send you
                information we think you may find useful or which you have
                requested from us, including information about our products and
                services, provided you have indicated that you have not objected
                to being contacted for these purposes. Subject to obtaining your
                consent we may contact you by email, SMS or telephone with
                details of other products and services. If you prefer not to
                receive any marketing communications from us, you can opt out at
                any time.
              </p>
              <p>
                We may pass your name and address on to a third party in order
                to make delivery of the product or services to you (for example
                to our courier, servicemen or supplier or sales agencies ). You
                must only submit to us the Site information which is accurate
                and not misleading, and you must keep it up to date and inform
                us of changes..
              </p>
              <p>
                Your actual service booking or order details may be stored with
                us but for security reasons cannot be retrieved directly by us.
                However, you may access this information by logging into your
                account on the Site. Here you can view the details of your
                service bookings and orders that have been completed, those
                which are open and those which are shortly to be dispatched and
                administer your address details, bank details ( for refund
                purposes) and any newsletter to which you may have subscribed.
                You undertake to treat the personal access data confidentially
                and not make it available to unauthorized third parties. We
                cannot assume any liability for misuse of passwords.
              </p>
              <h5 className='h-size'>5. Other Personal Information</h5>
              <p>
                We may use your personal information for opinion and market
                research. Your details are anonymous and will only be used for
                statistical purposes. You can choose to opt out of this at any
                time. Any answers to surveys or opinion polls we may ask you to
                complete will not be forwarded on to third parties. Disclosing
                your email address is only necessary if you would like to take
                part in competitions. We save the answers to our surveys
                separately from your email address.
              </p>
              <p>
                {' '}
                We may also send you other information about us, the Site, our
                other websites, our products, sales promotions, our newsletters,
                anything relating to other companies in our group or our
                business partners. If you would prefer not to receive any of
                this additional information as detailed in this paragraph (or
                any part of it) please click the 'unsubscribe' link in any email
                that we send to you. Within 21 working days (days which are
                neither (i) a Sunday, nor (ii) a public holiday anywhere in
                Pakistan) of receipt of your instruction we will cease to send
                you information as requested. If your instruction is unclear we
                will contact you for clarification.
              </p>
              <p>
                We may further anonymize data about users of the Site generally
                and use it for various purposes, including ascertaining the
                general location of the users and usage of certain aspects of
                the Site or a link contained in an email to those registered to
                receive them, and supplying that anonymized data to third
                parties such as publishers. However, that anonymized data will
                not be capable of identifying you personally
              </p>
              <h5 className='h-size'>6. Third Parties and Links </h5>
              <p>
                We may pass your details to other companies in our group. We may
                also pass your details to our agents and subcontractors,
                suppliers, servicemen, or agents to help us with any of our uses
                of your data set out in our Privacy Policy. For example, we may
                use third parties to assist us with delivering products to you,
                to help us to collect payments from you, to analyse data and to
                provide us with marketing or customer service assistance. We may
                exchange information with third parties for the purposes of
                fraud protection and credit risk reduction. We may transfer our
                databases containing your personal information if we sell our
                business or part of it. Other than as set out in this Privacy
                Policy, we shall NOT sell or disclose your personal data to
                third parties without obtaining your prior consent unless this
                is necessary for the purposes set out in this Privacy Policy or
                unless we are required to do so by law. The Site may contain
                advertising of third parties and links to other sites or frames
                of other sites. Please be aware that we are not responsible for
                the privacy practices or content of those third parties or other
                sites, nor for any third party to whom we transfer your data in
                accordance with our Privacy Policy.
              </p>
              <h5 className='h-size'>7. Your Content</h5>
              <p>
                In these Website Standard Terms and Conditions, “Your Content”
                shall mean any audio, video, text, images or other material you
                choose to display on this Website. With respect to Your Content,
                by displaying it, you grant Hundred Alpha Pvt Ltd a
                non-exclusive, worldwide, irrevocable, royalty- free, sub
                licensable license to use, reproduce, adapt, publish, translate
                and distribute it in any and all media. Your Content must be
                your own and must not be infringing on any third party’s rights.
                Hundred Alpha Pvt Ltd reserves the right to remove any of Your
                Content from this Website at any time, and for any reason,
                without notice.
              </p>
              <h5 className='h-size'>8. Security</h5>
              <p>
                We have in place appropriate technical and security measures to
                prevent unauthorized or unlawful access to or accidental loss of
                or destruction or damage to your information. When we collect
                data through the Site, we collect your personal details on a
                secure server. We use firewalls on our servers. Our security
                procedures mean that we may occasionally request proof of
                identity before we disclose personal information to you. You are
                responsible for protecting against unauthorized access to your
                password and to your computer.
              </p>
              <h5 className='h-size'>
                9. Payments, Refunds &amp; Returns – Goods &amp; Services
              </h5>
              <p>
                JagahOnline facilitates buyers and sellers with multiple payment
                options for exchange of goods and services against purchases and
                subscriptions for single or frequently recurring payment on its
                platform. You must use, as a user, appropriate means of care and
                compliance in using such facility and must agree to terms and
                conditions of payment gateway providers such as Visa, Master,
                Jazz Cash, Easypaisa. Bank transfer/deposit or cash collection
                facility. You must also agree on terms and condition on
                cancellation, return and exchange and refund policy under each
                product, service of subscription purchase.
              </p>
              <p>
                JagahOnline sellers or Service Providers on{' '}
                <a href='/' target='_blank'>
                  www.jagahonline.com
                </a>{' '}
                may or may not offer full or partial money back guarantee on
                some or on all purchases made but under certain conditions. We
                invite you to read the terms and conditions explained in more
                detail in each product and service offering on our website
                before you make the purchase from the seller or service
                provider. Each product or service offering is regulated under
                single product or service offering. In order to see the
                condition that applies to your situation, while following the
                correct procedure. If you are unhappy with the property,
                project, product or service that you have purchased from one of
                the seller, service provider or agents or builder registered on{' '}
                <a href='/' target='_blank'>
                  www.jagahonline.com
                </a>
                . please let us know. Returns &amp; Refunds Policy gives you
                certain days to return or exchange an article purchased online
                with a valid receipt. You may be eligible for a full refund
                within certain calendar days of your purchase categorically
                mentioned in product or service listing offering.
              </p>
              <p>
                After the refund time lapses, Sellers cannot offer you a refund
                or exchange. We encourage our customers to try the product (or
                service) in the first refund time period or days after their
                purchase to ensure it meets your needs. To generalise, to be
                eligible for a refund or exchange, all physical products must be
                packed in the original, unmarked packaging including any
                accessories, labels, free gifts, bonus item, manuals and
                documentation that shipped with the product. If the article is
                returned unopened in the original box, Seller shall exchange it
                or offer you a refund based on your original method of payment
                excluding any shipping charges (other than the original shipping
                costs invoiced).
              </p>
              <h5 className='h-size'>
                10. Refunds &amp; Returns – Advertising and Marketing{' '}
              </h5>
              <p>
                JagahOnline facilitates buyers and sellers with multiple payment
                options for exchange of goods and services against purchases and
                subscriptions for single or frequently recurring payment on its
                platform. You must use, as a user, appropriate means of care and
                compliance in using such facility and must agree to terms and
                conditions of payment gateway providers such as Visa, Master,
                Jazz Cash, Easypaisa. Bank transfer/deposit or cash collection
                facility. You must also agree on terms and condition on
                cancellation, return and exchange and refund policy under each
                product, service of subscription purchase.
              </p>
              <p>
                <a href='/' target='_blank'>
                  www.jagahonline.com
                </a>{' '}
                /Hundred Alpha Pvt Ltd may or may not offer full or partial
                money back guarantee on some or on all purchases for customers
                who place their advertisement banners on JagahOnline in order to
                promote their brand or to generate more leads or potential
                relevant customers. This is however possible under certain
                conditions due to calendar and slots availability of each
                advertising banners on JagahOnline. Here are applicable
                conditions,
              </p>
              <ol style={{ listStyleType: 'upper-alpha' }}>
                <li>
                  In order to be eligible for full or partial refund, customer
                  must book the adverting banner calendar and slots for future
                  display and must cancel at least minimum 3 days in advance in
                  order to be eligible to cancel and refund 100% . In these
                  grace period, customer can cancel and request for 100% refund
                  should customer had furnished the design content.
                </li>
                <li>
                  <a href='/' target='_blank'>
                    www.jagahonline.com
                  </a>{' '}
                  /Hundred Alpha Pvt Ltd may decline the request for refund in
                  full and partial in case,
                  <ol>
                    <li>
                      This booking made was the for future campaign and at least
                      3 days in advance of campaign/advertising banner going
                      live on Jagahonline.
                    </li>
                    <li>
                      No refund is made if refund is requested after one of all
                      of, design, strategy campaign was made, booked and made
                      live on digital platforms,
                    </li>
                  </ol>
                </li>
              </ol>
              <p />
              <p>
                Customer is fully liable and responsible for loss of money,
                time/slots, resources applied by JagahOnline and loss of
                opportunities that could have been offered to other customers by
                JagahOnline therefore, may not offer full or partial refund at
                the sole discretion held with JagahOnline/Hundred Alpha Pvt ltd.
              </p>
              <h5 className='h-size'>
                11. Refunds &amp; Returns – SAAS – Software as a service{' '}
              </h5>
              <p>
                JagahOnline facilitates buyers and sellers with multiple payment
                options for exchange of goods and services against purchases and
                subscriptions for single or frequently recurring payment on its
                platform. You must use, as a user, appropriate means of care and
                compliance in using such facility and must agree to terms and
                conditions of payment gateway providers such as Visa, Master,
                Jazz Cash, Easypaisa. Bank transfer/deposit or cash collection
                facility. You must also agree on terms and condition on
                cancellation, return and exchange and refund policy under each
                product, service of subscription purchase.
              </p>
              <p>
                Jagahonline/Hundred Alpha Pvt Ltd may or may not offer full or
                partial money back guarantee solely on its discretion on some or
                on all subscriptions made but under certain conditions. We
                invite you to read and carefully understand the software terms
                and features explained in more detail in each subscription
                service offering before pricing on our website before you make
                the purchase for the subscription for each software option. All
                our subscriptions are for Software as a Service with free
                subscription option with no string attached to it hence do not
                bind user in any condition under freemium option. However,
                subscriber who upgrade their subscriptions from freemium to
                premium features of software, must take the responsibility of
                zero refunds, of suitability of the subscription for their
                personal or business use, cost and freemium option before making
                the final or regular payment (frequently recurring payment
                options based on chosen frequency at the time of subscription).
              </p>
              <h5 className='h-size'>12. Your rights</h5>
              <p>
                If you are concerned about your data you have the right to
                request access to the personal data which we may hold or process
                about you. You have the right to require us to correct any
                inaccuracies in your data free of charge. At any stage you also
                have the right to ask us to stop using your personal data for
                direct marketing purposes.
              </p>
              <h5 className='h-size'> 13. No warranties</h5>
              <p>
                This Website is provided “as is,” with all faults, and Hundred
                Alpha Pvt Ltd makes no express or implied representations or
                warranties, of any kind related to this Website or the materials
                contained on this Website. Additionally, nothing contained on
                this Website shall be construed as providing consult or advice
                to you.
              </p>
              <h5 className='h-size'>14. Limitation of liability</h5>
              <p>
                In no event shall Hundred Alpha Pvt Ltd or any of its group
                companies, nor any of its officers, directors and employees, be
                liable to you for anything arising out of or in any way
                connected with your use of this Website, Apps and any other
                means whether such liability is under contract, tort or
                otherwise, and Hundred Alpha Pvt Ltd, including its officers,
                directors and employees shall not be liable for any indirect,
                consequential or special liability arising out of or in any way
                related to your use of this Website/Apps/ an all other means of
                Digital Business.{' '}
              </p>
              <h5 className='h-size'>15. Indemnification</h5>
              <p>
                You hereby indemnify to the fullest extent Hundred Alpha Pvt Ltd
                and its group companies or its subsidiaries from and against any
                and all liabilities, costs, demands, causes of action, damages
                and expenses (including reasonable attorney’s fees) arising out
                of or in any way related to your breach of any of the provisions
                of these Terms.{' '}
              </p>
              <h5 className='h-size'>16. Severability. </h5>
              <p>
                If any provision of these Terms is found to be unenforceable or
                invalid under any applicable law, such unenforceability or
                invalidity shall not render these Terms unenforceable or invalid
                as a whole, and such provisions shall be deleted without
                affecting the remaining provisions herein.
              </p>
              <h5 className='h-size'>17. Variation of Terms</h5>
              <p>
                Hundred Alpha Pvt Ltd and its group companies are permitted to
                revise these Terms at any time as it sees fit, and by using this
                Website you are expected to review such Terms on a regular basis
                to ensure you understand all terms and conditions governing use
                of this Website.
              </p>
              <h5 className='h-size'>18. Assignment</h5>
              <p>
                Hundred Alpha Pvt Ltd shall be permitted to assign, transfer,
                and subcontract its rights and/or obligations under these Terms
                without any notification or consent required. However, you shall
                not be permitted to assign, transfer, or subcontract any of your
                rights and/or obligations under these Terms.
              </p>
              <h5 className='h-size'>19. Entire Agreement</h5>
              These Terms, including any legal notices and disclaimers contained
              on this Website, constitute the entire agreement between Hundred
              Alpha Pvt Ltd and you in relation to your use of this Website, and
              supersede all prior agreements and understandings with respect to
              the same.
              <p />
              <h5 className='h-size'>20. Governing Law &amp; Jurisdiction</h5>
              <p>
                These Terms will be governed by and construed in accordance with
                the laws of Pakistan, and you submit to the non-exclusive
                jurisdiction of Pakistan and courts of Pakistan for the
                resolution of any disputes.
              </p>
              <p className='mt-2'>
                This Terms &amp; Conditions under user agreement has been
                updated on 1st September 2021.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default TermsConditions
