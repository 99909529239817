import React, { useState, useEffect } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useParams } from "react-router-dom";

import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  multiFormHeader,
  USER_AGENCY_PROFILE_IMAGEURL,
  USER_IMAGE_NOT_FOUND_URL,
} from "../../../Constants/global";

function ProjectDetailContact({ projectDetails }) {
  const project_params = useParams();
  console.log(project_params.searchquery.split("-").pop());
  const project_id = project_params.searchquery.split("-").pop();

  const [Statusmessage, setMessage] = useState({
    message: "",
    class: "",
  });
  const [isLoading, setIsLoading] = useState({
    isLoading: false,
    disabled: false,
  });
  function setSuccessMessage(msg) {
    setMessage({
      message: msg,
      class: "alert alert-success contactUsAlert",
    });
  }
  function setErrorMessage(msg) {
    setMessage({
      message: msg,
      class: "alert alert-danger contactUsAlert",
    });
  }
  function removeMessage(msg) {
    setMessage({
      message: "",
      class: "",
    });
  }

  const [apierror, setApierror] = useState("");
  const initialValues = {
    name: "",
    email: "",
    contact_number: "",
    message: "",
  };

  const validateSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email format").required("Required"),
    contact_number: Yup.number().required("Required"),
    message: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
  });

  const onSubmit = (values, { resetForm }) => {
    setIsLoading({
      isLoading: true,
      disabled: true,
    });
    console.log(values);
    var form = new FormData();
    form.append("name", values.name);
    form.append("email", values.email);
    form.append("contact_number", values.contact_number);
    form.append("message", values.message);
    form.append("type", "project");
    form.append("ref_id", project_id);
    form.append("title", projectDetails.title);
    //get current date
    const current = new Date();
    const date = `${current.getDate()}-${
      current.getMonth() + 1
    }-${current.getFullYear()} - ${current.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })}`;

    form.append("inquiry_date_time", date);
    if (window.location.href.includes("localhost:3000")) {
      form.append(
        "url",
        window.location.href.replace("localhost:3000", "jagahonline.com")
      );
    } else if (window.location.href.includes("revamp.jotesting.com")) {
      form.append(
        "url",
        window.location.href.replace("revamp.jotesting.com", "jagahonline.com")
      );
    } else {
      form.append("url", window.location.href);
    }

    console.log(form);

    axios({
      method: "POST",
      url: API_URL + "generalservices/inquiry",
      data: form,
      headers: multiFormHeader,
    }).then((response) => {
      if (response.data.status === 200) {
        setIsLoading({
          isLoading: false,
          disabled: false,
        });
        setSuccessMessage("Your request has been sent");
        setTimeout(removeMessage, 3000);
        resetForm({ values: "" });
      } else {
        setIsLoading({
          isLoading: false,
          disabled: false,
        });
        setApierror(response.data.message);
        setErrorMessage(response.data.message);
      }
    });
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validateSchema,
  });

  const builderProfile =
    projectDetails.builder_profile !== undefined
      ? projectDetails.builder_profile
      : undefined;

  return (
    <div className="sidebar">
      <div className="widget">
        <div className="widget-dec border-bottom-0 mb-0">
          <div className="agent-detail text-center">
            <div className="agent-avatar avatar avatar-xlll project-avatar mb-3">
              <img
                className="img-fluid"
                src={
                  builderProfile !== undefined
                    ? projectDetails.builder_profile.profile_image
                    : ""
                }
                alt=""
              />
            </div>
            <div className="agent-info">
              <h5 className="mb-0">
                {" "}
                <a href="#">
                  {builderProfile !== undefined
                    ? projectDetails.builder_profile.name
                    : ""}
                </a>
              </h5>
              {/* <span className="text-primary font-sm d-block mb-1">
                Company Agent
              </span> */}
              {/* <b className="property-number"><i className="fas fa-phone-volume mr-2" />(123) 345-6789</b> */}
            </div>
          </div>
        </div>
        {/* <a className="btn btn-dark btn-block" href="#">View listings</a>
                    <a className="btn btn-primary btn-block m-0" href="#">Request info</a> */}
      </div>
      <div className="widget">
        <div className={Statusmessage.class}>{Statusmessage.message}</div>
        <div className="widget-title">
          <h6>Contact info</h6>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              placeholder="Name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="error text-danger"> {formik.errors.name} </div>
            ) : null}
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Email"
              id="email"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="error text-danger"> {formik.errors.email} </div>
            ) : null}
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Phone Number"
              id="contact_number"
              name="contact_number"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.contact_number}
            />
            {formik.touched.contact_number && formik.errors.contact_number ? (
              <div className="error text-danger">
                {" "}
                {formik.errors.contact_number}{" "}
              </div>
            ) : null}
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              rows={4}
              placeholder="Message"
              id="message"
              name="message"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.message}
            />
            {formik.touched.message && formik.errors.message ? (
              <div className="error text-danger"> {formik.errors.message} </div>
            ) : null}
          </div>
          <button
            disabled={isLoading.disabled}
            type="submit"
            className="btn btn-primary btn-block"
          >
            Request info
            <span
              hidden={isLoading.disabled - 1}
              className="spinner-border spinner-border-sm ml-2"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
        </form>
      </div>
    </div>
  );
}

export default ProjectDetailContact;
