import { useEffect, useState } from "react";
import axios from "axios";
// import Swal from 'sweetalert2'

import { API_URL, BASE_URL, themeDefault, namesOfModes , jsonHeader, multiFormHeader} from '../Constants/global'

function useFetchData(url) {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // const [isAutherized, setIsAutherized] = UserContext(UserContext)

  useEffect(() => {
    // Swal.fire({
    //   html: 'Please wait...',
    //   allowEscapeKey: false,
    //   allowOutsideClick: false,
    //   width: '200px',
    //   didOpen: () => {
    //     // Swal.showLoading()
    //   }
    // });

   
    axios
      .get(url,{headers: jsonHeader})
      .then((response) => {
        console.log(response)
       
        setData(response.data);
        // Swal.close();
        // setIsAutherized(true);
      })
      .catch((err) => {
        if(err.response.status === 401){
          localStorage.setItem("token","");

          // Swal.fire({
          //   title: 'TechApp',
          //   text: "Token is expired, please login again.",
          //   icon: 'error',
          //   showCancelButton: true,
          //   confirmButtonColor: '#3085d6',
          //   cancelButtonColor: '#d33',
          //   confirmButtonText: 'Yes, delete it!'
          // }).then((result) => {
          //   if (result.isConfirmed) {
          //     window.location.href = "/login";
              
          //     Swal.close();
          //     return
          //   }
          // });
            return
         
        }
        setError(err);
        // setIsAutherized(false);
      })
      .finally(() => {
        // Swal.close();
        // setIsAutherized(false);
      });
  }, [url]);
  
  return { data, loading, error };

}





export default useFetchData;
