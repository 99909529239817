import React, { useState } from "react";
import ReactCodeInput from "react-code-input";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { setAuthUser } from "../../Redux/actions/authUser";
import PropTypes from "prop-types";
import {
  API_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  multiFormHeader,
  currentUserInformation,
  BASE_URL,
} from "../../Constants/global";

const Verify = ({ setAuthUser }) => {
  const verify_params = useParams();
  const [vcode, setVcode] = useState("");
  const [error, setError] = useState("");

  const handleChange = (value) => {
    setVcode(value);
  };

  const handleVerification = () => {
    if (vcode.length === 0 || vcode.length < 6) {
      setError("Invalid Code.");
    } else {
      axios({
        method: "GET",
        url: API_URL + "api/verify_user/" + verify_params.id + "/" + vcode,

        headers: multiFormHeader,
      }).then((response) => {
        if (response.data.status === 200) {
          window.location.href = "/";
          //   ----------------------------------------------------
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("user", response.data.data);
          localStorage.setItem("user_type", response.data?.data.user_type);
          localStorage.setItem(
            "total_favorites",
            response.data.total_favorites
          );
          if (response.data?.data.user_type === "Agent") {
            localStorage.setItem(
              "agency_profile_id",
              response.data?.data.agency_profile.id
            );
          }

          setAuthUser();

          // -----------------------------------------------------
          console.log(response.data);
        } else {
          setError(response.data.message);
        }
      });
    }
  };

  return (
    <>
      <div className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <a href="/">
                    {" "}
                    <i className="fas fa-home" />
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <i className="fas fa-chevron-right" />{" "}
                  <a href="#">Register</a>
                </li>
                <li className="breadcrumb-item active">
                  <i className="fas fa-chevron-right" />{" "}
                  <span> Verification</span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {/*=================================
Breadcrumb */}

      {/* ------------------ Verification Start--------------- */}
      <section class="verification-section space-ptb">
        <div className="container">
          <div class="row justify-content-center align-content-center align-items-center ">
            <div className="col-md-4 col-sm-12 text-center order-2 order-md-1 text-center">
              <div className="verify-input-main">
                <ReactCodeInput
                  name="verify-user"
                  inputMode="text"
                  fields={6}
                  type="text"
                  onChange={(value, idex) => {
                    handleChange(value);
                  }}
                />
                <span style={{ fontWeight: "bold", color: "red" }}>
                  {error}
                </span>
              </div>

              <div className="verify-btn-main mt-4">
                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  onClick={handleVerification}
                >
                  Verify
                </button>
              </div>
            </div>

            <div className="col-md-8 col-sm-12 text-center order-1 order-md-2">
              <video
                autoPlay
                loop
                muted
                playsInline
                style={{ width: "500px" }}
                className="img-fluid otp-screen"
              >
                <source
                  src="https://cdn.jagahonline.com/assets-dev/frontend-assets/images/otp.webm"
                  type="video/webm"
                />
                <source
                  src="https://cdn.jagahonline.com/assets-dev/frontend-assets/images/otp.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </section>
      {/* ------------------ Verification End--------------- */}
    </>
  );
};
Verify.propTypes = {
  // user_name: PropTypes.object.isRequired,
  setAuthUser: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  // user_name: state.user_profile,
});
export default connect(mapStateToProps, { setAuthUser })(Verify);
