import React, {useEffect, useState} from 'react';
// import Swiper core and required modules
import { Navigation, Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import axios from 'axios'

import {useParams} from 'react-router-dom'

import {API_URL, jsonHeader,} from '../../../Constants/global'


function ProjectDetailSliderMob() {

  const project_params = useParams()
  console.log(project_params.searchquery.split('-').pop())
  const project_id = project_params.searchquery.split('-').pop()
  const [imagesData, setimagesData] = useState([])

  useEffect(() => {


    async function fetchProjectImages() {
      axios({
        method: 'GET',
        url: API_URL + 'api/project_images_byid/' + project_id,

        headers: jsonHeader,
      }).then((response) => {
        console.log(response.data)
        setimagesData(response.data?.data[0]["additional_images"])

      })
    }

    fetchProjectImages()
  }, [])



  return (
      <>
        {/* ------------------- Property Detail Slider -------------------*/}
        <div className="col-md-12">
          <div className="property-detail-gallery overflow-hidden">


            <Swiper
                // install Swiper modules
                modules={[Navigation, Pagination]}
                spaceBetween={0}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }} className="property-slider-main "
            >
              {imagesData.map((item) => {
                if (item.image_type === 1) {
                  const imageURL =
                      item.image_url
                  return (
                      <SwiperSlide>
                        <img className="img-fluid" src={imageURL}
                             alt={imageURL}/>
                      </SwiperSlide>
                  )
                }
              })}
            </Swiper>

          </div>
        </div>
      </>
  );

}

export default ProjectDetailSliderMob;