import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import ListingBannerVertical from '../Banners/ListingBannerVertical'
import axios from 'axios'
import Pagination from 'react-js-pagination'

import { API_URL, jsonHeader } from '../../../Constants/global'

import DOMPurify from 'dompurify'

import 'bootstrap-less'
import PropertyListingColumn from './PropertyListingColumn'

import SEO from '../../Seo'

const PropertiesListing = ({ prop_params }) => {
  const sortbyArray = [
    { label: 'Default', value: 0 },
    { label: 'Price low to high', value: 1 },
    { label: 'Price high to low', value: 2 }
  ]
  const [sortby, setsortby] = useState(0)
  const [propertyData, setPropertyData] = useState([])
  const [total_records, settotal_records] = useState(0)
  const [pageNumber, setpageNumber] = useState(0)
  const [recordNotfound, setRecordNotFound] = useState('')

  const [isLoading, setisLoading] = useState(false)

  const [searchContent, setSearchContent] = useState('')

  var searchQueryGet = {}

  if (
    localStorage.getItem('searchQuery') !== undefined &&
    localStorage.getItem('searchQuery') !== ''
  ) {
    searchQueryGet = JSON.parse(localStorage.getItem('searchQuery')) || {
      property_purpose: 0,
      city_id: 0,
      area_id: 0,
      property_type: 0,
      min_area: 0,
      max_area: 0,
      price_min: 0,
      price_max: 0,
      beds: 0,
      keywords: '',
      sortby: 0
    }
  } else {
    searchQueryGet = {
      property_purpose: 0,
      city_id: 0,
      area_id: 0,
      property_type: 0,
      min_area: 0,
      max_area: 0,
      price_min: 0,
      price_max: 0,
      beds: 0,
      keywords: '',
      sortby: 0
    }
  }

  console.log(searchQueryGet)

  const property_thumbnail =
    'https://cdn.jagahonline.com/properties/275906/front_image-61b98b34e402a-thumb.jpeg'

  // console.log('Property Params' + prop_params.property_type)

  const user_id = localStorage.getItem('user_id')
    ? localStorage.getItem('user_id')
    : 0

  const data_m = {
    pageNumber: pageNumber,
    nPerPage: 10,
    isActive: 1,
    isDeleted: 0,
    showAll: false,
    id: 0,
    city_id: searchQueryGet.city_id,
    area_id: searchQueryGet.area_id,
    property_purpose: searchQueryGet.property_purpose,
    property_type: searchQueryGet.property_type,
    min_area: searchQueryGet.min_area,
    max_area: searchQueryGet.max_area,
    price_min: searchQueryGet.price_min,
    price_max: searchQueryGet.price_max,
    beds: searchQueryGet.beds,
    keywords: searchQueryGet.keywords,
    user_id: user_id,
    sortby: sortby
  }

  console.log('user_id : ' + localStorage.getItem('user_id'))

  useEffect(() => {
    function fetchProperties () {
      setisLoading(true)
      axios({
        method: 'POST',
        url: API_URL + 'api/properties_search',
        data: data_m,
        headers: jsonHeader
      }).then(response => {
        if (response.status === 200) {
          if (response.data.total_records === 0) {
            setisLoading(false)
            setRecordNotFound('Record not found. Try again')
          } else {
            settotal_records(response.data.total_records)
            // console.log('total records ' + response.data.total_records)
            setPropertyData(response.data?.data)
            setisLoading(false)
          }
        } else {
          setisLoading(false)
          setRecordNotFound(
            'Something went wrong with the requested data. Try again'
          )
        }
      })
    }

    fetchProperties()

    function fetchSEOByURL () {
      setisLoading(true)
      axios({
        method: 'POST',
        url: API_URL + 'api/get_seo_by_URL',
        data: { qurl: window.location.pathname.toLowerCase() },
        headers: jsonHeader
      }).then(response => {
        if (response.status === 200) {
          const result_data = response.data?.data
          localStorage.setItem('qsearch_seo_title', result_data['seo_title'])
          localStorage.setItem(
            'qsearch_seo_desc',
            result_data['seo_description']
          )
          localStorage.setItem('searchContent', result_data['listing_content'])
          localStorage.setItem('seo_keywords', result_data['seo_keywords'])
          setSearchContent(result_data['listing_content'])
          console.log(response.data?.data)
        } else {
          localStorage.setItem(
            'qsearch_seo_title',
            'Pakistan Best Property Portal – Sell, Buy, Rent Properties & Homes - JagahOnline.com'
          )
          localStorage.setItem(
            'qsearch_seo_desc',
            'Pakistan Best Property Portal – Sell, Buy, Rent Properties & Homes'
          )
          localStorage.setItem('searchContent', '')
          setSearchContent('')
          localStorage.setItem('seo_keywords', 'sale houses, rent properties')
          setisLoading(false)
          setRecordNotFound(
            'Something went wrong with the requested data. Try again'
          )
        }
      })
    }

    if (window.location.pathname !== '/') {
      fetchSEOByURL()
    }
  }, [pageNumber, sortby])

  function handlePageChange (pageNumber) {
    console.log(`active page is ${pageNumber}`)
    setpageNumber(pageNumber)
    this.setState({ activePage: pageNumber })
  }

  const resetSeach = () => {
    localStorage.setItem('searchQuery', '')
    window.location.reload()
  }

  const onSortChange = value => {
    // setSearchQuery({ ...searchQuery, sortby: value.value });
    setsortby(value.value)
    // localStorage.setItem("searchQueryProject", JSON.stringify(searchQuery));
    // console.log(searchQuery);

    // window.location = window.location.href
  }

  return (
    <>
      <section class='space-ptb'>
        <div class='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='section-title mb-3 mb-lg-4'>
                {isLoading ? (
                  ''
                ) : (
                  <h2>
                    <span className='text-primary'>{total_records}</span>{' '}
                    Results
                  </h2>
                )}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='property-filter-tag'>
                <ul className='list-unstyled'>
                  <li>
                    <a
                      className='filter-clear'
                      href='javascript:void(0);'
                      onClick={resetSeach}
                    >
                      Reset Search <i className='fas fa-redo-alt' />{' '}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col-lg-8'>
              <div className='property-filter d-sm-flex'>
                <ul className='property-short list-unstyled d-sm-flex mb-0'>
                  <li>
                    <form className='form-inline'>
                      <div className='form-group d-lg-flex d-block'>
                        <label className='justify-content-start'>
                          Sort by:
                        </label>
                        <div className='short-by'>
                          <Select
                            className='form-control'
                            //   isLoading={isloading}
                            options={sortbyArray}
                            styles={{
                              // Fixes the overlapping problem of the component
                              menu: provided => ({
                                ...provided,
                                zIndex: 9999
                              })
                            }}
                            isMulti={false}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            onChange={onSortChange}
                            value={sortbyArray.find(
                              c => c.value === parseInt(sortby)
                            )}
                          />
                        </div>
                      </div>
                    </form>
                  </li>
                </ul>
              </div>
              {/*Property Column Listing*/}
              <div className='row my-4 justify-content-center'>
                {isLoading ? (
                  <div className='col-md-6 text-center'>
                    <box className='shine property-box-shimmer'></box>

                    <div>
                      <lines className='my-1 shine property-box-shimmerLines d-block'></lines>
                      <lines className='shine property-box-shimmerLines d-block'></lines>
                      <lines className='shine property-box-shimmerLines d-block'></lines>
                      <lines className='my-1 shine property-box-shimmerLines d-block'></lines>
                    </div>
                  </div>
                ) : recordNotfound ? (
                  <div> {recordNotfound} </div>
                ) : (
                  <PropertyListingColumn propertyData={propertyData} />
                )}
              </div>
              {/*Property Column Listing*/}
              <div className='col-md-12'>
                <Pagination
                  activePage={pageNumber}
                  itemsCountPerPage={10}
                  totalItemsCount={total_records}
                  pageRangeDisplayed={3}
                  onChange={handlePageChange}
                  activeLinkClass='active'
                  innerClass='pagination'
                  itemClass='page-item'
                  linkClass='page-link'
                />
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: searchContent ? searchContent : ''
                }}
              ></div>
              <SEO
                title={localStorage.getItem('qsearch_seo_title')}
                description={localStorage.getItem('qsearch_seo_desc')}
                name='JagahOnline'
                type='properties'
                url={window.location.href}
                image_url=''
                keywords={localStorage.getItem('seo_keywords')}
              />
            </div>

            <ListingBannerVertical />
          </div>
        </div>
      </section>

      {/*Banner Modal*/}
      {/*<div className="banner-modal modal fade" id="myModal">*/}
      {/*  <div className="modal-dialog modal-lg modal-dialog-centered" role="document">*/}
      {/*    <div className="modal-content">*/}
      {/*      <div className="modal-body p-0">*/}
      {/*        <button type="button" className="close modal-banner-close" data-dismiss="modal" aria-label="Close">*/}
      {/*          <span aria-hidden="true">×</span>*/}
      {/*        </button>*/}
      {/*        <img className="modal-banner-width w-100 img-fluid" src="https://jocdn.sfo3.cdn.digitaloceanspaces.com/jo-classified/properties/276328/3fe58ae0-1561-11ed-9f7f-0242ac130002.jpg"/>*/}
      {/*      </div>*/}

      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*Banner Modal*/}
    </>
  )
}

export default PropertiesListing
