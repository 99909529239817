import React from "react";
import {BASE_URL} from "../../Constants/global";

export default function PaymentFooter() {
    return (
        <section className="payment-section py-3">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="text-center my-1 d-md-flex align-items-md-center justify-content-md-between">
                            <div className="payment-method-inner">
                                <div>Safe and Secure Payment Methods</div>
                            </div>
                            <div className="payment-method-inner">
                                <img
                                    className="img-fluid"
                                    alt="jagah online"
                                    src={BASE_URL + "classified_assets/images/payment/cod-icon.png"}
                                />
                                <img
                                    className="img-fluid"
                                    alt="jagah online"
                                    src={BASE_URL + "classified_assets/images/payment/easypaisa-icon.png"}
                                />
                                <img
                                    className="img-fluid"
                                    alt="jagah online"
                                    src={BASE_URL + "classified_assets/images/payment/jazzcash-icon.png"}
                                />
                                <img
                                    className="img-fluid"
                                    alt="jagah online"
                                    src={BASE_URL + "classified_assets/images/payment/mastercard-icon.png"}
                                />
                                <img
                                    className="img-fluid"
                                    alt="jagah online"
                                    src={BASE_URL + "classified_assets/images/payment/visa-icon.png"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
