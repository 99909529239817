import React, { useState, useEffect } from "react";
import axios from "axios";
import { multiFormHeader1 } from "../../../Constants/global";
import { connect } from "react-redux";
import { handleFavorite } from "../../../Redux/actions/favorite";
import { setAuthUser } from "../../../Redux/actions/authUser";
import PropTypes from "prop-types";
import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  currentUserInformation,
  multiFormHeader,
  USER_IMAGE_NOT_FOUND_URL,
} from "../../../Constants/global";
const UserProfileHeader = ({ authUser }) => {
  const user_id = localStorage.getItem("user_id")
    ? localStorage.getItem("user_id")
    : 0;
  return (
    <section className="space-pt">
      <div className="container">
        <div className="row">
          <div className="col-12 mb-3">
            <div
              className="profile-sidebar bg-holder bg-overlay-black-70"
              style={{
                backgroundImage:
                  "url(https://cdn.jagahonline.com/assets/frontend-assets/images/banner-01.jpg)",
              }}
            >
              <div className="d-sm-flex align-items-center position-relative">
                <div className="profile-avatar position-relative">
                  <img
                    className="img-fluid my-profile-user-img rounded-circle"
                    src={
                      authUser.profile_image !== undefined &&
                      authUser.profile_image !== ""
                        ? authUser.profile_image
                        : USER_IMAGE_NOT_FOUND_URL
                    }
                    id="front-img-preview"
                    alt="Pakistan Best Property Portal – Find Real Estate Sell, Buy, Rent Properties & Homes"
                    onError="if (this.src != 'https://cdn.jagahonline.com/default-user.jpg') this.src = 'https://cdn.jagahonline.com/default-user.jpg';"
                    loading="lazy"
                  />
                  {/*<i*/}
                  {/*  className="fas fa-camera rounded-circle edit-btn-icon browse"*/}
                  {/*  title="Choose file"*/}
                  {/*/>*/}
                </div>
                <div className="ml-sm-4">
                  <h4 className="text-white">{authUser.user_name}</h4>
                  <b className="text-white">{localStorage.getItem("email")}</b>
                </div>
                <div className="top-bar-list-btn dropdown d-inline-block pl-0 mt-3 mt-md-0 pl-md-0 top-bar-list-prop-div ml-auto">
                  <a className="btn btn-white btn-md" href="/property-add">
                    <i className="fas fa-plus-circle" /> Add Property
                  </a>
                </div>
              </div>
              <div className="profile-nav profile-new-v2 mt-4">
                <ul className="nav">
                  <li className="nav-item profile-nav-item ustad-list-menu-btn book-now-hman-inner position-relative">
                    <a className="nav-link" href="javascript:void(0)">
                      <i className="far fa-user" />
                      My profile
                      <i className="fas fa-chevron-down w-auto pl-1" />
                    </a>
                    <ul className="ustad-list-menu-btn-box">
                      <li title>
                        <a className="nav-link active" href="/profile">
                          <i className="far fa-user" />
                          My profile
                        </a>
                        <a className="nav-link " href="change-password">
                          <i className="far fa-edit" />
                          Change Password
                        </a>
                      </li>
                    </ul>
                  </li>

                  {parseInt(authUser.role_id) === 3 ? (
                    <li className="nav-item profile-nav-item ustad-list-menu-btn book-now-hman-inner position-relative">
                      <a className="nav-link" href="/company-profile">
                        <i className="far fa-user" />
                        Company Profile
                        <i className="fas fa-chevron-down w-auto pl-1" />
                      </a>
                    </li>
                  ) : (
                    ""
                  )}

                  <li className="nav-item profile-nav-item  ustad-list-menu-btn book-now-hman-inner position-relative">
                    <a className="nav-link" href="javascript:void(0)">
                      <i className="far fa-bell" />
                      My Properties
                      <i className="fas fa-chevron-down w-auto pl-1" />
                    </a>
                    <ul className="ustad-list-menu-btn-box">
                      <li title>
                        <a className="nav-link  " href="./my-properties">
                          <i className="far fa-bell" />
                          My Properties
                        </a>
                      </li>
                      <li title>
                        <a className="nav-link  " href="./my-favorites">
                          <i className="far fa-heart" />
                          My Favorites
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

UserProfileHeader.propTypes = {
  authUser: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  authUser: state.authUser,
});
export default connect(mapStateToProps, { setAuthUser })(UserProfileHeader);
