import React, {Component} from 'react'
import {BASE_URL,} from '../../../Constants/global'
import {BrowserView, MobileView} from "react-device-detect";

import {Link} from 'react-router-dom';


class Navbar extends Component {
    render() {
        return (
            <>
                <nav className="navbar navbar-dark navbar-static-top navbar-expand-lg header-sticky">
                    <div className="container-fluid">
                        <button
                            type="button"
                            className="navbar-toggler"
                            data-toggle="collapse"
                            data-target=".navbar-collapse"
                        >
                            <i className="fas fa-align-left"/>
                        </button>
                        <a className="navbar-brand" href="/">
                            <BrowserView>
                                <img
                                    className="img-fluid no-sticky-logo"
                                    src={BASE_URL + "classified_assets/images/logo-light.svg"}
                                    alt="logo"
                                />
                                <img
                                    className="img-fluid is-sticky-logo"
                                    src={BASE_URL + "classified_assets/images/logo.svg"}
                                    alt="logo"
                                />
                            </BrowserView>
                            <MobileView>
                                <img
                                    className="img-fluid"
                                    src={BASE_URL + "classified_assets/images/logo.svg"}
                                    alt="logo"
                                />
                            </MobileView>
                        </a>
                        <div className="navbar-collapse collapse justify-content-center">
                            <ul className="nav navbar-nav">
                                <li className="nav-item active">
                                    <Link to="/" className="nav-link">Home</Link>

                                </li>

                                <li className="nav-item">
                                    <Link to="/properties" className="nav-link">Properties</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/projects" className="nav-link">Projects</Link>
                                </li>

                                <li className="nav-item">
                                    <a href="/blog/" className="nav-link" target={"_blank"}>Blog</a>
                                </li>

                                <li className="nav-item">
                                    <a href="/360-jagah-tours" className="nav-link">Jagah360</a>
                                </li>

                                <li className="nav-item">
                                    <a href="/sell-rent-for-me" className="nav-link">Sell & Rent For Me</a>

                                </li>
                            </ul>
                        </div>
                        <div className="add-listing d-none d-sm-block">
                            {localStorage.getItem("token") !== '' ?
                                <a className="btn btn-primary btn-md pulse-button" href="/property-add">

                                    <i className="fa fa-plus-circle"/>
                                    list Property{' '}
                                </a>

                                :
                                <a className="btn btn-primary btn-md pulse-button" href="/login">

                                    <i className="fa fa-plus-circle"/>
                                    list Property{' '}
                                </a>


                            }

                        </div>
                    </div>
                </nav>
            </>
        )
    }
}

export default Navbar
