import React from 'react'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'

export const PropertyCard = ({
  data,
  key,
  imageURL,
  property_thumbnail,
  profile_image_url,
  property_detail_URL,
  bed,
  bath,
  areaUnit,
  add_to_favorite
}) => {
  //   const [
  //     key,
  //     imageURL,
  //     property_thumbnail,
  //     data,
  //     profile_image_url,
  //     property_detail_URL,
  //     bed,
  //     bath,
  //     areaUnit,
  //     add_to_favorite,
  //   ] = propertyData;
  return (
    <div key={key} className='col-sm-6'>
      <div className='pp-main'>
        <div className='property-item-listing property-item'>
          <Link to={property_detail_URL}>
            <div className='property-image bg-overlay-gradient-04'>
              <img
                className='img-fluid property-image-main'
                src={imageURL}
                onError={property_thumbnail}
                alt=''
                width={350}
                height={230}
                maxWidth={350}
                maxHeight={230}
                minWidth={350}
                minheight={230}
                loading='lazy'
              />
              <div className='property-lable'>
                <span className='badge badge-md badge-primary'>
                  {data.property_purpose_data.length > 0
                    ? data.property_purpose_data[0].title
                    : ''}
                </span>
                <span className='badge badge-md badge-info'>
                  {data['city']}
                </span>
              </div>
              <div className='property-agent'>
                <div className='property-agent-image'>
                  <img
                    className='img-fluid'
                    src={profile_image_url}
                    key={profile_image_url}
                    minWidth={50}
                    maxHeight={50}
                    alt=''
                    loading='lazy'
                  />
                </div>
              </div>
            </div>
          </Link>

          <div className='property-details'>
            <Link to={property_detail_URL}>
              <div className='property-details-inner'>
                <h5 className='property-title'>
                  <a href={property_detail_URL}>{data.title}</a>
                </h5>
                <span className='property-address'>
                  <i className='fas fa-map-marker-alt fa-xs'></i>
                  {data.address}
                </span>

                <div className='property-price'>
                  <span className='font-sm text-primary font-weight-bolder'>
                    {data.currency}
                  </span>{' '}
                  {data.price_txt ? data.price_txt : data.price.toString()}
                </div>
                <ul className='property-info list-unstyled d-flex'>
                  <li className='flex-fill property-bed'>
                    <i className='fas fa-bed'></i>Bed
                    <span>{data.beds}</span>
                  </li>
                  <li className='flex-fill property-bath'>
                    <i className='fas fa-bath'></i>Bath
                    <span>{data.baths}</span>
                  </li>
                  <li className='flex-fill property-m-sqft'>
                    <i className='far fa-square'></i>
                    {data.land_area === 0 ? '' : data.area_unit_txt}
                    <span>{data.land_area}</span>
                  </li>
                </ul>
              </div>
            </Link>
            <div className='property-btn'>
              <a className='property-link' href={property_detail_URL}>
                See Details
              </a>
              <ul className='property-listing-actions list-unstyled mb-0'>
                <li className='property-favourites'>
                  {localStorage.getItem('token') !== '' ? (
                    <a href='javascript:void(0);' title='Favourite'>
                      <i
                        id={data.id}
                        key={data.id}
                        onClick={add_to_favorite}
                        className={
                          data.isFavorite === 0 ? 'far fa-heart' : 'fa fa-heart'
                        }
                        name='favorite'
                      ></i>
                    </a>
                  ) : (
                    <a
                      data-toggle='modal'
                      data-target='#loginModal'
                      href='javascript:void(0);'
                      title='Favourite'
                    >
                      <i className='far fa-heart'></i>
                    </a>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
