import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';

import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  currentUserInformation,
  multiFormHeader,
  multiFormHeader2,
} from '../../../../Constants/global';

const MyOrderTabs = () => {
  const [orderData, setOrderData] = useState([]);
  const [orderData_, setOrderData_] = useState([]);
  const [dateString, setDateString] = useState('');
  const [search, setSearch] = useState('');

  const handleChange = (event) => {
    const temp_arr = [];
    setSearch(event.target.value);
    // console.log(event.target.value);
    orderData.map((item) => {
      if (
        item.category.toLowerCase().match(event.target.value.toLowerCase()) ||
        item.id.toString().match(event.target.value)
      ) {
        temp_arr.push(item);
      }
      setOrderData_(temp_arr);
    });
  };
  useEffect(() => {
    var date = new Date();
    const dateStr = date.toDateString().split(' ');
    var dateStrFormat = dateStr[2] + ' ' + dateStr[1];
    setDateString(dateStrFormat);

    axios({
      method: 'GET',
      url: API_URL + 'api/handymen_myorders/' + localStorage.getItem('user_id'),
      headers: multiFormHeader,
    }).then((response) => {
      if (response.data.status === 200) {
        console.log(response.data.data);
        setOrderData(response.data.data);
        setOrderData_(response.data.data);
      } else {
        console.log('cart data fetch fail');
      }
    });
  }, []);
  return (
    <Fragment>
      <div className='my-order-tabs-main mb-5'>
        <ul
          className='nav nav-tabs nav-tabs-02 my-order-tabs justify-content-between text-center'
          id='myTab'
          role='tablist'
        >
          <li className='nav-item my-order-item w-50'>
            <a
              className='nav-link active my-order-link'
              id='tab-01-tab'
              data-toggle='tab'
              href='#tab-01'
              role='tab'
              aria-controls='tab-01'
              aria-selected='true'
            >
              Active Orders
            </a>
          </li>
          <li className='nav-item my-order-item w-50'>
            <a
              className='nav-link my-order-link'
              id='tab-02-tab'
              data-toggle='tab'
              href='#tab-02'
              role='tab'
              aria-controls='tab-02'
              aria-selected='false'
            >
              Order History
            </a>
          </li>
        </ul>
        <div className='tab-content my-order-tab-content' id='myTabContent'>
          <div
            className='my-order-tab-pane tab-pane fade show active p-4'
            id='tab-01'
            role='tabpanel'
            aria-labelledby='tab-01-tab'
          >
            <div className='active-order mb-3'>
              <div className='heading-active-order'>Active Orders</div>
              <div className='date-order'>
                <i className='fas fa-calendar-day pr-2'></i>
                <span>{dateString}</span>
              </div>
            </div>
            <div className='search-order position-relative'>
              <input
                className='form-control'
                type='text'
                placeholder='Search'
                onChange={handleChange}
                value={search}
              />
              <i className='fas fa-search'></i>
            </div>
            <div className='row'>
              {/* --------------------------------- */}
              {orderData_.map((order, index) => {
                // if (index === 0) {
                //   order.order_status = 3;
                // }

                const service_date = order.order_date.split('T');
                let s_time = service_date[1];
                let s_date = service_date[0];

                // s_time = s_time.substring(0, s_time.lastIndexOf(':'));
                function formatTime(s_time) {
                  const [hourString, minute] = s_time.split(':');
                  const hour = +hourString % 24;
                  return (
                    (hour % 12 || 12) +
                    ':' +
                    minute +
                    (hour < 12 ? ' AM' : ' PM')
                  );
                }
                if (order.order_status !== 0) {
                  return (
                    <div className='col-md-4'>
                      <Link
                        to={`/my-orders/${order.id}`}
                        state={{
                          service: order,
                          service_date: service_date,
                          address: order.address,
                        }}
                        className={'w-100'}
                      >
                        <div className='order-service-main mt-4'>
                          <div className='order-service-name'>
                            {order.category ? order.category : ''}
                          </div>
                          <div class='order-service-detail'>
                            <div className='order-service-detail-left'>
                              Delivered
                            </div>
                            <div className='order-service-detail-right'>
                              <span className='order-date-span'>
                                {s_date.replaceAll('-', '/') +
                                  ' ' +
                                  formatTime(s_time)}
                              </span>
                              {/* <span className="order-time-span">3:00 PM</span> */}
                            </div>
                          </div>
                          <div className='order-service-detail'>
                            <div className='order-service-detail-left'>
                              Order Number
                            </div>
                            <div className='order-service-detail-right'>
                              <span className='order-mode-span'>
                                {order.id}
                              </span>
                            </div>
                          </div>
                          <div className='order-service-detail mb-0'>
                            <div className='order-service-detail-left'>
                              Status
                            </div>
                            <div className='order-service-detail-right'>
                              <span className='order-active-span'>
                                {order.order_status === 1 ? 'Pending' : ''}
                                {order.order_status === 2 ? 'Confirm' : ''}
                                {order.order_status === 3 ? 'In-Process' : ''}
                                {order.order_status === 4 ? 'Ir-Relevant' : ''}
                                {order.order_status === 5
                                  ? 'Not Answering'
                                  : ''}
                                {order.order_status === 6 ? 'Out of City' : ''}
                                {order.order_status === 7
                                  ? 'Required in Future'
                                  : ''}
                                {order.order_status === 8 ? 'Cancel' : ''}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                }
              })}

              {/* --------------------------------- */}
            </div>
          </div>
          <div
            className='my-order-tab-pane tab-pane fade p-4'
            id='tab-02'
            role='tabpanel'
            aria-labelledby='tab-02-tab'
          >
            <div className='active-order mb-3'>
              <div className='heading-active-order'>Previous Orders</div>
              <div className='date-order'>
                <i className='fas fa-calendar-day pr-2'></i>
                <span>{dateString} </span>
              </div>
            </div>
            <div className='search-order position-relative'>
              <input
                className='form-control'
                type='text'
                onChange={handleChange}
                value={search}
                placeholder='Search'
              />
              <i className='fas fa-search'></i>
            </div>

            {/* <div className="order-service-main mt-4">
              <div className="order-service-name">Ac Reparing</div>
              <div className="order-service-detail">
                <div className="order-service-detail-left">Delivered</div>
                <div className="order-service-detail-right">
                  <span className="order-date-span">13/6/2022</span>
                  <span className="order-time-span">3:00 PM</span>
                </div>
              </div>
              <div className="order-service-detail">
                <div className="order-service-detail-left">Order Number</div>
                <div className="order-service-detail-right">
                  <span className="order-mode-span">HM-220613-00068</span>
                </div>
              </div>
              <div className="order-service-detail mb-0">
                <div className="order-service-detail-left">Status</div>
                <div className="order-service-detail-right">
                  <span className="order-active-span">Active</span>
                </div>
              </div>
            </div> */}
            <div className='row'>
              {orderData_.map((order, index) => {
                // if (index === 0) {
                //   order.order_status = 3;
                // }

                const service_date = order.order_date.split('T');
                let s_time = service_date[1];
                let s_date = service_date[0];

                // s_time = s_time.substring(0, s_time.lastIndexOf(':'));
                function formatTime(s_time) {
                  const [hourString, minute] = s_time.split(':');
                  const hour = +hourString % 24;
                  return (
                    (hour % 12 || 12) +
                    ':' +
                    minute +
                    (hour < 12 ? ' AM' : ' PM')
                  );
                }
                if (order.order_status === 0) {
                  return (
                    <div className='col-md-4'>
                      <Link
                        to={`/my-orders/${order.id}`}
                        state={{
                          service: order,
                          service_date: service_date,
                          address: order.address,
                        }}
                        className={'w-100'}
                      >
                        <div className='order-service-main mt-4'>
                          <div className='order-service-name'>
                            {order.category ? order.category : ''}
                          </div>
                          <div class='order-service-detail'>
                            <div className='order-service-detail-left'>
                              Delivered
                            </div>
                            <div className='order-service-detail-right'>
                              <span className='order-date-span'>
                                {s_date.replaceAll('-', '/') +
                                  ' ' +
                                  formatTime(s_time)}
                              </span>
                              {/* <span className="order-time-span">3:00 PM</span> */}
                            </div>
                          </div>
                          <div className='order-service-detail'>
                            <div className='order-service-detail-left'>
                              Order Number
                            </div>
                            <div className='order-service-detail-right'>
                              <span className='order-mode-span'>
                                {order.id}
                              </span>
                            </div>
                          </div>
                          <div className='order-service-detail mb-0'>
                            <div className='order-service-detail-left'>
                              Status
                            </div>
                            <div className='order-service-detail-right'>
                              <span className='order-active-span'>
                                {order.order_status === 0 ? 'Cancelled' : ''}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MyOrderTabs;
