import React from 'react'

import PropertySearshbar from '../../../Components/Header/joclassified/PropertySearchbar'
import PropertyDetails from '../../../Components/ClassifiedComponents/Properties/PropertyDetails'
import { useParams, Link } from 'react-router-dom'

function Property() {
  const property_params = useParams()
  console.log(property_params)

  return (
    <>
      <PropertySearshbar />
      <PropertyDetails />
    </>
  )
}

export default Property
