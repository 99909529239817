import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import services from '../../../../Redux/reducers/services';
import { getServices } from '../../../../Redux/actions/services';
import { useFormik, yupToFormErrors } from 'formik';
import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  currentUserInformation,
  multiFormHeader,
} from '../../../../Constants/global';

const HomeBookNow = ({ getServices, services }) => {
  function formatDate(date, skip) {
    var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    var date = new Date(date - tzoffset).toISOString().slice(0, -1);
    //remove sec.mili_sec
    const segments = date.split(':');
    return segments.slice(0, segments.length - skip).join(':');
  }
  const [inputError, setInputError] = useState('');
  const [isLoading, setIsLoading] = useState({
    isLoading: false,
    disabled: false,
  });
  const [cartArray, setCartArray] = useState([
    { qty: '', price: '', title: '' },
    { qty: '', price: '', title: '' },
  ]);
  const [serviceArray, setServiceArray] = useState([]);
  const [selectedCity, setSelectedCity] = useState(0);

  // ---------------------------------------

  var [all_services, set_all_services] = useState([]);
  const initialValues = {
    full_name: '',
    phone_number: '',
    city: 0,
    services: '',
  };
  const [Statusmessage, setMessage] = useState({
    message: '',
    class: '',
    iconClass: '',
  });
  function setSuccessMessage(msg) {
    setMessage({
      message: msg,
      class: 'alert alert-success contactUsAlert mt-3',
      iconClass: 'fa fa-close float-right',
    });
  }
  function removeMessage(msg) {
    setMessage({
      message: '',
      class: '',
    });
  }

  function setErrorMessage(msg) {
    setMessage({
      message: msg,
      class: 'alert alert-danger contactUsAlert mt-3',
      iconClass: 'fa fa-close float-right',
    });
  }

  useEffect(() => {
    async function fetchSearchBy() {
      const response = await axios({
        method: 'GET',
        url: API_URL + 'api/searchby',
      });
      if (response.status === 200) {
        const cities = response.data?.data[2]['cities'];
        const nArray2 = [];
        cities.map((item) => {
          let roleData = {};
          if (
            item.id === 14 ||
            item.id === 3 ||
            item.id === 12 ||
            item.id === 62
          ) {
            roleData.value = item.id;
            roleData.label = item.city;
            nArray2.push(roleData);
          }
        });
        setCities(nArray2);
      }
    }
    fetchSearchBy();
  }, []);
  const [cities, setCities] = useState([]);

  const [dropDownValues, setDropDownValues] = useState({
    city_id: 0,
  });

  const handleSubmit = (values, { resetForm }) => {
    if (
      values.services !== undefined &&
      values.services !== 0 &&
      values.city !== 0 &&
      inputError === ''
    ) {
      setIsLoading({
        isLoading: true,
        disabled: true,
      });
      console.log(values);
      const selectedService = values.services.split('+');

      var form = new FormData();

      form.append('id', 0);
      form.append('name', values.full_name);
      form.append('phone', values.phone_number);
      form.append('city_id', values.city);
      form.append('service_id', selectedService[0]);
      form.append('category_id', selectedService[1]);
      form.append('charges', selectedService[2]);
      form.append('order_date', formatDate(Date.now(), 1));
      form.append('zone_id', 0);
      form.append('area_id', 0);
      form.append('order_medium', 'web');
      form.append('service_address', '');
      form.append('response_date', '');
      form.append('service_date', '');
      form.append('agent_id', 0);
      form.append('follow_up1', '');
      form.append('follow_up2', '');
      form.append('follow_up3', '');
      form.append('remarks', '');
      form.append('order_status', 0);
      form.append('service_qty', 0);

      axios({
        method: 'POST',
        url: API_URL + 'api/handymen_inquires_add',
        data: form,
        headers: multiFormHeader,
      }).then((response) => {
        if (response.data.status === 200) {
          setIsLoading({
            isLoading: false,
            disabled: false,
          });
          setSuccessMessage(
            'Your request has been forwarded to the customer support team, who will contact you shortly. Thank you!'
          );

          setTimeout(removeMessage, 7000);
        } else {
          setIsLoading({
            isLoading: false,
            disabled: false,
          });
          console.log(response);

          setErrorMessage(response.data.message);
          setTimeout(removeMessage, 7000);
        }
      });
    } else {
      setInputError('Required');
    }
  };
  const phoneRegExp =
    /^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/;
  const validateSchema = Yup.object().shape({
    full_name: Yup.string().required('Required'),
    phone_number: Yup.string().matches(phoneRegExp, 'Invalid phone number'),
  });
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: validateSchema,
    enableReinitialize: true,
  });
  useEffect(() => {
    setInputError('Required');

    setSelectedCity(Number(formik.values.city));

    let tempArray = [];

    services.map((item, index) => {
      if (item.city_id === selectedCity) {
        item.service_childs.map((service) => {
          tempArray.push(service);
        });
      }
    });
    setServiceArray(tempArray);
  }, [formik.values.city, selectedCity]);
  useEffect(() => {
    if (formik.values.services === '0') {
      setInputError('Required');
    } else {
      setInputError('');
    }
  }, [formik.values.services]);

  return (
    <section className='book-now-section space-ptb'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='text-center mb-3'>
              <div className='section-title text-center'>
                <h3 className='mb-3 banner-bg-slider-title text-center'>
                  Book Now
                </h3>
                <span className='banner-bg-slider-sub-title text-center'>
                  Connect with JO Ustad. Get reliable, affordable and quick
                  Handyman Services!
                </span>
              </div>
            </div>
            <div className='wb-search-main ustad-landing-book-now property-search-field bg-white'>
              <div className='property-search-item'>
                <form
                  className='form-row basic-select-wrapper'
                  onSubmit={formik.handleSubmit}
                >
                  <div className='form-group col-lg col-md-3'>
                    <label>
                      City <span className='text-danger'>*</span>
                    </label>
                    <select
                      className='form-control border-0 '
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      name='city'
                    >
                      <option value={''}>{'Select City'}</option>
                      {cities.map((item, i) => {
                        return (
                          <option key={i} value={parseInt(item.value)}>
                            {item.label}
                          </option>
                        );
                      }, this)}
                    </select>
                  </div>
                  <div className='form-group col-lg col-md-3'>
                    <label>
                      Service <span className='text-danger'>*</span>
                    </label>
                    <select
                      className='form-control border-0 '
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      name='services'
                    >
                      {' '}
                      <option key={0} value={''}>
                        Select service
                      </option>
                      {services !== undefined ? (
                        serviceArray.map((item, index) => {
                          return (
                            <option
                              key={index + 1}
                              value={
                                item.id +
                                '+' +
                                item.category_id +
                                '+' +
                                item.current_charges
                              }
                            >
                              {item.title}
                            </option>
                          );
                        })
                      ) : (
                        <option key={0} value={''}>
                          Select Service
                        </option>
                      )}
                    </select>
                    {inputError ? (
                      <div className='error text-danger'> {inputError} </div>
                    ) : null}
                  </div>
                  <div className='form-group col-lg col-md-3'>
                    <label>
                      Full Name <span className='text-danger'>*</span>
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Buissness name'
                      name='full_name'
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.full_name}
                    ></input>
                    {formik.touched.full_name && formik.errors.full_name ? (
                      <div className='error text-danger'>
                        {' '}
                        {formik.errors.full_name}{' '}
                      </div>
                    ) : null}
                  </div>
                  <div className='form-group col-lg col-md-3'>
                    <label>
                      Phone Number <span className='text-danger'>*</span>
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Enter Your Phone No.'
                      name='phone_number'
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.phone_number}
                    ></input>
                    {formik.touched.phone_number &&
                    formik.errors.phone_number ? (
                      <div className='error text-danger'>
                        {' '}
                        {formik.errors.phone_number}{' '}
                      </div>
                    ) : null}
                  </div>

                  <div className='form-group col-lg col-md-3'>
                    <div className='btn-block btn-mobile'>
                      <button
                        disabled={isLoading.disabled}
                        type='submit'
                        className='btn btn-primary btn-block align-items-center'
                      >
                        Book Now
                        <span
                          hidden={isLoading.disabled - 1}
                          className='spinner-border spinner-border-sm ml-2'
                          role='status'
                          aria-hidden='true'
                        ></span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className={Statusmessage.class}>
          {Statusmessage.message}{' '}
          <i class={Statusmessage.iconClass} onClick={removeMessage}></i>
        </div>
      </div>
    </section>
  );
};
HomeBookNow.propTypes = {
  getServices: PropTypes.func.isRequired,
  services: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  services: state.services,
});
export default connect(mapStateToProps, { getServices })(HomeBookNow);
