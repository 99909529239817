import React from 'react'
import '../css/sellRent.css'

function SellRentForMe() {

  return (
      <>
        <main className="font-weight sellRentMain">
          <div id="PageContainer">
            <main className="pro-seller-main">
              <div className="top-header-heading text-center bg-white-f2 py-3">
                <h1 className="top-header-heading-inner">Sell &amp; Rent for Me</h1>
                <h6 className="mb-0">Banayen Apni Zindagi Asaan!</h6>
              </div>
            </main>
          </div>
          <section className="target-audience space-ptb">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-sm-12">
                  <div className="target-audience-headings text-center pb-0">
                    <h6 className="heading-target-audience tour-hed">We understand that the process of selling and
                      renting your
                      property can be tiring, <br/>but we can help you market your properties better using a
                      preapproved network of agents, <br/>and a team of dedicated relationship officers!
                    </h6>
                    <div className="text-center mt-4">
                      <a className="btn btn-shadow sellBtn" href="javascript:void(0);" id="sellBtn">Sell for Me</a>
                      <a className="btn btn-shadow rentBtn" href="javascript:void(0);" id="rentBtn">Rent for Me</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sellBox" id="sellBox" style={{display: 'none'}}>
                <div className="row mt-4">
                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <div className="target-audience-headings text-center">
                      <h2 className="sub-heading-target-audience">Khareedar dhundna ho raha hai mushkil?</h2>
                      <p>With our Sell for Me feature, you can hand your properties over to us, and we will help
                        you sell them faster than ever before!</p>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-md-6 col-lg-6 col-sm-12 position-relative">
                    <div className="variations-video-globe">
                      <picture>
                        <img src="classified_assets/images/sell-rent/sellforme_2.jpg" alt="sell and rent"
                             title="sell and rent" className="img-fluid w-100"/>
                      </picture>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-12">
                    <div className="digital-businesss-headings text-left">
                      <h2 className="sub-heading-target-audience">Client ki chik chik Karen Dur!
                      </h2>
                      <p>Qualified and detailed screened buyer to close the deal. </p>
                      {/* <a href="/sell-rent-for-me/sell-rent-register" class="btn target-audience-plans"
                     tabindex="0">Sell Now </a> */}
                    </div>
                    <form method="get" action="sell-rent-for-me/sell-rent-register">
                      <div className="pro-seller-head-first-head-cta row">
                        <div className="col-md-8">
                          <div className="phone-material">
                            <div className="form-group">
                              <input type="hidden" name="source" defaultValue="sell-for-me"/>
                              <div className="head-cta-input floating-label ">
                                <input type="tel" name="cell_number" onkeyup="notfilled()" id="cell_number"
                                       className="inquirynumber phone number floating-input sell-for-me "
                                       placeholder="Cell Number" minLength={1} maxLength={20} defaultValue required/>
                                <span id="error-msg" className="hide"/>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 no-padding-column">
                          <button className="btn btn-shadow cta-send">Sell Now</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <form method="get" action="sell-rent-for-me/sell-rent-register">
                  <input type="hidden" name="source" defaultValue="sell-for-me"/>
                  <div className="row mt-4 align-items-center">
                    <div className="col-md-6 col-lg-6 col-sm-12">
                      <div className="digital-businesss-headings text-left">
                        <h2 className="sub-heading-target-audience">Hum le Kar Ayen Behtareen Offers!
                        </h2>
                        <p>Don’t let your property sit around, without buyers. We can help you sell your properties
                          faster, and get you the best deals in the market!
                        </p>
                        {/* <a href="/sell-rent-for-me/sell-rent-register" class="btn target-audience-plans"
                      tabindex="0">Sell Faster </a> */}
                        <button className="btn btn-shadow cta-send cta-sr">Sell Faster</button>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12 position-relative">
                      <div className="variations-video-globe">
                        <picture>
                          <img src="classified_assets/images/sell-rent/sellforme_1.jpg" alt="sell and rent"
                               title="sell and rent" className="img-fluid w-100"/>
                        </picture>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4 align-items-center">
                    <div className="col-md-6 col-lg-6 col-sm-12 position-relative">
                      <div className="variations-video-globe">
                        <picture>
                          <img src="classified_assets/images/sell-rent/sellforme_3.jpg" alt="sell and rent"
                               title="sell and rent" className="img-fluid w-100"/>
                        </picture>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12">
                      <div className="digital-businesss-headings text-left">
                        <h2 className="sub-heading-target-audience">Khusoosi Teams jo Bechen Apki Properties!</h2>
                        <p>We manage your listings, schedule appointments, and take queries for you! </p>
                        {/* <a href="/sell-rent-for-me/sell-rent-register" class="btn target-audience-plans"
                      tabindex="0">Sell with Ease </a> */}
                        <button className="btn btn-shadow cta-send cta-sr">Sell with Ease</button>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-12 col-lg-12 col-sm-12">
                      <div className="target-audience-headings text-center pb-0">
                        <h2 className="sub-heading-target-audience">We close the right deal for you! </h2>
                        {/* <a href="/sell-rent-for-me/sell-rent-register" class="btn target-audience-plans"
                      tabindex="0">Sell with Me </a> */}
                        <button className="btn btn-shadow cta-send cta-sr">Sell with Me</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="rentBox" id="rentBox" style={{display: 'none'}}>
                <div className="row mt-4">
                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <div className="target-audience-headings text-center">
                      <h2 className="sub-heading-target-audience">Roz roz property dikhanay se hain pareshan?</h2>
                      <p>Leave it to us, and we’ll find you the perfect tenants. With our Rent for Me feature, we
                        categorize, shortlist, and finalize prospective tenants for you! </p>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-md-6 col-lg-6 col-sm-12 position-relative">
                    <div className="variations-video-globe">
                      <picture>
                        <img src="classified_assets/images/sell-rent/rentforme_1.jpg" alt="sell and rent"
                             title="sell and rent" className="img-fluid w-100"/>
                      </picture>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-12">
                    <div className="digital-businesss-headings text-left">
                      <h2 className="sub-heading-target-audience">Ba-aitemaad Kirayedar</h2>
                      <p>Qualified and detailed screened tenants to close the deal. </p>
                      {/* <a href="/sell-rent-for-me/sell-rent-register" class="btn target-audience-plans"
                     tabindex="0">Rent Now </a> */}
                    </div>
                    <form method="get" action="sell-rent-for-me/sell-rent-register">
                      <div className="pro-seller-head-first-head-cta row">
                        <div className="col-md-8">
                          <div className="phone-material">
                            <div className="form-group">
                              <input type="hidden" name="source" defaultValue="rent-for-me"/>
                              <div className="head-cta-input floating-label ">
                                <input type="tel" name="cell_number" onkeyup="notfilled()" id="cell_number"
                                       className="inquirynumber phone number floating-input rent-for-me "
                                       placeholder="Cell Number" minLength={1} maxLength={20} defaultValue required/>
                                <span id="error-msg" className="hide"/>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 no-padding-column">
                          <button className="btn btn-shadow cta-send">Rent Now</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <form method="get" action="sell-rent-for-me/sell-rent-register">
                  <input type="hidden" name="source" defaultValue="rent-for-me"/>
                  <div className="row align-items-center">
                    <div className="col-md-6 col-lg-6 col-sm-12">
                      <div className="digital-businesss-headings text-left">
                        <h2 className="sub-heading-target-audience">Property Den Rent par Foran se Fori! </h2>
                        <p>We promote and market your property to the seriously interested tenant. </p>
                        {/* <a href="/sell-rent-for-me/sell-rent-register" class="btn target-audience-plans"
                      tabindex="0">Rent Faster </a> */}
                        <button className="btn btn-shadow cta-send cta-sr">Rent Faster</button>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12 position-relative">
                      <div className="variations-video-globe">
                        <picture>
                          <img src="classified_assets/images/sell-rent/rentforme_2.jpg" alt="sell and rent"
                               title="sell and rent" className="img-fluid w-100"/>
                        </picture>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-md-6 col-lg-6 col-sm-12 position-relative">
                      <div className="variations-video-globe">
                        <picture>
                          <img src="classified_assets/images/sell-rent/rentforme_3.jpg" alt="sell and rent"
                               title="sell and rent" className="img-fluid w-100"/>
                        </picture>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12">
                      <div className="digital-businesss-headings text-left">
                        <h2 className="sub-heading-target-audience">Behtareen kiraya ba-asani!</h2>
                        <p>Don’t worry about the rent, we’ll find you tenants according to your demands! </p>
                        {/* <a href="/sell-rent-for-me/sell-rent-register" class="btn target-audience-plans"
                      tabindex="0">Rent with Ease </a> */}
                        <button className="btn btn-shadow cta-send cta-sr">Rent with Ease</button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12">
                      <div className="target-audience-headings text-center">
                        <h2 className="sub-heading-target-audience">We close the right deal for you! </h2>
                        {/* <a href="/sell-rent-for-me/sell-rent-register" class="btn target-audience-plans"
                      tabindex="0">Rent with Me </a> */}
                        <button className="btn btn-shadow cta-send cta-sr">Rent with Me</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
          <section className="cta-section space-pb">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-sm-12">
                  <div className="target-audience-headings text-center">
                    <h2 className="sub-heading-target-audience">Bechen ba-asaani, inn features ke saath! </h2>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-4 text-center">
                  <div className="heading-tour">
                    <h6 className="heading-target-audience">HD Photography</h6>
                  </div>
                  <div className="heading-imgs mb-2">
                    <picture>
                      <img src="classified_assets/images/sell-rent/rent_&_sell_forme_6.jpg" alt="sell and rent"
                           title="sell and rent" className="img-fluid w-100"/>
                    </picture>
                  </div>
                </div>
                <div className="col-md-4 text-center">
                  <div className="heading-tour">
                    <h6 className="heading-target-audience">Videography</h6>
                  </div>
                  <div className="heading-imgs videography-main mb-2 embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item"
                            src="https://www.youtube.com/embed/KNIBO7424sI?autoplay=1&mute=1&playlist=KNIBO7424sI&loop=1"
                            frameBorder={0} allowFullScreen allow="autoplay"/>
                  </div>
                </div>
                <div className="col-md-4 text-center">
                  <div className="heading-tour">
                    <h6 className="heading-target-audience">360 Property Overview</h6>
                  </div>
                  <div className="heading-imgs mb-2 easyzoom easyzoom--overlay">
                    <a href="classified_assets/images/sell-rent/rent_&_sell_forme_4.jpg">
                      <picture>
                        <img src="classified_assets/images/sell-rent/rent_&_sell_forme_4.jpg" alt="sell and rent"
                             title="sell and rent" className="img-fluid w-100"/>
                      </picture>
                    </a>
                  </div>
                </div>
                <a href="/contact-us" className="btn target-audience-plans" tabIndex={0}>Go 360 Now ! </a>
              </div>
            </div>
          </section>
          <section className="cta-section space-ptb bg-white-f2">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-sm-12">
                  <div className="target-audience-headings text-center">
                    <h2 className="sub-heading-target-audience">Properties Bechen aur Kiraye par Den, JagahOnline ke
                      saath! </h2>
                    <p>Learn how you can make the most of our sell and rent for me feature for your properties! </p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-4 text-center">
                  <div className="heading-img mb-2">
                    <picture>
                      <img src="classified_assets/images/sell-rent/rent_&_sell_forme_3.png" alt="sell and rent"
                           title="sell and rent" className="img-fluid"/>
                    </picture>
                  </div>
                  <div className="heading-tour">
                    <h6 className="heading-target-audience">Get in Touch</h6>
                    <p>Call our customer support representatives, and we will help you learn more about the sell for
                      me and rent for me feature!</p>
                  </div>
                  <a href="/contact-us" className="btn btn-shadow">
                    Call Now
                  </a>
                </div>
                <div className="col-md-4 text-center">
                  <div className="heading-img mb-2">
                    <picture>
                      <img src="classified_assets/images/sell-rent/rent_&_sell_forme_2.png" alt="sell and rent"
                           title="sell and rent" className="img-fluid"/>
                    </picture>
                  </div>
                  <div className="heading-tour">
                    <h6 className="heading-target-audience">Go 360</h6>
                    <p>Upgrade your listings with our Jagah360 and give your clients a detailed property
                      viewing on their smartphones! </p>
                    <a href="/contact-us" className="btn btn-shadow">
                      Go 360 Now!
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="cta-section space-ptb">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-sm-12">
                  <div className="target-audience-headings text-center p-0">
                    <h2 className="sub-heading-target-audience">Agents aur Kirayedaron ki chik chik se na hon
                      pareshan!</h2>
                    <p className>Our dedicated resources invest time, money, and top-notch technology to bring
                      you closer to the right buyers and tenants. </p>
                    <h2 className="sub-heading-target-audience mt-3">Hum de rahe hain apko</h2>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-4 text-center">
                  <div className="heading-img mb-2">
                    <picture>
                      <img src="classified_assets/images/sell-rent/rent_&_sell_forme_sa.png" alt="sell and rent"
                           title="sell and rent" className="img-fluid"/>
                    </picture>
                  </div>
                  <div className="heading-tour">
                    <h6 className="heading-target-audience">Specialised Agents</h6>
                  </div>
                </div>
                <div className="col-md-4 text-center">
                  <div className="heading-img mb-2">
                    <picture>
                      <img src="classified_assets/images/sell-rent/rent_&_sell_forme_drc.png" alt="sell and rent"
                           title="sell and rent" className="img-fluid"/>
                    </picture>
                  </div>
                  <div className="heading-tour">
                    <h6 className="heading-target-audience">Decision-Ready Customers</h6>
                  </div>
                </div>
                <div className="col-md-4 text-center">
                  <div className="heading-img mb-2">
                    <picture>
                      <img src="classified_assets/images/sell-rent/sellforme_targetedmarketing.png" alt="sell and rent"
                           title="sell and rent" className="img-fluid"/>
                    </picture>
                  </div>
                  <div className="heading-tour">
                    <h6 className="heading-target-audience">Targeted Marketing</h6>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="cta-section space-pb">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-sm-12">
                  <div className="target-audience-headings text-center">
                    <h2 className="sub-heading-target-audience">Bechen, ya Rent par Den, Kabhi Bhi, Kahin Bhi! </h2>
                    <p className="mb-0">Download the App, and get started! </p>
                    <a href="http://onelink.to/pkj6gn" className="mt-3 btn target-audience-plans">
                      Download App
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="disclaimer-content">
            <div className="container">
              <div className="row pt-2 pb-2">
                <div className="d-flex w-100 heading-tour">
                  <div className="col-md-10 disclaimer-text">
                    <p>* In order to avail these service, the client must comply with some terms &amp; conditions! * To
                      learn more, get in touch with our customer support
                      team!</p>
                  </div>
                  <div className="col-md-2 cta-disclaimer text-right">
                    <a href="/contact-us" className="btn btn-small btn-disclaimer">
                      Call Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
    </>
  )
}

export default SellRentForMe;