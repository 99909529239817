import React, {useState} from 'react'
import {OurOffice} from '../components/OurOffice';
import {Banner} from '../components/Banner';
// import '../css/contactUs.css'
import { ContactForm } from '../components/ContactForm';


function ContactUs() {
  return (
    <>
    {/* -------------------------Banner Component-------------------- */}
    <Banner />
<section className='contact-section ContactUsCss space-ptb'>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-lg-6 mt-4 mt-lg-0">
        <div className="p-0">
          <h4 className="mt-0 mb-4 main-heading-contact">Always happy to answer your questions.
          </h4>
          <ContactForm />
        </div>
      </div>
      <div className="col-lg-6">
        <img className="img-fluid" alt="img" src="https://cdn.jagahonline.com/assets/frontend-assets/images/contact/contact_us.svg" />
      </div>
    </div>
    <div className="row mt-5">
      <div className="col-md-4">
        <div className="contact-details-box">
          <div className="contact-box-icon">
            <img className="contact-us-social img-fluid" alt="img" src="https://cdn.jagahonline.com/assets/frontend-assets/images/contact/office.png" />
          </div>
          <div className="contact-box-detail-inner">
            <h6> Pakistan Office</h6>
            <p>A-214, Block A, Sindhi Muslim Cooperative Housing Society (SMCHS), Karachi, Pakistan.</p>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="contact-details-box">
          <div className="contact-box-icon">
            <img className="contact-us-social img-fluid" alt="img" src="https://cdn.jagahonline.com/assets/frontend-assets/images/contact/contact.svg" />
          </div>
          <div className="contact-box-detail-inner">
            <h6>Contact Us</h6>
            <p className="m-0">Call:
              <a href="tel:922137131669">
                +92 213 7131669
              </a>
            </p>
            <p className="m-0">Email:
              support@jagahonline.com</p>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="contact-details-box">
          <div className="contact-box-icon">
            <img className="contact-us-social img-fluid" alt="img" src="https://cdn.jagahonline.com/assets/frontend-assets/images/contact/schedule.svg" />
          </div>
          <div className="contact-box-detail-inner">
            <h6>Working Schedule</h6>
            <p className="m-0">Working days:
              Mon-Sat
            </p>
            <p className="m-0">Time:
              9:00 AM-6:00 PM
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

{/* -------------------------------Our Office Component--------------------- */}
{/*<OurOffice />*/}
</>
  )
}

export default ContactUs;