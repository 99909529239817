import UserProfileHeader from "../../Components/Header/joclassified/UserProfileHeader";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik, yupToFormErrors } from "formik";
import Select from "react-select";
import axios from "axios";
import { connect } from "react-redux";
import { setAuthUser } from "../../Redux/actions/authUser";
import PropTypes from "prop-types";
import "yup-phone";
import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  currentUserInformation,
  multiFormHeader,
  USER_IMAGE_NOT_FOUND_URL,
} from "../../Constants/global";

const CompanyProfile = ({ authUser }) => {
  useEffect(() => {
    axios({
      method: "GET",
      url:
        API_URL +
        `api/agenciesByid/${localStorage.getItem("agency_profile_id")}`,
      headers: multiFormHeader,
    }).then((response) => {
      if (response.data.status === 200) {
        // alert(response.data.data.city_id);
        // ------------------------------Get Area id---------
        fetch(
          API_URL + "generalservices/areabycityid/" + response.data.data.city_id
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.status === 200) {
              const nArray = [];
              data.data.map((item) => {
                let roleData = {};
                roleData.value = item.id;
                roleData.label = item.area;
                nArray.push(roleData);
              });
              setLocations(nArray);
            }
          });
        // ------------------------------Get Area id---------
        setInitialValues({
          id: response.data.data.id,
          company_name: response.data.data.company_name,
          company_email: response.data.data.company_email,
          cell_1: response.data.data.cell_1,
          cell_2: response.data.data.cell_2,
          contact_person: response.data.data.contact_person,
          phone_1: response.data.data.phone_1,
          phone_2: response.data.data.phone_2,
          whatsapp_number: response.data.data.whatsapp_number,
          address: response.data.data.address,
          location: response.data.data.location,
          logo_image: response.data.data.logo_image,
          old_logo_file: response.data.data.old_logo_file,
          description: response.data.data.description,
          logo: response.data.data.logo,
        });
        const arr_business_expertise = [];

        response.data.data.agency_business_expertises.map((item) => {
          arr_business_expertise.push(item.business_expertise_id);
        });
        setBusiness_expertise_ids(arr_business_expertise);
        console.log(business_expertise_ids);

        const arr_business_specialization = [];
        const arr_area_ids = [];
        response.data.data.agency_expertises.map((item) => {
          arr_area_ids.push(item.area_id);
        });
        setArea_ids(arr_area_ids);
        response.data.data.agency_specializations.map((item) => {
          arr_business_specialization.push(item.agency_specialization_id);
        });
        setBusiness_specialization_ids(arr_business_specialization);
        console.log(arr_business_specialization);

        setDropDownValues({
          city_id: response.data.data.city_id,
          area_id: response.data.data.area_id,

          // area_ids: response.data.data.agency_expertises,
          active: response.data.data.active,
          is_featured: response.data.data.is_featured,
          no_agents_office_id: response.data.data.no_agents_office_id,
          no_year_business_id: response.data.data.no_year_business_id,
          show_contact: response.data.data.show_contact,

          business_specialization_ids:
            response.data.data.agency_specializations,
          area_ids: response.data.data.area_ids,
        });

        console.log(response.data);
      } else {
      }
    });
  }, []);
  const [Statusmessage, setMessage] = useState({
    message: "",
    class: "",
  });
  function setSuccessMessage(msg) {
    setMessage({
      message: msg,
      class: "alert alert-success contactUsAlert",
    });
  }
  function removeMessage(msg) {
    setMessage({
      message: "",
      class: "",
    });
  }

  function setErrorMessage(msg) {
    setMessage({
      message: msg,
      class: "alert alert-danger contactUsAlert",
    });
  }
  const [isLoading, setIsLoading] = useState({
    isLoading: false,
    disabled: false,
  });
  const [img, setImg] = useState();
  const [selectedfile, setSelectedFile] = useState();
  const handleChange = (e) => {
    console.log(e);
    setSelectedFile(e);
    const [file] = e.target.files;
    setImg(URL.createObjectURL(file));
  };

  const [business_specialization_ids, setBusiness_specialization_ids] =
    useState([0]);
  const [area_ids, setArea_ids] = useState([0]);
  const [dropDownValues, setDropDownValues] = useState({
    city_id: 0,
    area_id: 0,
    area_ids: [0],
    active: 0,
    is_featured: 0,
    no_agents_office_id: 0,
    no_year_business_id: 0,
    show_contact: 0,
    // business_specialization: 0,
  });
  // ---------------------Drop Down Options---------------------------
  const [cities, setCities] = useState([]);
  const [showContact, setShowContact] = useState([
    { value: 0, label: "Company" },
    { value: 1, label: "User" },
  ]);
  const [businessSpecialization, setBusinessSpecialization] = useState([
    { value: 1, label: "Buy" },
    { value: 2, label: "Sale" },
    { value: 3, label: "Rent" },
    { value: 4, label: "Project Sales" },
    { value: 5, label: "Development" },
  ]);
  const [noOfAgetns, setNoOfAgents] = useState([
    { value: 0, label: 1 },
    { value: 1, label: 2 },
    { value: 2, label: 3 },
    { value: 3, label: 4 },
    { value: 4, label: 5 },
    { value: 5, label: 6 },
  ]);
  const [noOfYears, setNoOfYears] = useState([
    { value: 0, label: "1 - 3" },
    { value: 1, label: "4 - 6" },
    { value: 2, label: "7 - 10" },
    { value: 3, label: "11 - 15" },
    { value: 4, label: "15+" },
  ]);
  const [businessExpertise, setBusinessExpertise] = useState([
    { value: 0, label: "Residential" },
    { value: 1, label: "Commercial" },
    { value: 2, label: "Industrial" },
    { value: 3, label: "Other" },
    { value: 4, label: "Test" },
  ]);
  const [business_expertise_ids, setBusiness_expertise_ids] = useState([]);
  // ---------------------Drop Down Options---------------------------
  const [locations, setLocations] = useState([]);

  const [initialValues, setInitialValues] = useState({
    id: 0,
    company_name: "",
    company_email: "",
    cell_1: "",
    cell_2: "",
    contact_person: "",
    phone_1: "",
    phone_2: "",
    whatsapp_number: "",
    address: "",
    location: "",
    logo_image: "",
    old_logo_file: "",
    description: "",
    logo: "",
    // area_ids: [0],
  });
  // ------------------------------Formik Validation---------------------------------------
  const phoneRegExp =
    /^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/;
  const validateSchema = Yup.object().shape({
    company_name: Yup.string().required("Required"),
    company_email: Yup.string()
      .email("Invalid email format")
      .required("Required"),
    cell_1: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
    cell_2: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
    contact_person: Yup.string().required("Required"),
    phone_1: Yup.string(),
    phone_2: Yup.string(),
    whatsapp_number: Yup.string().matches(
      phoneRegExp,
      "Phone number is not valid"
    ),
    address: Yup.string().required("Required"),
    show_contact: Yup.string(),
    business_specialization_ids: Yup.string(),
    no_year_business_id: Yup.number(),
    logo_image: Yup.string(),
    old_logo_file: Yup.string(),
    description: Yup.string(),
  });
  // ------------------------------Formik Validation---------------------------------------
  const handleSubmit = (values) => {
    setIsLoading({
      isLoading: true,
      disabled: true,
    });
    var form = new FormData();
    if (
      selectedfile !== undefined &&
      selectedfile !== null &&
      selectedfile.target.value !== undefined &&
      selectedfile.target.value !== null &&
      selectedfile.target.value !== ""
    ) {
      form.append("logo_image", selectedfile.target.files[0]);
    } else {
      form.append("logo_image", "");
    }
    form.append("company_name", values.company_name);
    form.append("company_email", values.company_email);
    form.append("cell_1", values.cell_1);
    form.append("cell_2", values.cell_2);
    form.append("contact_person", values.contact_person);
    form.append("phone_1", values.phone_1);
    form.append("phone_2", values.phone_2);
    form.append("whatsapp_number", values.whatsapp_number);
    form.append("address", values.address);
    form.append("city_id", dropDownValues.city_id);
    form.append("area_id", dropDownValues.area_id);
    form.append("location", dropDownValues.city_id);
    form.append("show_contact", dropDownValues.show_contact);

    form.append(
      "business_specialization_ids",
      JSON.stringify(business_specialization_ids, null, 2)
    );

    form.append(
      "business_specialization_ids",
      JSON.stringify(business_specialization_ids, null, 2)
    );

    form.append("no_year_business_id", dropDownValues.no_year_business_id);
    form.append("no_agents_office_id", dropDownValues.no_agents_office_id);
    form.append("old_logo_file", initialValues.old_logo_file);
    form.append("description", values.description);
    form.append("area_ids", JSON.stringify(area_ids, null, 2));

    form.append(
      "business_expertise_ids",
      JSON.stringify(business_expertise_ids, null, 2)
    );

    form.append(
      "business_expertise_ids",
      JSON.stringify(business_expertise_ids, null, 2)
    );

    form.append("role", authUser.role_id);
    form.append("is_featured", 0);
    form.append("active", 0);

    // --------------------------------user Details-----------------------------------
    form.append("id", localStorage.getItem("agency_profile_id")); //-----------------
    form.append("user_id", authUser.user_id);
    form.append("profile_image", "");
    form.append("old_profile_file", "");
    // form.append("user_email", authUser.email);
    // form.append("password", ""); //-----------------
    // form.append("first_name", authUser.first_name);
    // form.append("last_name", authUser.last_name);
    // form.append("phone_number", authUser.phone_number);

    console.log(area_ids);

    axios({
      method: "POST",
      url: API_URL + "api/agencyprofile_update",
      data: form,
      headers: multiFormHeader,
    }).then((response) => {
      if (response.data.status === 200) {
        setSuccessMessage(response.data.message);
        setTimeout(removeMessage, 3000);

        setIsLoading({
          isLoading: false,
          btnClass: "btn btn-primary btn-block",
          disabled: false,
        });
      } else {
        setIsLoading({
          isLoading: false,
          disabled: false,
        });
        setErrorMessage("Something went wrong. Please try again.");
        setTimeout(removeMessage, 3000);
      }
    });

    console.log(values);
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: validateSchema,
    enableReinitialize: true,
  });

  async function onChangeCityHandler(value) {
    setDropDownValues({ ...dropDownValues, city_id: parseInt(value.value) });

    const response = await axios({
      method: "GET",
      url: API_URL + "generalservices/areabycityid/" + value.value,
    });

    if (response.status === 200) {
      const nArray = [];
      response.data?.data.map((item) => {
        let roleData = {};
        roleData.value = item.id;
        roleData.label = item.area;
        nArray.push(roleData);
      });
      setLocations(nArray);
    }
  }
  function onAreaChange(value) {
    setDropDownValues({
      ...dropDownValues,
      area_id: parseInt(value.value),
    });
  }

  function onAreaExpertiseChange(value) {
    const nArray = [];

    value.map((item) => {
      nArray.push(parseInt(item.value));
    });

    setArea_ids(nArray);
  }

  useEffect(() => {
    async function fetchSearchBy() {
      const response = await axios({
        method: "GET",
        url: API_URL + "api/searchby",
      });
      if (response.status === 200) {
        const cities = response.data?.data[2]["cities"];
        const nArray2 = [];
        cities.map((item) => {
          let roleData = {};
          roleData.value = item.id;
          roleData.label = item.city;
          nArray2.push(roleData);
        });
        setCities(nArray2);
      }
    }
    fetchSearchBy();
    async function fetchLocations() {
      const response = await axios({
        method: "GET",
        url: API_URL + "generalservices/areabycityid/" + dropDownValues.city_id,
      });

      if (response.status === 200) {
        const nArray = [];
        response.data?.data.map((item) => {
          let roleData = {};
          roleData.value = item.id;
          roleData.label = item.area;
          nArray.push(roleData);
        });
        setLocations(nArray);
      }
    }
    fetchLocations();
  }, []);

  return (
    <>
      <UserProfileHeader />
      {/* <div className="d-sm-flex align-items-center position-relative">
        <div className="profile-avatar position-relative">
          <img
            className="img-fluid my-profile-user-img rounded-circle"
            src={
              initialValues.logo !== undefined && initialValues.logo !== ""
                ? initialValues.logo
                : USER_IMAGE_NOT_FOUND_URL
            }
            id="front-img-preview"
            alt="Pakistan Best Property Portal – Find Real Estate Sell, Buy, Rent Properties & Homes"
            onError="if (this.src != 'https://cdn.jagahonline.com/default-user.jpg') this.src = 'https://cdn.jagahonline.com/default-user.jpg';"
            loading="lazy"
          />
        </div>
      </div> */}
      <section className="space-pb">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title d-flex align-items-center mb-3">
                <h2 className="mb-0">Company Profile</h2>
              </div>
              <div className={Statusmessage.class}>{Statusmessage.message}</div>
              <form
                className="phone-validation-form axprofile-form-style"
                onSubmit={formik.handleSubmit}
              >
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label>
                      Business name for your contact{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Buissness name"
                      name="company_name"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.company_name}
                    />
                    {formik.touched.company_name &&
                    formik.errors.company_name ? (
                      <div className="error text-danger">
                        {" "}
                        {formik.errors.company_name}{" "}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-md-6">
                    <label>
                      Whom your contact reach to{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Whom your contact reach to"
                      name="contact_person"
                      onBlur={formik.handleBlur}
                      value={formik.values.contact_person}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.contact_person &&
                    formik.errors.contact_person ? (
                      <div className="error text-danger">
                        {" "}
                        {formik.errors.contact_person}{" "}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-md-6">
                    <label>
                      Email where people can contact you{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="company_email"
                      onBlur={formik.handleBlur}
                      value={formik.values.company_email}
                      placeholder="Email where people can contact you"
                      onChange={formik.handleChange}
                    />
                    {formik.touched.company_email &&
                    formik.errors.company_email ? (
                      <div className="error text-danger">
                        {" "}
                        {formik.errors.company_email}{" "}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-md-6">
                    <label>
                      Address for contacts to find you{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Address for contacts"
                      name="address"
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.address && formik.errors.address ? (
                      <div className="error text-danger">
                        {" "}
                        {formik.errors.address}{" "}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-lg col-md-6">
                    <label>Your city</label>
                    <Select
                      className="form-control isMulti"
                      placeholder={<div>All Cities</div>}
                      options={cities}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      onChange={onChangeCityHandler}
                      value={cities.find(
                        (c) => c.value === parseInt(dropDownValues.city_id)
                      )}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label>
                      Where are you located?{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      className="form-control isMulti"
                      placeholder={<div>Where are you located?</div>}
                      options={locations}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      onChange={onAreaChange}
                      value={locations.find(
                        (c) => c.value === parseInt(dropDownValues.area_id)
                      )}
                    />
                  </div>

                  <hr />
                  <hr />
                  <div className="form-group col-md-6">
                    <label>
                      Business mobile 1 <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Business mobile 1"
                      name="cell_1"
                      onBlur={formik.handleBlur}
                      value={formik.values.cell_1}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.cell_1 && formik.errors.cell_1 ? (
                      <div className="error text-danger">
                        {" "}
                        {formik.errors.cell_1}{" "}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Business mobile 2</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Business mobile 2"
                      name="cell_2"
                      onBlur={formik.handleBlur}
                      value={formik.values.cell_2}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.cell_2 && formik.errors.cell_2 ? (
                      <div className="error text-danger">
                        {" "}
                        {formik.errors.cell_2}{" "}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Landline no 1</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Landline no 1"
                      name="phone_1"
                      onBlur={formik.handleBlur}
                      value={formik.values.phone_1}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.phone_1 && formik.errors.phone_1 ? (
                      <div className="error text-danger">
                        {" "}
                        {formik.errors.phone_1}{" "}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Landline no 2</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Landline no 2"
                      name="phone_2"
                      onBlur={formik.handleBlur}
                      value={formik.values.phone_2}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.phone_2 && formik.errors.phone_2 ? (
                      <div className="error text-danger">
                        {" "}
                        {formik.errors.phone_2}{" "}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Whatsapp</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Whatsapp"
                      name="whatsapp_number"
                      onBlur={formik.handleBlur}
                      value={formik.values.whatsapp_number}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.whatsapp_number &&
                    formik.errors.whatsapp_number ? (
                      <div className="error text-danger">
                        {" "}
                        {formik.errors.whatsapp_number}{" "}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Logo image:</label>
                    <div className="custom-file">
                      <input
                        type="file"
                        className="form-control"
                        placeholder="Logo Image"
                        name="logo_image"
                        accept="image/*"
                        id="logo_image"
                        onChange={handleChange}
                      />{" "}
                      {formik.touched.logo_image && formik.errors.logo_image ? (
                        <div className="error text-danger">
                          {" "}
                          {formik.errors.logo_image}{" "}
                        </div>
                      ) : null}
                    </div>

                    {img ? (
                      <div className="multi-previewMain">
                        <img src={img} alt="" />{" "}
                      </div>
                    ) : (
                      <div className="multi-previewMain">
                        <img
                          src={
                            initialValues.logo !== undefined &&
                            initialValues.logo !== "undefined" &&
                            initialValues.logo !== "" &&
                            initialValues.logo !== null &&
                            initialValues.logo !== "null"
                              ? initialValues.logo
                              : USER_IMAGE_NOT_FOUND_URL
                          }
                          alt=""
                        />{" "}
                      </div>
                    )}
                  </div>
                  <div className="col-md-12">
                    <hr />
                    <div className="section-title d-flex align-items-center mb-3">
                      <h2 className="mb-0">Additional Information</h2>
                    </div>
                    <hr />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Show contacts</label>
                    <Select
                      className="form-control isMulti"
                      placeholder={<div>Show Contacts</div>}
                      options={showContact}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      onChange={(value) =>
                        setDropDownValues({
                          ...dropDownValues,
                          show_contact: value.value,
                        })
                      }
                      value={showContact.find(
                        (c) => c.value === parseInt(dropDownValues.show_contact)
                      )}
                    />
                  </div>
                  <div className="form-group col-md-6 select-border">
                    <label>
                      No. of agents in office{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      className="form-control isMulti"
                      placeholder={<div>Select No. of Agents in office</div>}
                      options={noOfAgetns}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      onChange={(value) =>
                        setDropDownValues({
                          ...dropDownValues,
                          no_agents_office_id: value.value,
                        })
                      }
                      value={noOfAgetns.find(
                        (c) =>
                          c.value ===
                          parseInt(dropDownValues.no_agents_office_id)
                      )}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Business expertise</label>
                    <Select
                      className="form-control isMulti"
                      placeholder={<div>Select Business Expertise</div>}
                      options={businessExpertise}
                      isMulti={true}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      onChange={(value) => {
                        const nArray = [];

                        value.map((item) => {
                          nArray.push(item.value);
                        });
                        setBusiness_expertise_ids(nArray);
                        console.log(business_expertise_ids);
                      }}
                      value={business_expertise_ids.map((id) =>
                        businessExpertise.find((c) => c.value === id)
                      )}
                    />
                  </div>
                  {/* speciality---------------------------- */}
                  <div className="form-group col-md-6">
                    <label>Business specialization</label>
                    <Select
                      className="form-control isMulti"
                      placeholder={<div>Select Business specialization</div>}
                      options={businessSpecialization}
                      isMulti={true}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      onChange={(value) => {
                        const nArray = [];

                        value.map((item) => {
                          nArray.push(item.value);
                        });
                        setBusiness_specialization_ids(nArray);
                        console.log(business_specialization_ids);
                      }}
                      value={business_specialization_ids.map((id) =>
                        businessSpecialization.find((c) => c.value === id)
                      )}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Business area expertise</label>
                    <Select
                      className="form-control isMulti"
                      placeholder={<div>Select Business Area Expertise</div>}
                      options={locations}
                      isMulti={true}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      onChange={onAreaExpertiseChange}
                      value={area_ids.map((id) =>
                        locations.find((c) => c.value === id)
                      )}
                    />
                  </div>

                  <div className="form-group col-md-6 select-border">
                    <label>No. of years in business</label>
                    <Select
                      className="form-control isMulti"
                      placeholder={<div>Select No. of years in Business</div>}
                      options={noOfYears}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      onChange={(value) =>
                        setDropDownValues({
                          ...dropDownValues,
                          no_year_business_id: value.value,
                        })
                      }
                      value={noOfYears.find(
                        (c) =>
                          c.value ===
                          parseInt(dropDownValues.no_year_business_id)
                      )}
                    />
                    {formik.touched.no_year_business_id &&
                    formik.errors.no_year_business_id ? (
                      <div className="error text-danger">
                        {" "}
                        {formik.errors.no_year_business_id}{" "}
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group col-md-12">
                    <label>Description</label>
                    <textarea
                      type="text"
                      rows={4}
                      className="form-control"
                      placeholder="Description"
                      name="description"
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.description && formik.errors.description ? (
                      <div className="error text-danger">
                        {" "}
                        {formik.errors.description}{" "}
                      </div>
                    ) : null}
                  </div>

                  <div className="col-md-12 text-center mt-2">
                    <button
                      disabled={isLoading.disabled}
                      type="submit"
                      className="btn btn-primary sweet-delete btn-shadow"
                    >
                      Update Company Profile
                      <span
                        hidden={isLoading.disabled - 1}
                        className="spinner-border spinner-border-sm ml-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
CompanyProfile.propTypes = {
  authUser: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  authUser: state.authUser,
});
export default connect(mapStateToProps, { setAuthUser })(CompanyProfile);
