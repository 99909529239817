import React from 'react';
import Loginform from './LoginForm'
import Registrationform from './RegistrationForm'
import {BASE_URL} from "../../../Constants/global";

function Login(props){
   
    return(
        <>

            {/*================================= Modal login */}
        <div className="modal login fade loginModal" id="loginModal" tabIndex={-1} role="dialog" aria-labelledby="loginModalLabel" aria-hidden="true" data-backdrop="false">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header border-0 text-center position-relative">
                        <div className="col-md-12 text-center">
                            <a className="navbar-brand p-0" href="/">
                                <img
                                    className="img-fluid" width={180}
                                    src={BASE_URL + "classified_assets/images/logo.svg"}
                                    alt="logo"
                                />
                            </a>
                        </div>
                        <button type="button" className="close btn-modal-close" width={26} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body pb-5">

                        <ul className="nav nav-tabs nav-tabs-02 justify-content-center" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="login-tab" data-toggle="tab" href="#login" role="tab"
                                   aria-controls="login" aria-selected="false">Log in</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="register-tab" data-toggle="tab" href="#register" role="tab"
                                   aria-controls="register" aria-selected="true">Register</a>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="login" role="tabpanel" aria-labelledby="login-tab">
                                <Loginform />
                                
                            </div>

                            {/* --------------Register Form -------------- */}
                            <div className="tab-pane fade" id="register" role="tabpanel" aria-labelledby="register-tab">
                                <Registrationform />
                            </div>
                            {/* --------------Register Form -------------- */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/*=================================
Modal login */}
    </>
    )
}

export default Login;


