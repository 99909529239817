import React, { Fragment } from 'react'
import { BASE_URL } from '../Constants/global'

function Faq () {
  return (
    <Fragment>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <ol className='breadcrumb mb-0'>
              <li className='breadcrumb-item home-link'>
                <a href='/'>JagahOnline</a>
              </li>
              <li className='breadcrumb-item active'>
                <i className='fas fa-chevron-right'></i>
                <span>FAQs</span>
              </li>
            </ol>
          </div>
        </div>
      </div>

      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='about-faqs text-center'>
                <img
                  className='img-fluid faq-svg-icon'
                  src={BASE_URL + 'classified_assets/images/faq/faq.png'}
                  alt='faq'
                  loading='lazy'
                />
                <h1>Do You Have Any Questions For Us?</h1>
                <h2>We Are Here To Help!</h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              {/*<ul className="nav nav-tabs nav-tabs-02 faq-tabs" id="myTab" role="tablist">*/}
              {/*    <li className="nav-item faq-tabs-items">*/}
              {/*        <a className="nav-link active" id="tab-01-tab" data-toggle="tab" href="#tab-01"*/}
              {/*           role="tab" aria-controls="tab-01" aria-selected="true">*/}
              {/*            <div className="icons">*/}
              {/*                <img className="img-fluid faq-svg-icon" src={BASE_URL + "classified_assets/images/faq/faqs.svg"} alt="faq"*/}
              {/*                     loading="lazy"/>*/}
              {/*            </div>*/}
              {/*            General FAQs*/}
              {/*        </a>*/}
              {/*    </li>*/}
              {/*    <li className="nav-item faq-tabs-items">*/}
              {/*        <a className="nav-link" id="tab-02-tab" data-toggle="tab" href="#tab-02" role="tab"*/}
              {/*           aria-controls="tab-02" aria-selected="false">*/}
              {/*            <div className="icons">*/}
              {/*                <img className="img-fluid faq-svg-icon" src={BASE_URL + "classified_assets/images/faq/ustad-faq.svg"} alt="faq"*/}
              {/*                     loading="lazy"/>*/}
              {/*            </div>*/}
              {/*            Jagah Online Ustad*/}
              {/*        </a>*/}
              {/*    </li>*/}
              {/*    <li className="nav-item faq-tabs-items">*/}
              {/*        <a className="nav-link" id="tab-03-tab" data-toggle="tab" href="#tab-03" role="tab"*/}
              {/*           aria-controls="tab-03" aria-selected="false">*/}
              {/*            <div className="icons">*/}
              {/*                <img className="img-fluid faq-svg-icon" src={BASE_URL + "classified_assets/images/faq/pro-seller.svg"}*/}
              {/*                     alt="faq" loading="lazy"/>*/}
              {/*            </div>*/}
              {/*            Jagah Online ProSeller*/}
              {/*        </a>*/}
              {/*    </li>*/}
              {/*</ul>*/}
              <div className='tab-content faq-tabs-content' id='myTabContent'>
                <div
                  className='tab-pane fade active show'
                  id='tab-01'
                  role='tabpanel'
                  aria-labelledby='tab-01-tab'
                >
                  <div className='accordion accordion-faq' id='accordion'>
                    <div className='accordion-item accordion-item-faq'>
                      <div className='accordion-title' id='accordion-title-one'>
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-one'
                          aria-expanded='true'
                          aria-controls='accordion-one'
                        >
                          <i className='fas fa-angle-down' />
                          What are the benefits of listing my property on Jagah
                          Online?
                        </a>
                      </div>
                      <div
                        id='accordion-one'
                        className='collapse'
                        aria-labelledby='accordion-title-one'
                        data-parent='#accordion'
                      >
                        <div className='accordion-content'>
                          <p>
                            Jagah Online is home to thousands of people looking
                            to buy and rent properties, your property has a
                            better chance of getting discovered by interested
                            buyers and tenants from here than anywhere else.
                            Listing your property on Jagah Online is quite
                            simple and does not take long.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div className='accordion-title' id='accordion-title-tow'>
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-two'
                          aria-expanded='false'
                          aria-controls='accordion-two'
                        >
                          <i className='fas fa-angle-down' />
                          How do I search for a Property on Jagah Online?
                        </a>
                      </div>
                      <div
                        id='accordion-two'
                        className='collapse'
                        aria-labelledby='accordion-title-tow'
                        data-parent='#accordion'
                      >
                        <div className='accordion-content'>
                          Searching for a property on Jagah Online is simple,
                          just visit our homepage and in the search bar enter a
                          location for your desired property. You can also
                          narrow down your search by filtering your search by
                          city, property category, area unit, prices, and
                          keywords.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-three'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-three'
                          aria-expanded='false'
                          aria-controls='accordion-three'
                        >
                          <i className='fas fa-angle-down' />
                          How long can I market my property with you?
                        </a>
                      </div>
                      <div
                        id='accordion-three'
                        className='collapse'
                        aria-labelledby='accordion-title-three'
                        data-parent='#accordion'
                      >
                        <div className='accordion-content'>
                          <p>
                            You can market your property with Jagah Online for
                            as long as you wish.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-four'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-four'
                          aria-expanded='false'
                          aria-controls='accordion-four'
                        >
                          <i className='fas fa-angle-down' />
                          How can I share my property with my friends on
                          Facebook?
                        </a>
                      </div>
                      <div
                        id='accordion-four'
                        className='collapse'
                        aria-labelledby='accordion-title-four'
                        data-parent='#accordion'
                      >
                        <div className='accordion-content'>
                          You can share your property with your friends with the
                          link of the property page on Jagah Online.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-five'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-five'
                          aria-expanded='false'
                          aria-controls='accordion-five'
                        >
                          <i className='fas fa-angle-down' />
                          What should I disclose to potential buyers?
                        </a>
                      </div>
                      <div
                        id='accordion-five'
                        className='collapse'
                        aria-labelledby='accordion-title-five'
                        data-parent='#accordion'
                      >
                        <div className='accordion-content'>
                          When you list a property on our site, make sure you
                          provide all important details like the property
                          address, number of rooms, price, area, amenities, and
                          good photos.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div className='accordion-title' id='accordion-title-six'>
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-six'
                          aria-expanded='false'
                          aria-controls='accordion-six'
                        >
                          <i className='fas fa-angle-down' />
                          How do I contact an agency from Jagah Online?
                        </a>
                      </div>
                      <div
                        id='accordion-six'
                        className='collapse'
                        aria-labelledby='accordion-title-six'
                        data-parent='#accordion'
                      >
                        <div className='accordion-content'>
                          You can contact an agency Jagah Online by filling in
                          the contact form with your name, email, and message or
                          on the number they have provided.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-seven'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-seven'
                          aria-expanded='false'
                          aria-controls='accordion-seven'
                        >
                          <i className='fas fa-angle-down' />
                          What do I do with my Jagah Online account?
                        </a>
                      </div>
                      <div
                        id='accordion-seven'
                        className='collapse'
                        aria-labelledby='accordion-title-seven'
                        data-parent='#accordion'
                      >
                        <div className='accordion-content'>
                          With your Jagah Online account you can:
                          <ul>
                            <li>
                              Stay connected with latest property listings
                            </li>
                            <li>Find a property for sale or rent</li>
                            <li>List a property for sale or rent</li>
                            <li>
                              Save properties and projects that you like to view
                              later
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-nine'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-nine'
                          aria-expanded='false'
                          aria-controls='accordion-nine'
                        >
                          <i className='fas fa-angle-down' />
                          What do I do if I forget my password?
                        </a>
                      </div>
                      <div
                        id='accordion-nine'
                        className='collapse'
                        aria-labelledby='accordion-title-nine'
                        data-parent='#accordion'
                      >
                        <div className='accordion-content'>
                          If you forget your password, during Sign in, click/tap
                          on forget password and enter your email address. We
                          will email you with a password reset link through
                          which you can set a new password for your account.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div className='accordion-title' id='accordion-title-ten'>
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-ten'
                          aria-expanded='false'
                          aria-controls='accordion-ten'
                        >
                          <i className='fas fa-angle-down' />
                          How do I stay connected to Jagah Online?
                        </a>
                      </div>
                      <div
                        id='accordion-ten'
                        className='collapse'
                        aria-labelledby='accordion-title-ten'
                        data-parent='#accordion'
                      >
                        <div className='accordion-content'>
                          You can sign up for our newsletter via email or
                          download the Jagah Online app to stay connected with
                          the latest property listings and happenings in the
                          real estate world.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-eleven'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-eleven'
                          aria-expanded='false'
                          aria-controls='accordion-eleven'
                        >
                          <i className='fas fa-angle-down' />
                          Will my property be listed as soon as I submit it?
                        </a>
                      </div>
                      <div
                        id='accordion-eleven'
                        className='collapse'
                        aria-labelledby='accordion-title-eleven'
                        data-parent='#accordion'
                      >
                        <div className='accordion-content'>
                          Yes, your property will be live on our site as soon as
                          you submit it.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-tewelve'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-tewelve'
                          aria-expanded='false'
                          aria-controls='accordion-tewelve'
                        >
                          <i className='fas fa-angle-down' />
                          Are there any charges to use Jagah Online?
                        </a>
                      </div>
                      <div
                        id='accordion-tewelve'
                        className='collapse'
                        aria-labelledby='accordion-title-tewelve'
                        data-parent='#accordion'
                      >
                        <div className='accordion-content'>
                          Jagah Online is completely free to use
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-thirteen'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-thirteen'
                          aria-expanded='false'
                          aria-controls='accordion-thirteen'
                        >
                          <i className='fas fa-angle-down' />
                          Where will interested buyers or tenants contact me?
                        </a>
                      </div>
                      <div
                        id='accordion-thirteen'
                        className='collapse'
                        aria-labelledby='accordion-title-thirteen'
                        data-parent='#accordion'
                      >
                        <div className='accordion-content'>
                          Interested buyers or tenants will contact you on the
                          contact details you provide on our site.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-forteen'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-forteen'
                          aria-expanded='false'
                          aria-controls='accordion-forteen'
                        >
                          <i className='fas fa-angle-down' />
                          How do I list my property on Jagah Online?
                        </a>
                      </div>
                      <div
                        id='accordion-forteen'
                        className='collapse'
                        aria-labelledby='accordion-title-forteen'
                        data-parent='#accordion'
                      >
                        <div className='accordion-content'>
                          To list a property on Jagah Online:
                          <ol>
                            <li>Sign in to your Jagah Online account.</li>
                            <li>
                              Click or Tap on the List Property button from the
                              homepage
                            </li>
                            <li>
                              Enter property details including features,
                              pictures, amenities, and other details.
                            </li>
                            <li>Submit it once you are done.</li>
                            <li>
                              Your listing will be live as soon as our team is
                              done reviewing it.
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-fifteen'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-fifteen'
                          aria-expanded='false'
                          aria-controls='accordion-fifteen'
                        >
                          <i className='fas fa-angle-down' />
                          Are there any limits to the number of Properties I can
                          list on Jagah Online?
                        </a>
                      </div>
                      <div
                        id='accordion-fifteen'
                        className='collapse'
                        aria-labelledby='accordion-title-fifteen'
                        data-parent='#accordion'
                      >
                        <div className='accordion-content'>
                          ProSeller can list unlimited properties on Jagah
                          Online without any problems.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-sixteen'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-sixteen'
                          aria-expanded='false'
                          aria-controls='accordion-sixteen'
                        >
                          <i className='fas fa-angle-down' />
                          Do I contact more than one agent for buying and
                          selling decisions?
                        </a>
                      </div>
                      <div
                        id='accordion-sixteen'
                        className='collapse'
                        aria-labelledby='accordion-title-sixteen'
                        data-parent='#accordion'
                      >
                        <div className='accordion-content'>
                          Yes, you have the freedom to contact as many agents as
                          you like!
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-seventeen'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-seventeen'
                          aria-expanded='false'
                          aria-controls='accordion-seventeen'
                        >
                          <i className='fas fa-angle-down' />
                          Which property location is best?
                        </a>
                      </div>
                      <div
                        id='accordion-seventeen'
                        className='collapse'
                        aria-labelledby='accordion-title-seventeen'
                        data-parent='#accordion'
                      >
                        <div className='accordion-content'>
                          The best property location for you depends on a
                          variety of factors including amenities, utilities,
                          your budget, etc. Stay connected to our blogs to get
                          more insight on how to make the right buying and
                          renting decisions.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-eighteen'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-eighteen'
                          aria-expanded='false'
                          aria-controls='accordion-eighteen'
                        >
                          <i className='fas fa-angle-down' />
                          How do I inquire about a project on Jagah Online?
                        </a>
                      </div>
                      <div
                        id='accordion-eighteen'
                        className='collapse'
                        aria-labelledby='accordion-title-eighteen'
                        data-parent='#accordion'
                      >
                        <div className='accordion-content'>
                          If you wish to know more details about a project on
                          our site, go to the project and enter your email
                          address with your name and query or call on the
                          provided number. You will soon be contacted by someone
                          on their team.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='tab-pane fade'
                  id='tab-02'
                  role='tabpanel'
                  aria-labelledby='tab-02-tab'
                >
                  <div className='accordion accordion-faq' id='accordiontwo'>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-ninteen'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-ninteen'
                          aria-expanded='true'
                          aria-controls='accordion-ninteen'
                        >
                          <i className='fas fa-angle-down' />
                          What is the Ustad feature on Jagah Online?
                        </a>
                      </div>
                      <div
                        id='accordion-ninteen'
                        className='collapse'
                        aria-labelledby='accordion-title-ninteen'
                        data-parent='#accordiontwo'
                      >
                        <div className='accordion-content'>
                          Ustad feature on Jagah Online is for Ustad including
                          electricians, laborers, plumbers, carpenters, etc. If
                          you are a Ustad, you can have your profile on our site
                          and find work in no time. If you are looking for a
                          Ustad, you can find them easily via the feature.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-twenty'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-twenty'
                          aria-expanded='false'
                          aria-controls='accordion-twenty'
                        >
                          <i className='fas fa-angle-down' />
                          How do I choose a Ustad?
                        </a>
                      </div>
                      <div
                        id='accordion-twenty'
                        className='collapse'
                        aria-labelledby='accordion-title-twenty'
                        data-parent='#accordiontwo'
                      >
                        <div className='accordion-content'>
                          You can view hundreds of Ustad profiles on Jagah
                          Online. Check their expertise, years of experience,
                          and contact them on their contact details before
                          making your decision.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-twentyone'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-twentyone'
                          aria-expanded='false'
                          aria-controls='accordion-twentyone'
                        >
                          <i className='fas fa-angle-down' />
                          Are Ustad on Jagah Online genuine?
                        </a>
                      </div>
                      <div
                        id='accordion-twentyone'
                        className='collapse'
                        aria-labelledby='accordion-title-twentyone'
                        data-parent='#accordiontwo'
                      >
                        <div className='accordion-content'>
                          You can rest assured that all Ustad profiles on Jagah
                          Online are 100% genuine.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='tab-pane fade'
                  id='tab-03'
                  role='tabpanel'
                  aria-labelledby='tab-03-tab'
                >
                  <div className='accordion accordion-faq' id='accordionthree'>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-twentythree'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-twentythree'
                          aria-expanded='false'
                          aria-controls='accordion-twentythree'
                        >
                          <i className='fas fa-angle-down' />
                          Who is the Jagah Online ProSeller for?
                        </a>
                      </div>
                      <div
                        id='accordion-twentythree'
                        className='collapse'
                        aria-labelledby='accordion-title-twentythree'
                        data-parent='#accordionthree'
                      >
                        <div className='accordion-content'>
                          Jagah Online ProSeller is for brokers, builders,
                          handymen, and traders. Through which they can sell or
                          rent their products, services, or properties easily
                          and make use of dozens of features.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-twentyfour'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-twentyfour'
                          aria-expanded='false'
                          aria-controls='accordion-twentyfour'
                        >
                          <i className='fas fa-angle-down' />
                          Is my data secure with Jagah Online ProSeller?
                        </a>
                      </div>
                      <div
                        id='accordion-twentyfour'
                        className='collapse'
                        aria-labelledby='accordion-title-twentyfour'
                        data-parent='#accordionthree'
                      >
                        <div className='accordion-content'>
                          Yes, you can absolutely trust Jagah Online ProSeller
                          with your data. We make use of the best data security
                          software to ensure that your data remains safe and
                          secure.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-twentyfive'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-twentyfive'
                          aria-expanded='false'
                          aria-controls='accordion-twentyfive'
                        >
                          <i className='fas fa-angle-down' />
                          What does the Analytics feature on my dashboard
                          entail?
                        </a>
                      </div>
                      <div
                        id='accordion-twentyfive'
                        className='collapse'
                        aria-labelledby='accordion-title-twentyfive'
                        data-parent='#accordionthree'
                      >
                        <div className='accordion-content'>
                          You can monitor your agency and property analytics via
                          this feature as well as keep track of your email and
                          call count.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-twentysix'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-twentysix'
                          aria-expanded='false'
                          aria-controls='accordion-twentysix'
                        >
                          <i className='fas fa-angle-down' />
                          What can I do with the Reporting feature on Jagah
                          Online ProSeller?
                        </a>
                      </div>
                      <div
                        id='accordion-twentysix'
                        className='collapse'
                        aria-labelledby='accordion-title-twentysix'
                        data-parent='#accordionthree'
                      >
                        <div className='accordion-content'>
                          Through the Reporting feature, you can view your
                          financial and property listing reports.perty analytics
                          via this feature as well as keep track of your email
                          and call count.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-twentyseven'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-twentyseven'
                          aria-expanded='false'
                          aria-controls='accordion-twentyseven'
                        >
                          <i className='fas fa-angle-down' />
                          Is there any way to keep track of my leads on
                          ProSeller?
                        </a>
                      </div>
                      <div
                        id='accordion-twentyseven'
                        className='collapse'
                        aria-labelledby='accordion-title-twentyseven'
                        data-parent='#accordionthree'
                      >
                        <div className='accordion-content'>
                          Absolutely! You can manage all your leads via the
                          Rocket CRM feature on Jagah Online ProSeller.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-twentyeight'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-twentyeight'
                          aria-expanded='false'
                          aria-controls='accordion-twentyeight'
                        >
                          <i className='fas fa-angle-down' />
                          Is there a mobile app for Jagah Online ProSeller?
                        </a>
                      </div>
                      <div
                        id='accordion-twentyeight'
                        className='collapse'
                        aria-labelledby='accordion-title-twentyeight'
                        data-parent='#accordionthree'
                      >
                        <div className='accordion-content'>
                          Yes, you can download the Jagah Online ProSeller app
                          from the Apple App Store and Play Store. Manage your
                          property business on the go with the Jagah Online
                          ProSeller app!
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-twentynine'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-twentynine'
                          aria-expanded='false'
                          aria-controls='accordion-twentynine'
                        >
                          <i className='fas fa-angle-down' />
                          Do I need any training to use Jagah Online ProSeller?
                        </a>
                      </div>
                      <div
                        id='accordion-twentynine'
                        className='collapse'
                        aria-labelledby='accordion-title-twentynine'
                        data-parent='#accordionthree'
                      >
                        <div className='accordion-content'>
                          Jagah Online is quite simple to use and you will get
                          the hang of it without any training by spending some
                          time using it. For any queries, we are always here to
                          help.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-thirty'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-thirty'
                          aria-expanded='false'
                          aria-controls='accordion-thirty'
                        >
                          <i className='fas fa-angle-down' />
                          Does Jagah Online make any transactions?
                        </a>
                      </div>
                      <div
                        id='accordion-thirty'
                        className='collapse'
                        aria-labelledby='accordion-title-thirty'
                        data-parent='#accordionthree'
                      >
                        <div className='accordion-content'>
                          Jagah Online is quite simple to use and you will get
                          the hang of it without any training by spending some
                          time using it. For any queries, we are always here to
                          help.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-thirtyone'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-thirtyone'
                          aria-expanded='false'
                          aria-controls='accordion-thirtyone'
                        >
                          <i className='fas fa-angle-down' />
                          Is registration free for Jagah Online ProSeller?
                        </a>
                      </div>
                      <div
                        id='accordion-thirtyone'
                        className='collapse'
                        aria-labelledby='accordion-title-thirtyone'
                        data-parent='#accordionthree'
                      >
                        <div className='accordion-content'>
                          Yes, registration is absolutely free for Jagah Online
                          ProSeller.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-thirtytwo'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-thirtytwo'
                          aria-expanded='false'
                          aria-controls='accordion-thirtytwo'
                        >
                          <i className='fas fa-angle-down' />
                          How do I upgrade my pricing plan?
                        </a>
                      </div>
                      <div
                        id='accordion-thirtytwo'
                        className='collapse'
                        aria-labelledby='accordion-title-thirtytwo'
                        data-parent='#accordionthree'
                      >
                        <div className='accordion-content'>
                          In your ProSeller portal, go to menu and tap/click on
                          Billing and Plans, from there select pricing plans
                          where you can choose from different pricing plans and
                          upgrade to the one that you find best.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-thirtythree'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-thirtythree'
                          aria-expanded='false'
                          aria-controls='accordion-thirtythree'
                        >
                          <i className='fas fa-angle-down' />
                          How do I change my agent Name and Password on
                          ProSeller?
                        </a>
                      </div>
                      <div
                        id='accordion-thirtythree'
                        className='collapse'
                        aria-labelledby='accordion-title-thirtythree'
                        data-parent='#accordionthree'
                      >
                        <div className='accordion-content'>
                          To change your name, password, email, profile picture,
                          or contact number, go to Administration from the menu
                          and then click/tap on My Profile where you will be
                          able to modify and update your profile details.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-thirtyfour'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-thirtyfour'
                          aria-expanded='false'
                          aria-controls='accordion-thirtyfour'
                        >
                          <i className='fas fa-angle-down' />
                          Are there any costs for advertising my listings?
                        </a>
                      </div>
                      <div
                        id='accordion-thirtyfour'
                        className='collapse'
                        aria-labelledby='accordion-title-thirtyfour'
                        data-parent='#accordionthree'
                      >
                        <div className='accordion-content'>
                          No, there are not any costs for advertising your
                          listings. It is absolutely free and fair!
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-thirtyfive'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-thirtyfive'
                          aria-expanded='false'
                          aria-controls='accordion-thirtyfive'
                        >
                          <i className='fas fa-angle-down' />
                          Are there any premium or featured listings?
                        </a>
                      </div>
                      <div
                        id='accordion-thirtyfive'
                        className='collapse'
                        aria-labelledby='accordion-title-thirtyfive'
                        data-parent='#accordionthree'
                      >
                        <div className='accordion-content'>
                          No, there is no premium or featured listings. Jagah
                          Online does 100% free and fair advertising for your
                          listings all the way through.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-thirtysix'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-thirtysix'
                          aria-expanded='false'
                          aria-controls='accordion-thirtysix'
                        >
                          <i className='fas fa-angle-down' />
                          Can I give Access to my Jagah Online account to
                          another person?
                        </a>
                      </div>
                      <div
                        id='accordion-thirtysix'
                        className='collapse'
                        aria-labelledby='accordion-title-thirtysix'
                        data-parent='#accordionthree'
                      >
                        <div className='accordion-content'>
                          For security reasons, we advise you not to disclose
                          your account details to another person.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item accordion-item-faq'>
                      <div
                        className='accordion-title'
                        id='accordion-title-thirtyseven'
                      >
                        <a
                          href='#'
                          className='collapsed transform-accordian'
                          data-toggle='collapse'
                          data-target='#accordion-thirtyseven'
                          aria-expanded='false'
                          aria-controls='accordion-thirtyseven'
                        >
                          <i className='fas fa-angle-down' />
                          Do I benefit from upgrades to Jagah Online and how
                          often do you update the CRM?
                        </a>
                      </div>
                      <div
                        id='accordion-thirtyseven'
                        className='collapse'
                        aria-labelledby='accordion-title-thirtyseven'
                        data-parent='#accordionthree'
                      >
                        <div className='accordion-content'>
                          We always make useful upgrades to Jagah Online to make
                          the experience of the portal more reliable for its
                          users.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}

export default Faq
