import React from 'react'
import {BrowserView, MobileView, isBrowser, isMobile} from 'react-device-detect';
import "../../css/jo-projects.css";

import {
    API_URL,
    BASE_URL,
    themeDefault,
    namesOfModes,
    jsonHeader,
    multiFormHeader,
} from '../../../../Constants/global'

function KingsComfort() {

    return (
        <main className="joProject">
            {/*JO Project Browser View*/}
            <BrowserView>
                <div className="BrowserView">
                    {/*Project Header*/}
                    <header className="header-stick pj-is-sticky">
                        <div className="container-fluid bg-dark">
                            <nav className="navbar navbar-expand-lg navbar-dark bg-dark header-sticky-single">
                                <div className="project-logo-main">
                                    <a className="navbar-brand ml-2" href="#">
                                        <img className="img-fluid" width={175}
                                             height={46}
                                             src={BASE_URL + "classified_assets/images/logo-light.svg"}
                                             alt="" loading="lazy"/>
                                    </a>
                                    <a className="navbar-brand mx-4" href="#">
                                        <img className="img-fluid" width={165}
                                             src={BASE_URL + "classified_assets/images/jo-projects/king-comfort-v2/kc_logo.svg"}
                                             alt="" loading="lazy"/>
                                    </a>
                                </div>
                                <button className="navbar-toggler" type="button" data-toggle="collapse"
                                        data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02"
                                        aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"/>
                                </button>
                                <div className="collapse navbar-collapse project-nav-jo" id="navbarTogglerDemo02">
                                    <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                                        <li className="nav-item">
                                            <a className="nav-link" href="#features">Features <span
                                                className="sr-only">(current)</span></a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#developer">About the Developer</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#location">Location</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#payment">Payment Plan</a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </header>
                    {/*Project Header*/}

                    {/*Banner Header Project*/}
                    <section className="banner-header-project"
                             style={{backgroundImage: 'url(../../../classified_assets/images/jo-projects/king-comfort-v2/kc_banner.jpg)'}}/>
                    {/*Banner Header Project*/}

                    {/*Project Features*/}
                    <section id="features" className="jp-features-section space-ptb">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center mb-5">
                                    <h1>Features</h1>
                                </div>
                            </div>
                            <div className="jp-features-main">
                                <div className="row">
                                    <div className="col-md-4 mb-5">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/king-comfort-v2/standstall.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Stand Tall</h6>
                                                <p>Kings Comforts is located
                                                    among the high rises of
                                                    Nazimabad Block 3.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-5">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/king-comfort-v2/elevator.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>G+12 Elevation
                                                </h6>
                                                <p>Kings Comforts elevation is
                                                    designed on international
                                                    standards.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-5">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/king-comfort-v2/approvals.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Approvals</h6>
                                                <p>Kings Comforts is approved
                                                    by KDA and SBCA.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-5">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/king-comfort-v2/lifts.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Amenities</h6>
                                                <p>Kings Comforts offers world
                                                    class amenities like High Speed
                                                    Lifts and Garbage Chutes.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-5">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/king-comfort-v2/rooms.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>4 &amp; 5 Rooms
                                                </h6>
                                                <p>Kings Comforts offers 4 &amp; 5
                                                    rooms apartments with serene
                                                    views.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-5">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/king-comfort-v2/connectivity.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Connectivity</h6>
                                                <p>Kings Comforts is located in mid
                                                    of the city connected with every
                                                    major area.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*Project Features*/}

                    {/*Project Developer*/}
                    <section id="developer" className="about-builder-section space-ptb">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-4">
                                    <div className="about-developer-img-main">
                                        <img className="about-developer-img img-fluid" alt="img"
                                             src={BASE_URL + "classified_assets/images/jo-projects/king-comfort-v2/kc-mid.jpg"}
                                             loading="lazy"/>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="about-developer-descp text-white">
                                        <h1>
                                            About <span>Kings Group</span>
                                        </h1>
                                        <p>
                                            Kings Group is one of the pioneers of private construction companies in the
                                            country. Kings
                                            Group
                                            started
                                            delivering projects from the 80’s and has delivered over 20 projects till
                                            now
                                            with the same
                                            more
                                            under construction.
                                        </p>
                                        <p>
                                            Kings Comforts is another landmark project of the builder located at the
                                            ideal
                                            location of
                                            Nazimabad block 3.
                                            Kings Group has make sure to equip this project with all the modern
                                            amenities
                                            which will
                                            give
                                            you the
                                            utmost comfort and better living standards everyone wish for
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*Project Developer*/}

                    {/*Project Location*/}
                    <section id="location" className="jo-proj-loc-main space-ptb">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    <div className="jo-proj-loc-main-inner mb-4">
                                        <div className="jo-location-heading">
                                            <span className="line-horizontal">Location <span>Highlights</span></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="jo-location-points">
                                        <div className="jo-location-points-route-km">
                                            2 KM FROM
                                        </div>
                                        <div className="jo-location-points-route">
                                            ABBASI SHAHEED HOSPITAL
                                        </div>
                                    </div>
                                    <div className="jo-location-points">
                                        <div className="jo-location-points-route-km">
                                            3 KM FROM
                                        </div>
                                        <div className="jo-location-points-route">
                                            MATRIC BOARD OFFICE
                                        </div>
                                    </div>
                                    <div className="jo-location-points">
                                        <div className="jo-location-points-route-km">
                                            8 KM FROM
                                        </div>
                                        <div className="jo-location-points-route">
                                            SHAHRA-E-FAISAL
                                        </div>
                                    </div>
                                    <div className="jo-location-points d-flex align-items-center">
                                        <div className="jo-location-points-route">
                                            CONNECTED WITH GREEN LINE
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="jo-location-iframe">
                                        <img className="img-fluid" alt="img"
                                             src={BASE_URL + "classified_assets/images/jo-projects/king-comfort-v2/map_kc.png"}
                                             loading="lazy"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*Project Location*/}

                    {/*Project Payment*/}
                    <section id="payment" className="inns-plans-section space-pt">
                        <div className="container">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-md-3">
                                    <div className="starting-from-main">
                                        <div className="starting-from-inner">
                                            <div className="black-head">Price Starting From</div>
                                            <div className="read-head"><span>PKR</span> 9,800,000</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 text-center">
                                    <div className="starting-from-main">
                                        <div className="starting-from-img-logo">
                                            <img className="starting-from-img-lg img-fluid" alt="img"
                                                 src={BASE_URL + "classified_assets/images/jo-projects/king-comfort-v2/kc_logo_black.svg"}
                                                 loading="lazy"/>
                                        </div>
                                        <div className="starting-from-main-inner-head">
                                            Appartments
                                        </div>
                                        <div className="starting-from-img-main">
                                            <img className="starting-from-img-mn img-fluid" alt="img"
                                                 src={BASE_URL + "classified_assets/images/jo-projects/king-comfort-v2/kc_unit.jpg"}
                                                 loading="lazy"/>
                                        </div>
                                        <div className="read-head-blk"><span>3.5 Years</span> Easy Installment Plan
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="starting-from-main">
                                        <div className="starting-from-inner">
                                            <div className="black-head">Installments Starting From</div>
                                            <div className="read-head"><span>PKR</span> 65,000</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*Project Payment*/}
                </div>
            </BrowserView>
            {/*JO Project Browser View*/}

            {/*JO Project Mobile View*/}
            <MobileView>
                <div className="MobileView">
                    {/*Project Header*/}
                    <header className="header-stick">
                        <div className="container-fluid bg-dark">
                            <nav className="navbar navbar-expand-lg navbar-dark bg-dark header-sticky-single">
                                <div className="project-logo-main d-flex">
                                    <div className="col-md-6">
                                        <a className="navbar-brands" href="#">
                                            <img className="img-fluid w-100"
                                                 style={{marginTop: '10px'}}
                                                 src={BASE_URL + "classified_assets/images/logo-light.svg"}
                                                 alt="" loading="lazy"/>
                                        </a>
                                    </div>
                                    <div className="col-md-6">
                                        <a className="navbar-brands" href="#">
                                            <img className="img-fluid w-100"
                                                 style={{padding: '5px'}}
                                                 src={BASE_URL + "classified_assets/images/jo-projects/king-comfort-v2/kc_logo.svg"}
                                                 alt="" loading="lazy"/>
                                        </a>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </header>
                    {/*Project Header*/}

                    {/*Banner Header Project*/}
                    <section className="banner-header-project"
                             style={{backgroundImage: 'url(../../../classified_assets/images/jo-projects/king-comfort-v2/kc_banner.jpg)'}}/>
                    {/*Banner Header Project*/}

                    {/*Project Features*/}
                    <section id="features" className="jp-features-section space-ptb">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center mb-2">
                                    <h1>Features</h1>
                                </div>
                            </div>
                            <div className="jp-features-main">
                                <div className="row">
                                    <div className="col-md-4 mb-3">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/king-comfort-v2/standstall.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Stand Tall</h6>
                                                <p>Kings Comforts is located
                                                    among the high rises of
                                                    Nazimabad Block 3.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/king-comfort-v2/elevator.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>G+12 Elevation
                                                </h6>
                                                <p>Kings Comforts elevation is
                                                    designed on international
                                                    standards.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/king-comfort-v2/approvals.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Approvals</h6>
                                                <p>Kings Comforts is approved
                                                    by KDA and SBCA.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/king-comfort-v2/lifts.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Amenities</h6>
                                                <p>Kings Comforts offers world
                                                    class amenities like High Speed
                                                    Lifts and Garbage Chutes.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/king-comfort-v2/rooms.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>4 &amp; 5 Rooms
                                                </h6>
                                                <p>Kings Comforts offers 4 &amp; 5
                                                    rooms apartments with serene
                                                    views.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/king-comfort-v2/connectivity.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Connectivity</h6>
                                                <p>Kings Comforts is located in mid
                                                    of the city connected with every
                                                    major area.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*Project Features*/}

                    {/*Project Developer*/}
                    <section id="developer" className="about-builder-section space-ptb">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-5">
                                    <div className="about-developer-img-main">
                                        <img className="about-developer-img img-fluid" alt="img"
                                             src={BASE_URL + "classified_assets/images/jo-projects/king-comfort-v2/kc-mid.jpg"}
                                             loading="lazy"/>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <div className="about-developer-descp text-white">
                                        <h1>
                                            About <span>Kings Group</span>
                                        </h1>
                                        <p>
                                            Kings Group is one of the pioneers of private construction companies in the
                                            country. Kings
                                            Group
                                            started
                                            delivering projects from the 80’s and has delivered over 20 projects till
                                            now with the same
                                            more
                                            under construction.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="about-developer-descp text-white">
                                        <p className="mb-0">
                                            <span>Kings Comforts</span> is another landmark project of the builder
                                            located at the ideal
                                            location of
                                            Nazimabad block 3.
                                            Kings Group has make sure to equip this project with all the modern
                                            amenities which will
                                            give
                                            you the
                                            utmost comfort and better living standards everyone wish for
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*Project Developer*/}

                    {/*Project Location*/}
                    <section id="location" className="jo-proj-loc-main space-ptb">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    <div className="jo-proj-loc-main-inner mb-4">
                                        <div className="jo-location-heading">
                                            <span className="line-horizontal">Location <span>Highlights</span></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="jo-location-points">
                                        <div className="jo-location-points-route-km">
                                            2 KM FROM
                                        </div>
                                        <div className="jo-location-points-route">
                                            ABBASI SHAHEED HOSPITAL
                                        </div>
                                    </div>
                                    <div className="jo-location-points">
                                        <div className="jo-location-points-route-km">
                                            3 KM FROM
                                        </div>
                                        <div className="jo-location-points-route">
                                            MATRIC BOARD OFFICE
                                        </div>
                                    </div>
                                    <div className="jo-location-points">
                                        <div className="jo-location-points-route-km">
                                            8 KM FROM
                                        </div>
                                        <div className="jo-location-points-route">
                                            SHAHRA-E-FAISAL
                                        </div>
                                    </div>
                                    <div className="jo-location-points d-flex align-items-center">
                                        <div className="jo-location-points-route">
                                            CONNECTED WITH GREEN LINE
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="jo-location-iframe">
                                        <img className="img-fluid" alt="img"
                                             src={BASE_URL + "classified_assets/images/jo-projects/king-comfort-v2/map_kc.png"}
                                             loading="lazy"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*Project Location*/}

                    {/*Project Payment*/}
                    <section id="payment" className="inns-plans-section space-ptb">
                        <div className="container">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-md-3">
                                    <div className="starting-from-main">
                                        <div className="starting-from-main-inner-head mt-0">
                                            Appartments
                                        </div>
                                        <div className="starting-from-inner">
                                            <div className="black-head">Price Starting From</div>
                                            <div className="read-head"><span>PKR</span> 9,800,000</div>
                                            <div className="black-head">Installments Starting From</div>
                                            <div className="read-head"><span>PKR</span> 65,000</div>
                                        </div>
                                        <div className="read-head-blk"><span>3.5 Years</span> Easy Installment Plan
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*Project Payment*/}
                </div>
            </MobileView>
            {/*JO Project Browser View*/}
        </main>
    )
}

export default KingsComfort