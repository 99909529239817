import React, { Fragment, useState } from "react";
import { setAuthUser } from "../../../../Redux/actions/authUser";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams, Link } from "react-router-dom";
import ReactCodeInput from "react-code-input";
import { useFormik, yupToFormErrors } from "formik";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";
import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  multiFormHeader,
  USER_AGENCY_PROFILE_IMAGEURL,
  USER_IMAGE_NOT_FOUND_URL,
  USER_PROFILE_URL,
  EMAIL_SERVICE_ID,
  EMAIL_VERIFICATION_TEMPLATE_ID,
  EMAIL_PUBLIC_KEY,
} from "../../../../Constants/global";

import axios from "axios";

const UstadAuth = ({ setAuthUser }) => {
  const [message, setMessage] = useState("");
  const verify_params = useParams();
  const [vcode, setVcode] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState({
    isLoading: false,
    disabled: false,
  });
  const [mobile_number, setMobile_number] = useState("");
  const [modelState, setModelState] = useState({
    disabled: false,
    btnVisibility: "hidden",
    btnTxt: "",
  });
  const initialValues_number = {
    number: "",
    // email: "",
    // contact_number: "",
    // message: "",
    // customCheck1: false,
  };
  const phoneRegExp =
    /^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/;
  const validateSchema_number = Yup.object().shape({
    number: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
    // message: Yup.string().required("Required"),
    // email: Yup.string().email("Invalid email format").required("Required"),
    // contact_number: Yup.number().required("Required"),
    // customCheck1: Yup.boolean().oneOf([true], "Please agree before processing"),
  });
  const onSubmit_number = (values) => {
    console.log(values);
    setIsLoading({
      isLoading: true,
      disabled: true,
    });
    var form = new FormData();
    form.append("phone_number", values.number);
    axios({
      method: "POST",
      url: API_URL + "auth/login_pn",
      data: form,
      headers: multiFormHeader,
    }).then((response) => {
      if (response.status === 200) {
        setIsLoading({
          isLoading: false,
          disabled: false,
        });
        if (response.data.status === 404) {
          localStorage.setItem("user_id", response.data.user_id);
          setIsLoading({
            isLoading: false,
            disabled: false,
          });
          setModelState({
            disabled: true,
            btnVisibility: "",
            btnTxt: "register",
          });
          setMobile_number(values.number);
        } else {
          localStorage.setItem("user_id", response.data.user.id);
          setModelState({
            disabled: true,
            btnVisibility: "",
            btnTxt: "login",
          });
          setIsLoading({
            isLoading: false,
            disabled: false,
          });
        }
      } else {
        console.log("Server Error: " + "" + response.status);
        setIsLoading({
          isLoading: false,
          disabled: false,
        });
      }
    });

    // document.getElementById("register-tab-btn").click();
  };
  const formik_number = useFormik({
    initialValues: initialValues_number,
    onSubmit: onSubmit_number,
    validationSchema: validateSchema_number,
  });

  // ---------------------------Register----------
  const initialValues_reg = {
    fullname: "",
    number: mobile_number,
    gender: "",
  };
  const validateSchema_reg = Yup.object().shape({
    fullname: Yup.string().required("Name is required"),
    gender: Yup.string().required("Gender is required"),
  });
  const onSubmit_reg = (values) => {
    console.log(values);

    var form = new FormData();
    const name = values.fullname.split(" ");
    form.append("first_name", name[0]);
    form.append("last_name", name[1]);
    form.append("email", "");
    form.append("phone_number", values.number);
    form.append("role", 5);
    form.append("password", "");

    axios({
      method: "POST",
      url: API_URL + "api/create_user",
      data: form,
      headers: multiFormHeader,
    }).then((response) => {
      if (response.data.status === 200) {
        localStorage.setItem("user_id", response.data.user_id);
        setIsLoading({
          isLoading: false,
          disabled: false,
        });
        setModelState({
          disabled: true,
          btnVisibility: "",
          btnTxt: "login",
        });
        // setApierror(response.data.message);

        // setErrorclass("error text-success");

        var templateParams = {
          name: name[0],
          to_email: values.email,
          remember_pin: response.data.code,
          email_verify_url: BASE_URL + "/verify/" + response.data?.user_id,
        };
        localStorage.setItem("remember_pin", response.data.code);

        emailjs
          .send(
            EMAIL_SERVICE_ID,
            EMAIL_VERIFICATION_TEMPLATE_ID,
            templateParams,
            EMAIL_PUBLIC_KEY
          )
          .then((result) => {});

        setTimeout(function () {
          // window.location.href = "/verify/" + response.data?.user_id;
        }, 3000);
      } else {
        // setIsLoading({
        //   isLoading: false,
        //   disabled: false,
        // });
        // setApierror(response.data.message);
        // setErrorclass("error text-danger");

        if (response.data.message === "Please verify your account.") {
          // localStorage.setItem("user_id", response.data.user_id);
          // localStorage.setItem("name", values.first_name);
          // localStorage.setItem("to_email", values.email);
          // localStorage.setItem("remember_pin", response.data.code);
        }
      }
    });
  };
  const formik_reg = useFormik({
    initialValues: initialValues_reg,
    onSubmit: onSubmit_reg,
    enableReinitialize: true,
    validationSchema: validateSchema_reg,
  });
  // ---------------------------Register-----------------------

  const handleChange = (value) => {
    setVcode(value);
  };

  const handleVerification = () => {
    if (vcode.length === 0 || vcode.length < 6) {
      setError("Invalid Code.");
    } else {
      axios({
        method: "GET",
        url:
          API_URL +
          "api/verify_user/" +
          localStorage.getItem("user_id") +
          "/" +
          vcode,

        headers: multiFormHeader,
      }).then((response) => {
        if (response.data.status === 200) {
          window.location.href = "/ustad";
          setMessage("");
          //   ----------------------------------------------------
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("user", response.data.data);
          localStorage.setItem("user_type", response.data?.data.user_type);
          localStorage.setItem(
            "total_favorites",
            response.data.total_favorites
          );
          if (response.data?.data.user_type === "Agent") {
            localStorage.setItem(
              "agency_profile_id",
              response.data?.data.agency_profile.id
            );
          }

          setAuthUser();

          // -----------------------------------------------------
          console.log(response.data);
        } else {
          // alert("Wrong code");
          setMessage("Invalid code.");
          setError(response.data.message);
        }
      });
    }
  };
  return (
    <Fragment>
      {/* <div className="login d-inline-block">
        <a data-toggle="modal" data-target="#loginModalUstad" href="#">
          Hello sign in
          <i className="fa fa-user pl-2" />
        </a>
      </div> */}
      {/*=================================
 Modal login */}
      <div
        className="ustad-auth-modal modal login fade"
        id="loginModalUstad"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="loginModalUstadLabel"
        aria-hidden="true"
      >
        <div
          className="ustad-auth-dialog modal-dialog modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header py-4 border-0 text-center position-relative">
              <div className="col-md-12 text-center">
                <a className="navbar-brand p-0" href="/">
                  <img
                    className="img-fluid"
                    width={220}
                    src={BASE_URL + "classified_assets/images/logo.svg"}
                    alt="logo"
                  />
                </a>
              </div>
              <button
                type="button"
                className="close btn-modal-close"
                width={26}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="login"
                  role="tabpanel"
                  aria-labelledby="login-tab"
                >
                  <div className="row align-items-center">
                    <div className="col-md-5">
                      <div className="welcome-note">
                        <h3>Welcome to JagahOnline</h3>
                        <h4>Let's Start!</h4>
                      </div>
                      <form
                        className="form-row mt-4 align-items-center"
                        onSubmit={formik_number.handleSubmit}
                      >
                        <div className="form-group col-sm-12">
                          <div className="input-absolute position-relative">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Your Mobile Number"
                              name="number"
                              onChange={formik_number.handleChange}
                              // value={formik.values.number}
                            />
                            <i className="fas fa-mobile-alt"></i>
                            {formik_number.touched.number &&
                            formik_number.errors.number ? (
                              <div className="error text-danger">
                                {" "}
                                {formik_number.errors.number}{" "}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <button
                            className="btn btn-primary"
                            id="register-tab"
                            // data-toggle={modelState}
                            // disabled={modelState.disabled}
                            disabled={isLoading.disabled}
                            // href="#register"
                            // role="tab"
                            // aria-controls="register"
                            // aria-selected="true"
                            type="submit"
                          >
                            Continue
                            <span
                              hidden={isLoading.disabled - 1}
                              className="spinner-border spinner-border-sm ml-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </button>
                        </div>
                        {modelState.btnVisibility !== "hidden" ? (
                          <div className="tc-main text-black-100">
                            <a
                              // className="btn btn-primary"
                              id={
                                modelState.btnTxt === "login"
                                  ? "verification-tab"
                                  : "register-tab"
                              }
                              data-toggle="tab"
                              href={
                                modelState.btnTxt === "login"
                                  ? "#verification"
                                  : "#register"
                              }
                              role="tab"
                              aria-controls="register"
                              aria-selected="true"
                            >
                              {modelState.btnTxt === "login"
                                ? "Enter OTP"
                                : "Get Registered"}
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                      </form>

                      <hr className="mt-5" />
                      <div className="tc-main mb-2 text-black-100">
                        Here you can see our{" "}
                        <a href="#" target="_blank">
                          Terms & Conditions
                        </a>{" "}
                        or
                      </div>
                      <div className="pp-main">
                        Visit our{" "}
                        <a href="#" target="_blank">
                          Privacy Policy.
                        </a>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="side-image-auth mb-4">
                        <img
                          className="img-fluid"
                          alt="AC Services"
                          src="/classified_assets/images/handymen-images/auth/login-vector.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="register"
                  role="tabpanel"
                  aria-labelledby="register-tab"
                >
                  <div className="row align-items-center">
                    <div className="col-md-5">
                      <div className="welcome-note">
                        <h3>Welcome to JagahOnline</h3>
                        <h4>Let's Start!</h4>
                      </div>
                      <form
                        className="form-row mt-4 align-items-center"
                        onSubmit={formik_reg.handleSubmit}
                      >
                        <div className="form-group col-sm-12">
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Your Full Name"
                              name="fullname"
                              onChange={formik_reg.handleChange}
                            />
                          </div>
                          {formik_reg.touched.fullname &&
                          formik_reg.errors.fullname ? (
                            <div className="error text-danger">
                              {" "}
                              {formik_reg.errors.fullname}{" "}
                            </div>
                          ) : null}
                        </div>
                        <div className="form-group col-sm-12">
                          <div className="position-relative">
                            <input
                              type="text"
                              name="number"
                              onChange={formik_reg.handleChange}
                              disabled="true"
                              className="form-control"
                              value={formik_reg.values.number}
                              placeholder="Your Mobile Number"
                            />
                          </div>
                        </div>
                        <div className="form-group col-sm-12">
                          <div className="input-absolute position-relative">
                            <select
                              // form-control basic-select border-0
                              className="form-control border-0 "
                              onBlur={formik_reg.handleBlur}
                              onChange={formik_reg.handleChange}
                              name="gender"
                            >
                              {/* <option value={""}>{"Gender"}</option> */}
                              <option label="Gender"></option>
                              <option value={"Male"}>{"Male"}</option>
                              <option value={"Female"}>{"Female"}</option>
                              <option value={"Others"}>{"Others"}</option>
                            </select>
                            {formik_reg.touched.gender &&
                            formik_reg.errors.gender ? (
                              <div className="error text-danger">
                                {" "}
                                {formik_reg.errors.gender}{" "}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        {/* <div className="form-group col-sm-12">
                                                    <div className="position-relative">
                                                        <input type="text" className="form-control"
                                                               placeholder="Password"/>
                                                    </div>
                                                </div> */}
                        <div className="col-sm-6">
                          <button
                            className="btn btn-primary"
                            id="verification-tab"
                            // data-toggle="tab"
                            href="#verification"
                            role="tab"
                            aria-controls="verification"
                            aria-selected="true"
                            type="submit"
                          >
                            Continue
                          </button>
                        </div>
                        {modelState.btnVisibility !== "hidden" ? (
                          <div className="tc-main text-black-100">
                            <a
                              // className="btn btn-primary"
                              id={
                                modelState.btnTxt === "login"
                                  ? "verification-tab"
                                  : "register-tab"
                              }
                              data-toggle="tab"
                              href={
                                modelState.btnTxt === "login"
                                  ? "#verification"
                                  : "#register"
                              }
                              role="tab"
                              aria-controls="register"
                              aria-selected="true"
                            >
                              {modelState.btnTxt === "login" ? "Enter OTP" : ""}
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                      </form>
                      <hr className="mt-5" />
                      <div className="tc-main mb-2 text-black-100">
                        Here you can see our{" "}
                        <a href="#" target="_blank">
                          Terms & Conditions
                        </a>{" "}
                        or
                      </div>
                      <div className="pp-main">
                        Visit our{" "}
                        <a href="#" target="_blank">
                          Privacy Policy.
                        </a>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="side-image-auth mb-4">
                        <img
                          className="img-fluid"
                          alt="AC Services"
                          src="/classified_assets/images/handymen-images/auth/signup-vector.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade verification"
                  id="verification"
                  role="tabpanel"
                  aria-labelledby="verification-tab"
                >
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      {message !== "" ? (
                        <div className="alert alert-danger contactUsAlert">
                          {message}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="verify-note">
                        <h3>OTP Verification</h3>
                        <h4>Enter the pin you have received via SMS on</h4>
                        <h6>{mobile_number}</h6>
                      </div>
                      <form className="form-row mt-4 align-items-center">
                        <ReactCodeInput
                          name="verify-user"
                          inputMode="text"
                          fields={6}
                          type="text"
                          onChange={(value, idex) => {
                            handleChange(value);
                          }}
                        />
                        <div className="col-sm-12 mt-4">
                          {/* <a
                            className="btn btn-primary"
                            id="register-tab"
                            data-toggle="tab"
                            href="#register"
                            role="tab"
                            aria-controls="register"
                            aria-selected="true"
                          >
                            Continue
                          </a> */}
                          <button
                            type="button"
                            className="btn btn-primary btn-block"
                            onClick={handleVerification}
                          >
                            Verify
                          </button>
                        </div>

                        <div className="col-sm-12 mt-4">
                          <div className="resend-otp">
                            Resend OTP in 60 seconds
                          </div>
                          <div className="change-otp-num mt-3">
                            Change Mobile Number?
                          </div>
                        </div>
                      </form>

                      <hr className="mt-5" />
                      <div className="tc-main mb-2 text-black-100">
                        Here you can see our{" "}
                        <a href="#" target="_blank">
                          Terms & Conditions
                        </a>{" "}
                        or
                      </div>
                      <div className="pp-main">
                        Visit our{" "}
                        <a href="#" target="_blank">
                          Privacy Policy.
                        </a>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="side-image-auth mb-4">
                        <img
                          className="img-fluid"
                          alt="AC Services"
                          src="/classified_assets/images/handymen-images/auth/otp-vector.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*=================================
  Modal login */}
    </Fragment>
  );
};
UstadAuth.propTypes = {
  // user_name: PropTypes.object.isRequired,
  setAuthUser: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  // user_name: state.user_profile,
});
export default connect(mapStateToProps, { setAuthUser })(UstadAuth);
