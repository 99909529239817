import axios from 'axios';
import React, {useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import useFetchData from '../../../Helpers/useFetchData';
import emailjs from '@emailjs/browser'
import {
    API_URL,
    themeDefault,
    namesOfModes,
    jsonHeader,
    multiFormHeader1,
    currentUserInformation,
  } from '../../../Constants/global'

export const ContactForm = () => {
    // const { data, loading, error } = useFetchData("http://164.92.102.85:8001/generalservices/contactus")


    const [apierror,setApierror] = useState('');

    //Response Message
    const [Statusmessage, setMessage] = useState({
        message: '',
        class: ''
    });


    function setSuccessMessage(msg){
        setMessage({
            message: msg,
            class: 'alert alert-success contactUsAlert'
        });
    }

    function setErrorMessage(msg){
        setMessage({
            message: msg,
            class: 'alert alert-danger contactUsAlert'
        });
    }

    const initialValues = 
    {
        'name':'',
        'email':'',
        'contact_number':'',
        'subject':'',
        'message':''

    }

    const validateSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email format').required('Required'),
        contact_number: Yup.string().required('Required'),
        // user_role_id: Yup.string().required('Required'),
        subject: Yup.string().required('Required'),
        message: Yup.string().required('Required')
    })

    const onSubmit = values   => {

        // console.log(values)
//alert(localStorage.getItem('token'));
        var form = new FormData()
            // form.append('name', values.first_name)
            form.append('email', values.email)
            form.append('contact_number', values.contact_number)
            form.append('subject', values.subject)
            form.append('message', values.message)

        axios({
            method: 'POST',
            url: API_URL + 'generalservices/contactus',
            data: form,
           headers: multiFormHeader1,
        }).then((response) => {
            // alert(response.data.message);
            if (response.data.status === 200) {
                // console.log(`RESPONCE: ${response.data.message}`);
                // alert(response.data.message);
                setSuccessMessage(response.data.message);
                }
                else{
                    setApierror(response.data.message)
                    // console.log(response.data.message);
                    setErrorMessage(response.data.message);
                }
            })

    }


    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: onSubmit,
        validationSchema:validateSchema
    })

  return (
    <>
              <form  className="form-row align-items-center phone-validation-form" onSubmit={formik.handleSubmit}  >
            <input type="hidden" name="_token" defaultValue="DdVleW6UFWHgWyM34ehxqIOqhgudAej53Di3aGP3" />
            <div className="col-md-6">
              <div className="form-group">
                <div className="floating-label ">
                  <input type="text" name="name"  id="contact_name"  className="form-control" placeholder="Your name" minLength={1} maxLength={100} defaultValue required
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange} 
                    value={formik.values.name} 
                  />
                  {formik.touched.name && formik.errors.name ? <div className='error text-danger'> {formik.errors.name} </div> : null}
                  {/* <label htmlFor="name">Your name
                    <span className="mandatory"> *</span>
                  </label> */}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <div className="floating-label ">
                  <input type="email" name="email"  id="contact_email" className="form-control" placeholder="Your email" minLength={1} maxLength={100} defaultValue required
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange} 
                  value={formik.values.email} 
                  />
                  {formik.touched.email && formik.errors.email ? <div className='error text-danger'> {formik.errors.email} </div> : null}
                  {/* <label className="sign-up-label" htmlFor="email">Your email
                    <span className="mandatory"> *</span>
                  </label> */}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <div className="floating-label ">
                  <input type="text" name="contact_number"  id="contact_phone" className="form-control" placeholder="Cell Number" minLength={1} maxLength={100} defaultValue required
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange} 
                  value={formik.values.contact_number} 
                  />
                  {formik.touched.contact_number && formik.errors.contact_number ? <div className='error text-danger'> {formik.errors.contact_number} </div> : null}
                  <span id="error-msg" className="hide" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <div className="floating-label ">
                  <input type="text" name="subject" id="contact_subject" className="form-control" placeholder="Subject" minLength={1} maxLength={100} defaultValue required
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange} 
                    value={formik.values.subject} 
                  />
                  {formik.touched.subject && formik.errors.subject ? <div className='error text-danger'> {formik.errors.subject} </div> : null}
                  {/* <label className="sign-up-label" htmlFor="subject">Subject
                    <span className="mandatory"> *</span>
                  </label> */}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <div className="floating-label">
                  <textarea type="text" className="form-control floating-textarea "  name="message"  rows={4} id="contact_message" placeholder="Your message" minLength={10} defaultValue={""} required
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange} 
                    value={formik.values.message} 
                  />
                  {formik.touched.message && formik.errors.message ? <div className='error text-danger'> {formik.errors.message} </div> : null}
                </div>
              </div>
            </div>
            <div className="col-md-12 text-center">
              <button type="submit" id="contact_submit" className="btn btn-primary">Get in touch</button>
            </div>
          </form>
          <div className={Statusmessage.class}>{Statusmessage.message}</div>
    </>
  )
}
