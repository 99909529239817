import React, {Fragment} from 'react'

export const Jaga360 = () => {
  return <Fragment>
      <div className="row justify-content-center">
        <div className="col-md-12 col-lg-12 col-sm-12 text-center">
          <div className="cta-main">
            <div className="live-tour-inner">
              <iframe width="100%" height={600} allow="autoplay; gyroscope; accelerometer" src="https://livetour.istaging.com/979a405a-4ea7-4294-85ed-e5c37c9d599e" frameBorder={0} webkitallowfullscreen mozallowfullscreen allowFullScreen />
            </div>
          </div>
        </div>
      </div>
  </Fragment>
}
