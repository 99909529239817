import { SET_AUTH_USER } from '../actions/types'
import axios from 'axios'
import { USER_IMAGE_NOT_FOUND_URL } from '../../Constants/global'

const initialState = {
  user_name: localStorage.getItem('user_name'),
  profile_image: localStorage.getItem('profile_image'),

  user_id: localStorage.getItem('user_id'),
  email: localStorage.getItem('email'),
  first_name: localStorage.getItem('first_name'),
  last_name: localStorage.getItem('last_name'),
  phone_number: localStorage.getItem('phone_number'),
  role_id: localStorage.getItem('role_id'),
  agency_pofile_id: localStorage.getItem('agency_profile_id')

  // agency_profile_id: localStorage.getItem("agency_profile_id"),
}

// function that takes in a state with default as initial state and an action
export default function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case SET_AUTH_USER:
      return payload
    default:
      return state
  }
}
