import React from 'react'
import axios from 'axios'

import { API_URL, USER_IMAGE_NOT_FOUND_URL } from '../../../Constants/global'

// import Swiper core and required modules
import { Navigation, Pagination } from 'swiper'

import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Link } from 'react-router-dom'

function ProjectBox ({ project_Data }) {
  const property_thumbnail =
    'https://cdn.jagahonline.com/properties/275906/front_image-61b98b34e402a-thumb.jpeg'

  const add_to_favorite = e => {
    const response = axios({
      method: 'GET',
      url:
        API_URL +
        'generalservices/property_favorites_add/' +
        e.target.id +
        '/' +
        localStorage.getItem('user_id')
    })

    if (response.status === 200) {
    }
  }

  if (project_Data.length === 0) {
    return (
      <section className='space-ptb'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-12'>
              <photo className='shine w-100 shimmerphoto'></photo>
            </div>
            <div className='col-md-12 mt-1'>
              <lines className='shine w-100 shimmerlines'></lines>
            </div>
          </div>
        </div>
      </section>
    )
  }
  return (
    <Swiper
      className='swiper-landing'
      // install Swiper modules
      modules={[Navigation, Pagination]}
      spaceBetween={10}
      slidesPerView={1}
      breakpoints={{
        768: { slidesPerView: 2 },
        1024: { slidesPerView: 3 }
      }}
      pagination={{ clickable: true }}
    >
      {project_Data.map(function (data, key) {
        console.log('Current Data row ' + data)

        const detail_page_url =
          '/project/' +
          data.city_data[0].city.toLowerCase() +
          '-' +
          data.slug.toLowerCase() +
          '-' +
          data.id

        const builderProfile = data.builder_profile
        // const areaUnit = data.area_unit_data

        var user_data_q = USER_IMAGE_NOT_FOUND_URL

        if (data.builder_profile.length !== 0) {
          if (
            builderProfile[0].profile_image === null ||
            builderProfile[0].profile_image === ''
          ) {
            user_data_q = USER_IMAGE_NOT_FOUND_URL
          } else {
            user_data_q = builderProfile[0].profile_image
          }
        }

        const profile_image_url = user_data_q

        var imageURL = ''
        if (data.front_image !== '' && data.front_image !== 'null') {
          imageURL = data.front_image
        } else {
          imageURL = property_thumbnail
        }

        return (
          <SwiperSlide>
            <div key={key} className='swiper-main'>
              <div className='property-item'>
                <Link to={detail_page_url}>
                  <div className='property-image bg-overlay-gradient-04'>
                    <img
                      className='img-fluid property-image-main'
                      src={imageURL}
                      onError={property_thumbnail}
                      alt=''
                      width={350}
                      height={230}
                      maxWidth={350}
                      maxHeight={230}
                      minWidth={350}
                      minheight={230}
                    />
                    <div className='property-lable'>
                      <span className='badge badge-md badge-primary'></span>
                      <span className='badge badge-md badge-info'>
                        {data.city_data[0].city}
                      </span>
                    </div>
                    <div className='property-agent'>
                      <div className='property-agent-image'>
                        <img
                          className='img-fluid'
                          src={profile_image_url}
                          minWidth={50}
                          maxHeight={50}
                          alt=''
                        />
                      </div>
                    </div>
                  </div>
                </Link>
                <div className='property-details'>
                  <Link to={detail_page_url}>
                    <div className='property-details-inner'>
                      <h5 className='property-title'>
                        <a href={detail_page_url}>{data.title}</a>
                      </h5>
                      <span className='property-address'>
                        <i className='fas fa-map-marker-alt fa-xs'></i>
                        {data.area_data[0].area}
                      </span>

                      <div className='property-price'>
                        <span className='font-md text-black-50 font-weight-bold'>
                          Starting From:
                        </span>{' '}
                        <span className='font-sm text-primary font-weight-bolder'>
                          PKR
                        </span>{' '}
                        {data.price_txt}
                      </div>
                      <ul className='property-info list-unstyled d-flex project-amenities'>
                        <li className='flex-fill property-bed'>
                          <i className='fas fa-bed'></i>
                        </li>
                        <li className='flex-fill property-bath'>
                          <i className='fas fa-bath'></i>
                        </li>
                        <li className='flex-fill property-m-sqft'>
                          <i className='far fa-square'></i>
                        </li>
                      </ul>
                    </div>
                  </Link>
                  <div className='property-btn'>
                    <a className='property-link' href={detail_page_url}>
                      See Details
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        )
      })}
      <a className='btn btn-see-more p-1' href='/project-list'>
        View More <i className='fas fa-angle-double-right pl-2 pr-0'></i>
      </a>
    </Swiper>
  )
}

export default ProjectBox
