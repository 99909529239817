import React, { Component, useState, useEffect } from "react";
import { useFormik, yupToFormErrors } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  multiFormHeader,
  USER_AGENCY_PROFILE_IMAGEURL,
  USER_IMAGE_NOT_FOUND_URL,
  USER_PROFILE_URL,
} from "../../../Constants/global";
import { useField } from "formik";
import axios from "axios";

function PropertyDetailContact({
  userProfile,
  agency_profile_id,
  property_details,
  id,
}) {
  const property_params = useParams();
  console.log(property_params.searchquery.split("-").pop());
  const property_id = property_params.searchquery.split("-").pop();

  const [isLoading, setIsLoading] = useState({
    isLoading: false,
    disabled: false,
  });
  const [Statusmessage, setMessage] = useState({
    message: "",
    class: "",
  });
  function setSuccessMessage(msg) {
    setMessage({
      message: msg,
      class: "alert alert-success contactUsAlert",
    });
  }
  function setErrorMessage(msg) {
    setMessage({
      message: msg,
      class: "alert alert-danger contactUsAlert",
    });
  }
  function removeMessage(msg) {
    setMessage({
      message: "",
      class: "",
    });
  }
  const [apierror, setApierror] = useState("");
  const [userName, setUserName] = useState("");
  const [userMobile, setuserMobile] = useState("");
  const [showMobileNumber, setshowMobileNumber] = useState(false);
  const [profileURL, setprofileURL] = useState("");
  const [userType, setuserType] = useState("");
  const [user_id, setUserID] = useState("");
  const [contact_person, setcontact_person] = useState("");

  useEffect(() => {
    if (agency_profile_id !== undefined) {
      if (agency_profile_id === 0) {
        try {
          setUserName(
            userProfile.user_profile[0].first_name +
              " " +
              userProfile.user_profile[0].last_name
          );
        } catch (error) {
          setUserName("Anonymous");
        }

        setuserMobile(userProfile.cell_number);

        var imageURL = "";

        try {
          const mainRegExp = RegExp("https");

          if (mainRegExp.test(userProfile.user_profile[0].profile_image)) {
            setprofileURL(userProfile.user_profile[0].profile_image);
          } else {
            if (userProfile.user_profile[0].profile_image === "") {
              setprofileURL(USER_IMAGE_NOT_FOUND_URL);
            } else {
              setprofileURL(
                CDN_URL +
                  imageFolder +
                  "/" +
                  user_id +
                  "/" +
                  userProfile.user_profile[0].profile_image
              );
            }
          }
        } catch (error) {
          setprofileURL(USER_IMAGE_NOT_FOUND_URL);
        }

        setuserType(userProfile.id !== 1 ? userProfile.user_type : "");
        setUserID(userProfile.id);
      } else {
        // console.log(userProfile.agency_profile[0]);
        try {
          setUserName(userProfile.agency_profile[0].company_name);
          setuserType(
            userProfile.agency_profile[0].id !== 1 ? userProfile.user_type : ""
          );
          setUserID(userProfile.agency_profile[0].id);
          setuserMobile(userProfile.agency_profile[0].cell_1);
          setcontact_person(userProfile.agency_profile[0].contact_person);
        } catch (error) {
          setUserName("Anonymous");
          setuserType("");
          setUserID("");
          setuserMobile("");
          setcontact_person("");
        }

        try {
          const mainRegExp = RegExp("https");
          if (mainRegExp.test(userProfile.agency_profile[0].logo)) {
            setprofileURL(userProfile.agency_profile[0].logo);
          } else {
            setprofileURL(
              CDN_URL +
                imageFolder +
                "/" +
                userProfile.agency_profile[0].id +
                "/" +
                userProfile.agency_profile[0].logo
            );
          }
        } catch (error) {
          setprofileURL(USER_IMAGE_NOT_FOUND_URL);
        }
      }
    }
  }, [userProfile]);

  console.log(userProfile);
  const imageFolder = userType !== "User" ? "agencies" : "users";
  const CDN_URL =
    profileURL !== null
      ? USER_AGENCY_PROFILE_IMAGEURL
      : USER_IMAGE_NOT_FOUND_URL;

  const initialValues = {
    name: "",
    email: "",
    contact_number: "",
    message: "",
    customCheck1: false,
  };

  const validateSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    message: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email format").required("Required"),
    contact_number: Yup.number().required("Required"),
    customCheck1: Yup.boolean().oneOf([true], "Please agree before processing"),
  });

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    setIsLoading({
      isLoading: true,
      disabled: true,
    });

    var form = new FormData();
    form.append("name", values.name);
    form.append("email", values.email);
    form.append("contact_number", values.contact_number);
    form.append("message", values.message);
    form.append("type", "property");
    form.append("ref_id", property_id);
    form.append("title", property_details.title);
    //get current date
    const current = new Date();
    const date = `${current.getDate()}-${
      current.getMonth() + 1
    }-${current.getFullYear()} - ${current.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })}`;

    form.append("inquiry_date_time", date);

    if (window.location.href.includes("localhost:3000")) {
      form.append(
        "url",
        window.location.href.replace("localhost:3000", "jagahonline.com")
      );
    } else if (window.location.href.includes("revamp.jotesting.com")) {
      form.append(
        "url",
        window.location.href.replace("revamp.jotesting.com", "jagahonline.com")
      );
    } else {
      form.append("url", window.location.href);
    }

    axios({
      method: "POST",
      url: API_URL + "generalservices/inquiry",
      data: form,
      headers: multiFormHeader,
    }).then((response) => {
      if (response.data.status === 200) {
        setIsLoading({
          isLoading: false,
          disabled: false,
        });
        setSuccessMessage("Your message has been sent");
        setTimeout(removeMessage, 3000);
        resetForm({ values: "" });
        setshowMobileNumber(true);
      } else {
        setIsLoading({
          isLoading: false,
          disabled: false,
        });
        setApierror(response.data.message);
        setErrorMessage(response.data.message);
      }
    });
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validateSchema,
  });
  try {
    return (
      <div>
        {/* ------------------- Property Detail Contact Card -------------------*/}
        <div className="agent-contact-inner bg-white p-4">
          <div className={Statusmessage.class}>{Statusmessage.message}</div>
          <div className="d-flex align-items-center mb-4">
            <div className="agent-contact-avatar mr-3">
              <img
                className="img-fluid rounded-circle avatar avatar-lg"
                src={profileURL}
                alt=""
              />
            </div>
            <div className="agent-contact-name">
              <h6 className="text-black">{userName}</h6>
              {userType !== "User" ? <span>{contact_person}</span> : ""}
              {showMobileNumber ? userMobile : ""}
            </div>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder="Your Name"
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="error text-danger"> {formik.errors.name} </div>
              ) : null}
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="email"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                placeholder="Your Email Address"
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="error text-danger"> {formik.errors.email} </div>
              ) : null}
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="contact_number"
                name="contact_number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.contact_number}
                placeholder="Your Phone Number"
              />
              {formik.touched.contact_number && formik.errors.contact_number ? (
                <div className="error text-danger">
                  {" "}
                  {formik.errors.contact_number}{" "}
                </div>
              ) : null}
            </div>
            <div className="form-group">
              <textarea
                className="form-control"
                rows={4}
                placeholder="Message"
                id="message"
                name="message"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.message}
              />
              {formik.touched.message && formik.errors.message ? (
                <div className="error text-danger">
                  {" "}
                  {formik.errors.message}{" "}
                </div>
              ) : null}
            </div>
            <div className="custom-control custom-checkbox mb-3">
              <input
                type="checkbox"
                className="custom-control-input"
                id={id}
                name="customCheck1"
                onChange={formik.handleChange}
              />
              <label
                className="custom-control-label text-black font-sm"
                htmlFor={id}
              >
                I hereby agree for processing my personal data{" "}
              </label>
              {formik.touched.customCheck1 && formik.errors.customCheck1 ? (
                <div className="error text-danger">
                  {" "}
                  {formik.errors.customCheck1}{" "}
                </div>
              ) : null}
            </div>

            <button
              disabled={isLoading.disabled}
              type="submit"
              className="btn btn-primary btn-block"
            >
              Send message
              <span
                hidden={isLoading.disabled - 1}
                className="spinner-border spinner-border-sm ml-2"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          </form>
        </div>
        {/* ------------------- Property Detail Contact Card -------------------*/}
      </div>
    );
  } catch (error) {
    console.log(error);
    return <p> unable to fetch details</p>;
  }
}

export default PropertyDetailContact;
