import React, { Fragment } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setCart } from "../../Redux/actions/cart";
import axios from "axios";
import * as Yup from "yup";
import searchBar from "../../Redux/reducers/searchBar";
import { setSearchBar } from "../../Redux/actions/searchBar";
import { useFormik, yupToFormErrors } from "formik";
import dayjs, { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Stack from "@mui/material/Stack";

import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  currentUserInformation,
  multiFormHeader,
} from "../../Constants/global";

const OrderBookModal = ({ serviceData, setCart, setSearchBar, searchBar }) => {
  const [date, setDate] = useState(dayjs("2022-01-01"));
  useEffect(() => {
    const dateStr = date.$d.toDateString().split(" ");
    var dateStrFormat = dateStr[2] + " " + dateStr[1] + " " + dateStr[3];
    setDateString(dateStrFormat);
  }, [date]);
  const [dateString, setDateString] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  // const [bill, setBill] = useState(0);
  var bill = 0;
  const [address_list, setAddress_list] = useState([]);
  const [selectedCity, setSelectedCity] = useState(0);
  const [locations, setLocations] = useState([]);
  function addToCart(id, title, charges, quantity) {
    // alert(quantity);
    // alert(id);
    // alert(`${charges} ${quantity}`);
    if (quantity !== "0") {
      setCart(
        charges,
        quantity,
        serviceData.id,
        serviceData.city_id,
        id,
        id,
        1
      );
    }
  }
  const [cartData, setCartData] = useState([]);
  const [cities, setCities] = useState([]);
  function place_order() {
    const data = {
      address: selectedAddress,
      serviceDate: date.$d,
      total_price: bill,
      cart_items: cartData,
      payment_method: "COD",
      additional_info: additional_info,
    };
    setAdditional_info("");
    console.log(data);
  }
  const get_cart = () => {
    axios({
      method: "GET",
      url: API_URL + "api/handymen_cart/" + localStorage.getItem("user_id"),
      headers: multiFormHeader,
    }).then((response) => {
      if (response.data.status === 200) {
        console.log(response.data.data);
        setCartData(response.data.data);
      } else {
        // alert("Fail");
        console.log("cart data fetch fail");
      }
    });
  };
  useEffect(() => {
    if (address_list) {
      if (address_list[0]) {
        setSelectedAddress(
          address_list[address_list.length - 1].address +
            ", " +
            address_list[address_list.length - 1].area +
            ", " +
            address_list[address_list.length - 1].city
        );
      }
    }
  }, [address_list]);
  useEffect(() => {
    function fetchAddress() {
      axios({
        method: "GET",
        url:
          API_URL +
          "api/handymen_user_addres/" +
          localStorage.getItem("user_id"),
        headers: multiFormHeader,
      }).then((response) => {
        if (response.data.status === 200) {
          console.log(response.data.data);
          setAddress_list(response.data.data);
        } else {
          // alert("Fail");
          console.log("address fetch fail");
        }
      });
    }
    fetchAddress();
    async function fetchSearchBy() {
      const response = await axios({
        method: "GET",
        url: API_URL + "api/searchby",
      });
      if (response.status === 200) {
        const cities = response.data?.data[2]["cities"];
        const nArray2 = [];
        cities.map((item) => {
          let roleData = {};
          if (
            item.id === 14 ||
            item.id === 3 ||
            item.id === 12 ||
            item.id === 62
          ) {
            roleData.value = item.id;
            roleData.label = item.city;
            nArray2.push(roleData);
          }
        });
        setCities(nArray2);
      }
    }
    fetchSearchBy();

    // alert("");

    get_cart();
  }, []);

  // -----------------------------add Address-------------------------
  const [initialValues, setInitialValues] = useState({
    city: 0,
    area: "",
    address: "",
  });
  const handleSubmit = (values) => {
    // alert("");
    console.log(values);
    var form = new FormData();
    form.append("city_id", values.city);
    form.append("area_id", values.area);
    form.append("address", values.address);
    form.append("user_id", localStorage.getItem("user_id"));
    form.append("id", 0);

    axios({
      method: "POST",
      url: API_URL + "api/handymen_user_address_add",
      data: form,
      headers: multiFormHeader,
    }).then((response) => {
      if (response.data.status === 200) {
        console.log("Adress Added successfully");
      } else {
        console.log("Add address Fail");
      }
    });
  };

  const validateSchema = Yup.object().shape({
    // full_name: Yup.string().required("Required"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: validateSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    // alert(formik.values.city);
    setSelectedCity(Number(formik.values.city));

    let tempArray = [];

    axios({
      method: "GET",
      url: API_URL + "generalservices/areabycityid/" + selectedCity,
    }).then((response) => {
      if (response.status === 200) {
        const nArray = [];
        response.data?.data.map((item) => {
          if (item.city_id === selectedCity) {
            let roleData = {};
            roleData.value = item.id;
            roleData.label = item.area;

            nArray.push(roleData);
          }
        });
        setLocations(nArray);
      }
    });

    // locations.map((item, index) => {
    //   // alert(typeof item.city_id + typeof selectedCity);
    //   if (item.city_id === selectedCity) {
    //     tempArray.push(item);
    //   }
    // });
    // setServiceArray(tempArray);
    // alert(serviceArray);
  }, [formik.values.city, selectedCity]);
  // -----------------------------add Address-------------------------

  // ------------------------------delete Address-----------------------------------
  function delete_address(e) {
    console.log(e.target.id);
  }

  function select_address(e) {
    console.log(e.target.value);
    setSelectedAddress(e.target.value);
  }

  // ------------------------------delete Address-----------------------------------
  const [additional_info, setAdditional_info] = useState("");
  const handleChange = (event) => {
    setAdditional_info(event.target.value);
  };
  return (
    <Fragment>
      <section className="orderBookingSection">
        {/* Modal */}
        <div
          className="modal OrderBookModal fade"
          id="OrderBookModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="OrderBookModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body space-ptb">
                <div className="container">
                  <div className="row mt-5">
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="myOrderPayment d-flex justify-content-between align-items-center">
                            <div>
                              <h3>Select Date</h3>
                            </div>
                            <div className="select-book-date-show">
                              <i className="fas fa-calendar-day"></i>{" "}
                              {dateString}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3}>
                            <DatePicker
                              label="Booking Date"
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              value={date}
                              onChange={(newValue) => {
                                setDate(newValue);
                                console.log(date);
                                console.log(date.$d);
                              }}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>
                      <div className="myOrderItem w-100 mt-5">
                        <h3 className="mb-3">Items</h3>
                        <div className="col-md-6 p-0">
                          {/* ------------------------------------------ */}
                          {cartData.map((item) => {
                            // alert("");
                            return (
                              <div className="all-services-box">
                                <div className="all-service-box-img">
                                  <img
                                    className="img-fluid"
                                    alt="icons"
                                    src="/classified_assets/images/handymen-images/service-img/airconditioning.jpg"
                                  />
                                </div>
                                <div className="all-service-detail">
                                  <div className="all-service-title">
                                    <h3>{item.service}</h3>
                                  </div>
                                  <div className="all-service-price">
                                    Rs. {item.service_charges}
                                  </div>
                                  <div className="all-service-inspecrtion">
                                    (Very After Inspection)
                                  </div>

                                  <ul className="serviceCounter p-0 d-flex align-items-center justify-content-end mb-0">
                                    <li
                                      className="list-style-none"
                                      data-btn-type="decrement"
                                      onClick={() => {
                                        // alert(
                                        //   document.getElementById(item.id).value
                                        // );
                                        if (
                                          document.getElementById(item.id)
                                            .value !== "0"
                                        ) {
                                          document.getElementById(
                                            item.id
                                          ).value =
                                            parseInt(
                                              document.getElementById(item.id)
                                                .value
                                            ) - 1;

                                          if (localStorage.getItem("token")) {
                                            // alert(item.id);

                                            addToCart(
                                              item.id,
                                              item.service,
                                              item.service_charges,
                                              document.getElementById(item.id)
                                                .value
                                            );
                                          }
                                        }
                                        get_cart();
                                      }}
                                    >
                                      <span className="btn btn-primary increBtn px-2 py-1">
                                        <i className="fa fa-minus p-0" />
                                      </span>
                                    </li>
                                    <li className="list-style-none counterText">
                                      <input
                                        type="text"
                                        name="guestCount"
                                        id={item.id}
                                        // defaultValue={
                                        //   item.service_qty !== undefined
                                        //     ? Number(item.service_qty)
                                        //     : 0
                                        // }
                                        // value={count}
                                        value={item.service_qty}
                                        disabled
                                      />
                                    </li>
                                    <li
                                      className="list-style-none"
                                      data-btn-type="increment"
                                      onClick={() => {
                                        document.getElementById(item.id).value =
                                          parseInt(
                                            document.getElementById(item.id)
                                              .value
                                          ) + 1;

                                        if (localStorage.getItem("token")) {
                                          addToCart(
                                            item.id,
                                            item.service,
                                            item.service_charges,
                                            parseInt(
                                              document.getElementById(item.id)
                                                .value
                                            )
                                          );
                                        }
                                        get_cart();
                                      }}
                                    >
                                      <span className="btn btn-primary increBtn px-2 py-1">
                                        <i className="fa fa-plus p-0"></i>
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            );
                          })}

                          {/* ------------------------------------------ */}
                        </div>
                      </div>

                      <div className="row mt-5">
                        <div className="col-md-6">
                          <div className="myOrderPayment">
                            <h3>Payment Method</h3>
                          </div>
                          <label className="offwhite-box d-flex justify-content-between">
                            <div className="pm-left">
                              <img
                                className="img-fluid"
                                alt="icons"
                                width="20"
                                src="/classified_assets/images/handymen-images/payment-icon.svg"
                              />
                              <span className="pl-2">Cash</span>
                            </div>
                            <div className="pm-right">
                              <div className="form-check">
                                <input
                                  className
                                  type="radio"
                                  name="paymentMethod"
                                  id="flexRadioDefault2"
                                  // defaultValue="cash"
                                  // defaultChecked={true}
                                  checked={true}
                                />
                              </div>
                            </div>
                          </label>
                        </div>
                        <div className="col-md-6">
                          <div className="myOrderPayment">
                            <h3>Add Screenshot</h3>
                          </div>
                          <label className="service-problem-imgs">
                            <div className="service-problem-imgs-inner">
                              <div className="service-problem-add">
                                <button className="btn btn-primary px-2 py-1">
                                  <i className="fas fa-plus p-0"></i>
                                </button>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>

                      <div className="row mb-md-0 mb-5 mt-5">
                        <div className="col-md-12">
                          <div className="myOrderPayment">
                            <h3>Additional Information</h3>
                            <div className="additional-info-txt">
                              <form>
                                <textarea
                                  id="additional_info"
                                  name="additional_info"
                                  onChange={handleChange}
                                  value={additional_info}
                                  height="120"
                                  className="form-control"
                                ></textarea>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="delivering-status">
                        <div className="delivering-status-inner">
                          <div className="delivering-to">Delivering To:</div>
                          <div className="add-new-address d-flex align-items-center justify-content-between">
                            <div class="add-new-btn">Add New</div>
                            <div className="dropdown ml-2 mr-0">
                              <button
                                className="order-modal-dd-btn btn btn-primary dropdown-toggle text-black p-0"
                                type="button"
                                data-toggle="modal"
                                data-target="#AddNewAddressModal"
                              >
                                <i className="fas fa-chevron-down p-0" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ------------------Address_map-------------------- */}

                      <div className="delivering-status my-3 pri-address">
                        <div className="delivering-status-inner">
                          <div className="delivering-to">Primary Address</div>
                        </div>
                        <div className="address-specific">
                          <p className="m-0">{selectedAddress}</p>
                        </div>
                      </div>
                      <div className="delivering-status my-3  deliver-overflow">
                        <div className="delivering-status-inner">
                          <div className="delivering-to">Other Addresses</div>
                        </div>
                        <div className="address-specific">
                          <p className="m-0">
                            {address_list !== []
                              ? address_list
                                  .slice(0)
                                  .reverse()
                                  .map((item, index) => {
                                    return (
                                      <div
                                        className="delivering-status my-3 bg-white"
                                        key={index}
                                      >
                                        <div className="address-specific">
                                          <p className="m-0">
                                            <input
                                              className="mr-2"
                                              type="radio"
                                              name="address_radio"
                                              id={item.id}
                                              value={
                                                item.address +
                                                ", " +
                                                item.area +
                                                ", " +
                                                item.city
                                              }
                                              defaultChecked={index === 0}
                                              onClick={select_address}
                                            />
                                            {item.address}
                                            {item.area}, {item.city}
                                            <i
                                              id={item.id}
                                              key={item.id}
                                              onClick={delete_address}
                                              className="fa fa-trash float-right"
                                              name="delete_address"
                                            ></i>
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  })
                              : ""}
                          </p>
                        </div>
                      </div>
                      {/* <div class="bs-example">
                        <div class="row">
                          <div class="span4 collapse-group">
                            <div class="collapse" id="viewdetails"></div>
                            <p>
                              <a
                                class="btn"
                                data-toggle="collapse"
                                data-target="#viewdetails"
                              >
                                View More &raquo;
                              </a>
                            </p>
                          </div>
                        </div>
                      </div> */}

                      {/* --------------------------------------- */}
                      <div className="delivering-status-promo mt-4">
                        <div className="delivering-status-promo-in">
                          <div className="delivering-promo">
                            Apply Promo Code
                          </div>
                          <div className="input-promo position-relative my-2">
                            <button className="btn btn-primary apply-promo">
                              Apply
                            </button>
                            <input
                              type="text"
                              className="form-control border-0"
                              placeholder="Promo Code"
                            />
                          </div>
                        </div>
                        <div className="billing-specific mt-3">
                          <div className="billing-heading mb-2">Address</div>
                          {/* ------------------------------------- */}

                          <div className="billing-detail">
                            <div className="billing-left">
                              {selectedAddress}
                            </div>
                            {/* <div className="billing-right">Rs:</div> */}
                          </div>

                          {/* ------------------------------------- */}

                          <hr className="my-1" />
                        </div>
                        <div className="billing-specific mt-3">
                          <div className="billing-heading mb-2">Billing</div>
                          {/* ------------------------------------- */}
                          {cartData.map((item) => {
                            bill =
                              bill + item.service_charges * item.service_qty;

                            return (
                              <div className="billing-detail">
                                <div className="billing-left">
                                  {item.service}{" "}
                                  <span>({item.service_qty})</span>
                                </div>
                                <div className="billing-right">
                                  Rs: {item.service_charges} x{" "}
                                  {item.service_qty}
                                </div>
                              </div>
                            );
                          })}

                          {/* ------------------------------------- */}
                          <hr className="my-1" />
                          <div className="billing-detail">
                            <div className="billing-left">Amount</div>
                            <div className="billing-right">Rs: {bill}</div>
                          </div>
                          <hr className="my-1" />
                          <div className="billing-detail total-price">
                            <div className="billing-left">Total Price</div>
                            <div className="billing-right">Rs: {bill}</div>
                          </div>
                        </div>
                        <div className="total-place-order mt-3">
                          <button
                            className="btn btn-primary w-100 p-2"
                            onClick={place_order}
                          >
                            Place Order
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*<div className="modal-footer">*/}
              {/*    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>*/}
              {/*    <button type="button" className="btn btn-primary">Save changes</button>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </section>

      {/* Modal */}
      <div
        className="modal fade"
        id="AddNewAddressModal"
        tabIndex={-1}
        aria-labelledby="AddNewAddressModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="AddNewAddressModalLabel">
                Add New Address
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={formik.handleSubmit}>
                <div className="form-row">
                  <div className="form-group col-md-12 select-border">
                    <label>Please Select City</label>
                    <select
                      // form-control basic-select border-0
                      className="form-control border-0 "
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      name="city"
                    >
                      <option value={""}>{"All City"}</option>
                      {cities.map((item, i) => {
                        return (
                          <option key={i} value={parseInt(item.value)}>
                            {item.label}
                          </option>
                        );
                      }, this)}
                    </select>
                    {/* <select
                      className="form-control basic-select"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      name="city"
                    >
                      <option value="value 01" selected="selected">
                        Karachi
                      </option>
                    </select> */}
                  </div>
                  <div className="form-group col-md-12 select-border">
                    <label>Please Select Area</label>
                    <select
                      className="form-control border-0 "
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      name="area"
                    >
                      {" "}
                      <option key={0} value={""}>
                        Choose Area
                      </option>
                      {locations !== [] ? (
                        locations.map((item, index) => {
                          // alert(item.area);

                          return (
                            <option key={index + 1} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })
                      ) : (
                        <option key={0} value={""}>
                          Choose Area
                        </option>
                      )}
                    </select>
                  </div>

                  <div className="form-group col-md-12">
                    <label>Please Type Your Address </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Please Type Your Address"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      name="address"
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-primary">Done</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
OrderBookModal.propTypes = {
  // user_name: PropTypes.object.isRequired,
  setCart: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  // user_name: state.user_profile,
});
export default connect(mapStateToProps, { setCart })(OrderBookModal);
