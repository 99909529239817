import React, {Fragment} from "react";
import MainBanner from "./components/landing/MainBanner";
import HomeBookNow from "./components/landing/HomeBookNow";
import HomeServices from "./components/landing/HomeServices";
import HomeBestServices from "./components/landing/HomeBestServices";
import HandymenTestimonial from "./components/landing/HandymenTestimonial";
import UstadAuth from "./components/authentication/UstadAuth";

function HomepageUstad() {
    return (
        <Fragment>
            <MainBanner/>
            <HomeBookNow/>
            <HomeServices/>
            <HomeBestServices/>
            <HandymenTestimonial/>
            <UstadAuth/>

        </Fragment>
    )
};

export default HomepageUstad;
