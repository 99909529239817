import React, { Fragment, useState } from 'react';
import UserProfileHeader from '../../../../Components/Header/joclassified/UserProfileHeader';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import OrderCancelModal from '../OrderCancelModal';
import MyOrderTabs from './MyOrderTabs';
// -------------------------------

const OrderDetail = () => {
  const [p_class, set_p_class] = useState({
    cancelled: 'order-track-text-stat text-danger',
    normal: 'order-track-text-stat',
    completed: 'order-track-text-stat text-success',
  });

  const [dot_class, set_dot_class] = useState({
    cancelled: 'order-track-status-dot bg-danger',
    normal: 'order-track-status-dot',
    completed: 'order-track-status-dot bg-success',
  });
  const order_params = useParams();
  const order_id = order_params.id;
  const location = useLocation();
  const service_data = location.state.service;
  let date = location.state.service_date;

  let s_time = date[1];
  let s_date = date[0];

  // s_time = s_time.substring(0, s_time.lastIndexOf(':'));
  function formatTime(s_time) {
    const [hourString, minute] = s_time.split(':');
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ':' + minute + (hour < 12 ? ' AM' : ' PM');
  }

  const address = location.state.address;

  return (
    <Fragment>
      <UserProfileHeader />

      <section className='my-order-section space-ptb'>
        <div className='container'>
          <div className='row'>
            {/* --------------------------------------------------------------------------OrderTab--------------------------- */}
            <div className='col-md-4'>
              <div className='order-service-main mt-4'>
                <div className='order-service-name'>
                  {service_data.category ? service_data.category : ''}
                </div>
                <div class='order-service-detail'>
                  <div className='order-service-detail-left'>Delivered</div>
                  <div className='order-service-detail-right'>
                    <span className='order-date-span'>
                      {s_date.replaceAll('-', '/') + ' ' + formatTime(s_time)}
                    </span>
                    {/* <span className="order-time-span">3:00 PM</span> */}
                  </div>
                </div>
                <div className='order-service-detail'>
                  <div className='order-service-detail-left'>Order Number</div>
                  <div className='order-service-detail-right'>
                    <span className='order-mode-span'>{service_data.id}</span>
                  </div>
                </div>
                <div className='order-service-detail mb-0'>
                  <div className='order-service-detail-left'>Status</div>
                  <div className='order-service-detail-right'>
                    <span className='order-active-span'>
                      {service_data.order_status === 0 ? 'Cancelled' : ''}
                      {service_data.order_status === 1 ? 'Pending' : ''}
                      {service_data.order_status === 2 ? 'Confirm' : ''}
                      {service_data.order_status === 3 ? 'In-Process' : ''}
                      {service_data.order_status === 4 ? 'Ir-Relevant' : ''}
                      {service_data.order_status === 5 ? 'Not Answering' : ''}
                      {service_data.order_status === 6 ? 'Out of City' : ''}
                      {service_data.order_status === 7
                        ? 'Required in Future'
                        : ''}
                      {service_data.order_status === 8 ? 'Cancel' : ''}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* --------------------------------------------------------------------------OrderTab--------------------------- */}

            <div className='col-md-8'>
              <div className='order-detail-main'>
                <div className='order-detail-inner'>
                  <div className='order-detail-top-header'>
                    <div className='od-header-heading'>
                      Order # {service_data.id}
                    </div>
                    <div className='od-header-dropdown'>
                      <div className='dropdown mr-0'>
                        {service_data.order_status !== 0 ? (
                          <Fragment>
                            <button
                              className='order-detail-dd-btn btn dropdown-toggle text-black p-0'
                              type='button'
                              id='dropdownMenuButton'
                              data-toggle='dropdown'
                              aria-haspopup='true'
                              aria-expanded='false'
                            >
                              <i className='fas fa-chevron-down pl-1 p-0' />
                            </button>
                            <div
                              className='dropdown-menu dropdown-menu-right'
                              aria-labelledby='dropdownMenuButton'
                            >
                              {/* <a
                                 className="dropdown-item px-1 font-weight-normal p-2"
                                 href=""
                               >
                                 Update Order
                               </a>
                               <hr className="my-1" /> */}
                              <a
                                className='dropdown-item px-1 font-weight-normal text-danger p-2'
                                type='button'
                                data-toggle='modal'
                                data-target='#orderCancelModal'
                                // id="SuccessDialogue"
                              >
                                Cancel Order
                              </a>
                            </div>
                          </Fragment>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='order-detail-content'>
                    <div className='service-detail-spec'>
                      <div className='left-service-name'>Service Quantity</div>
                      <div className='right-service-name'>
                        {service_data.service_qty}
                      </div>
                    </div>
                    <div className='service-detail-spec'>
                      <div className='left-service-name'>Service Name</div>
                      <div className='right-service-name'>
                        {service_data.service}
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div className='order-detail-content'>
                    <div className='service-detail-sub'>Order Date & Time</div>
                    <div className='service-detail-spec'>
                      <div className='left-service-name'>Date</div>
                      <div className='right-service-name'>{s_date}</div>
                    </div>
                    <div className='service-detail-spec'>
                      <div className='left-service-name'>Time</div>
                      <div className='right-service-name'>
                        {formatTime(s_time)}
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div className='order-detail-content'>
                    <div className='service-detail-spec'>
                      <div className='left-service-name'>Order ID</div>
                      <div className='right-service-name'>
                        {service_data.id}
                      </div>
                    </div>
                  </div>
                  <div className='order-detail-content'>
                    <div className='service-detail-spec'>
                      <div className='left-service-name'>Address</div>
                      <div className='right-service-name'>
                        {service_data.service_address}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className='order-detail-content'>
                    <div className='service-detail-spec'>
                      <div className='left-service-name'>Service Price</div>
                      <div className='right-service-name'>
                        <span>Rs</span> {service_data.charges}
                      </div>
                    </div>
                  </div>
                  <hr className='mb-0' />

                  {/*Order Tracking*/}
                  <div className='order-track p-4'>
                    {service_data.status_log
                      ? service_data.status_log.map((item) => {
                          let s_time = item.datetime.split('T')[1];
                          let s_date = item.datetime.split('T')[0];

                          // s_time = s_time.substring(0, s_time.lastIndexOf(':'));
                          s_time = formatTime(s_time);

                          return (
                            <div className='order-track-step active'>
                              <div className='order-track-status'>
                                <span
                                  className={`order-track-status-dot ${
                                    item.status === 0 ? 'bg-danger' : ''
                                  }`}
                                ></span>
                                <span className='order-track-status-line'></span>
                              </div>
                              <div className='order-track-text'>
                                <p
                                  className={`order-track-text-stat ${
                                    item.status === 0 ? 'text-danger' : ''
                                  }`}
                                >
                                  {item.title
                                    ? item.title
                                    : item.status === 0
                                    ? ' Cancelled'
                                    : ''}
                                </p>
                                <span className='order-track-text-sub'>
                                  {/* 15/6/2022, 1 PM */}
                                  {s_date + ', ' + s_time}
                                </span>
                              </div>
                            </div>
                          );
                        })
                      : ''}
                  </div>
                  {/*Order Tracking*/}
                  <div className='order-detail-content bg-white py-3'>
                    <div className='service-detail-sub'>Summary</div>
                    <div className='service-detail-spec'>
                      <div className='left-service-name'>Service Name</div>
                      <div className='right-service-name'>
                        {service_data.service}
                      </div>
                    </div>
                    <div className='service-detail-spec'>
                      <div className='left-service-name'>
                        Rs. <span>Charges</span>*<span>number</span> of service
                      </div>
                      <div className='right-service-name'>
                        {service_data.charges} x {service_data.service_qty}
                      </div>
                    </div>

                    <div className='service-detail-spec total-rs-service mb-0'>
                      <div className='left-service-name'>Total</div>
                      <div className='right-service-name'>
                        {parseInt(service_data.charges) *
                          parseInt(service_data.service_qty)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <OrderCancelModal service_data={service_data} />
    </Fragment>
  );
};

export default OrderDetail;
