import React, { useState, useEffect } from 'react'

import axios from 'axios'
import Pagination from 'react-js-pagination'

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useParams,
  Switch
} from 'react-router-dom'

import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  multiFormHeader
} from '../../Constants/global'

import 'bootstrap-less'
import SmilarPropertiesRow from './Properties/SmilarPropertiesRow'

const SimilarProperties = () => {
  const [propertyData, setPropertyData] = useState([])

  const property_thumbnail =
    'https://cdn.jagahonline.com/properties/275906/front_image-61b98b34e402a-thumb.jpeg'

  var searchQueryGet = {}

  if (
    localStorage.getItem('searchQuery') !== undefined &&
    localStorage.getItem('searchQuery') !== ''
  ) {
    searchQueryGet = JSON.parse(localStorage.getItem('searchQuery')) || {
      property_purpose: 1,
      city_id: 14,
      area_id: 0,
      property_type: 1,
      min_area: 0,
      max_area: 0,
      price_min: 0,
      price_max: 0,
      beds: 0,
      keywords: ''
    }
  } else {
    searchQueryGet = {
      property_purpose: 1,
      city_id: 14,
      area_id: 0,
      property_type: 1,
      min_area: 0,
      max_area: 0,
      price_min: 0,
      price_max: 0,
      beds: 0,
      keywords: ''
    }
  }

  var flag = 0
  useEffect(() => {
    const scrollFun = () => {
      if (window.pageYOffset % 500 >= 450 && flag === 0) {
        flag = 1
        // alert("scrolled 50-ish pixels");
        axios({
          method: 'GET',
          url: localStorage.getItem('token')
            ? API_URL +
              'api/similar_properties/' +
              searchQueryGet.city_id +
              '/' +
              searchQueryGet.property_type +
              '/' +
              searchQueryGet.property_purpose +
              '/' +
              localStorage.getItem('user_id') +
              '/' +
              searchQueryGet.area_id
            : API_URL +
              'api/similar_properties/' +
              searchQueryGet.city_id +
              '/' +
              searchQueryGet.property_type +
              '/' +
              searchQueryGet.property_purpose +
              '/' +
              '0' +
              '/' +
              searchQueryGet.area_id
        }).then(response => {
          if (response.status === 200) {
            console.log('total records ' + response.data.total_records)
            if (response.data?.data === []) {
              setPropertyData(['none'])
            } else {
              setPropertyData(response.data?.data)
            }
          }
          if (response.status === 400) {
            alert('Internet is not available, try again.')
          }
        })
      }
    }

    window.addEventListener('scroll', scrollFun)

    return () => {
      window.removeEventListener('scroll', scrollFun)
    }
  }, [])

  function handlePageChange (pageNumber) {
    console.log(`active page is ${pageNumber}`)

    this.setState({ activePage: pageNumber })
  }
  if (propertyData.length === 0) {
    return (
      <section className='space-ptb'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-12'>
              <photo className='shine w-100 shimmerphoto'></photo>
            </div>
            <div className='col-md-12 mt-1'>
              <lines className='shine w-100 shimmerlines'></lines>
            </div>
          </div>
        </div>
      </section>
    )
  }
  if (propertyData !== ['none']) {
    return (
      <>
        <SmilarPropertiesRow propertyData={propertyData} />

        {/* <!--Newly Listed Properties--> */}
      </>
    )
  }
}

export default SimilarProperties
