import { HANDLE_FAVORITE } from "./types";
import axios from "axios";

export const handleFavorite =
  (total_favorites, favorite_class) => (dispatch) => {
    // axios.get(`http://www.randomnumberapi.com/api/v1.0/random`).then((res) => {});
    dispatch({
      type: HANDLE_FAVORITE,
      payload: [total_favorites],
    });
    // dispatch({
    //   type: HANDLE_FAVORITE_CLASS,
    //   payload: favorite_class,
    // });
  };
