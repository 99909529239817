import React, { Fragment, useEffect, useState } from "react";
import { getServices } from "../../../../Redux/actions/services";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { setCart } from "../../../../Redux/actions/cart";
import axios from "axios";
import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  currentUserInformation,
  multiFormHeader,
} from "../../../../Constants/global";

const ServicesSection = ({ serviceData, setCart }) => {
  const [cartData, setCartData] = useState([]);
  function clearCart() {
    if (
      localStorage.clear("cart_total") !== null &&
      localStorage.clear("cart_qty") !== null
    ) {
      localStorage.clear("cart_total");
      localStorage.clear("cart_qty");
    }
  }
  function addToCart(id, title, charges, quantity) {
    // alert(`${charges} ${quantity}`);
    // id = 0;
    if (quantity !== "0") {
      setCart(charges, quantity, serviceData.id, serviceData.city_id, id, 0, 0);
    }
  }
  // function removeFromCart(id, title, charges) {
  //   alert(id + " " + title + " " + charges);
  // }
  // const [totalPrice, setTotalPrice]
  const [count, setCount] = useState(0);
  const [data, setData] = useState({});
  useEffect(() => {
    // ----------------------resetCart
    axios({
      method: "GET",
      url: API_URL + "api/handymen_cart/" + localStorage.getItem("user_id"),
      headers: multiFormHeader,
    }).then((response) => {
      if (response.data.status === 200) {
        console.log(response.data.data);
        // setCartData(response.data.data);
        localStorage.setItem("cart_total", "0");
        localStorage.setItem("cart_qty", "0");
        response.data.data.map((item) => {
          // alert(typeof item.service_qty);

          localStorage.setItem(
            "cart_total",
            parseInt(localStorage.getItem("cart_total")) +
              parseInt(item.service_charges) * parseInt(item.service_qty)
          );
          localStorage.setItem(
            "cart_qty",
            parseInt(localStorage.getItem("cart_qty")) +
              parseInt(item.service_qty)
          );
        });
      } else {
        // alert("Fail");
        console.log("cart data fetch fail");
      }
    });
    // ----------------------resetCart
  }, []);
  useEffect(() => {
    axios({
      method: "GET",
      url: API_URL + "api/handymen_cart/" + localStorage.getItem("user_id"),
      headers: multiFormHeader,
    }).then((response) => {
      if (response.data.status === 200) {
        // alert("Success");
        // alert(response.data.data);
        setCartData(response.data.data);
      } else {
        // alert("Fail");
        console.log("Get Cart API Fail");
      }
    });
    if (
      serviceData !== "" &&
      serviceData !== null &&
      serviceData !== undefined &&
      serviceData !== "null"
    ) {
      setData(serviceData);
    }
    // alert(services);
  }, [serviceData]);
  return (
    <section className="all-services-section space-ptb">
      <div className="container">
        {/* -----------------------Remove--------------------- */}
        {/* <button onClick={clearCart}>Clear</button> */}
        {/* -----------------------Remove--------------------- */}
        <div className="all-service-bg p-md-3 p-2">
          <div className="row no-gutters">
            <div className="col-12">
              <div className="text-center mb-3">
                <div className="section-title text-center">
                  <h3 className="mb-3 banner-bg-slider-title text-center">
                    {`${data.title !== undefined ? data.title : ""} `} in
                    Karachi
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* -------------------------------- */}
            {data.service_childs !== undefined
              ? data.service_childs.map((item) => {
                  let cart_index = null;

                  cartData.map((cart_item, index) => {
                    if (
                      cart_item.category_id === serviceData.id &&
                      cart_item.service_id === item.id
                    ) {
                      // alert(cart_item.category_id + " " + serviceData.id);
                      cart_index = index;
                      // alert(cart_index);
                    }
                  });
                  return (
                    <div className="col-md-4 pl-md-3 no-padd-childs">
                      <div className="all-services-box">
                        <div className="all-service-box-img">
                          <img
                            className="img-fluid"
                            alt="icons"
                            src="/classified_assets/images/handymen-images/service-img/airconditioning.jpg"
                          />
                        </div>
                        <div className="all-service-detail">
                          <div className="all-service-title">
                            <h3> {item.title}</h3>
                          </div>
                          <div className="all-service-price">
                            Rs. {item.charges}
                          </div>
                          <div className="all-service-inspecrtion">
                            (Very After Inspection)
                          </div>

                          <ul className="serviceCounter p-0 d-flex align-items-center justify-content-end mb-0 mt-3">
                            <li
                              className="list-style-none"
                              data-btn-type="decrement"
                              onClick={(e) => {
                                if (
                                  document.getElementById(item.id).value !== "0"
                                ) {
                                  document.getElementById(item.id).value =
                                    parseInt(
                                      document.getElementById(item.id).value
                                    ) - 1;
                                  // addToCart(
                                  //   item.id,
                                  //   item.title,
                                  //   item.charges,
                                  //   document.getElementById(item.id).value
                                  // );
                                }
                              }}
                            >
                              <span className="btn btn-primary increBtn px-2 py-1">
                                <i className="fa fa-minus p-0" />
                              </span>
                            </li>
                            <li className="list-style-none counterText">
                              <input
                                type="text"
                                name="guestCount"
                                id={item.id}
                                defaultValue={
                                  // cartData[cart_index] !== undefined &&
                                  // cart_index !== null
                                  //   ? Number(cartData[cart_index].service_qty)
                                  //   : 0
                                  0
                                }
                                // value={count}
                                value={
                                  // cartData[cart_index] !== undefined
                                  //   ? Number(cartData[cart_index].service_qty)
                                  //   : 0
                                  0
                                }
                                disabled
                              />
                            </li>
                            <li
                              className="list-style-none"
                              data-btn-type="increment"
                              //increment_btn
                              onClick={() => {
                                if (
                                  document.getElementById(item.id).value !==
                                  "100"
                                ) {
                                  document.getElementById(item.id).value =
                                    parseInt(
                                      document.getElementById(item.id).value
                                    ) + 1;
                                  // addToCart(
                                  //   item.id,
                                  //   item.title,
                                  //   item.charges,
                                  //   document.getElementById(item.id).value
                                  // );
                                }
                              }}
                            >
                              <span className="btn btn-primary increBtn px-2 py-1">
                                <i className="fa fa-plus p-0"></i>
                              </span>
                            </li>
                            <li
                              data-toggle={
                                localStorage.getItem("token") ? "" : "modal"
                              }
                              data-target={
                                localStorage.getItem("token")
                                  ? ""
                                  : "#loginModalUstad"
                              }
                              className="list-style-none"
                              data-btn-type="increment"
                              //increment_btn
                              onClick={() => {
                                if (localStorage.getItem("token")) {
                                  addToCart(
                                    item.id,
                                    item.title,
                                    item.charges,
                                    document.getElementById(item.id).value
                                  );
                                  document.getElementById(item.id).value = 0;
                                  document.getElementById(
                                    item.id
                                  ).defaultValue = 0;
                                }
                              }}
                            >
                              <span className="btn btn-primary increBtn ml-2 px-2 py-1">
                                <i className="fas fa-shopping-cart  p-0"></i>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}

            {/* -------------------------------- */}
          </div>
        </div>
      </div>
    </section>
  );
};
ServicesSection.propTypes = {
  // user_name: PropTypes.object.isRequired,
  setCart: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  // user_name: state.user_profile,
});
export default connect(mapStateToProps, { setCart })(ServicesSection);
