import React, { Fragment, useEffect, useState } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { handleFavorite } from '../../../Redux/actions/favorite'
import ProjectListingCard from '../Projects/ProjectListingCard'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { useParams } from 'react-router-dom'

import {
  API_URL,
  multiFormHeader,
  BASE_URL,
  jsonHeader,
  USER_IMAGE_NOT_FOUND_URL,
  USER_PROFILE_URL
} from '../../../Constants/global'

const FavoritePropertyListingColumn = ({ propertyData, handleFavorite }) => {
  const [project_data, set_project_data] = useState({
    id: ''
  })
  const [projectData, setProjectData] = useState([])
  useEffect(() => {
    axios({
      method: 'GET',
      url: API_URL + 'api/featured_projects'
    }).then(response => {
      setProjectData(response.data.data)
    })
  }, [])
  // console.log(project_params.searchquery.split("-").pop());

  // const project_id = projectData.searchquery.split("-").pop();

  const [Statusmessage, setMessage] = useState({
    message: '',
    class: ''
  })
  const [isLoading, setIsLoading] = useState({
    isLoading: false,
    disabled: false
  })
  function setSuccessMessage (msg) {
    setMessage({
      message: msg,
      class: 'alert alert-success contactUsAlert'
    })
  }
  function setErrorMessage (msg) {
    setMessage({
      message: msg,
      class: 'alert alert-danger contactUsAlert'
    })
  }
  function removeMessage (msg) {
    setMessage({
      message: '',
      class: ''
    })
  }

  const [apierror, setApierror] = useState('')
  const initialValues = {
    name: '',
    email: '',
    contact_number: '',
    message: ''
  }

  const validateSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email format').required('Required'),
    contact_number: Yup.number().required('Required'),
    message: Yup.string().required('Required'),
    name: Yup.string().required('Required')
  })
  const onSubmit = (values, { resetForm }) => {
    const data_arr = project_data.id.split('+')

    const id = data_arr[0]
    const title = data_arr[1]
    const href = 'https://jagahonline.com' + data_arr[2]

    setIsLoading({
      isLoading: true,
      disabled: true
    })
    console.log(values)
    var form = new FormData()
    form.append('name', values.name)
    form.append('email', values.email)
    form.append('contact_number', values.contact_number)
    form.append('message', values.message)
    form.append('type', 'project')
    form.append('ref_id', id)
    form.append('title', title)
    //get current date

    const current = new Date()
    const date = `${current.getDate()}-${
      current.getMonth() + 1
    }-${current.getFullYear()} - ${current.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    })}`

    form.append('inquiry_date_time', date)

    form.append('url', href)

    console.log(form)

    axios({
      method: 'POST',
      url: API_URL + 'generalservices/inquiry',
      data: form,
      headers: multiFormHeader
    }).then(response => {
      if (response.data.status === 200) {
        setIsLoading({
          isLoading: false,
          disabled: false
        })
        setSuccessMessage('Your request has been sent')
        setTimeout(removeMessage, 3000)
        resetForm({ values: '' })
      } else {
        setIsLoading({
          isLoading: false,
          disabled: false
        })
        setApierror(response.data.message)
        setErrorMessage(response.data.message)
      }
    })
  }
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validateSchema
  })

  const [favoriteIconClass, setFavoriteIconClass] = useState('')
  const property_thumbnail =
    BASE_URL + 'classified_assets/images/placeholder/jo-placeholder.png'

  const inquiry_modal_func = e => {
    set_project_data({ id: e.target.id })
  }
  const add_to_favorite = e => {
    const response = axios({
      method: 'GET',
      url:
        API_URL +
        'generalservices/property_favorites_add/' +
        e.target.id +
        '/' +
        localStorage.getItem('user_id')
    }).then(response => {
      if (response.status === 200) {
        document.getElementById(e.target.id).className =
          document.getElementById(e.target.id).className === 'fa fa-heart'
            ? 'far fa-heart'
            : 'fa fa-heart'
        localStorage.setItem('total_favorites', response.data.total_favorites)
        handleFavorite(response.data.total_favorites)
        console.log(localStorage.getItem('total_favorites'))
      }
    })
  }

  const delete_property = e => {
    const body = [
      {
        table_name: 'joproperties',
        field_id: e.target.id
        // field_value: ,
      }
    ]

    const response = axios({
      method: 'POST',
      url: API_URL + 'generalservices/delete_record',
      headers: jsonHeader,
      data: {
        table_name: 'joproperties',
        field_id: 'id',
        field_value: e.target.id
      }
    }).then(response => {
      console.log(response)
      if (response.status === 200) {
        console.log('Property Removed')
        // window.location.reload();
        // window.location.reload();
      }
    })
  }

  try {
    return (
      <>
        {/* Iterate over newly_listed_pproperties data */}

        {propertyData.map((data, key) => {
          console.log('Current Data row ' + data)

          const detail_page_url = '/property/' + data.slug + '-' + data.id
          const property_detail_URL =
            '/property/' +
            data.city_data[0].city.toString().toLowerCase() +
            '-' +
            data.slug.toLowerCase() +
            '-' +
            data.id

          const userProfile = data.user_profile
          const areaUnit = data.area_unit_data

          var user_data_q = USER_IMAGE_NOT_FOUND_URL

          if (userProfile.length !== 0) {
            if (
              data.user_profile[0].profile_image === undefined ||
              data.user_profile[0].profile_image === null ||
              data.user_profile[0].profile_image === ''
            ) {
              user_data_q = USER_IMAGE_NOT_FOUND_URL
            } else {
              const mainRegExp = RegExp('https')

              if (mainRegExp.test(data.user_profile[0].profile_image)) {
                user_data_q = data.user_profile[0].profile_image
              } else {
                user_data_q =
                  USER_PROFILE_URL +
                  data.user_id +
                  '/' +
                  data.user_profile[0].profile_image
              }
            }
          }

          const profile_image_url = user_data_q

          var imageURL = property_thumbnail

          if (data.front_image !== '' && data.front_image !== 'null') {
            const mainRegExp = RegExp('https')

            if (mainRegExp.test(data.front_image)) {
              imageURL = data.front_image
            } else {
              imageURL =
                'https://cdn.jagahonline.com/properties/' +
                data.id +
                '/' +
                data.front_image.split('.')[0] +
                '-thumb.' +
                data.front_image.split('.')[1]
            }
          }

          var bath = 0
          var bed = 0

          if (data.beds_baths.length !== 0) {
            data.beds_baths.map(item => {
              try {
                if (item.bathroom_data === 'Bed Room') {
                  bed = item.value
                }

                if (item.bathroom_data === 'Bath Room') {
                  bath = item.value
                }
              } catch {}
            })
          }
          return (
            <div key={key} className='col-sm-6'>
              <div className='pp-main'>
                <div className='property-item-listing property-item'>
                  <div className='property-image bg-overlay-gradient-04'>
                    <img
                      className='img-fluid property-image-main'
                      src={imageURL}
                      onError={property_thumbnail}
                      alt=''
                      width={350}
                      height={230}
                      maxWidth={350}
                      maxHeight={230}
                      minWidth={350}
                      minheight={230}
                      loading='lazy'
                    />
                    <div className='property-lable'>
                      <span className='badge badge-md badge-primary'>
                        {data.property_purpose_data.length > 0
                          ? data.property_purpose_data[0].title
                          : ''}
                      </span>
                      <span className='badge badge-md badge-info'>
                        {data.city_data[0].city}
                      </span>
                    </div>
                    <div className='property-agent'>
                      <div className='property-agent-image'>
                        <img
                          className='img-fluid'
                          src={profile_image_url}
                          key={profile_image_url}
                          minWidth={50}
                          maxHeight={50}
                          alt=''
                          loading='lazy'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='property-details'>
                    <div className='property-details-inner'>
                      <h5 className='property-title'>
                        <a href={property_detail_URL}>{data.title}</a>
                      </h5>
                      <span className='property-address'>
                        <i className='fas fa-map-marker-alt fa-xs'></i>
                        {data.address}
                      </span>

                      <div className='property-price'>
                        <span className='font-sm text-primary font-weight-bolder'>
                          {data.currency}
                        </span>{' '}
                        {data.price_txt}
                      </div>
                      <ul className='property-info list-unstyled d-flex'>
                        <li className='flex-fill property-bed'>
                          <i className='fas fa-bed'></i>Bed
                          <span>{bed}</span>
                        </li>
                        <li className='flex-fill property-bath'>
                          <i className='fas fa-bath'></i>Bath
                          <span>{bath}</span>
                        </li>
                        <li className='flex-fill property-m-sqft'>
                          <i className='far fa-square'></i>
                          {areaUnit.length === 0 ? '' : areaUnit[0].title}
                          <span>{data.land_area}</span>
                        </li>
                      </ul>
                    </div>
                    <div className='property-btn'>
                      <a className='property-link' href={property_detail_URL}>
                        See Details
                      </a>
                      <ul className='property-listing-actions list-unstyled mb-0'>
                        <li className='property-favourites'>
                          {localStorage.getItem('token') !== '' ? (
                            <a href='javascript:void(0);' title='Favourite'>
                              <i
                                id={data.id}
                                key={data.id}
                                onClick={add_to_favorite}
                                className={
                                  data.isFavorite === 0
                                    ? 'far fa-heart'
                                    : 'fa fa-heart'
                                }
                                name='favorite'
                              ></i>
                            </a>
                          ) : (
                            <a
                              data-toggle='modal'
                              data-target='#loginModal'
                              href='javascript:void(0);'
                              title='Favourite'
                            >
                              <i className='far fa-heart'></i>
                            </a>
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </>
    )
  } catch (err) {
    console.log(err.message)
    return (
      <>
        <div className='container'>Server error, Please try again later.</div>
      </>
    )
  }
}

export default connect(null, { handleFavorite })(FavoritePropertyListingColumn)
