import { BROWSE_CATEGORY } from "../actions/types";
import axios from "axios";

const initialState = [];

// function that takes in a state with default as initial state and an action
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case BROWSE_CATEGORY:
      return payload;

    default:
      return state;
  }
}
