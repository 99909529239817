import React from "react";
import ListingBanner from "../../../Components/ClassifiedComponents/ListingBanner";
import ProSellerSection from "../../../Components/ClassifiedComponents/ProSellerSection";
import AppDownloadFooter from "../../../Components/footer/AppDownloadFooter";
import NewlyListedProjects from "../../../Components/ClassifiedComponents/Projects/NewlyListedProjects";
import ProjectSearchBar from "../../../Components/Header/joclassified/ProjectSearchBar";
import BrowseByCities from "../../../Components/ClassifiedComponents/BrowseByCities";

function Projects() {
  localStorage.setItem("searchQueryProject", "");
  return (
    <div>
      <ProjectSearchBar />
      <BrowseByCities />
      <NewlyListedProjects />
      <ListingBanner />

      {/* <ProSellerSection/> */}
      <AppDownloadFooter />
    </div>
  );
}

export default Projects;
