import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import useFetchData from '../../../Helpers/useFetchData'
import emailjs from '@emailjs/browser'

import {
  API_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  multiFormHeader,
  currentUserInformation,
  EMAIL_SERVICE_ID,
  EMAIL_VERIFICATION_TEMPLATE_ID,
  EMAIL_PUBLIC_KEY,
  BASE_URL
} from '../../../Constants/global'

function Registrationform () {
  const [passwordType, setPasswordType] = useState({
    passwordType: 'password',
    eyeIconClass: 'fa fa-eye-slash eyeIcon',
    eyeColorClass: ''
  })
  function togglePassword () {
    if (passwordType.passwordType === 'password') {
      setPasswordType({
        passwordType: 'text',
        eyeIconClass: 'fa fa-eye eyeIcon',
        eyeColorClass: 'text-primary'
      })
    } else {
      setPasswordType({
        passwordType: 'password',
        eyeIconClass: 'fa fa-eye-slash eyeIcon',
        eyeColorClass: ''
      })
    }
  }
  const [data, setData] = useState(null)
  // const { data, loading, error } = useFetchData(API_URL + 'api/roles')

  const [isLoading, setIsLoading] = useState({
    isLoading: false,
    disabled: false
  })

  const [isLoadingVerify, setIsLoadingVerify] = useState({
    isLoading: false,
    disabled: false
  })

  const [apierror, setApierror] = useState('')
  const [errorclass, setErrorclass] = useState('error text-danger')

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    user_role_id: 3,
    password: '',
    confirm_password: ''
  }

  const validateSchema = Yup.object().shape({
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email format').required('Required'),
    phone_number: Yup.string().required('Required'),
    // user_role_id: Yup.string().required('Required'),
    password: Yup.string().required('Required').min('8'),
    confirm_password: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match'
    )
  })

  const onSubmit = values => {
    setIsLoading({
      isLoading: true,
      disabled: true
    })
    console.log(values)

    var form = new FormData()
    form.append('first_name', values.first_name)
    form.append('last_name', values.last_name)
    form.append('email', values.email)
    form.append('phone_number', values.phone_number)
    form.append('role', values.user_role_id)
    form.append('password', values.password)

    axios({
      method: 'POST',
      url: API_URL + 'api/create_user',
      data: form,
      headers: multiFormHeader
    }).then(response => {
      if (response.data.status === 200) {
        setIsLoading({
          isLoading: false,
          disabled: false
        })
        setApierror(response.data.message)

        setErrorclass('error text-success')

        // var templateParams = {
        //   name: values.first_name,
        //   to_email: values.email,
        //   remember_pin: response.data.code,
        //   email_verify_url: BASE_URL + '/verify/' + response.data?.user_id
        // }
        // localStorage.setItem('remember_pin', response.data.code)

        // emailjs
        //   .send(
        //     EMAIL_SERVICE_ID,
        //     EMAIL_VERIFICATION_TEMPLATE_ID,
        //     templateParams,
        //     EMAIL_PUBLIC_KEY
        //   )
        //   .then(result => {})

        setTimeout(function () {
          window.location.href = '/verify/' + response.data?.user_id
        }, 3000)
      } else {
        setIsLoading({
          isLoading: false,
          disabled: false
        })
        setApierror(response.data.message)
        setErrorclass('error text-danger')

        if (response.data.message === 'Please verify your account.') {
          localStorage.setItem('user_id', response.data.user_id)
          localStorage.setItem('name', values.first_name)
          localStorage.setItem('to_email', values.email)
          localStorage.setItem('remember_pin', response.data.code)
        }
      }
    })
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validateSchema
  })

  function sendVerification () {
    setIsLoadingVerify({
      isLoading: true,
      disabled: true
    })
    axios({
      method: 'GET',
      url:
        API_URL +
        `api/resend_verification_code/${localStorage.getItem('user_id')}`,

      headers: multiFormHeader
    }).then(response => {
      if (response.data.status === 200) {
        setIsLoadingVerify({
          isLoading: false,
          disabled: false
        })
        // ------------------------------------------------
        var templateParams = {
          name: localStorage.getItem('name'),
          to_email: localStorage.getItem('to_email'),
          remember_pin: response.data.code,
          email_verify_url:
            BASE_URL + 'verify/' + localStorage.getItem('user_id')
        }
        // alert(localStorage.getItem("name"));
        // alert(localStorage.getItem("to_email"));
        // alert(templateParams.remember_pin);
        // alert(templateParams.email_verify_url);

        emailjs
          .send(
            EMAIL_SERVICE_ID,
            EMAIL_VERIFICATION_TEMPLATE_ID,
            templateParams,
            EMAIL_PUBLIC_KEY
          )
          .then(result => {})
        // ---------------------------------------------------------
        window.location.href = `/verify/${localStorage.getItem('user_id')}`
      } else {
        setIsLoadingVerify({
          isLoading: false,
          disabled: false
        })
      }
    })
  }
  return (
    <div>
      <form
        className='form-row mt-4 mb-2 align-items-center'
        onSubmit={formik.handleSubmit}
      >
        <div className='form-group col-sm-6'>
          <input
            type='first_name'
            className='form-control'
            id='first_name'
            name='first_name'
            placeholder='First Name'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.first_name}
          />
          {formik.touched.first_name && formik.errors.first_name ? (
            <div className='error text-danger'>{formik.errors.first_name}</div>
          ) : null}
        </div>
        <div className='form-group col-sm-6'>
          <input
            type='last_name'
            className='form-control'
            id='last_name'
            name='last_name'
            placeholder='Last Name'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.last_name}
          />
          {formik.touched.last_name && formik.errors.last_name ? (
            <div className='error text-danger'> {formik.errors.last_name} </div>
          ) : null}
        </div>
        <div className='form-group col-sm-12'>
          <input
            type='email'
            className='form-control'
            aria-describedby='emailHelp'
            id='email'
            name='email'
            placeholder='Email Address'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className='error text-danger'> {formik.errors.email} </div>
          ) : null}
        </div>

        <div className='form-group col-sm-12'>
          <input
            type='phone_num'
            className='form-control'
            id='phone_number'
            name='phone_number'
            placeholder='Phone Number'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.phone_number}
          />
          {formik.touched.phone_number && formik.errors.phone_number ? (
            <div className='error text-danger'>
              {' '}
              {formik.errors.phone_number}{' '}
            </div>
          ) : null}
        </div>
        <div className='form-group col-sm-12'>
          <select
            type='user_type'
            className='form-control'
            id='user_role_id'
            name='user_role_id'
            placeholder='Select user type'
            onChange={formik.handleChange}
            value={formik.values.user_role_id}
          >
            {data?.data.map(item => {
              return (
                <option key={item.id} value={item.id}>
                  {item.title}
                </option>
              )
            })}
          </select>
        </div>
        <div className='form-group col-sm-12'>
          <input
            type={passwordType.passwordType}
            className='form-control'
            id='password'
            name='password'
            placeholder='Password'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.password}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className='error text-danger'> {formik.errors.password} </div>
          ) : null}
        </div>
        <div className='form-group col-sm-12 iconIn'>
          <input
            type={passwordType.passwordType}
            className='form-control'
            id='confirm_password'
            name='confirm_password'
            placeholder='Confirm Password'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.confirm_password}
          />
          <span
            onClick={togglePassword}
            type='button'
            className={passwordType.eyeColorClass}
          >
            {' '}
            <i class={passwordType.eyeIconClass}></i>
          </span>
          {formik.touched.confirm_password && formik.errors.confirm_password ? (
            <div className='error text-danger'>
              {' '}
              {formik.errors.confirm_password}{' '}
            </div>
          ) : null}
        </div>
        <div className='col-sm-12'>
          {apierror ? <p className={errorclass}> {apierror} </p> : null}
          {apierror === 'Please verify your account.' ? (
            <a
              href='javascript:void(0);'
              onClick={sendVerification}
              disabled={isLoadingVerify.disabled}
            >
              <p>
                {' '}
                clich here to verify
                <span
                  hidden={isLoadingVerify.disabled - 1}
                  className='spinner-border spinner-border-sm ml-2'
                  role='status'
                  aria-hidden='true'
                ></span>
              </p>
            </a>
          ) : null}
        </div>
        <div className='col-sm-6'>
          <button
            disabled={isLoading.disabled}
            type='submit'
            className='btn btn-primary btn-block'
          >
            Sign up
            <span
              hidden={isLoading.disabled - 1}
              className='spinner-border spinner-border-sm ml-2'
              role='status'
              aria-hidden='true'
            ></span>
          </button>
        </div>
        <div className='col-sm-6 text-right'>
          <ul className='list-unstyled mb-1 mt-sm-0 mt-3'>
            <li className='mr-1'>
              <a data-toggle='tab' href='#login'>
                <b>Already Registered User? Login</b>
              </a>
            </li>
          </ul>
        </div>
      </form>
    </div>
  )
}

export default Registrationform
