import React, { useState } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { handleFavorite } from '../../Redux/actions/favorite'
//changes
import {
  API_URL,
  BASE_URL,
  jsonHeader,
  USER_IMAGE_NOT_FOUND_URL,
  USER_PROFILE_URL
} from '../../Constants/global'
import { Link } from 'react-router-dom'

const MyPropertyListingColumn = ({ propertyData, handleFavorite }) => {
  const [Statusmessage, setMessage] = useState({
    msg: '',
    class: ''
  })

  function setAlert () {
    setMessage({
      msg: 'This property is pending approval.',
      class: 'alert alert-warning fixed-bottom'
    })
  }

  function removeAlert () {
    setMessage({
      msg: '',
      class: ''
    })
  }
  function handleClick () {
    // alert("clicked");
    setAlert()
    setTimeout(removeAlert, 3000)
  }
  const property_thumbnail =
    BASE_URL + 'classified_assets/images/placeholder/jo-placeholder.png'

  // generalservices/property_favorite_add/{property_id}/{user_id}
  const add_to_favorite = e => {
    const response = axios({
      method: 'GET',
      url:
        API_URL +
        'generalservices/property_favorites_add/' +
        e.target.id +
        '/' +
        localStorage.getItem('user_id')
    })

    if (response.status === 200) {
      if (response.status === 200) {
        document.getElementById(e.target.id).className =
          document.getElementById(e.target.id).className === 'fa fa-heart'
            ? 'far fa-heart'
            : 'fa fa-heart'
        localStorage.setItem('total_favorites', response.data.total_favorites)
        handleFavorite(response.data.total_favorites)
        console.log(localStorage.getItem('total_favorites'))
      }
    }
  }

  const delete_property = e => {
    const body = [
      {
        table_name: 'joproperties',
        field_id: e.target.id
        // field_value: ,
      }
    ]

    const response = axios({
      method: 'POST',
      url: API_URL + 'generalservices/delete_record',
      headers: jsonHeader,
      data: {
        table_name: 'joproperties',
        field_id: 'id',
        field_value: e.target.id
      }
    }).then(response => {
      console.log(response)
      if (response.status === 200) {
        console.log('Property Removed')
        window.location.reload()
      }
    })
  }

  const edit_property = e => {
    window.location.href = '/property-add/' + e.target.id
  }

  try {
    return (
      <>
        <div className={Statusmessage.class}>{Statusmessage.msg}</div>
        {/* Iterate over newly_listed_pproperties data */}
        {propertyData.map(function (data, key) {
          console.log('Current Data row ' + data)

          const detail_page_url = '/property/' + data.slug + '-' + data.id
          const city = data.city_data.length === 0 ? '' : data.city_data[0].city
          const property_detail_URL =
            '/property/' +
            city.toLowerCase() +
            '-' +
            data.slug.toLowerCase() +
            '-' +
            data.id

          const userProfile = data.user_profile
          const areaUnit = data.area_unit_data

          var user_data_q = USER_IMAGE_NOT_FOUND_URL

          if (userProfile.length !== 0) {
            if (
              data.user_profile[0].profile_image === undefined ||
              data.user_profile[0].profile_image === null ||
              data.user_profile[0].profile_image === ''
            ) {
              user_data_q = USER_IMAGE_NOT_FOUND_URL
            } else {
              const mainRegExp = RegExp('https')

              if (mainRegExp.test(data.user_profile[0].profile_image)) {
                user_data_q = data.user_profile[0].profile_image
              } else {
                user_data_q =
                  USER_PROFILE_URL +
                  data.user_id +
                  '/' +
                  data.user_profile[0].profile_image
              }
            }
          }

          const profile_image_url = user_data_q

          var imageURL = property_thumbnail

          console.log(data.front_image)

          if (data.front_image !== '' && data.front_image !== 'null') {
            const mainRegExp = RegExp('https')

            if (mainRegExp.test(data.front_image)) {
              imageURL = data.front_image
            } else {
              imageURL =
                'https://cdn.jagahonline.com/properties/' +
                data.id +
                '/' +
                data.front_image
            }
          }

          var bath = 0
          var bed = 0

          if (data.beds_baths.length !== 0) {
            data.beds_baths.map(item => {
              try {
                if (item.bathroom_data === 'Bed Room') {
                  bed = item.value
                }

                if (item.bathroom_data === 'Bath Room') {
                  bath = item.value
                }
              } catch {}
            })
          }

          return (
            <div key={key} className='col-sm-6'>
              <div className='pp-main'>
                <div className='property-item-listing property-item'>
                  <Link to={property_detail_URL}>
                    <div className='property-image bg-overlay-gradient-04'>
                      <img
                        className='img-fluid property-image-main'
                        src={imageURL}
                        onError={property_thumbnail}
                        alt=''
                        width={350}
                        height={230}
                        maxWidth={350}
                        maxHeight={230}
                        minWidth={350}
                        minheight={230}
                      />
                      <div className='property-lable'>
                        <span className='badge badge-md badge-primary'>
                          {data.property_purpose_data.length > 0
                            ? data.property_purpose_data[0].title
                            : ''}
                        </span>
                        <span className='badge badge-md badge-info'>
                          {data.city_data.length === 0
                            ? ''
                            : data.city_data[0].city}
                        </span>
                      </div>
                      <div className='property-agent'>
                        <div className='property-agent-image'>
                          <img
                            className='img-fluid'
                            src={profile_image_url}
                            minWidth={50}
                            maxHeight={50}
                            alt=''
                          />
                        </div>
                      </div>
                    </div>
                  </Link>
                  <div className='property-details'>
                    <Link to={property_detail_URL}>
                      <div className='property-details-inner'>
                        <h5 className='property-title'>
                          {data.active === 0 ? (
                            <a href='avascript:void(0)' onClick={handleClick}>
                              {data.title}
                            </a>
                          ) : (
                            <a href={property_detail_URL}>{data.title}</a>
                          )}
                        </h5>
                        <span className='property-address'>
                          <i className='fas fa-map-marker-alt fa-xs'></i>
                          {data.address}
                        </span>

                        <div className='property-price'>
                          <span className='font-sm text-primary font-weight-bolder'>
                            {data.currency}
                          </span>{' '}
                          {data.price_txt}
                        </div>
                        <ul className='property-info list-unstyled d-flex'>
                          <li className='flex-fill property-bed'>
                            <i className='fas fa-bed'></i>Bed
                            <span>{data.beds}</span>
                          </li>
                          <li className='flex-fill property-bath'>
                            <i className='fas fa-bath'></i>Bath
                            <span>{data.baths}</span>
                          </li>
                          <li className='flex-fill property-m-sqft'>
                            <i className='far fa-square'></i>
                            {data.land_area === 0 ? '' : data.land_area}
                            <span>{data.area_unit_txt}</span>
                          </li>
                        </ul>
                      </div>
                    </Link>
                    <div className='property-btn'>
                      {data.active === 0 ? (
                        <a
                          className='property-link'
                          href='avascript:void(0)'
                          onClick={handleClick}
                        >
                          See Details
                        </a>
                      ) : (
                        <a className='property-link' href={property_detail_URL}>
                          See Details
                        </a>
                      )}

                      <ul className='property-listing-actions list-unstyled mb-0'>
                        {/* ----------------------------------------Trash---------------------------------------- */}
                        {/* ------------------------------------------------------------------------------- */}
                        {/*                        Pending and Approved status added*/}
                        {data.active === 0 ? (
                          <li className='property-compare'>
                            <a
                              data-toggle='tooltip'
                              data-placement='top'
                              title=''
                              href='#'
                              data-original-title='Pending'
                              className='d-flex align-items-center justify-content-center'
                            >
                              <i className='fas fa-clock pr-1'></i>{' '}
                              <span className='font-sm'>Pending</span>
                            </a>
                          </li>
                        ) : (
                          <li className='property-compare'>
                            <a
                              data-toggle='tooltip'
                              data-placement='top'
                              title=''
                              href='javascript:void(0);'
                              data-original-title='Approved'
                              className='d-flex align-items-center justify-content-center text-success'
                            >
                              <i className='fas fa-check-circle pr-1'></i>{' '}
                              <span className='font-sm'>Approved</span>
                            </a>
                          </li>
                        )}
                        <li className='property-compare'>
                          <a
                            data-toggle='tooltip'
                            data-placement='top'
                            title=''
                            href={`/property-add/${data.id}`}
                            data-original-title='Approved'
                            className='d-flex align-items-center justify-content-center text-success'
                          >
                            <i className='fas fa-edit pr-1'></i>{' '}
                            <span className='font-sm'></span>
                          </a>
                        </li>
                        <li className='property-favourites'>
                          {localStorage.getItem('token') !== '' ? (
                            <a href='javascript:void(0);' title='Favourite'>
                              <i
                                id={data.id}
                                key={data.id}
                                onClick={delete_property}
                                className='fa fa-trash'
                                name='favorite'
                              ></i>
                            </a>
                          ) : (
                            <a
                              data-toggle='modal'
                              data-target='#loginModal'
                              href='javascript:void(0);'
                              title='Favourite'
                            >
                              <i className='far fa-heart'></i>
                            </a>
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </>
    )
  } catch (err) {
    console.log(err.message)
    return (
      <>
        <div className='container'>Server error, Please try again later.</div>
      </>
    )
  }
}

export default connect(null, { handleFavorite })(MyPropertyListingColumn)
