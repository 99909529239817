import React from "react";
import UstadAuth from "../authentication/UstadAuth";
import OrderBookModal from "../../../Handymen/OrderBookModal";
import { useParams, Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cart from "../../../../Redux/reducers/cart";
import { setCart } from "../../../../Redux/actions/cart";
import services from "../../../../Redux/reducers/services";
import { getServices } from "../../../../Redux/actions/services";

const ServiceBookNow = ({ cart, services }) => {
  const params = useParams();
  const [serviceData, setServiceData] = useState();
  useEffect(() => {
    getServices();
  }, []);
  useEffect(() => {
    services.map((item, index) => {
      // alert(item.id);
      // alert(params.id);
      // alert(item.id + params.id);

      if (item.id.toString() === params.id) {
        setServiceData(services[index]);
      }
      getServices();
    });
  }, [services]);
  const [cartData, setCartData] = useState({
    cart_total: "0",
    cart_qty: "0",
  });
  const [shake, add_shake] = useState("");
  useEffect(() => {
    add_shake("shake");
    setTimeout(() => {
      add_shake("");
    }, 800);
  }, [cart.cart_qty]);
  return (
    <>
      <div className="service-button-wrapper active">
        <a
          className={`btn btn-primary btn-service d-flex align-items-center justify-content-between p-0 ${shake}`}
          //if not logged in
          data-toggle={localStorage.getItem("token") ? "" : "modal"}
          data-target={localStorage.getItem("token") ? "" : "#loginModalUstad"}
          href={localStorage.getItem("token") ? "/check-out" : ""}

          //if logged in
          //open other modal
        >
          <div className="btn-service-right">
            <span className="service-btn-count">
              {localStorage.getItem("token") ? cart.cart_qty : "0"}
            </span>
            <span className="service-btn-total">
              Rs {localStorage.getItem("token") ? cart.cart_total : "0"}
            </span>
          </div>
          <div className="lets-continue">
            Tap to Continue
            <i className="fas fa-arrow-circle-right"></i>
          </div>
        </a>
      </div>
      <UstadAuth />
      {/* <OrderBookModal serviceData={serviceData} /> */}
    </>
  );
};
ServiceBookNow.propTypes = {
  // getCart: PropTypes.func.isRequired,
  cart: PropTypes.object.isRequired,
  getServices: PropTypes.func.isRequired,
  services: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  cart: state.cart,
  services: state.services,
});

export default connect(mapStateToProps, { setCart, getServices })(
  ServiceBookNow
);
