import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import services from "../../../../Redux/reducers/services";
import { getServices } from "../../../../Redux/actions/services";

const ServiceHeader = ({ serviceData }) => {
  const [data, setData] = useState({});
  useEffect(() => {
    if (
      serviceData !== "" &&
      serviceData !== null &&
      serviceData !== undefined &&
      serviceData !== "null"
    ) {
      setData(serviceData);
    }
    // alert(services);
  }, [serviceData]);
  return (
    <section className="hman-service-header overflow-hidden position-relative">
      <div className="service-hero-header"></div>
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-5 col-lg-5">
            <div className="py-5 px-md-5">
              <h1 className="text-white mb-3 banner-bg-slider-title">
                {/* {services !== "" ? services[0].title : ""} */}
                {/* {data !== [] ? data[0].title : ""} */}
                {`${data.title !== undefined ? data.title : ""} `}
                in Karachi
              </h1>
              <span className="text-white banner-bg-slider-sub-title">
                We Service, Repair, and Install All AC Brands!
              </span>
            </div>
          </div>
          <div className="col-md-7 col-lg-7">
            <div className="service-category-main">
              <div className="service-category-box">
                <div className="service-category-icon">
                  <img
                    className="img-fluid"
                    alt="icons"
                    src="/classified_assets/images/handymen-images/headphone-icon.svg"
                  />
                </div>
                <div className="service-category-price">
                  <div className="service-price-inner">Rs. 800</div>
                </div>
                <div className="service-category-starting">
                  <div className="service-starting-inner">Starting From</div>
                </div>
              </div>
              <div className="service-category-box">
                <div className="service-category-icon">
                  <img
                    className="img-fluid"
                    alt="icons"
                    src="/classified_assets/images/handymen-images/headphone-icon.svg"
                  />
                </div>
                <div className="service-category-price">
                  <div className="service-price-inner">Rs. 800</div>
                </div>
                <div className="service-category-starting">
                  <div className="service-starting-inner">Starting From</div>
                </div>
              </div>
              <div className="service-category-box">
                <div className="service-category-icon">
                  <img
                    className="img-fluid"
                    alt="icons"
                    src="/classified_assets/images/handymen-images/headphone-icon.svg"
                  />
                </div>
                <div className="service-category-price">
                  <div className="service-price-inner">Rs. 800</div>
                </div>
                <div className="service-category-starting">
                  <div className="service-starting-inner">Starting From</div>
                </div>
              </div>
              <div className="service-category-box">
                <div className="service-category-icon">
                  <img
                    className="img-fluid"
                    alt="icons"
                    src="/classified_assets/images/handymen-images/headphone-icon.svg"
                  />
                </div>
                <div className="service-category-price">
                  <div className="service-price-inner">Rs. 800</div>
                </div>
                <div className="service-category-starting">
                  <div className="service-starting-inner">Starting From</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
ServiceHeader.propTypes = {
  getServices: PropTypes.func.isRequired,
  services: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  services: state.services,
});
export default connect(mapStateToProps, { getServices })(ServiceHeader);
