import React, { Fragment, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import services from "../../Redux/reducers/services";
import { getServices } from "../../Redux/actions/services";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ServiceHeader from "./components/servicesLanding/ServiceHeader";
import ServicesSection from "./components/servicesLanding/ServicesSection";
import HandymenTestimonial from "./components/landing/HandymenTestimonial";
import ServiceBookNow from "./components/servicesLanding/ServiceBookNow";
import OrderBookModal from "./OrderBookModal";

const ServicesLanding = ({ getServices, services }) => {
  const params = useParams();
  const [serviceData, setServiceData] = useState();
  useEffect(() => {
    getServices();
  }, []);
  useEffect(() => {
    services.map((item, index) => {
      // alert(item.id);
      // alert(params.id);
      // alert(item.id + params.id);

      if (item.id.toString() === params.id) {
        setServiceData(services[index]);
      }
    });
  }, [services]);

  return (
    <Fragment>
      <ServiceHeader serviceData={serviceData} />
      <ServicesSection serviceData={serviceData} />
      <HandymenTestimonial />
      <ServiceBookNow />
      <OrderBookModal serviceData={serviceData} />
    </Fragment>
  );
};
ServicesLanding.propTypes = {
  getServices: PropTypes.func.isRequired,
  services: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  services: state.services,
});
export default connect(mapStateToProps, { getServices })(ServicesLanding);
