// local server

// const API_URL = 'http://127.0.0.1:8001/'
// const BASE_URL = 'http://127.0.0.1:3000/'

// development server

//const API_URL = "http://164.92.102.85:8001/";
// const BASE_URL = 'http://164.92.114.64/'

// Development  URLs

// const API_URL = "https://devapi.jotesting.com/";
// const BASE_URL = "https://revamp.jotesting.com/";

// Live URLs

//aaaa

// const API_URL = 'https://devapi.jotesting.com/'
// const BASE_URL = 'https://revamp.jotesting.com/'

const HOSTNAME =
  typeof window !== 'undefined' && window.location.hostname
    ? window.location.hostname
    : ''
const ORIGIN =
  typeof window !== 'undefined' && window.location.origin
    ? window.location.origin
    : ''

// const BASE_URL = '

const API_URL = 'https://cloudapi.cloudapiserver.com/'
const BASE_URL = 'https://www.jagahonline.com/'

// JazzCash Payment API

const JAZZCASH_SANDBOX = 'https://'
const JAZZCASH_LIVE = 'https://'

//

const themeDefault = 'dark'
const namesOfModes = ['dark', 'moonlight', 'eclipse', 'light']

const USER_AGENCY_PROFILE_IMAGEURL = 'https://cdn.jagahonline.com/'
const USER_IMAGE_NOT_FOUND_URL =
  'https://jocdn.sfo3.cdn.digitaloceanspaces.com/classified_images%2Fwebsite_assets%2Fimages%2FMale.jpg'

const USER_PROFILE_URL =
  'https://storage.googleapis.com/jagahonline-data/users/'

const jsonHeader = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('token'),
  'Accept-Encoding': 'gzip, compress, br'
}

//  SEO - search reasult URL  - property_type/city/purpose/area-area-id

const multiFormHeader = {
  'Content-Type': 'multipart/form-data',
  Authorization: 'Bearer ' + localStorage.getItem('token'),
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
  'Access-Control-Allow-Headers': '*',
  'Access-Control-Max-Age': '1728000',
  'Content-Length': '0',
  'Accept-Encoding': 'gzip, compress, br'
}

const multiFormHeader_No_Token = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
  'Access-Control-Allow-Headers': '*',
  'Access-Control-Max-Age': '1728000',
  'Content-Length': '0',
  'Accept-Encoding': 'gzip, compress, br'
}

const multiFormHeader1 = {
  'Content-Type': 'multipart/form-data',
  Authorization: 'Bearer ' + localStorage.getItem('token'),
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
  'Access-Control-Allow-Headers': '*',
  'Access-Control-Max-Age': '1728000',
  'Accept-Encoding': 'gzip, compress, br'
  // "Content-Length": "0",
}
const multiFormHeader2 = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('token'),
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
  'Access-Control-Allow-Headers': '*',
  'Access-Control-Max-Age': '1728000',
  'Accept-Encoding': 'gzip, compress, br',
  'Content-Length': '0'
}

const USER_ROLE = []

var currentUserInformation = {}

const EMAIL_SERVICE_ID = 'service_0jlburf'
const EMAIL_CHANGEPASSWORD_TEMPLATE_ID = 'template_1nfoxfn'
const EMAIL_PUBLIC_KEY = 'yRVr8tXfvPQFYSg_7'
const EMAIL_VERIFICATION_TEMPLATE_ID = 'template_un5u93c'

export {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  multiFormHeader,
  USER_ROLE,
  currentUserInformation,
  USER_AGENCY_PROFILE_IMAGEURL,
  USER_IMAGE_NOT_FOUND_URL,
  USER_PROFILE_URL,
  multiFormHeader1,
  multiFormHeader2,
  EMAIL_SERVICE_ID,
  EMAIL_CHANGEPASSWORD_TEMPLATE_ID,
  EMAIL_PUBLIC_KEY,
  EMAIL_VERIFICATION_TEMPLATE_ID,
  multiFormHeader_No_Token,
  HOSTNAME,
  ORIGIN
}

export function goToDashBoard ({ emptyField }) {
  localStorage.setItem('emptyField', '')
  localStorage.setItem('editProjectID', '')
  window.location.href = BASE_URL + 'dashboard'
}
