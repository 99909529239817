import React, { Component, useState, useEffect, Fragment } from 'react'
import ReactPlayer from 'react-player'
import axios from 'axios'

// import PropertyAmunities from '../../ClassifiedComponents/Properties/PropertyAmunities'
import { useParams, Link } from 'react-router-dom'

import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  multiFormHeader
} from '../../../Constants/global'

function PropertyInfoCard ({ property_details }) {
  //update----------------------------------
  const property_params = useParams()
  console.log(property_params.searchquery.split('-').pop())
  const property_id = property_params.searchquery.split('-').pop()

  const [amnData, setAmnData] = useState([])

  console.log(property_details)

  var mapURL = ''
  const areaData = property_details.area_data
  if (areaData !== undefined && areaData.length > 0) {
    mapURL =
      'https://maps.google.com/maps?q=' +
      encodeURI(areaData[0].area) +
      '&output=embed'
  } else {
    mapURL =
      'https://maps.google.com/maps?q=' +
      encodeURI(property_details.address) +
      '&output=embed'
  }

  var bath = 0
  var bed = 0

  if (property_details.beds_baths !== undefined) {
    if (property_details.beds_baths.length > 0) {
      property_details.beds_baths.map(item => {
        try {
          if (item.bathroom_data === 'Bed Room') {
            bed = item.value
          }

          if (item.bathroom_data === 'Bath Room') {
            bath = item.value
          }
        } catch {}
      })
    }
  }

  return (
    <div>
      {/* ------------------- Property Info Card-------------------*/}
      <div className='card property-details-inner text-left px-3'>
        <div className='property-info mt-4'>
          <div className='row'>
            <div className='col-sm-3 mb-3 mb-sm-0'>
              <h5>Property details</h5>
            </div>
            <div className='col-sm-9'>
              <div className='row mb-3'>
                <div className='col-sm-6'>
                  <ul className='property-list list-unstyled'>
                    <li>
                      <b>Property ID:</b> {property_details.id}
                    </li>
                    <li>
                      <b>Property Size:</b> {property_details.land_area}{' '}
                      {property_details.area_unit_txt}
                    </li>
                  </ul>
                </div>
                <div className='col-sm-6'>
                  <ul className='property-list list-unstyled'>
                    {property_details.beds !== 0 ? (
                      <li>
                        <b>Bedroom(s):</b> {property_details.beds}
                      </li>
                    ) : (
                      ''
                    )}
                    {property_details.baths !== 0 ? (
                      <li>
                        <b>Bath(s):</b> {property_details.baths}
                      </li>
                    ) : (
                      ''
                    )}

                    {property_details.depth !== 0 ? (
                      <li>
                        <b>Depth:</b> {property_details.depth}
                      </li>
                    ) : (
                      ''
                    )}

                    {property_details.width !== 0 ? (
                      <li>
                        <b>Width:</b> {property_details.width}
                      </li>
                    ) : (
                      ''
                    )}

                    {property_details.dining_lounge !== 0 ? (
                      <li>
                        <b>Dining / Lounge:</b> {property_details.dining_lounge}
                      </li>
                    ) : (
                      ''
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className='mt-4 mb-4 mb-sm-5 mt-sm-5' />
        <div className='property-description'>
          <div className='row'>
            <div className='col-sm-3 mb-3 mb-sm-0'>
              <h5>Description</h5>
            </div>
            <div className='col-sm-9'>
              <div
                dangerouslySetInnerHTML={{
                  __html: property_details.description
                }}
              ></div>
            </div>
          </div>
        </div>
        <hr className='mt-4 mb-4 mb-sm-5 mt-sm-5' />
        <div className='property-features'>
          <div className='row'>
            <div className='col-sm-3 mb-3 mb-sm-0'>
              <h5>Features</h5>
            </div>
            <div className='col-sm-9'>
              <div className='row'>
                <div className='property-list-style-2 list-unstyled mb-0 w-100'>
                  {property_details.amenities
                    ? property_details.amenities.map(group => {
                        return (
                          <div className='amenity-box-feature-main mb-3'>
                            <h6 className='property-main-features-name'>
                              {group.amenity_group}
                            </h6>
                            <div className='amenity-group d-flex'>
                              {group.amenities_childs
                                ? group.amenities_childs.map(item => {
                                    // console.log(item.amenities[0].amenity_group_id);
                                    if (item.amenity_group_id === group.id) {
                                      let am_title = ''
                                      // item.amenity
                                      if (item.input_type === 'Boolean') {
                                        am_title = item.amenity
                                      } else if (
                                        item.input_type === 'number' ||
                                        item.input_type === 'text'
                                      ) {
                                        am_title =
                                          item.amenity +
                                          (item.amenity_value &&
                                          item.amenity_value !== ''
                                            ? ` (${item.amenity_value})`
                                            : '')
                                      }
                                      return (
                                        <div className='amenityInfo mr-3'>
                                          {item.icon !== null &&
                                          item.icon !== 'null' &&
                                          item.icon !== undefined &&
                                          item.icon !== '' ? (
                                            <img
                                              class='img-fluid'
                                              src={item.icon}
                                              alt={item.amenity}
                                            ></img>
                                          ) : (
                                            <img
                                              class='img-fluid'
                                              src='../../../../classified_assets/svg/components/otherempty.svg'
                                              alt={item.amenity}
                                            ></img>
                                          )}
                                          {am_title}
                                        </div>
                                      )
                                    }
                                  })
                                : ''}
                            </div>
                          </div>
                        )
                      })
                    : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className='mt-4 mb-4 mb-sm-5 mt-sm-5' />
        <div className='property-address'>
          <div className='row'>
            <div className='col-sm-3 mb-3 mb-sm-0'>
              <h5>Address</h5>
            </div>
            <div className='col-sm-9'>
              <div className='row'>
                <div className='col-sm-6'>
                  <ul className='property-list list-unstyled mb-0'>
                    <li>
                      <b>Address:</b> {property_details.address}
                    </li>
                    <li>
                      <b>Area:</b>{' '}
                      {property_details.area_data !== undefined &&
                      property_details.area_data !== [] &&
                      property_details.area_data.length !== 0
                        ? property_details.area_data[0].area
                        : ''}
                    </li>
                    {/* <li>
                      <b>State/County:</b> {property_details.city_data[0].city}
                    </li> */}
                    <li>
                      <b>Zip:</b>
                      {property_details.zipcode}
                    </li>
                  </ul>
                </div>
                <div className='col-sm-6'>
                  <ul className='property-list list-unstyled mb-0'>
                    <li>
                      <b>City:</b>{' '}
                      {property_details.city_data !== undefined
                        ? property_details.city_data[0].city
                        : ''}
                    </li>
                    <li>
                      <b>Country:</b>
                      {'Pakistan '}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {property_details.front_video === 'null' ||
        property_details.front_video === '' ||
        property_details.front_video === undefined ? (
          ''
        ) : (
          <Fragment>
            <hr className='mt-4 mb-4 mb-sm-5 mt-sm-5' />
            <div className='property-video'>
              <div className='row'>
                <div className='col-sm-3 mb-3 mb-sm-0'>
                  <h5>Property video</h5>
                </div>
                <div className='col-sm-9'>
                  <div className='w-100 h-100'>
                    <ReactPlayer
                      className='w-100 h-100'
                      url={property_details.front_video}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}

        <hr className='mt-4 mb-4 mb-sm-5 mt-sm-5' />
        {mapURL !== undefined || mapURL !== '' ? (
          <div className='property-map mb-4 mb-sm-5'>
            <div className='row'>
              <div className='col-sm-3 mb-3 mb-sm-0'>
                <h5>Location</h5>
              </div>
              <div className='col-sm-9'>
                <div className='embed-responsive embed-responsive-16by9'>
                  <iframe
                    title={property_details.id}
                    src={mapURL}
                    style={{ border: 0, width: '100%', height: '100%' }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {/* ------------------- Property Info Card-------------------*/}
    </div>
  )
}

export default PropertyInfoCard
