import { HANDLE_INQUIRY } from '../actions/types';

const initialState = {};

// function that takes in a state with default as initial state and an action
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HANDLE_INQUIRY:
      return payload;
    default:
      return state;
  }
}
