import React, { Component, useState, useEffect } from 'react'
import axios from 'axios'
import { useParams, Link } from 'react-router-dom'
// import Swiper core and required modules
import { Navigation, Pagination } from 'swiper'

import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  multiFormHeader
} from '../../../Constants/global'

function PropertyDetailSliderMob ({ property_details }) {
  const property_thumbnail =
      BASE_URL + 'classified_assets/images/placeholder/jo-placeholder.png'

  const property_params = useParams()
  console.log(property_params.searchquery.split('-').pop())
  const property_id = property_params.searchquery.split('-').pop()

  const [imagesData, setimagesData] = useState([])

  useEffect(() => {
    function fetchPropertiesImages () {
      axios({
        method: 'GET',
        url: API_URL + 'api/get_property_images/' + property_id,

        headers: jsonHeader
      }).then(response => {
        console.log(response.data)
        setimagesData(response.data?.data)
      })
    }

    fetchPropertiesImages()
  }, [])

  var mapURL = ''
  const areaData = property_details.area_data
  if (areaData !== undefined && areaData.length > 0) {
    mapURL =
        'https://maps.google.com/maps?q=' +
        encodeURI(areaData[0].area) +
        '&output=embed'
  }

  function checkImage (url) {
    var request = new XMLHttpRequest()
    request.open('GET', url, true)
    request.send()
    request.onload = function () {
      if (request.status === 200) {
        //if(statusText == OK)
        return true
      } else {
        return false
      }
    }
  }
  // alert(property_details.video_360);

  return (
      <div>
        {/* ------------------- Property Detail Slider -------------------*/}
        <div className='property-detail-gallery overflow-hidden'>
          <ul
              className='nav nav-tabs nav-tabs-02 mb-4'
              id='pills-tab'
              role='tablist'
          >
            {property_details.video_360 !== undefined &&
            property_details.video_360 !== null &&
            property_details.video_360 !== 'null' &&
            property_details.video_360 !== '' ? (
                <li className='nav-item'>
                  <a
                      className='nav-link shadow'
                      id='jo-360-tab'
                      data-toggle='pill'
                      href='#jo-360'
                      role='tab'
                      aria-controls='jo-360'
                      aria-selected='false'
                  >
                    JO 360
                  </a>
                </li>
            ) : (
                ''
            )}
            <li className='nav-item'>
              <a
                  className='nav-link shadow active'
                  id='photo-tab'
                  data-toggle='pill'
                  href='#photo'
                  role='tab'
                  aria-controls='photo'
                  aria-selected='true'
              >
                Photo
              </a>
            </li>
            <li className='nav-item'>
              <a
                  className='nav-link shadow'
                  id='map-tab'
                  data-toggle='pill'
                  href='#map'
                  role='tab'
                  aria-controls='map'
                  aria-selected='false'
              >
                Map
              </a>
            </li>
          </ul>
          <div className='tab-content' id='pills-tabContent'>
            {property_details.video_360 !== null ? (
                <div
                    className='tab-pane fade'
                    id='jo-360'
                    role='tabpanel'
                    aria-labelledby='jo-360-tab'
                >
                  <iframe
                      width='100%'
                      height='100%'
                      allow='autoplay; gyroscope; accelerometer'
                      src={property_details.video_360}
                      frameBorder='0'
                      webkitallowfullscreen=''
                      mozallowfullscreen=''
                      allowFullScreen=''
                  ></iframe>
                </div>
            ) : (
                ''
            )}
            <div
                className='tab-pane fade show active'
                id='photo'
                role='tabpanel'
                aria-labelledby='photo-tab'
            >
              <Swiper
                  // install Swiper modules
                  modules={[Navigation, Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  navigation
                  pagination={{ clickable: true }}
                  className=''
              >
                {imagesData.map(item => {
                  var imageURL = property_thumbnail

                  if (item.image_url !== '' && item.image_url !== 'null') {
                    const mainRegExp = RegExp('https')

                    if (mainRegExp.test(item.image_url)) {
                      imageURL = item.image_url
                    } else {
                      imageURL =
                          'https://cdn.jagahonline.com/properties/' +
                          item.property_id +
                          '/' +
                          item.image_url
                    }
                  } else {
                    imageURL = item.image_url
                  }
                  return (
                      <SwiperSlide>
                        <div className='property-slider-main detail-big-car-gallery'>
                          <img className='img-fluid' src={imageURL} alt='' />
                        </div>
                      </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
            <div
                className='tab-pane fade'
                id='map'
                role='tabpanel'
                aria-labelledby='map-tab'
            >
              <iframe
                  title={property_details.id}
                  src={mapURL}
                  style={{ border: 0, width: '100%', height: '100%' }}
              />
            </div>
          </div>
        </div>
        {/* ------------------- Property Detail Slider -------------------*/}
      </div>
  )
}

export default PropertyDetailSliderMob
