import React from "react";
// import Swiper core and required modules
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function HomeBestServices() {
  return (
    <section className="book-now-section space-ptb">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="text-center mb-3">
              <div className="section-title text-center">
                <h3 className="mb-3 banner-bg-slider-title text-center">
                  Best Services
                </h3>
                <span className="banner-bg-slider-sub-title text-center">
                  We provide you an amazing Handyman Services at reasonable
                  prices!
                </span>
              </div>
            </div>
          </div>
          <div className="col-12">
            <Swiper
              className="swiper-landing ustad-swiper-services"
              // install Swiper modules
              modules={[Navigation, Pagination]}
              spaceBetween={10}
              slidesPerView={1}
              breakpoints={{
                768: { slidesPerView: 3 },
                1024: { slidesPerView: 4 },
              }}
              navigation
            >
              <SwiperSlide>
                <div className="home-best-serv">
                  <a href="#">
                    <img
                      className="img-fluid"
                      src="/classified_assets/images/handymen-images/jo-ustad-placeholder.png"
                    />
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="home-best-serv">
                  <a href="#">
                    <img
                      className="img-fluid"
                      src="/classified_assets/images/handymen-images/jo-ustad-placeholder.png"
                    />
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="home-best-serv">
                  <a href="#">
                    <img
                      className="img-fluid"
                      src="/classified_assets/images/handymen-images/jo-ustad-placeholder.png"
                    />
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="home-best-serv">
                  <a href="#">
                    <img
                      className="img-fluid"
                      src="/classified_assets/images/handymen-images/jo-ustad-placeholder.png"
                    />
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="home-best-serv">
                  <a href="#">
                    <img
                      className="img-fluid"
                      src="/classified_assets/images/handymen-images/jo-ustad-placeholder.png"
                    />
                  </a>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeBestServices;
