import React from 'react'

// import TopHeader from '../../../Components/Header/joclassified/TopHeader'
// import NavBar from '../../../Components/Header/joclassified/Navbar'
import PropertySearchbar from '../../../Components/Header/joclassified/PropertySearchbar'
import BrowseByCategory from '../../../Components/ClassifiedComponents/BrowseByCategory'
import NewlyListedProperties from '../../../Components/ClassifiedComponents/NewlyListedProperties'
import AppDownloadFooter from '../../../Components/footer/AppDownloadFooter'
import ListingBanner from '../../../Components/ClassifiedComponents/ListingBanner'
import ProSellerSection from '../../../Components/ClassifiedComponents/ProSellerSection'
import PopularCities from '../../../Components/ClassifiedComponents/PopularCities'
import NewlyListedProjects from '../../../Components/ClassifiedComponents/Projects/NewlyListedProjects'
import { Helmet } from 'react-helmet'

import SEO from '../../../Components/Seo'

function Home () {
  localStorage.setItem('searchQuery', '')

  console.log('url : ' + window.location.pathname)

  return (
    <div>
      <SEO
        title='Pakistan Best Property Portal – Sell, Buy, Rent Properties &amp; Homes
        - JagahOnline.com'
        description='Pakistan Best Property Portal – Sell, Buy, Rent Properties &amp; Homes'
        name='JagahOnline'
        type='property'
        url={window.location.href}
        image_url=''
        keywords='sale house, rent house'
      />

      <PropertySearchbar />
      <BrowseByCategory />
      <NewlyListedProjects />
      <ListingBanner />
      <NewlyListedProperties />
      <PopularCities />
      {/* <ProSellerSection/> */}
      <AppDownloadFooter />
      {/* Footer  */}
      {/* <MainFooter />
            
       */}
      <div
        dangerouslySetInnerHTML={{
          __html: localStorage.getItem('footer_section')
            ? localStorage.getItem('footer_section')
            : ''
        }}
      />
    </div>
  )
}

export default Home
