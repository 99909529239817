import { combineReducers } from "redux";
import favorite from "./favorite";
import authUser from "./authUser";
import searchBar from "./searchBar";
import browseCategory from "./browseCategory";
import services from "./services";
import cart from "./cart";
import inquiry from "./inquiry";

export default combineReducers({
  favorite,
  authUser,
  searchBar,
  browseCategory,
  services,
  cart,
  inquiry,
});
