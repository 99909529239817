import React from "react";

function ListingBanner() {
    return (
        <>
            <section className="add-listing-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <picture>
                                {/*<source type="image/webp"*/}
                                {/*        srcSet="/classified_assets/images/add-listing-banner.webp"/>*/}
                                <img className="img-fluid" alt="jagah online"
                                     src="/classified_assets/images/add-listing-banner.jpg"
                                     loading="lazy"/>
                            </picture>
    {/*                       {localStorage.getItem("token") === '' ?*/}
    {/*                      */}
    {/*                        <a className="btn btn-primary ml-5 mt-3 mb-3" data-toggle="modal"*/}
    {/*                           data-target="#loginModal"> <i className="fas fa-plus-circle"/>Add Listings </a>*/}
    {/*                       */}
    {/*                        :*/}
    {/*                       */}
    {/*                        <a className="btn btn-primary ml-5 mt-3 mb-3"*/}
    {/*                           href="/property-add"> <i className="fas fa-plus-circle"/>Add Listings </a>*/}
    {/*}*/}
                        
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ListingBanner
