import { SET_CART } from "../actions/types";
import axios from "axios";

const initialState = {
  cart_total:
    localStorage.getItem("cart_total") !== null
      ? localStorage.getItem("cart_total")
      : "0",
  cart_qty:
    localStorage.getItem("cart_qty") !== null
      ? localStorage.getItem("cart_qty")
      : "0",
};
// function that takes in a state with default as initial state and an action
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_CART:
      return payload;

    default:
      return state;
  }
}
