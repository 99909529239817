import React, { useState, useEffect } from 'react'
import UserProfileHeader from '../Components/Header/joclassified/UserProfileHeader'
import axios from 'axios'
import * as Yup from 'yup'
import emailjs from '@emailjs/browser'
import { useFormik } from 'formik'
import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  currentUserInformation,
  multiFormHeader
} from '../Constants/global'

function ChangePassword () {
  const [isLoading, setIsLoading] = useState({
    isLoading: false,
    disabled: false
  })
  const user_id = localStorage.getItem('user_id')
    ? localStorage.getItem('user_id')
    : 0

  // --------------------------------FormIK-Config-Start--------------------------------
  const initialValues = {
    password: '',
    password2: ''
  }
  // console.log(initialValues);
  const validateSchema = Yup.object().shape({
    password: Yup.string().required('Required'),
    password2: Yup.string().required('Required')
  })
  const handleSubmit = values => {
    setIsLoading({
      isLoading: true,
      disabled: true
    })
    console.log(localStorage.getItem('user_id'))
    var form = new FormData()
    form.append('password', values.password)
    form.append('id', localStorage.getItem('user_id'))

    if (values.password === values.password2) {
      // console.log(form.values);
      //Update Password
      axios({
        method: 'POST',
        url: API_URL + 'api/updatepassword',
        data: {
          password: values.password,
          id: localStorage.getItem('user_id')
        },
        headers: jsonHeader
      }).then(response => {
        console.log(response)
        if (response.data.status === 200) {
          setIsLoading({
            isLoading: false,
            btnClass: 'btn btn-primary btn-block',
            disabled: false
          })
          console.log(response.data.message)
          setSuccessMessage('Password hes been changed')
          // window.location.reload(false);
        } else {
          setIsLoading({
            isLoading: false,
            disabled: false
          })
          console.log(response.data.message)
          setErrorMessage('Something went wrong, Please try again.')
        }
      })
    } else {
      setErrorMessage('Passwords do not match')
      setIsLoading({
        isLoading: false,
        disabled: false
      })
    }
  }
  // console.log(localStorage.getItem('user_name'));
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: validateSchema
  })
  // --------------------------------FormIK-Config-END--------------------------------
  const [Statusmessage, setMessage] = useState({
    message: '',
    class: ''
  })
  function setSuccessMessage (msg) {
    setMessage({
      message: msg,
      class: 'alert alert-success contactUsAlert'
    })
  }
  function setErrorMessage (msg) {
    setMessage({
      message: msg,
      class: 'alert alert-danger contactUsAlert'
    })
  }

  return (
    <>
      <UserProfileHeader />
      <section className='space-pb'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='section-title d-flex align-items-center mb-3'>
                <h2 className='mb-0'>Profile</h2>
                <span className='ml-auto joined-date'></span>
              </div>
              <div className={Statusmessage.class}>{Statusmessage.message}</div>
              <form
                className='phone-validation-form axprofile-form-style'
                onSubmit={formik.handleSubmit}
              >
                <input
                  type='hidden'
                  name='_token'
                  defaultValue='WZbOyEi0TBpW6rePKioUCSgDpArnGiNF6b335mbB'
                />
                <div className='form-row'>
                  <div className='form-group col-md-6'>
                    <label>Password:</label>
                    <div className='custom-file'>
                      <input
                        type='password'
                        className='form-control'
                        name='password'
                        placeholder='New Password'
                        minLength={1}
                        maxLength={100}
                        defaultValue
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.password}
                      />
                      {formik.touched.password && formik.errors.password ? (
                        <div className='error text-danger'>
                          {' '}
                          {formik.errors.password}{' '}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='form-group col-md-6'>
                    <label>Confirm Password:</label>
                    <div className='custom-file'>
                      <input
                        type='password'
                        className='form-control'
                        name='password2'
                        placeholder='Confirm Password'
                        minLength={1}
                        maxLength={100}
                        defaultValue
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.password2}
                      />
                      {formik.touched.password2 && formik.errors.password2 ? (
                        <div className='error text-danger'>
                          {' '}
                          {formik.errors.password2}{' '}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='col-md-12 text-center'>
                    <button
                      disabled={isLoading.disabled}
                      type='submit'
                      className='btn btn-primary sweet-delete btn-shadow'
                    >
                      Change Password
                      <span
                        hidden={isLoading.disabled - 1}
                        className='spinner-border spinner-border-sm ml-2'
                        role='status'
                        aria-hidden='true'
                      ></span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default ChangePassword
