import React from "react";

function PrivacyPolicy () {
    return (
        <>
          
            <div className="bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="/"> <i className="fas fa-home"/> </a>
                                </li>
                                <li className="breadcrumb-item active"><i className="fas fa-chevron-right"/> <span>Privacy Policy</span>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
          
            <section className="space-ptb">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{direction: 'ltr', textAlign: 'left'}}>
                            <h2>Privacy Policy</h2>
                            <p>Your privacy is important to us. It is JagahOnline/Hundred Alpha Pvt Ltd.’s ( a
                                subsidiary of
                                Hatch Techs) policy to respect your privacy regarding any information we may collect
                                from you
                                across our website, <a href="/"
                                                       target="_blank">https://www.jagahonline.com</a>,
                                and other sites we own and operate.</p>
                            <p>We ask for personal information when we truly need it to provide a service to you. We
                                collect it
                                by fair and lawful means, with your knowledge and consent. We also let you know why
                                we’re
                                collecting it and how it will be used. We only retain collected information for as long
                                as
                                necessary to provide you with your requested service. What data we store, we’ll protect
                                within
                                commercially acceptable means to prevent loss and theft, as well as unauthorized access,
                                disclosure, copying, use or modification. We don’t share any personally identifying
                                information
                                publicly or with third-parties, except when required to by law.
                            </p>
                            <p>Our website may link to external sites that are not operated by us. Please be aware that
                                we have
                                no control over the content and practices of these sites, and cannot accept
                                responsibility or
                                liability for their respective privacy policies. You are free to refuse our request for
                                your
                                personal information, with the understanding that we may be unable to provide you with
                                some of
                                your desired services. Your continued use of our website will be regarded as acceptance
                                of our
                                practices around privacy and personal information. If you have any questions about how
                                we handle
                                user data and personal information, feel free to contact us.</p>
                            <h2>Terms</h2>
                            <p>Third Parties – Any website either our subsidiary or unaffiliated source except us, which
                                is
                                connected with us for any marketing purpose or any other reason. </p>
                            <p>Promotion – Spread of information to gain reach for marketing purposes. </p>
                            <p>Subsidiary – Any sub-company that is a part of a holding company. (Propertify, Hatch
                                Techs, and
                                Hundred Alpha)</p>
                            <h2>User Personal Data</h2>
                            <p>Any information like name, identification number, IP address, etc. that allows us for
                                user
                                identification is called User Personal Data. Data protection and privacy laws are
                                implemented on
                                it. </p>
                            <h2>Why We Collect Data</h2>
                            <p>For demographics, user preference, and marketing purposes. It is only for the sake of a
                                better
                                user experience with the right information for the right person to save time and
                                provision
                                convenience. Location-based data collection is for the customization of each user of
                                prompt
                                response of the website without any complication of backend activities. Its sole purpose
                                is to
                                prevent any damage to users through identity verification with the help of the data we
                                collect.
                                It is only for the safety of users.</p>
                            <h2>How We Use Your Personal Data</h2>
                            <p>While you visit the website, your personal information can be collected from browsing
                                history,
                                operating system or IP address. This data is used for the below activities,</p>
                            <ul>
                                <li>to customize results for each user,</li>
                                <li>to improve the responsiveness of the website for the users,</li>
                                <li>to help people reach us and to support marketing activities with similar traits,
                                </li>
                                <li>to update our customers with every update in system and structure, and</li>
                                <li>to make customer satisfaction and relationships more convenient.</li>
                            </ul>
                            <h2>Your Personal Data to Third Parties</h2>
                            <ul>
                                <li>Your personal data can be shared with our subsidiaries.</li>
                                <li>It can be used for internal promotion and marketing within our domain.</li>
                                <li>Any external link of a third party that is not our subsidiary but connected with us
                                    for
                                    business purposes, Jagah Online will not be responsible for any problem caused by
                                    those
                                    links.
                                </li>
                            </ul>
                            <h2>Protection of Your Personal Data</h2>
                            <p>Your personal data is protected in our system and database with efficient protective
                                tools and
                                software but it can not be secured 100% and threats can occur in rare cases. You are
                                advised to
                                use measures and firewalls to protect yourself from any unfair scenario from the
                                internet or any
                                website.</p>
                            <h2>Cookies</h2>
                            <p>The acceptance of cookies is not a requirement for visiting the Site. However, we would
                                like to
                                point out that the use of the 'basket' functionality on the Site and ordering is only
                                possible
                                with the activation of cookies. Cookies are tiny text files that identify your computer
                                to our
                                server as a unique user when you visit certain pages on the Site and they are stored by
                                your
                                Internet browser on your computer's hard drive. Cookies can be used to recognize your
                                Internet
                                Protocol address, saving you time while you are on, or want to enter, the Site. We only
                                use
                                cookies for your convenience in using the Site (for example to remember who you are when
                                you
                                want to amend your shopping cart without having to re-enter your email address) and not
                                for
                                obtaining or using any other information about you (for example targeted advertising).
                                Your
                                browser can be set to not accept cookies, but this would restrict your use of the Site.
                                Please
                                accept our assurance that our use of cookies does not contain any personal or private
                                details
                                and are free from viruses.</p>
                            <p>This policy is effective as of 1st September 2021.</p>
                            <h2> Cookies and Other Tracking Tools</h2>
                            <p>Cookies are small files that contain basic data of users when they reach a specific
                                website.
                                These files are stored in your hard drive when you visit a website. It is for
                                improvements in
                                the website and for customized results for the users so they will not have to share
                                again their
                                information or preference every time they visit a specific website. While tracking tools
                                are
                                used for the data collection like click rates, visiting pattern, user pattern, IP
                                address and
                                domain just for the sake of website performance and better user experience.</p>
                            <h2>How to Delete Cookies</h2>
                            <p>You may delete cookies from the browser or you may set up cookie management in the
                                settings to
                                stop them from a website.</p>
                            <h2>How to Contact Us for Data Deletion Request</h2>
                            <p>Users shall be restrained for 24 hours after posting information for data deletion
                                requests. The
                                shared information can be requested for deletion later by contacting us. You may contact
                                us via
                                <a style={{color: '#8a288f', cursor: 'pointer'}}
                                   mailto="support@jagahonline.com">(support@jagahonline.com)</a> for data deletion
                                requests. You may also contact us at <a style={{cursor: 'pointer', color: '#8a288f'}}
                                                                        tel={922137131669}>(+92 213 7131669)</a></p>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default PrivacyPolicy;