import React, {Fragment, useEffect, useState} from 'react';

import axios from 'axios'

import {useParams} from 'react-router-dom'

import {API_URL, jsonHeader,} from '../../../Constants/global'

import {Fancybox as NativeFancybox} from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";

function ProjectOtherImages() {
    const project_params = useParams()
    console.log(project_params.searchquery.split('-').pop())
    const project_id = project_params.searchquery.split('-').pop()
    const [imagesData, setimagesData] = useState([])
    const [floorplan, setfloorplan] = useState([])
    const [masterplan, setmasterplan] = useState([])
    const [paymentplan, setpaymentplan] = useState([])

   

    useEffect(() => {
        

        async function fetchProjectImages() {
            axios({
                method: 'GET',
                url: API_URL + 'api/project_images_byid/' + project_id,

                headers: jsonHeader,
            }).then((response) => {
                console.log(response.data)

                setimagesData(response.data?.data[0]["additional_images"])
                setfloorplan(response.data?.data[1]["floorplan_images"])
                setmasterplan(response.data?.data[2]["masterplan_images"])
                setpaymentplan(response.data?.data[3]["paymentplan_images"])


            })
            
        }

        fetchProjectImages()
    }, [])

    function Fancybox(props) {
        const delegate = props.delegate || "[data-fancybox]";



        useEffect(() => {
            const opts = props.options || {};

            NativeFancybox.bind(delegate, opts);

            return () => {
                NativeFancybox.destroy();
            };
        }, []);

        return <>{props.children}</>;
    }

    return (
        <>
            {/* ------------------- Property Detail Slider -------------------*/}
            <hr className="mt-4 mb-4 mb-sm-5 mt-sm-5"/>
            <div className="property-video">
                <div className="row">
                    {floorplan.length > 0 ?
                        <Fragment>
                            <div className="col-sm-3 mb-3 mb-sm-0">
                                <h5>Floor Plan</h5>
                            </div>
                            <div className="col-sm-9">
                                <div className="d-flex flex-wrap">
                                    <Fancybox  options={{infinite: false}}>
                                        {floorplan.map((item) => {
                                            
                                                const imageURL =
                                                    item.image_url
                                                return (
                                                    <div
                                                        data-fancybox="gallery"
                                                        data-src={imageURL}
                                                        className="plans-images"
                                                    >
                                                        <img className="img-fluid" src={imageURL}
                                                             alt={imageURL}/>
                                                    </div>
                                                )
                                            
                                        })}
                                    </Fancybox>
                                </div>
                            </div>
                        </Fragment>
                        : ""}
                </div>
                <div className="row">
                    {masterplan.length > 0 ?
                        <Fragment>
                    <div className="col-sm-3 mb-3 mb-sm-0">
                        <h5>Master Plan</h5>
                    </div>
                    <div className="col-sm-9">
                        <div className="d-flex flex-wrap">
                            <Fancybox options={{infinite: false}}>
                                {masterplan.map((item) => {
                                    
                                        const imageURL =
                                            item.image_url
                                        return (
                                            <div
                                                data-fancybox="gallery"
                                                data-src={imageURL}
                                                className="plans-images"
                                            >
                                                <img className="img-fluid" src={imageURL}
                                                     alt={imageURL}/>
                                            </div>
                                        )
                                    
                                })}
                            </Fancybox>
                        </div>
                    </div>
                        </Fragment>
                            : ""}
                </div>
                <div className="row">
                    {paymentplan.length > 0 ?
                        <Fragment>
                    <div className="col-sm-3 mb-3 mb-sm-0">
                        <h5>Payment Plan</h5>
                    </div>
                    <div className="col-sm-9">
                        <div className="d-flex flex-wrap">
                            <Fancybox options={{infinite: false}}>
                                {paymentplan.map((item) => {
                                   
                                        const imageURL =
                                            item.image_url
                                        return (
                                            <div
                                                data-fancybox="gallery"
                                                data-src={imageURL}
                                                className="plans-images"
                                            >
                                                <img className="img-fluid" src={imageURL}
                                                     alt={imageURL}/>
                                            </div>
                                        )
                                    
                                })}
                            </Fancybox>
                        </div>
                    </div>
                        </Fragment>
                        : ""}
                </div>
            </div>

        </>
    );

}

export default ProjectOtherImages;