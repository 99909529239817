import { SET_CART } from "./types";
import axios from "axios";
import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  currentUserInformation,
  multiFormHeader,
  USER_IMAGE_NOT_FOUND_URL,
} from "../../Constants/global";

export const setCart =
  (charges, quantity, cat_id, city_id, id, logic_id, cart) => (dispatch) => {
    var form = new FormData();
    form.append("category_id", cat_id);
    form.append("city_id", city_id);
    form.append("area_id", 0);
    form.append("service_id", id);
    form.append("user_id", localStorage.getItem("user_id"));
    form.append("service_qty", quantity);
    form.append("id", logic_id);
    form.append("cart", cart);
    form.append("service_charges", charges);
    //   alert(id + " " + title + " " + charges + " " + quantity);
    axios({
      method: "POST",
      url: API_URL + "api/handymen_cart_add",
      data: form,
      headers: multiFormHeader,
    }).then((response) => {
      if (response.data.status === 200) {
        console.log("Add to cart Success");
        axios({
          method: "GET",
          url: API_URL + "api/handymen_cart/" + localStorage.getItem("user_id"),
          headers: multiFormHeader,
        }).then((response) => {
          if (response.data.status === 200) {
            console.log(response.data.data);
            // setCartData(response.data.data);
            localStorage.setItem("cart_total", "0");
            localStorage.setItem("cart_qty", "0");
            response.data.data.map((item) => {
              // alert(typeof item.service_qty);

              localStorage.setItem(
                "cart_total",
                parseInt(localStorage.getItem("cart_total")) +
                  parseInt(item.service_charges) * parseInt(item.service_qty)
              );
              localStorage.setItem(
                "cart_qty",
                parseInt(localStorage.getItem("cart_qty")) +
                  parseInt(item.service_qty)
              );
            });
            dispatch({
              type: SET_CART,
              payload: {
                cart_total: localStorage.getItem("cart_total"),
                cart_qty: localStorage.getItem("cart_qty"),
              },
            });
          } else {
            // alert("Fail");
            console.log("cart data fetch fail");
          }
        });
      } else {
        console.log("Add to cart Fail");
      }
    });

    // if (logic_id === 0) {
    //   if (
    //     localStorage.getItem("cart_total") === null &&
    //     localStorage.getItem("cart_qty") === null
    //   ) {
    //     localStorage.setItem("cart_total", charges * quantity);
    //     localStorage.setItem("cart_qty", quantity);
    //   } else {
    //     localStorage.setItem(
    //       "cart_total",
    //       parseInt(localStorage.getItem("cart_total")) + charges * quantity
    //     );
    //     localStorage.setItem(
    //       "cart_qty",
    //       parseInt(localStorage.getItem("cart_qty")) + parseInt(quantity)
    //     );
    //   }
    // } else if (logic_id === 1) {
    //   if (
    //     localStorage.getItem("cart_total") === null &&
    //     localStorage.getItem("cart_qty") === null
    //   ) {
    //     localStorage.setItem("cart_total", charges);
    //     localStorage.setItem("cart_qty", 1);
    //   } else {
    //     if (parseInt(localStorage.getItem("cart_qty")) > quantity) {
    //       localStorage.setItem(
    //         "cart_total",
    //         parseInt(localStorage.getItem("cart_total")) - charges
    //       );
    //       localStorage.setItem(
    //         "cart_qty",
    //         parseInt(localStorage.getItem("cart_qty")) - 1
    //       );
    //     } else {
    //       localStorage.setItem(
    //         "cart_total",
    //         parseInt(localStorage.getItem("cart_total")) + charges
    //       );
    //       localStorage.setItem(
    //         "cart_qty",
    //         parseInt(localStorage.getItem("cart_qty")) + 1
    //       );
    //     }
    //   }
    // }
  };
