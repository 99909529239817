import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
// import Swiper core and required modules
import { Navigation, Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import $ from "jquery";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { API_URL, BASE_URL, jsonHeader } from "../../../Constants/global";
import PropertyDetailContact from "./PropertyDetailContact";
import ReactPlayer from "react-player";

function PropertyDetailSlider({
  property_details,
  userProfile,
  agency_profile_id,
}) {
  const [playState, setPlayState] = useState(false);

  $("#detail-gallery-frontImg").click(function () {
    var a = 4;
    if (a == 5) {
      $("#detail-gallery-frontImg").modal("show");
    } else {
      setPlayState(false);
      $("#detail-gallery-frontImg").modal("hide");
    }
  });

  const onPlayFunction = (event) => {
    // console.log("Your event: ", event);
    setPlayState(true);
  };

  useEffect(() => {
    setTabPhotos();
  }, []);
  const [mapTabState, setMapTabState] = useState({
    class_1: "", //active
    class_2: "", //show acive
    area_selected: "false", //true
  });
  const [videoTabState, setVideoTabState] = useState({
    class_1: "", //active
    class_2: "", //show acive
    area_selected: "false", //true
  });
  const [photosTabState, setPhotosTabState] = useState({
    class_1: "", //active
    class_2: "", //show acive
    area_selected: "false", //true
  });
  const [jo360TabState, setJo360TabState] = useState({
    class_1: "", //active
    class_2: "", //show acive
    area_selected: "false", //true
  });
  const setTabMap = () => {
    setMapTabState({
      class_1: "active", //active
      class_2: "active show", //show acive
      area_selected: "true", //true
    });
    setVideoTabState({
      class_1: "", //active
      class_2: "", //show acive
      area_selected: "false", //true
    });
    setPhotosTabState({
      class_1: "", //active
      class_2: "", //show acive
      area_selected: "false", //true
    });
    setJo360TabState({
      class_1: "", //active
      class_2: "", //show acive
      area_selected: "false", //true
    });
  };
  const setTabPhotos = () => {
    setMapTabState({
      class_1: "", //active
      class_2: "", //show acive
      area_selected: "false", //true
    });
    setVideoTabState({
      class_1: "", //active
      class_2: "", //show acive
      area_selected: "false", //true
    });
    setPhotosTabState({
      class_1: "active", //active
      class_2: "active show", //show acive
      area_selected: "true", //true
    });
    setJo360TabState({
      class_1: "", //active
      class_2: "", //show acive
      area_selected: "false", //true
    });
  };
  const setTabVideo = () => {
    setMapTabState({
      class_1: "", //active
      class_2: "", //show acive
      area_selected: "false", //true
    });
    setPhotosTabState({
      class_1: "", //active
      class_2: "", //show acive
      area_selected: "false", //true
    });
    setVideoTabState({
      class_1: "active", //active
      class_2: "active show", //show acive
      area_selected: "true", //true
    });
    setJo360TabState({
      class_1: "", //active
      class_2: "", //show acive
      area_selected: "false", //true
    });
  };
  const setTabJo360 = () => {
    setMapTabState({
      class_1: "", //active
      class_2: "", //show acive
      area_selected: "false", //true
    });
    setPhotosTabState({
      class_1: "", //active
      class_2: "", //show acive
      area_selected: "false", //true
    });
    setJo360TabState({
      class_1: "active", //active
      class_2: "active show", //show acive
      area_selected: "true", //true
    });
    setVideoTabState({
      class_1: "", //active
      class_2: "", //show acive
      area_selected: "false", //true
    });
  };
  const property_thumbnail =
    BASE_URL + "classified_assets/images/placeholder/jo-placeholder.png";

  const property_params = useParams();
  console.log(property_params.searchquery.split("-").pop());
  const property_id = property_params.searchquery.split("-").pop();

  const [imagesData, setimagesData] = useState([]);
  let count = 0;

  function fetchPropertiesImages() {
    if (imagesData.length === 0 && count === 0) {
      count = count + 1;

      axios({
        method: "GET",
        url: API_URL + "api/get_property_images/" + property_id,
        headers: jsonHeader,
      }).then((response) => {
        console.log(response.data);
        setimagesData(response.data?.data);
      });
    }
  }

  var mapURL = "";
  const areaData = property_details.area_data;
  if (areaData !== undefined && areaData.length > 0) {
    mapURL =
      "https://maps.google.com/maps?q=" +
      encodeURI(areaData[0].area) +
      "&output=embed";
  }

  function checkImage(url) {
    var request = new XMLHttpRequest();
    request.open("GET", url, true);
    request.send();
    request.onload = function () {
      if (request.status === 200) {
        //if(statusText == OK)
        return true;
      } else {
        return false;
      }
    };
  }

  if (
    property_details.front_image !== "" &&
    property_details.front_image !== "null"
  ) {
    const mainRegExp = RegExp("https");
    var imageURL = property_thumbnail;
    if (mainRegExp.test(property_details.front_image)) {
      imageURL = property_details.front_image;
    } else {
      imageURL =
        "https://cdn.jagahonline.com/properties/" +
        property_details.id +
        "/" +
        property_details.front_image;
    }
  }

  return (
    <Fragment>
      {/* ------------------- Property Detail Slider WEB-------------------*/}
      <div
        className="property-detail-gallery overflow-hidden"
        onClick={fetchPropertiesImages}
      >
        <div className="frontImg-ctas-counters">
          <a onClick={setTabPhotos}>
            <div
              className="fImg-counter fImg-img-counter"
              href="#tab-01"
              data-toggle="modal"
              data-target="#detail-gallery-frontImg"
            >
              <i className="fas fa-image"></i>
              <span className="fImg-total-count-content pl-1">
                {imagesData.length}
              </span>
            </div>
          </a>
          {property_details.front_video &&
          property_details.front_video !== "null" ? (
            <a onClick={setTabVideo}>
              <div
                className="fImg-counter fImg-video-counter"
                href="#tab-02"
                data-toggle="modal"
                data-target="#detail-gallery-frontImg"
              >
                <i className="fas fa-video"></i>
                <span className="fImg-total-count-content pl-1">
                  {property_details.front_video ? 1 : 0}
                </span>
              </div>
            </a>
          ) : (
            ""
          )}
          <a onClick={setTabMap}>
            <div
              className="fImg-counter fImg-map-counter"
              href="#tab-03"
              data-toggle="modal"
              data-target="#detail-gallery-frontImg"
            >
              <i className="fas fa-map"></i>
              <span className="fImg-total-count-content pl-1">
                {mapURL ? 1 : 0}
              </span>
            </div>
          </a>
        </div>
        <a
          onClick={
            property_details.front_video &&
            property_details.front_video !== "null"
              ? setTabVideo
              : setTabPhotos
          }
        >
          <div
            className="detail-gallery-frontImg position-relative"
            data-toggle="modal"
            data-target="#detail-gallery-frontImg"
          >
            {" "}
            <div className="detail-gallery-frontImg-off"></div>
            <div className="frontImg-ctas-arrow">
              <div
                className="fImg-arrow fImg-arrow-left"
                data-toggle="modal"
                data-target="#detail-gallery-frontImg"
              >
                <i className="fas fa-chevron-left"></i>
              </div>
              <div
                className="fImg-arrow fImg-arrow-right"
                data-toggle="modal"
                data-target="#detail-gallery-frontImg"
              >
                <i className="fas fa-chevron-right"></i>
              </div>
            </div>
            {property_details.front_video &&
            property_details.front_video !== "null" ? (
              <ReactPlayer
                className="frontImg-property-video w-100 h-100"
                url={property_details.front_video}
              />
            ) : (
              <img
                className="img-fluid w-100"
                src={
                  imageURL
                    ? imageURL
                    : BASE_URL +
                      "classified_assets/images/placeholder/jo-placeholder.png"
                }
                alt=""
              />
            )}
          </div>
        </a>

        {/* Modal */}
        <div
          className="modal fade detail-gallery-frontImg-modal"
          id="detail-gallery-frontImg"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="detail-gallery-frontImgTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              {/*<div className="modal-header">*/}
              {/*  <h5 className="modal-title" id="exampleModalLongTitle">Modal title</h5>*/}
              {/*  <button type="button" className="close" data-dismiss="modal" aria-label="Close">*/}
              {/*    <span aria-hidden="true">×</span>*/}
              {/*  </button>*/}
              {/*</div>*/}
              <div className="modal-body pt-0">
                <button
                  type="button"
                  className="modal-detail-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="closeBtn"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <div className="row">
                  <div className="col-md-8 p-0">
                    <ul
                      className="nav nav-tabs nav-tabs-01"
                      id="myTab"
                      role="tablist"
                    >
                      <li className={`nav-item ${photosTabState.class_1}`}>
                        <a
                          onClick={setTabPhotos}
                          className={`nav-link ${photosTabState.class_1}`}
                          id="tab-01-tab"
                          data-toggle="tab"
                          href="#tab-01"
                          role="tab"
                          aria-controls="tab-01"
                          aria-selected={`${photosTabState.area_selected}`}
                        >
                          Photos
                        </a>
                      </li>
                      {property_details.front_video &&
                      property_details.front_video !== "null" ? (
                        <li className={`nav-item ${videoTabState.class_1}`}>
                          <a
                            onClick={setTabVideo}
                            className={`nav-link ${videoTabState.class_1}`}
                            id="tab-02-tab"
                            data-toggle="tab"
                            href="#tab-02"
                            role="tab"
                            aria-controls="tab-02"
                            aria-selected={`${videoTabState.area_selected}`}
                          >
                            Video
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {mapURL !== undefined || mapURL !== "" ? (
                        <li className={`nav-item ${mapTabState.class_1}`}>
                          <a
                            onClick={setTabMap}
                            className={`nav-link ${mapTabState.class_1}`} //1active
                            id="tab-03-tab"
                            data-toggle="tab"
                            href="#tab-03"
                            role="tab"
                            aria-controls="tab-03"
                            aria-selected={`${mapTabState.area_selected}`} //2true
                          >
                            Map
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {property_details.video_360 ? (
                        <li className={`nav-item ${jo360TabState.class_1}`}>
                          <a
                            onClick={setTabJo360}
                            className={`nav-link ${jo360TabState.class_1}`}
                            id="tab-04-tab"
                            data-toggle="tab"
                            href="#tab-04"
                            role="tab"
                            aria-controls="tab-04"
                            aria-selected={`${jo360TabState.area_selected}`}
                          >
                            JO 360
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className={`tab-pane fade  ${photosTabState.class_2}`}
                        id="tab-01"
                        role="tabpanel"
                        aria-labelledby="tab-01-tab"
                      >
                        <Swiper
                          // install Swiper modules
                          modules={[Navigation, Pagination]}
                          spaceBetween={0}
                          slidesPerView={1}
                          initialSlide={0}
                          navigation
                          pagination={{ clickable: true }}
                          className=""
                        >
                          {imagesData.length ? (
                            imagesData.map((item) => {
                              var imageURL = property_thumbnail;

                              if (
                                item.image_url !== "" &&
                                item.image_url !== "null"
                              ) {
                                const mainRegExp = RegExp("https");

                                if (mainRegExp.test(item.image_url)) {
                                  imageURL = item.image_url;
                                } else {
                                  imageURL =
                                    "https://cdn.jagahonline.com/properties/" +
                                    item.property_id +
                                    "/" +
                                    item.image_url;
                                }
                              } else {
                                imageURL = item.image_url;
                              }
                              return (
                                <SwiperSlide>
                                  <div className="detail-big-car-gallery">
                                    <img
                                      className="img-fluid"
                                      src={imageURL}
                                      alt=""
                                    />
                                  </div>
                                </SwiperSlide>
                              );
                            })
                          ) : imageURL ? (
                            <img
                              className="img-fluid w-100"
                              src={imageURL}
                              alt=""
                            />
                          ) : (
                            <img
                              className="img-fluid w-100"
                              src={
                                BASE_URL +
                                "classified_assets/images/placeholder/jo-placeholder.png"
                              }
                              alt=""
                            />
                          )}
                        </Swiper>
                      </div>
                      <div
                        className={`tab-pane fade  ${videoTabState.class_2}`}
                        id="tab-02"
                        role="tabpanel"
                        aria-labelledby="tab-02-tab"
                      >
                        <ReactPlayer
                          className="property-modal-video w-100 h-100"
                          url={property_details.front_video}
                          playing={playState}
                          onPlay={onPlayFunction}
                          controls={true}
                        />
                      </div>
                      {mapURL !== undefined || mapURL !== "" ? (
                        <div
                          className={`tab-pane fade  ${mapTabState.class_2}`} //3
                          id="tab-03"
                          role="tabpanel"
                          aria-labelledby="tab-03-tab"
                        >
                          <iframe
                            className="pt-3 px-3 property-modal-map"
                            title={property_details.id}
                            src={mapURL}
                            style={{
                              border: 0,
                              width: "100%",
                              height: "575px",
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      {property_details.video_360 !== null ? (
                        <div
                          className={`tab-pane fade  ${jo360TabState.class_2}`}
                          id="tab-04"
                          role="tabpanel"
                          aria-labelledby="tab-04-tab"
                        >
                          <iframe
                            width="100%"
                            height="100%"
                            allow="autoplay; gyroscope; accelerometer"
                            src={property_details.video_360}
                            frameBorder="0"
                            webkitallowfullscreen=""
                            mozallowfullscreen=""
                            allowFullScreen=""
                          ></iframe>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 p-0">
                    <PropertyDetailContact
                      userProfile={userProfile}
                      agency_profile_id={property_details.agency_profile_id}
                      property_details={property_details}
                      id={"customCheck2"}
                    />
                  </div>
                </div>
              </div>
              {/*<div className="modal-footer">*/}
              {/*  <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>*/}
              {/*  <button type="button" className="btn btn-primary">Save changes</button>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
      {/* ------------------- Property Detail Slider WEB-------------------*/}
    </Fragment>
  );
}

export default PropertyDetailSlider;
