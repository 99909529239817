import React from 'react'
import PaymentFooter from './PaymentFooter'
import { Link } from 'react-router-dom'
import { BASE_URL } from '../../Constants/global'
import { Helmet } from 'react-helmet'

export default function MainFooter () {
  return (
    <div>
      <div>
        <PaymentFooter />
      </div>
      <footer className='footer bg-dark space-pt'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-6'>
              <div className='footer-contact-info'>
                <h5 className='text-secondary-jo mb-4'>About Jagah Online</h5>
                <p className='text-white mb-4'>
                  Jagah Online is a digital platform designed to help you with
                  all your real estate worries! We pledge to make real estate
                  experiences more accessible to everyone – whether they need to
                  buy, sell, or rent properties, products, projects, or
                  services!
                </p>
                <p className='text-white mb-4'>
                  With Jagah Online, you don’t have to worry about finding the
                  right place, again!
                </p>
                <ul className='list-unstyled mb-0'>
                  <li>
                    <b>
                      {' '}
                      <i className='fas fa-map-marker-alt' />
                    </b>
                    <span>
                      A-214, Block A, Sindhi Muslim Cooperative Housing Society
                      (SMCHS), Karachi, Pakistan.
                    </span>
                  </li>
                  <li>
                    <b>
                      <i className='fas fa-microphone-alt' />
                    </b>
                    <span>+92 213 7131669</span>
                  </li>
                  <li>
                    <b>
                      <i className='fas fa-headset' />
                    </b>
                    <span>support@jagahonline.com</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 mt-4 mt-md-0'>
              <div className='footer-link'>
                <h5 className='text-secondary-jo mb-4'>Useful links</h5>
                <ul className='list-unstyled mb-0'>
                  <li>
                    <a href='/blog/' target={'_blank'}>
                      Blog
                    </a>
                  </li>
                  <li>
                    <a href='https://htnexuz.com/' target={'_blank'}>
                      Careers
                    </a>
                  </li>
                  <li>
                    <a href='/privacy'>Privacy Policy </a>
                  </li>
                  <li>
                    <a href='/term-condition'>Terms &amp; Conditions </a>
                  </li>
                  <li>
                    <a href='/contact-us'>Contact Us</a>
                  </li>
                  <li>
                    <a href='/faq'>FAQs </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 mt-4 mt-md-0'>
              <h5 className='text-secondary-jo mb-4'>Download our app</h5>
              <div className>
                <p className='text-white'>
                  {' '}
                  Where convenience is at your fingertip.{' '}
                </p>
                <a
                  target='_blank'
                  href='https://play.google.com/store/apps/details?id=com.ha.jagahonlinewebview'
                >
                  <img
                    className='img-fluid google-app-download footer-app mb-3'
                    src={
                      BASE_URL + 'classified_assets/images/footer/google.png'
                    }
                    alt='Pakistan Best Property Portal – Find Real Estate Sell, Buy, Rent Properties & Homes'
                    loading='lazy'
                  />
                </a>
                <a
                  target='_blank'
                  href='https://apps.apple.com/pk/app/jagah-online/id1513865840'
                >
                  <img
                    className='img-fluid ios-app-download footer-app'
                    src={BASE_URL + 'classified_assets/images/footer/apple.png'}
                    alt='Pakistan Best Property Portal – Find Real Estate Sell, Buy, Rent Properties & Homes'
                    loading='lazy'
                  />
                </a>
              </div>
              <div className='col-lg-12 col-md-6 mt-4 mt-md-3'>
                <div class='row'>
                  <div className='col-2 '>
                    <a
                      href='https://www.facebook.com/jagahonline'
                      target='_blank'
                    >
                      <img src='https://jocdn.sfo3.cdn.digitaloceanspaces.com/classified_images%2Fwebsite_assets%2Fimages%2Fsocials%2Ffacebook-icon.png' />
                    </a>
                  </div>
                  <div className='col-2 '>
                    <a
                      href='https://www.linkedin.com/company/jagah-online/'
                      target='_blank'
                    >
                      <img src='https://jocdn.sfo3.cdn.digitaloceanspaces.com/classified_images%2Fwebsite_assets%2Fimages%2Fsocials%2Flinkedin-icon.png' />
                    </a>
                  </div>
                  <div className='col-2'>
                    <a href='https://twitter.com/JagahOnline' target='_blank'>
                      <img src='https://jocdn.sfo3.cdn.digitaloceanspaces.com/classified_images%2Fwebsite_assets%2Fimages%2Fsocials%2Ftwitter-icon.png' />
                    </a>
                  </div>
                  <div className='col-2'>
                    <a
                      href='https://www.youtube.com/@JagahOnline'
                      target='_blank'
                    >
                      <img src='https://jocdn.sfo3.cdn.digitaloceanspaces.com/classified_images%2Fwebsite_assets%2Fimages%2Fsocials%2Fyoutube-icon.png' />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-bottom'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-md-4 text-center text-md-left'>
                <a href='/'>
                  <img
                    className='img-fluid footer-logo'
                    src={BASE_URL + 'classified_assets/images/logo-light.svg'}
                    alt=''
                  />{' '}
                </a>
              </div>
              <div className='col-md-4 text-center my-3 mt-md-0 mb-md-0'>
                <a id='back-to-top' className='back-to-top text-white' href='#'>
                  <i className='fas fa-angle-double-up text-white' />{' '}
                </a>
              </div>
              <div className='col-md-4 text-center text-md-right text-white'>
                <p className='mb-0 text-white'>
                  {' '}
                  ©Copyright <span id='copyright'> </span>{' '}
                  <a href='/' className='text-white'>
                    {' '}
                    JagahOnline{' '}
                  </a>{' '}
                  All Rights Reserved{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
