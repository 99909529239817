import { HANDLE_FAVORITE, HANDLE_FAVORITE_CLASS } from "../actions/types";
import axios from "axios";
// function getState() {
//   axios.get(`http://www.randomnumberapi.com/api/v1.0/random`).then((res) => {
//     console.log(res.data);
//     return res.data;
//   });
// }

const initialState = [localStorage.getItem("total_favorites")];

// function that takes in a state with default as initial state and an action
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HANDLE_FAVORITE:
      return [payload];

    default:
      return state;
  }
}
