import React, { Fragment, useEffect, useState } from 'react'
import Home from './Pages/classified/properties/Home'
import ForgotPassword from './Pages/ForgotPassword'

import TopHeader from '../src/Components/Header/joclassified/TopHeader'
import NavBar from '../src/Components/Header/joclassified/Navbar'
import Logout from './Components/Logout'
import Profile from './Pages/Profile'
import PrivacyPolicy from './Pages/privacy-policy'
import TermsConditions from './Pages/terms-condition'
import Verify from './Pages/classified/Verify'
import Careers from './Pages/Careers'

// Properties
import Properties from './Pages/classified/properties/Properties'
import AddProperty from './Pages/classified/properties/AddProperty'
import Property from './Pages/classified/properties/Property'
import { Helmet } from 'react-helmet'
// Projects
import Projects from './Pages/classified/Projects/Projects'

//  footer pages
import MainFooter from '../src/Components/footer/MainFooter'

// Modal
import LoginModal from '../src/Components/ClassifiedComponents/Auth/LoginModal'
import NotFound from './Pages/NotFound'

//

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useParams,
  Switch
} from 'react-router-dom'
import ProjectListings from './Components/ClassifiedComponents/Projects/ProjectListings'
import ProjectDetail from './Components/ClassifiedComponents/Projects/ProjectDetail'
import PropertyDetails from './Components/ClassifiedComponents/Properties/PropertyDetails'
import TourJo from './Pages/tourJO/pages/TourJo'
import MyProperties from './Pages/MyProperties/pages/MyProperties'
import MyFavorites from './Pages/MyFavorites/pages/MyFavorites'
import KingsAlahmedTown from './Pages/jo-projects/pages/kings-alahmed-town/kings-alahmed-town'
import KingsComfort from './Pages/jo-projects/pages/kings-comfort/kings-comfort'
import KingsParadise from './Pages/jo-projects/pages/kings-paradise/kings-paradise'
import KingsPlaza from './Pages/jo-projects/pages/kings-plaza/kings-plaza'
import SellRentForMe from './Pages/SellRentForMe/pages/SellRentForMe'
import KingsRegency from './Pages/jo-projects/pages/kings-regency/kings-regency'
import KingsScenicMall from './Pages/jo-projects/pages/kings-scenic-mall/kings-scenic-mall'
import QaswaaResidency from './Pages/jo-projects/pages/qaswaa-residency/qaswaa-residency'
import ContactUs from './Pages/ContactUs/pages/ContactUs'
import ChangePassword from './Pages/ChangePassword'
import Faq from './Pages/Faq'
import CompanyProfile from './Pages/AdminPanel/CompanyProfile'

//redux
import { Provider } from 'react-redux'
import store from './Redux/store'
import HomepageUstad from './Pages/Handymen/HomepageUstad'
import OrderCheckout from './Pages/Handymen/OrderCheckout'
import OrderDetail from './Pages/Handymen/components/myOrder/OrderDetail'
import ServicesLanding from './Pages/Handymen/ServicesLanding'
import MyOrderLanding from './Pages/Handymen/MyOrderLanding'
import { PropertiesPage } from './Pages/classified/properties/PropertiesPage'
import { BrowserView, MobileView } from 'react-device-detect'
import LoginPage from './Components/ClassifiedComponents/Auth/Pages/LoginPage'
import RegisterPage from './Components/ClassifiedComponents/Auth/Pages/RegisterPage'
import AddPropertyV2 from './Pages/classified/properties/AddProperty-V2'
import axios from 'axios'
import SeoFetchData from './Helpers/SeoFetchData'

import SEO from './Components/Seo'

import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  multiFormHeader,
  multiFormHeader_No_Token,
  HOSTNAME,
  ORIGIN
} from './Constants/global'

// Jazz Cash

import JazzCash from './Components/Payments/JazzCash'
import CallbackPayment from './Components/Payments/callback_payment'

//  SEO

import { HelmetProvider } from 'react-helmet-async'

const helmetContext = {}

const App = () => {
  const helmetContext = {}

  const { data, loading, error } = SeoFetchData(API_URL + 'seoapi/custom_code')

  if (data !== undefined && data !== null && data['data'] !== null) {
    localStorage.setItem('header_section', data['data']['header_section'])
    localStorage.setItem('body_section', data['data']['body_section'])
    localStorage.setItem('footer_section', data['data']['footer_section'])
  } else {
    localStorage.setItem('header_section', '')
    localStorage.setItem('body_section', '')
    localStorage.setItem('footer_section', '')
  }

  if (
    !localStorage.getItem('token') &&
    window.location.pathname === '/property-add'
  ) {
    localStorage.setItem('token', '')
    window.location.href = '/login'
  }

  if (
    !localStorage.getItem('token') &&
    window.location.pathname === '/profile'
  ) {
    localStorage.setItem('token', '')
    window.location.href = '/login'
  }

  useEffect(() => {
    const Page_Name = window.location.pathname
      ? window.location.pathname.replace('/', '')
      : 'Home'

    if (window.location.pathname === 'properties') {
      localStorage.setItem('searchContent', '')
    }

    fetch(API_URL + 'api/get-website-vendor/' + window.location.hostname)
      .then(response => response.json())
      .then(data => {
        if (data.status === 200) {
          localStorage.setItem('website_vendor_id', data.website_vendor_id)
        } else {
          localStorage.setItem('website_vendor_id', 0)
        }
      })

    var form = new FormData()
    form.append('page_name', Page_Name)
    form.append('website_vendor_id', localStorage.getItem('website_vendor_id'))

    function meta_setup_client () {
      axios({
        method: 'POST',
        url: API_URL + 'seoapi/meta_setup_client',
        data: form,
        headers: multiFormHeader_No_Token
      }).then(response => {
        if (response.data.status === 200) {
          localStorage.setItem('seo_title', response.data['data']['seo_title'])
          localStorage.setItem(
            'seo_description',
            response.data['data']['seo_description']
          )
        } else {
          localStorage.setItem(
            'seo_title',
            "Pakistan's Best Property Portal – Sell, Buy, Rent Properties &amp; Homes - JagahOnline.com"
          )
          localStorage.setItem(
            'seo_description',
            'Buy, sell, rent homes on Pakistan’s best real estate website – Find property dealers, developers, handymen and everything else related to property under one roof.'
          )
        }
      })
    }
    meta_setup_client()
  }, [])

  // const getData = async () => {
  //   // const res = await axios.get('https://geolocation-db.com/json/')
  //   // console.log('Client Information')
  //   console.log('host-name ' + window.location.hostname)
  // }

  // useEffect(() => {
  //   //passing getData method to the lifecycle method
  //   getData()
  // }, [])

  return (
    <HelmetProvider context={helmetContext}>
      <Provider store={store}>
        <Router>
          <div className='App'>
            <SEO
              title={localStorage.getItem('seo_title')}
              description={localStorage.getItem('seo_description')}
              name='JagahOnline'
              type='properties'
              url={window.location.href}
              image_url=''
              keywords='sale house, rent house'
            />
            {window.location.pathname === '/kings-alahmed-town' ||
            window.location.pathname === '/kings-comfort' ||
            window.location.pathname === '/kings-paradise' ||
            window.location.pathname === '/kings-plaza' ||
            window.location.pathname === '/kings-regency' ||
            window.location.pathname === '/kings-scenic-mall' ||
            window.location.pathname === '/qaswaa-residency' ? (
              <></>
            ) : window.location.pathname === '/' ||
              window.location.pathname === '/property' ||
              window.location.pathname === '/projects' ||
              window.location.pathname === '/project-list' ||
              window.location.pathname === '/ustad' ? (
              <Fragment>
                <BrowserView>
                  <header className='header header-transparent header-bg'>
                    <TopHeader />
                    <hr className='top-bar-hr' />
                    <NavBar />
                  </header>
                </BrowserView>
                <MobileView>
                  <header className='header header-bg'>
                    <TopHeader />
                    <hr className='top-bar-hr' />
                    <NavBar />
                  </header>
                </MobileView>
              </Fragment>
            ) : (
              <Fragment>
                <BrowserView>
                  <header className='header header-white header-bg'>
                    <TopHeader />
                    <hr className='top-bar-hr' />
                    <NavBar />
                  </header>
                </BrowserView>
                <MobileView>
                  <header className='header header-bg'>
                    <TopHeader />
                    <hr className='top-bar-hr' />
                    <NavBar />
                  </header>
                </MobileView>
              </Fragment>
            )}
            <Routes>
              <Route path='/' element={<Home />} />
              {localStorage.getItem('token') === '' ? (
                <Route path='/forgotpassword' element={<ForgotPassword />} />
              ) : (
                <Route path='/forgotpassword' element={<Home />} />
              )}

              <Route path='/profile' element={<Profile />} />
              <Route path='/company-profile' element={<CompanyProfile />} />

              {/*Add Property V1*/}
              {/*<Route path='/property-add/:id' element={<AddProperty />} />*/}
              {/*<Route path='/property-add' element={<AddProperty />} />*/}
              {/*Add Property V1*/}

              {/*Add Property V2*/}
              <Route path='/property-add/:id' element={<AddPropertyV2 />} />
              <Route path='/property-add' element={<AddPropertyV2 />} />
              {/*Add Property V2*/}

              {/* Property listings */}
              <Route
                path='/:property_type/:property_purpose/:city'
                element={<Properties />}
              />

              <Route path='/properties' element={<PropertiesPage />} />

              {/*Contact US*/}
              <Route path='/contact-us' element={<ContactUs />} />

              {/* Property Details */}
              <Route
                path='/property/:searchquery'
                element={<PropertyDetails />}
              />
              <Route path='/property' element={<Property />} />
              <Route path='/property-detail' element={<PropertyDetails />} />

              <Route path='/projects' element={<Projects />} />
              <Route
                path='/projects/:property_type/:property_purpose/:city'
                element={<ProjectListings />}
              />
              <Route path='/project-list' element={<ProjectListings />} />
              <Route path='/project/:searchquery' element={<ProjectDetail />} />

              <Route path='/term-condition' element={<TermsConditions />} />
              <Route path='/privacy' element={<PrivacyPolicy />} />
              <Route path='/logout' element={<Logout />} />
              {localStorage.getItem('token') === '' ? (
                <Route path='/verify/:id' element={<Verify />} />
              ) : (
                <Route path='/verify/:id' element={<Home />} />
              )}

              {/*//Auth Pages*/}
              <Route path='/login' element={<LoginPage />} />
              <Route path='/register' element={<RegisterPage />} />

              {/* <Route path="/verify/:id" element={<Verify />} /> */}
              <Route path='*' element={<NotFound />} />
              <Route path='/notfound' element={<NotFound />} />

              <Route path='/360-jagah-tours' element={<TourJo />} />
              <Route path='/sell-rent-for-me' element={<SellRentForMe />} />
              <Route path='/my-properties' element={<MyProperties />} />
              <Route path='/my-favorites' element={<MyFavorites />} />
              <Route path='/careers' element={<Careers />} />
              <Route path='/change-password' element={<ChangePassword />} />
              <Route path='/faq' element={<Faq />} />

              {/*JO PROJECTS*/}
              <Route
                path='/kings-alahmed-town'
                element={<KingsAlahmedTown />}
              />
              <Route path='/kings-comfort' element={<KingsComfort />} />
              <Route path='/kings-paradise' element={<KingsParadise />} />
              <Route path='/kings-plaza' element={<KingsPlaza />} />
              <Route path='/kings-regency' element={<KingsRegency />} />
              <Route path='/kings-scenic-mall' element={<KingsScenicMall />} />
              <Route path='/qaswaa-residency' element={<QaswaaResidency />} />
              <Route path='/JazzCash' element={<JazzCash />} />
              <Route path='/callback_payment' element={<CallbackPayment />} />

              {/*Handymen */}
              {!window.location.href.includes('jagahonline') ? (
                <Route path='/ustad' element={<HomepageUstad />} />
              ) : (
                ''
              )}
              {!window.location.href.includes('jagahonline') ? (
                <Route
                  path='/ustad-service/:id'
                  element={<ServicesLanding />}
                />
              ) : (
                ''
              )}
              {!window.location.href.includes('jagahonline') ? (
                <Route path='/my-orders' element={<MyOrderLanding />} />
              ) : (
                ''
              )}
              {!window.location.href.includes('jagahonline') ? (
                <Route path='/check-out' element={<OrderCheckout />} />
              ) : (
                ''
              )}
              {!window.location.href.includes('jagahonline') ? (
                <Route path='/my-orders/:id' element={<OrderDetail />} />
              ) : (
                ''
              )}
              {/*Handymen */}
            </Routes>
            <LoginModal />
            <MainFooter />
          </div>
        </Router>
      </Provider>
    </HelmetProvider>
  )
}

export default App
