import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';

import { handleFavorite } from '../../../Redux/actions/favorite';
import ProjectListingCard from '../Projects/ProjectListingCard';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { useParams } from 'react-router-dom';

import {
  API_URL,
  multiFormHeader,
  BASE_URL,
  jsonHeader,
  USER_IMAGE_NOT_FOUND_URL,
  USER_PROFILE_URL,
} from '../../../Constants/global';

const InquiryModal = ({ inquiry }) => {
  // useEffect(() => {
  //   alert(inquiry);
  // }, [inquiry]);
  const [Statusmessage, setMessage] = useState({
    message: '',
    class: '',
  });
  const [isLoading, setIsLoading] = useState({
    isLoading: false,
    disabled: false,
  });
  function setSuccessMessage(msg) {
    setMessage({
      message: msg,
      class: 'alert alert-success contactUsAlert',
    });
  }
  function setErrorMessage(msg) {
    setMessage({
      message: msg,
      class: 'alert alert-danger contactUsAlert',
    });
  }
  function removeMessage(msg) {
    setMessage({
      message: '',
      class: '',
    });
  }

  const [apierror, setApierror] = useState('');
  const initialValues = {
    name: '',
    email: '',
    contact_number: '',
    message: '',
  };

  const validateSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email format').required('Required'),
    contact_number: Yup.number().required('Required'),
    message: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
  });
  const onSubmit = (values, { resetForm }) => {
    const href = 'https://jagahonline.com' + inquiry.url;

    setIsLoading({
      isLoading: true,
      disabled: true,
    });
    console.log(values);
    var form = new FormData();
    form.append('name', values.name);
    form.append('email', values.email);
    form.append('contact_number', values.contact_number);
    form.append('message', values.message);
    form.append('type', 'project');
    form.append('ref_id', inquiry.id);
    form.append('title', inquiry.title);
    //get current date

    const current = new Date();
    const date = `${current.getDate()}-${
      current.getMonth() + 1
    }-${current.getFullYear()} - ${current.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })}`;

    form.append('inquiry_date_time', date);

    form.append('url', href);

    console.log(form);

    axios({
      method: 'POST',
      url: API_URL + 'generalservices/inquiry',
      data: form,
      headers: multiFormHeader,
    }).then((response) => {
      if (response.data.status === 200) {
        setIsLoading({
          isLoading: false,
          disabled: false,
        });
        setSuccessMessage('Your request has been sent');
        setTimeout(removeMessage, 3000);
        resetForm({ values: '' });
      } else {
        setIsLoading({
          isLoading: false,
          disabled: false,
        });
        setApierror(response.data.message);
        setErrorMessage(response.data.message);
      }
    });
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validateSchema,
  });
  return (
    <Fragment>
      {/*// Project Box in Property Listing Modal*/}
      <div
        className='modal fade'
        id='ProjectInquiryModal'
        tabIndex={-1}
        role='dialog'
        aria-labelledby='ProjectInquiryModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='ProjectInquiryModalLabel'>
                <p className='mb-0'>Project Inquiry </p>
                {inquiry.title}
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>×</span>
              </button>
            </div>
            <div className='modal-body'>
              <div className={Statusmessage.class}>{Statusmessage.message}</div>
              <form onSubmit={formik.handleSubmit}>
                <div className='form-group'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    name='name'
                    placeholder='Name'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className='error text-danger'>
                      {' '}
                      {formik.errors.name}{' '}
                    </div>
                  ) : null}
                </div>
                <div className='form-group'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Email'
                    id='email'
                    name='email'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className='error text-danger'>
                      {' '}
                      {formik.errors.email}{' '}
                    </div>
                  ) : null}
                </div>
                <div className='form-group'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Phone Number'
                    id='contact_number'
                    name='contact_number'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.contact_number}
                  />
                  {formik.touched.contact_number &&
                  formik.errors.contact_number ? (
                    <div className='error text-danger'>
                      {' '}
                      {formik.errors.contact_number}{' '}
                    </div>
                  ) : null}
                </div>
                <div className='form-group'>
                  <textarea
                    className='form-control'
                    rows={4}
                    placeholder='Message'
                    id='message'
                    name='message'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.message}
                  />
                  {formik.touched.message && formik.errors.message ? (
                    <div className='error text-danger'>
                      {' '}
                      {formik.errors.message}{' '}
                    </div>
                  ) : null}
                </div>
                <button
                  disabled={isLoading.disabled}
                  type='submit'
                  className='btn btn-primary btn-block'
                >
                  Request info
                  <span
                    hidden={isLoading.disabled - 1}
                    className='spinner-border spinner-border-sm ml-2'
                    role='status'
                    aria-hidden='true'
                  ></span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/*// Project Box in Property Listing Modal*/}
    </Fragment>
  );
};
InquiryModal.propTypes = {
  inquiry: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  inquiry: state.inquiry,
});

export default connect(mapStateToProps)(InquiryModal);
