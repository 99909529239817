import React from 'react'

export default function AppDownload () {
  return (
    <div>
      {/*=================================
mobile app */}
      <section className='space-pt bg-light'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-7'>
              <div className='section-title'>
                <h2>Get JagahOnline App Now</h2>
                <p>
                  Rent, Sell or Buy properties, projects, products and Ustad
                  Services on your fingertips
                </p>
                <p>Pakistan's Largest unified Property Market Place.</p>
              </div>
              <div className='row'>
                <div className='col-md-4 mb-5'>
                  <div className='d-flex'>
                    <div className='mr-3'>
                      <i className='flaticon-rent font-xlll' />
                    </div>
                    <h6 className='text-primary font-md'>
                      Real time property listing
                    </h6>
                  </div>
                </div>
                <div className='col-md-4 mb-5'>
                  <div className='d-flex'>
                    <div className='mr-3'>
                      <i className='flaticon-contract font-xlll' />
                    </div>
                    <h6 className='text-primary font-md'>
                      Budget filter for budget
                    </h6>
                  </div>
                </div>
                <div className='col-md-4 mb-5'>
                  <div className='d-flex'>
                    <div className='mr-3'>
                      <i className='flaticon-notification font-xlll' />
                    </div>
                    <h6 className='text-primary  font-md'>
                      Unified Market Place
                    </h6>
                  </div>
                </div>
              </div>
              <div className='d-block d-sm-flex'>
                <a
                  className='btn btn-dark btn-sm btn-app mr-0 mr-sm-2 mb-2 mb-sm-0'
                  href='https://apps.apple.com/pk/app/jagah-online/id1513865840'
                >
                  <i className='fab fa-apple' />
                  <div className='btn-text text-left'>
                    <small>Download on the </small>
                    <span className='mb-0 text-white d-block'>App store</span>
                  </div>
                </a>
                <a
                  className='btn btn-dark btn-sm btn-app mb-2 mb-sm-0'
                  href='https://play.google.com/store/apps/details?id=com.ha.jagahonlinewebview'
                >
                  <i className='fab fa-google-play' />
                  <div className='btn-text text-left'>
                    <small>Get in on </small>
                    <span className='mb-0 text-white d-block'>Google Play</span>
                  </div>
                </a>
              </div>
            </div>
            <div className='col-lg-5 mt-5 mt-lg-0 text-center'>
              <picture>
                <source
                  type='image/webp'
                  srcSet='/classified_assets/images/mobile-app.webp'
                />
                <img
                  className='img-fluid'
                  alt='jagahonline'
                  src='/classified_assets/images/mobile-app.png'
                  loading='lazy'
                />
              </picture>
            </div>
          </div>
        </div>
      </section>
      {/*=================================
mobile app */}
    </div>
  )
}
