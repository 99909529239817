import React from "react";
// import Swiper core and required modules
import {Navigation, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function HandymenTestimonial() {
    return (

        <section className="testimonial-section space-ptb">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="text-center mb-3">
                            <div className="section-title text-center">
                                <h3 className="mb-3 banner-bg-slider-title text-center">Testimonial</h3>
                                <span className="banner-bg-slider-sub-title text-center">We let our customers speak for us!</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <Swiper
                            className="testimonials-main"
                            // install Swiper modules
                            modules={[Navigation, Pagination]}
                            spaceBetween={20}
                            slidesPerView={1} breakpoints={{
                            768: {slidesPerView: 2},
                            1024: {slidesPerView: 3}
                        }}
                            pagination={{clickable: true}}
                        >
                            <SwiperSlide>
                                <div className="testimonials-inner">
                                    <div className="testimonial-icon text-center mb-3">
                                        <img className="img-fluid"
                                             src="/classified_assets/images/handymen-images/doublequote-icon.svg"/>
                                    </div>
                                    <div className="testimonial-text">
                                        <span>JagahOnline is quite useful. It assisted me in finding flats and dealers,
                                        and as a working couple, I don't have the time to walk door-to-door
                                        looking for property dealers</span>
                                    </div>
                                </div>
                                <div className="testimonials-name-sec mt-4">
                                   <h6 className="testimonial-user">Suleiman Salim</h6>
                                    <span className="testimonial-service">AC Master Service</span>
                                    <div className="testimonial-rating"><i className="fas fa-star"></i> 4.5<span>/5</span></div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="testimonials-inner">
                                    <div className="testimonial-icon text-center mb-3">
                                        <img className="img-fluid"
                                             src="/classified_assets/images/handymen-images/doublequote-icon.svg"/>
                                    </div>
                                    <div className="testimonial-text">
                                        <span>JagahOnline is quite useful. It assisted me in finding flats and dealers,
                                        and as a working couple, I don't have the time to walk door-to-door
                                        looking for property dealers</span>
                                    </div>
                                </div>
                                <div className="testimonials-name-sec mt-4">
                                    <h6 className="testimonial-user">Suleiman Salim</h6>
                                    <span className="testimonial-service">AC Master Service</span>
                                    <div className="testimonial-rating"><i className="fas fa-star"></i> 4.5<span>/5</span></div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="testimonials-inner">
                                    <div className="testimonial-icon text-center mb-3">
                                        <img className="img-fluid"
                                             src="/classified_assets/images/handymen-images/doublequote-icon.svg"/>
                                    </div>
                                    <div className="testimonial-text">
                                        <span>JagahOnline is quite useful. It assisted me in finding flats and dealers,
                                        and as a working couple, I don't have the time to walk door-to-door
                                        looking for property dealers</span>
                                    </div>
                                </div>
                                <div className="testimonials-name-sec mt-4">
                                    <h6 className="testimonial-user">Suleiman Salim</h6>
                                    <span className="testimonial-service">AC Master Service</span>
                                    <div className="testimonial-rating"><i className="fas fa-star"></i> 4.5<span>/5</span></div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="testimonials-inner">
                                    <div className="testimonial-icon text-center mb-3">
                                        <img className="img-fluid"
                                             src="/classified_assets/images/handymen-images/doublequote-icon.svg"/>
                                    </div>
                                    <div className="testimonial-text">
                                        <span>JagahOnline is quite useful. It assisted me in finding flats and dealers,
                                        and as a working couple, I don't have the time to walk door-to-door
                                        looking for property dealers</span>
                                    </div>
                                </div>
                                <div className="testimonials-name-sec mt-4">
                                    <h6 className="testimonial-user">Suleiman Salim</h6>
                                    <span className="testimonial-service">AC Master Service</span>
                                    <div className="testimonial-rating"><i className="fas fa-star"></i> 4.5<span>/5</span></div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>

    )
};

export default HandymenTestimonial;
