import React, { useState } from 'react';
import axios from 'axios';
import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  currentUserInformation,
  multiFormHeader,
  multiFormHeader2,
} from '../../../Constants/global';

const OrderCancelModal = ({ service_data }) => {
  formatDate(Date.now(), 1);
  function formatDate(date, skip) {
    var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    var date = new Date(date - tzoffset).toISOString().slice(0, -1);
    //remove sec.mili_sec
    const segments = date.split(':');
    return segments.slice(0, segments.length - skip).join(':');
  }

  const [isLoading, setIsLoading] = useState({
    isLoading: false,
    disabled: false,
  });
  const [error, setError] = useState('');
  const [check, setCheck] = useState(false);
  function handleCheck(e) {
    console.log(e.target.checked);
    setCheck(e.target.checked);
  }
  const [reason_txt, setReason_txt] = useState('');
  const handleChange = (event) => {
    setReason_txt(event.target.value);
  };
  function cancel_order() {
    if (check) {
      setIsLoading({
        isLoading: true,
        disabled: true,
      });
      setError('');

      var form = new FormData();

      form.append('order_status', service_data.service_status);
      form.append('cancel_reason', reason_txt);
      form.append('id', service_data.id);
      form.append('service_date', formatDate(Date.now(), 1));

      axios({
        method: 'POST',
        url: API_URL + 'api/handymen_inquires_cancel',
        data: form,
        headers: multiFormHeader,
      }).then((response) => {
        if (response.data.status === 200) {
          setIsLoading({
            isLoading: false,
            disabled: false,
          });

          // -----------------------Reset Cart---------------------

          console.log('order Canceled');
          window.location.href = '/my-orders';
        } else {
          setIsLoading({
            isLoading: false,
            disabled: false,
          });
          console.log('order failed to Cancel');
          setError('Failed to cancell order.');
        }
      });
    } else {
      setError('Please confirm');
    }
  }

  return (
    <div
      class='modal fade'
      id='orderCancelModal'
      tabindex='-1'
      role='dialog'
      aria-labelledby='orderCancelModalTitle'
      aria-hidden='true'
    >
      <div class='modal-dialog modal-dialog-centered' role='document'>
        <div class='modal-content'>
          <div class='modal-header'>
            <button
              type='button'
              class='close'
              data-dismiss='modal'
              aria-label='Close'
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>

          <div class='modal-body  '>
            <div className='mb-3'>
              Are you sure you wish to cancel the order ?
            </div>

            <textarea
              type='text'
              height='120'
              className='form-control'
              placeholder='Reason for cancellation'
              name='reason_txt'
              id='reason_txt'
              onChange={handleChange}
              value={reason_txt}
            ></textarea>
            <div className='ml-4 mt-3'>
              <input
                className='custom-control-input'
                type='checkbox'
                name='confirm'
                id='confirm'
                onClick={handleCheck}
              />
              <label className='custom-control-label' htmlFor='confirm'>
                Yes, I would like to cancel my order.
              </label>
            </div>
            {error ? (
              <div className='error text-danger mt-3'> {error} </div>
            ) : null}
          </div>
          <div class='modal-footer'>
            <a
              disabled={isLoading.disabled}
              className='btn btn-primary'
              onClick={cancel_order}
            >
              Confirm
              <span
                hidden={isLoading.disabled - 1}
                className='spinner-border spinner-border-sm ml-2'
                role='status'
                aria-hidden='true'
              ></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderCancelModal;
