import { GET_SERVICES } from "./types";
import axios from "axios";
import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  currentUserInformation,
  multiFormHeader2,
  USER_IMAGE_NOT_FOUND_URL,
} from "../../Constants/global";

export const getServices = (city_id) => (dispatch) => {
  //   ------------------------------------
  const form = {
    showAll: "true",
    user_id: "166436",
    isActive: "0",
    isDeleted: "0",
    id: "0",
  };

  const services_array = [];

  axios({
    data: form,
    headers: multiFormHeader2,
    method: "POST",
    url: API_URL + "metadata/handymen_allcategories",
  }).then((response) => {
    if (response.status === 200) {
      //   const services = response.data.data;
      // ------------------------------
      if (city_id) {
        response.data.data.map((item) => {
          if (item.city_id === city_id) {
            services_array.push(item);
          }
        });
        if (services_array.length === 0) {
          dispatch({
            type: GET_SERVICES,
            payload: ["x"],
          });
        } else {
          dispatch({
            type: GET_SERVICES,
            payload: services_array,
          });
        }
      } else {
        response.data.data.map((item) => {
          if (item.city_id === 14) {
            services_array.push(item);
          }
        });
        dispatch({
          type: GET_SERVICES,
          payload: services_array,
        });
      }
    }
  });
};
