import React, { useState, useEffect } from "react";
import UserProfileHeader from "../Components/Header/joclassified/UserProfileHeader";
import axios from "axios";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";
import { connect } from "react-redux";
import "yup-phone";
import { setAuthUser } from "../Redux/actions/authUser";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  currentUserInformation,
  multiFormHeader,
} from "../Constants/global";
import { type } from "@testing-library/user-event/dist/type";
import { isWindows } from "react-device-detect";

const Profile = ({ setAuthUser, authUser }) => {
  const [img, setImg] = useState();
  const [alertWarning, setAlertWarning] = useState({
    msg: "",
    class: "",
  });
  function setAlertWarningFunc(msg = "No values were changed") {
    setAlertWarning({
      msg: msg,
      class: "alert alert-warning contactUsAlert",
    });
  }

  function removeAlertWarningFunc() {
    setAlertWarning({
      msg: "",
      class: "",
    });
  }
  const FILE_SIZE = 160 * 1024;
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
  ];
  const [isLoading, setIsLoading] = useState({
    isLoading: false,
    disabled: false,
  });
  const user_id = localStorage.getItem("user_id")
    ? localStorage.getItem("user_id")
    : 0;
  useEffect(
    function getData() {
      if (localStorage.getItem("token") !== "") {
        axios({
          method: "GET",
          url: API_URL + "api/user_profile",
          headers: multiFormHeader,
        })
          .then((response) => {
            if (response.status === 200) {
              //set localstorage values
              localStorage.setItem("email", response.data.data.email);

              setAuthUser({
                user_name:
                  response.data.data.first_name +
                  " " +
                  response.data.data.last_name,
                profile_image: response.data.data.profile_image,
              });
              setiInitialValues({
                first_name: response.data.data.first_name,
                last_name: response.data.data.last_name,
                email: response.data.data.email,
                profile_image: response.data.data.profile_image,
                phone_number: response.data.data.phone_number,
                mobile: response.data.data.mobile,
                whatsapp: response.data.data.whatsapp,
                password: response.data.data.password,
                // 'old_file': response.data.data.old_file,
              });
              // console.log(initialValues);
            }
          })
          .catch(function (error) {
            if (
              error.response.status === 403 &&
              error.response.data.detail === "token has been expired"
            ) {
              setAlertWarningFunc("token has been expired");
              localStorage.setItem("editSiteID", "");
              localStorage.setItem("editUserID", "");
              localStorage.setItem("editProjectID", "");
              // setIsAutherized(false);
              localStorage.setItem("token", "");
              localStorage.setItem("user_id", "");
              localStorage.setItem("email", "");
              localStorage.setItem("user_id", "");
              localStorage.setItem("profile_image", "");
              localStorage.setItem("user_name", "");
              localStorage.setItem("user_type", "");

              localStorage.setItem("first_name", "");
              localStorage.setItem("last_name", "");
              localStorage.setItem("phone_number", "");
              localStorage.setItem("role_id", "");
              localStorage.setItem("agency_profile_id", "");
            }
          });
      }
    },
    [UserProfileHeader]
  );
  // --------------------------------FormIK-Config-Start--------------------------------
  const [initialValues, setiInitialValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    profile_image: "",
    phone_number: "",
    mobile: "",
    whatsapp: "",
    password: "",
    // 'old_file': '',
  });
  // console.log(initialValues);
  const phoneRegExp =
    /^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/;
  const validateSchema = Yup.object().shape({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email format").required("Required"),
    // profile_image: Yup.string(),
    phone_number: Yup.string(),
    mobile: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
    whatsapp: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
  });
  const handleSubmit = (values) => {
    if (
      selectedfile === undefined &&
      values.first_name === initialValues.first_name &&
      values.last_name === initialValues.last_name &&
      values.email === initialValues.email &&
      values.mobile === initialValues.mobile &&
      values.phone_number === initialValues.phone_number &&
      values.whatsapp === initialValues.whatsapp &&
      values.profile_image === initialValues.profile_image
    ) {
      setAlertWarningFunc();
      setTimeout(removeAlertWarningFunc, 3000);
    } else {
      setIsLoading({
        isLoading: true,
        disabled: true,
      });
      //
      var form = new FormData();
      //Append profile_image file only if file is selected, otherwise append none
      if (
        selectedfile !== undefined &&
        selectedfile !== null &&
        selectedfile.target.value !== undefined &&
        selectedfile.target.value !== null &&
        selectedfile.target.value !== ""
      ) {
        form.append("profile_image", selectedfile.target.files[0]);
      } else {
        form.append("profile_image", "");
      }

      form.append("first_name", values.first_name);
      form.append("last_name", values.last_name);
      form.append("email", values.email);
      form.append("phone_number", values.phone_number);
      form.append("mobile", values.mobile);
      form.append("whatsapp", values.whatsapp);
      form.append("password", "");
      form.append("old_file", "");
      form.append("user_id", authUser.user_id);

      console.log(form.values);
      axios({
        method: "POST",
        url: API_URL + "api/update_profile",
        data: form,
        headers: multiFormHeader,
      }).then((response) => {
        if (response.data.status === 200) {
          setIsLoading({
            isLoading: false,
            btnClass: "btn btn-primary btn-block",
            disabled: false,
          });
          console.log(response.data.message);
          setSuccessMessage(response.data.message);
          setTimeout(removeMessage, 3000);
          // window.location.reload(false);

          setAuthUser();
          localStorage.setItem("email", `${values.email}`);
          // localStorage.setItem("profile_image", `${values.profile_image}`);
          console.log(localStorage.getItem("profile_image"));
          console.log("Updated");
          selectedfile.target.value = null;
          //--------------------------------------------------------------
        } else {
          setIsLoading({
            isLoading: false,
            disabled: false,
          });
          // console.log(response.data.message);
          setErrorMessage("Something went wrong, Please try again.");
        }
      });

      setiInitialValues({
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        mobile: values.mobile,
        phone_number: values.phone_number,
        whatsapp: values.whatsapp,
        profile_image: values.profile_image,
      });
    }
  };
  // console.log(localStorage.getItem('user_name'));
  const formik = useFormik({
    initialValues: initialValues,

    onSubmit: handleSubmit,
    validationSchema: validateSchema,
    enableReinitialize: true,
  });
  // --------------------------------FormIK-Config-END--------------------------------
  const [Statusmessage, setMessage] = useState({
    message: "",
    class: "",
  });
  function setSuccessMessage(msg) {
    setMessage({
      message: msg,
      class: "alert alert-success contactUsAlert",
    });
  }
  function removeMessage(msg) {
    setMessage({
      message: "",
      class: "",
    });
  }

  function setErrorMessage(msg) {
    setMessage({
      message: msg,
      class: "alert alert-danger contactUsAlert",
    });
  }
  const [selectedfile, setSelectedFile] = useState();
  const handleChange = (e) => {
    console.log(e);
    setSelectedFile(e);
    const [file] = e.target.files;
    setImg(URL.createObjectURL(file));
  };
  return (
    <>
      <UserProfileHeader />

      <section className="space-pb">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title d-flex align-items-center mb-3">
                <h2 className="mb-0">Profile</h2>
                <span className="ml-auto joined-date"></span>
              </div>
              <div className={Statusmessage.class}>{Statusmessage.message}</div>
              <div className={alertWarning.class}>{alertWarning.msg}</div>
              <form
                className="phone-validation-form axprofile-form-style"
                onSubmit={formik.handleSubmit}
              >
                <input
                  type="hidden"
                  name="_token"
                  defaultValue="WZbOyEi0TBpW6rePKioUCSgDpArnGiNF6b335mbB"
                />
                <div className="form-row">
                  <div className="form-group col-md-6 select-border">
                    <label>First Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      name="first_name"
                      minLength={1}
                      maxLength={100}
                      defaultValue
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.first_name}
                    />
                    {formik.touched.first_name && formik.errors.first_name ? (
                      <div className="error text-danger">
                        {" "}
                        {formik.errors.first_name}{" "}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-md-6 select-border">
                    <label>Last Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      name="last_name"
                      minLength={1}
                      maxLength={100}
                      defaultValue
                      required
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.last_name}
                    />
                    {formik.touched.last_name && formik.errors.last_name ? (
                      <div className="error text-danger">
                        {" "}
                        {formik.errors.last_name}{" "}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-md-6 select-border">
                    <label>Email Address:</label>
                    <input
                      type="email"
                      className="form-control"
                      id="contact_email"
                      placeholder="Email Address"
                      name="email"
                      minLength={1}
                      maxLength={100}
                      defaultValue
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="error text-danger">
                        {" "}
                        {formik.errors.email}{" "}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Phone Number:</label>
                    <div className="custom-file">
                      <input
                        type="text"
                        className="form-control"
                        name="phone_number"
                        placeholder="Phone Number"
                        minLength={1}
                        maxLength={100}
                        defaultValue
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.phone_number}
                      />
                      {formik.touched.phone_number &&
                      formik.errors.phone_number ? (
                        <div className="error text-danger">
                          {" "}
                          {formik.errors.phone_number}{" "}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label>Mobile:</label>
                    <div className="custom-file">
                      <input
                        type="text"
                        className="form-control"
                        name="mobile"
                        placeholder="mobile"
                        minLength={1}
                        maxLength={100}
                        defaultValue
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.mobile}
                      />
                      {formik.touched.mobile && formik.errors.mobile ? (
                        <div className="error text-danger">
                          {" "}
                          {formik.errors.mobile}{" "}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label>Whatsapp:</label>
                    <div className="custom-file">
                      <input
                        type="text"
                        className="form-control"
                        name="whatsapp"
                        placeholder="Whatsapp"
                        minLength={1}
                        maxLength={100}
                        defaultValue
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.whatsapp}
                      />
                      {formik.touched.whatsapp && formik.errors.whatsapp ? (
                        <div className="error text-danger">
                          {" "}
                          {formik.errors.whatsapp}{" "}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {/* <div className="form-group col-md-6">
                                    <label>Password:</label>
                                    <div className="custom-file">
                                        <input type="password" className="form-control" name= 'password' placeholder='Password' minLength={1} maxLength={100} defaultValue
                                         onBlur={formik.handleBlur}
                                         onChange={formik.handleChange}
                                         value={formik.values.password}/>
                                          {formik.touched.password && formik.errors.password ? <div className='error text-danger'> {formik.errors.password} </div> : null}
                                    </div>
                                </div> */}
                  <div className="form-group col-md-6">
                    <label>Profile Image:</label>
                    <div className="custom-file">
                      <input
                        type="file"
                        className="form-control"
                        placeholder="Profile Image"
                        name="profile_image"
                        accept="image/*"
                        id="profile_image"
                        onChange={handleChange}
                        // value={formik.values.profile_image}
                      />{" "}
                      {formik.touched.profile_image &&
                      formik.errors.profile_image ? (
                        <div className="error text-danger">
                          {" "}
                          {formik.errors.profile_image}{" "}
                        </div>
                      ) : null}
                    </div>

                    {img ? (
                      <div className="multi-previewMain">
                        <img src={img} alt="" />{" "}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-12 text-center">
                    <button
                      disabled={isLoading.disabled}
                      type="submit"
                      className="btn btn-primary sweet-delete btn-shadow"
                    >
                      Update Profile
                      <span
                        hidden={isLoading.disabled - 1}
                        className="spinner-border spinner-border-sm ml-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
Profile.propTypes = {
  setAuthUser: PropTypes.func.isRequired,
  authUser: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authUser: state.authUser,
});

export default connect(mapStateToProps, { setAuthUser, setAuthUser })(Profile);
