import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { BrowserView, MobileView } from 'react-device-detect'

import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  multiFormHeader
} from '../../../Constants/global'
import Select from 'react-select'

function ProjectSearchBar () {
  let navigate = useNavigate()
  var searchQueryGet = {}

  if (
    localStorage.getItem('searchQueryProject') !== undefined &&
    localStorage.getItem('searchQueryProject') !== ''
  ) {
    searchQueryGet = JSON.parse(localStorage.getItem('searchQueryProject')) || {
      property_purpose: 0,
      city_id: 0,
      area_id: 0,
      property_type: 0,
      builder_id: 0,
      min_area: 0,
      max_area: 0,
      price_min: 0,
      price_max: 0,
      beds: 0,
      keywords: '',
      sortby: 1
    }
  } else {
    searchQueryGet = {
      property_purpose: 0,
      city_id: 0,
      area_id: 0,
      property_type: 0,
      builder_id: 0,
      min_area: 0,
      max_area: 0,
      price_min: 0,
      price_max: 0,
      beds: 0,
      keywords: '',
      sortby: 1
    }
  }

  const [searchQuery, setSearchQuery] = useState(searchQueryGet)

  const [propertyType, setpropertyType] = useState([])
  const [propertyPupose, setpropertyPupose] = useState([])
  const [builders, setbuilders] = useState([])
  const [cities, setCities] = useState([])
  const [locations, setLocations] = useState([])

  const [searchBarCity, setsearchBarCity] = useState('Karachi')
  const [searchBarPurpose, setsearchBarPurpose] = useState('Sale')
  const [searchBarPropertyType, setsearchBarPropertyType] =
    useState('Residential')

  useEffect(() => {
    async function fetchSearchBy () {
      const response = await axios({
        method: 'GET',
        url: API_URL + 'api/searchby'
      })

      if (response.status === 200) {
        const propert_purpose = response.data?.data[0]['propert_purpose']

        const nArray = []
        let roleData1 = {}
        roleData1.value = 0
        roleData1.label = 'All Purposes'
        nArray.push(roleData1)
        propert_purpose.map(item => {
          let roleData = {}
          roleData.value = item.id
          roleData.label = item.title
          nArray.push(roleData)
        })
        setpropertyPupose(nArray)

        const propert_type = response.data?.data[1]['propert_type']

        const nArray1 = []
        let roleData2 = {}
        roleData2.value = 0
        roleData2.label = 'All Categories'
        nArray1.push(roleData2)
        propert_type.map(item => {
          let roleData = {}
          roleData.value = item.id
          roleData.label = item.title
          nArray1.push(roleData)
        })
        setpropertyType(nArray1)

        const cities = response.data?.data[2]['cities']

        const nArray2 = []
        let roleData3 = {}
        roleData3.value = 0
        roleData3.label = 'All Cities'
        nArray2.push(roleData3)
        cities.map(item => {
          let roleData = {}
          roleData.value = item.id
          roleData.label = item.city
          nArray2.push(roleData)
        })
        setCities(nArray2)

        const buildersdata = response.data?.data[3]['builders']

        const nArray3 = []
        let roleData4 = {}
        roleData4.value = 0
        roleData4.label = 'All Developers'
        nArray3.push(roleData4)
        buildersdata.map(item => {
          let roleData = {}
          roleData.value = item.id
          roleData.label = item.name
          nArray3.push(roleData)
        })
        setbuilders(nArray3)
      }
    }

    fetchSearchBy()
  }, [])

  console.log(searchQuery)

  async function onChangeCityHandler (value) {
    setSearchQuery({ ...searchQuery, city_id: parseInt(value.value) })
    setsearchBarCity(value.label)

    const response = await axios({
      method: 'GET',
      url: API_URL + 'generalservices/areabycityid/' + value.value
    })

    if (response.status === 200) {
      const nArray = []
      let roleData4 = {}
      roleData4.value = 0
      roleData4.label = 'All Areas'
      nArray.push(roleData4)
      response.data?.data.map(item => {
        let roleData = {}
        roleData.value = item.id
        roleData.label = item.area
        nArray.push(roleData)
      })
      setLocations(nArray)
    }
  }

  const searchSubmit = () => {
    localStorage.setItem('searchQueryProject', JSON.stringify(searchQuery))
    console.log(searchQuery)

    window.location =
      '/projects/' +
      searchBarPropertyType +
      '/' +
      searchBarPurpose +
      '/' +
      searchBarCity
  }
  function onAreaChange (value) {
    setSearchQuery({ ...searchQuery, area_id: value.value })
  }

  const onChangePropertyPurpose = e => {
    setSearchQuery({
      ...searchQuery,
      property_purpose: parseInt(e.value)
    })
    setsearchBarPurpose(e.label.split(' ')[1])
  }

  const onChangePropertyType = e => {
    setSearchQuery({
      ...searchQuery,
      property_type: parseInt(e.value)
    })
    setsearchBarPropertyType(e.label.split(' ')[1])
  }

  return (
    <div>
      {/* ------------------- Property Search Bar -------------------*/}
      <section className='banner bg-holder bg-overlay-black-30'>
        <div className='hero-header'>
          <picture>
            <source
              type='image/webp'
              srcSet='/classified_assets/images/banner-01.webp'
            />
            <img
              fetchpriority='high'
              className='hero-header-main-img'
              alt='header'
              src='/classified_assets/images/banner-01.jpg'
            />
          </picture>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h1 className='text-white text-center mb-4 notCapitalize'>
                Find your Dream Homes, and Projects in Pakistan
              </h1>
              <MobileView>
                <div className='searchButtonModalOuter text-center'>
                  <button
                    className='btn btn-searchModal'
                    data-toggle='modal'
                    data-target='#letSearch'
                  >
                    Search Projects
                  </button>
                </div>
              </MobileView>
              {/* <p className="lead text-center text-white mb-4 font-weight-normal">Take a step to realizing your dream. #TimeToMove</p> */}
              <BrowserView>
                <div className='wb-search-main property-search-field bg-white text-left'>
                  <div className='property-search-item'>
                    <form className='form-row basic-select-wrapper'>
                      <div className='form-group col-lg-2 col-md-3'>
                        <label>All Cities</label>
                        <Select
                          className='form-control'
                          placeholder={<div>All Cities</div>}
                          //   isLoading={isloading}
                          options={cities}
                          isMulti={false}
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          onChange={onChangeCityHandler}
                          value={cities.find(
                            c => c.value === parseInt(searchQuery.city_id)
                          )}
                        />
                      </div>
                      <div className='form-group col-lg-2 col-md-3'>
                        <label>Property Purpose</label>
                        <Select
                          className='form-control'
                          //   isLoading={isloading}
                          options={propertyPupose}
                          isMulti={false}
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          onChange={onChangePropertyPurpose}
                          value={propertyPupose.find(
                            c =>
                              c.value === parseInt(searchQuery.property_purpose)
                          )}
                        />
                      </div>

                      <div className='form-group d-flex col-lg-3'>
                        <div className='form-group-search'>
                          <label>Location</label>
                          <Select
                            className='form-control'
                            placeholder={
                              <div>Select Location eg. Clifton, DHA</div>
                            }
                            //   isLoading={isloading}
                            options={locations}
                            isMulti={false}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            onChange={onAreaChange}
                            value={locations.find(
                              c => c.value === parseInt(searchQuery.area_id)
                            )}
                          />
                        </div>
                      </div>
                      {/*<div className="form-group col-lg col-md-3">*/}
                      {/*    <label>Project Title</label>*/}
                      {/*    <Select className="form-control"*/}
                      {/*    />*/}
                      {/*</div>*/}
                      <div className='form-group col-lg col-md-3'>
                        <label>Find Developers</label>
                        <Select
                          className='form-control'
                          placeholder={<div>Select ...</div>}
                          //   isLoading={isloading}
                          options={builders}
                          isMulti={false}
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          onChange={e =>
                            setSearchQuery({
                              ...searchQuery,
                              builder_id: parseInt(e.value)
                            })
                          }
                          value={builders.find(
                            c => c.value === parseInt(searchQuery.builder_id)
                          )}
                        />
                      </div>

                      <div className='d-none d-lg-block form-group text-center col-lg-2 d-lg-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center justify-content-between w-100'>
                          <span className='align-items-center d-none d-lg-block'>
                            <button
                              className='btn btn-search btn-primary d-flex align-items-center'
                              type='button'
                              onClick={searchSubmit}
                            >
                              <i className='fas fa-search mr-1' />
                            </button>
                          </span>

                          <div className='d-flex justify-content-center d-md-inline-block'>
                            <a
                              className='more-search p-0'
                              data-toggle='collapse'
                              href='#advanced-search'
                              role='button'
                              aria-expanded='false'
                              aria-controls='advanced-search'
                            >
                              <i className='fas fa-angle-double-down p-0' />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        className='collapse advanced-search'
                        id='advanced-search'
                      >
                        <div className='card card-body'>
                          <div className='form-row'>
                            <div className='form-group col-md-2'>
                              <label>Min Area (sq ft)</label>
                              <input
                                className='form-control'
                                placeholder='Type (sq ft)'
                                onChange={e =>
                                  setSearchQuery({
                                    ...searchQuery,
                                    min_area: e.target.value.replace(/\D/g, '')
                                  })
                                }
                                value={searchQuery.min_area}
                              />
                            </div>
                            <div className='form-group col-md-2'>
                              <label>Max Area (sq ft)</label>
                              <input
                                className='form-control'
                                placeholder='Type (sq ft)'
                                onChange={e =>
                                  setSearchQuery({
                                    ...searchQuery,
                                    max_area: e.target.value.replace(/\D/g, '')
                                  })
                                }
                                value={searchQuery.max_area}
                              />
                            </div>
                            <div className='form-group col-md-2'>
                              <label>Price (Min)</label>
                              <input
                                className='form-control'
                                placeholder='Price (Min)'
                                onChange={e =>
                                  setSearchQuery({
                                    ...searchQuery,
                                    price_min: e.target.value.replace(/\D/g, '')
                                  })
                                }
                                value={searchQuery.price_min}
                              />
                            </div>
                            <div className='form-group col-md-2'>
                              <label>Price (Max)</label>
                              <input
                                className='form-control'
                                placeholder='Price (Max)'
                                onChange={e =>
                                  setSearchQuery({
                                    ...searchQuery,
                                    price_max: e.target.value.replace(/\D/g, '')
                                  })
                                }
                                value={searchQuery.price_max}
                              />
                            </div>
                            <div className='form-group col-lg col-md-4'>
                              <label>Property Category</label>
                              <Select
                                placeholder={<div>Property Category</div>}
                                //   isLoading={isloading}
                                options={propertyType}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                hideSelectedOptions={false}
                                onChange={onChangePropertyType}
                                value={propertyType.find(
                                  c =>
                                    c.value ===
                                    parseInt(searchQuery.property_type)
                                )}
                              />
                            </div>
                            <div className='form-group col-md-2'>
                              <label>Keywords</label>
                              <input
                                className='form-control'
                                placeholder='e.g Clifton, DHA or Zip Code'
                                onChange={e =>
                                  setSearchQuery({
                                    ...searchQuery,
                                    keywords: e.target.value
                                  })
                                }
                                value={searchQuery.keywords}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='d-lg-none d-flex align-items-center justify-content-between btn-block btn-mobile m-3'>
                        <a
                          className='more-search p-0 mr-2'
                          data-toggle='collapse'
                          href='#advanced-search'
                          role='button'
                          aria-expanded='false'
                          aria-controls='advanced-search'
                        >
                          <i className='fas fa-angle-double-down p-0' />
                        </a>
                        <button
                          className='btn btn-primary btn-block align-items-center'
                          type='button'
                          onClick={searchSubmit}
                        >
                          <i className='fas fa-search mr-1' />
                          <span>Search</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </BrowserView>
            </div>
          </div>
        </div>
      </section>
      <MobileView>
        <div
          class='modal fade searchModalMain'
          id='letSearch'
          tabindex='-1'
          role='dialog'
          aria-labelledby='letSearchTitle'
          aria-hidden='true'
        >
          <div class='modal-dialog' role='document'>
            <div className='modal-header border-0 text-center position-relative bg-white'>
              <div className='col-md-12 text-center'>
                <a className='navbar-brand p-0' href='/'>
                  <img
                    className='img-fluid h-100'
                    width={180}
                    src={BASE_URL + 'classified_assets/images/logo.svg'}
                    alt='logo'
                  />
                </a>
              </div>
              <button
                type='button'
                className='close btn-modal-close'
                width={26}
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>×</span>
              </button>
            </div>
            <div class='modal-content'>
              <div class='modal-body'>
                <div className='property-search-field bg-white text-left position-relative h-100'>
                  <div className='property-search-item h-100'>
                    <form className='form-row basic-select-wrapper h-100'>
                      <div className='form-group col-12'>
                        <label>All Cities</label>
                        <Select
                          className='form-control'
                          placeholder={<div>All Cities</div>}
                          //   isLoading={isloading}
                          options={cities}
                          isMulti={false}
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          onChange={onChangeCityHandler}
                          value={cities.find(
                            c => c.value === parseInt(searchQuery.city_id)
                          )}
                        />
                      </div>
                      <div className='form-group col-12'>
                        <label>Property Purpose</label>
                        <Select
                          className='form-control'
                          //   isLoading={isloading}
                          options={propertyPupose}
                          isMulti={false}
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          onChange={onChangePropertyPurpose}
                          value={propertyPupose.find(
                            c =>
                              c.value === parseInt(searchQuery.property_purpose)
                          )}
                        />
                      </div>
                      <div className='form-group col-12'>
                        <div className='form-group-search'>
                          <label>Location</label>
                          <Select
                            placeholder={
                              <div>Select Location eg. Clifton, DHA</div>
                            }
                            //   isLoading={isloading}
                            options={locations}
                            isMulti={false}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            onChange={e =>
                              setSearchQuery({
                                ...searchQuery,
                                area_id: parseInt(e.value)
                              })
                            }
                            value={locations.find(
                              c => c.value === parseInt(searchQuery.area_id)
                            )}
                          />
                        </div>
                      </div>
                      <div className='form-group col-12'>
                        <label>Find Developers</label>
                        <Select
                          placeholder={<div>Select ...</div>}
                          //   isLoading={isloading}
                          options={builders}
                          isMulti={false}
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          onChange={e =>
                            setSearchQuery({
                              ...searchQuery,
                              builder_id: parseInt(e.value)
                            })
                          }
                          value={builders.find(
                            c => c.value === parseInt(searchQuery.builder_id)
                          )}
                        />
                      </div>
                      <div className='form-group col-12'>
                        <label>Find Developers</label>
                        <Select className='form-control' />
                      </div>
                      <div className='form-group col-12'>
                        <label>Min Area (sq ft)</label>
                        <input
                          className='form-control'
                          placeholder='Type (sq ft)'
                          onChange={e =>
                            setSearchQuery({
                              ...searchQuery,
                              min_area: parseInt(e.target.value)
                            })
                          }
                          value={searchQuery.min_area}
                        />
                      </div>
                      <div className='form-group col-12'>
                        <label>Max Area (sq ft)</label>
                        <input
                          className='form-control'
                          placeholder='Type (sq ft)'
                          onChange={e =>
                            setSearchQuery({
                              ...searchQuery,
                              max_area: parseInt(e.target.value)
                            })
                          }
                          value={searchQuery.max_area}
                        />
                      </div>
                      <div className='form-group col-12'>
                        <label>Price (Min)</label>
                        <input
                          className='form-control'
                          placeholder='Price (Min)'
                          onChange={e =>
                            setSearchQuery({
                              ...searchQuery,
                              price_min: parseInt(e.target.value)
                            })
                          }
                          value={searchQuery.price_min}
                        />
                      </div>
                      <div className='form-group col-12'>
                        <label>Price (Max)</label>
                        <input
                          className='form-control'
                          placeholder='Price (Max)'
                          onChange={e =>
                            setSearchQuery({
                              ...searchQuery,
                              price_max: parseInt(e.target.value)
                            })
                          }
                          value={searchQuery.price_max}
                        />
                      </div>
                      <div className='form-group col-12'>
                        <label>Property Category</label>
                        <Select
                          placeholder={<div>Property Category</div>}
                          //   isLoading={isloading}
                          options={propertyType}
                          isMulti={false}
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          onChange={onChangePropertyType}
                          value={propertyType.find(
                            c => c.value === parseInt(searchQuery.property_type)
                          )}
                        />
                      </div>
                      <div className='form-group col-12'>
                        <label>Keywords</label>
                        <input
                          className='form-control'
                          placeholder='e.g Clifton, DHA or Zip Code'
                          onChange={e =>
                            setSearchQuery({
                              ...searchQuery,
                              keywords: e.target.value
                            })
                          }
                          value={searchQuery.keywords}
                        />
                      </div>
                      <div className='stickySearchModalBar w-100'>
                        {/*<a*/}
                        {/*    className="more-search p-0 mr-2"*/}
                        {/*    data-toggle="collapse"*/}
                        {/*    href="#advanced-search"*/}
                        {/*    role="button"*/}
                        {/*    aria-expanded="false"*/}
                        {/*    aria-controls="advanced-search"*/}
                        {/*>*/}
                        {/*    <i className="fas fa-angle-double-down p-0"/>*/}
                        {/*</a>*/}
                        <button
                          className='btn btn-primary btn-block align-items-center'
                          type='button'
                          onClick={searchSubmit}
                        >
                          <i className='fas fa-search mr-1' />
                          <span>Search</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MobileView>
      {/* ------------------- Property Search Bar -------------------*/}
    </div>
  )
}

export default ProjectSearchBar
