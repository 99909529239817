import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import {
  API_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  multiFormHeader,
  currentUserInformation,
  EMAIL_SERVICE_ID,
  EMAIL_CHANGEPASSWORD_TEMPLATE_ID,
  EMAIL_PUBLIC_KEY
} from '../../Constants/global'
import emailjs from '@emailjs/browser'

function ForgotPasswordForm () {
  const [apierror, setApierror] = useState('')
  const [errorclass, setErrorclass] = useState('error text-danger')

  const initialValues = {
    email: ''
  }

  const onSubmit = values => {
    console.log(values)

    var form = new FormData()
    form.append('email', values.email)

    axios({
      method: 'POST',
      url: API_URL + 'api/forgotpassword',
      data: form,
      headers: multiFormHeader
    }).then(response => {
      if (response.data.status === 200) {
        setApierror(response.data.message)
        setErrorclass('error text-success')

        var templateParams = {
          to_email: values.email,
          link: 'New password : ' + response.data.code
        }

        emailjs
          .send(
            EMAIL_SERVICE_ID,
            EMAIL_CHANGEPASSWORD_TEMPLATE_ID,
            templateParams,
            EMAIL_PUBLIC_KEY
          )
          .then(result => {})
      } else {
        setApierror(response.data.message)
        setErrorclass('error text-danger')
      }
    })
  }

  const validateSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email format').required('Required')
  })

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validateSchema
  })

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <input
          type='text'
          className='form-control my-4'
          aria-describedby='emailHelp'
          name='email'
          id='email'
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.email}
        />
        {formik.touched.email && formik.errors.email ? (
          <div className='error text-danger'> {formik.errors.email} </div>
        ) : null}
        <div className='col-sm-12'>
          {apierror ? <p className={errorclass}> {apierror} </p> : null}
        </div>
        <button
          type='submit'
          className='btn btn-primary btn-block'
          data-dismiss='modal'
        >
          Continue
        </button>
      </form>
    </>
  )
}

export default ForgotPasswordForm
