import React from 'react'

import ForgotPasswordForm from '../Components/ClassifiedComponents/ForgotPasswordForm'



function ForgotPassword() {
    
  return (
      
    <>
   
    <div className="bg-light">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item"><a href="/"> <i className="fas fa-home" />
                        </a></li>
                        <li className="breadcrumb-item"><i className="fas fa-chevron-right" /> 
                        <a href="/">Register</a></li>
                        <li className="breadcrumb-item active"><i className="fas fa-chevron-right" /> <span> Forgot Password</span>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    {/*=================================
Breadcrumb */}


    <section className="space-ptb">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-7 text-center">
                    <img className="img-fluid" alt="img" src="https://cdn.jagahonline.com/assets/seller-portal-assets/assets/media/login/forget-password.png" loading="lazy" />
                </div>
                <div className="col-lg-5 mt-4 mt-lg-0">
                    <div className="contact-form forgot px-3 py-4 mt-0">
                        <div className="firstsectionemail visible">
                            <div className="section-title mb-2">
                                <h2 className="text-center mb-0 text-heading">Lost your
                                </h2>
                                <h2 className="text-center mt-0 mb-3 text-heading">password?
                                </h2>
                                <p className="text-muted font-weight-bold mb-0 text-center">
                                    Dont worry, we will sort this out
                                </p>
                                <div className="text-center mt-3 px-lg-4">
                                    Just provide your email address so that
                                </div>
                                <div className="text-center mt-0 px-lg-4">
                                    we can verify your Jagah Online account
                                </div>
                               <ForgotPasswordForm />

                                <div className="col-sm-12 mt-4">
                                    <div className="text-center">
                                        We will send password reset instructions to your email address
                                        associated with Jagah Online.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  
</>
  )
}

export default ForgotPassword