import React from 'react'
import {BrowserView, MobileView} from 'react-device-detect';
import "../../css/jo-projects.css";

import {BASE_URL,} from '../../../../Constants/global'

function KingsScenicMall() {

    return (
        <main className="joProject">
            {/*JO Project Browser View*/}
            <BrowserView>
                <div className="BrowserView">
                    {/*Project Header*/}
                    <header className="header-stick pj-is-sticky">
                        <div className="container-fluid bg-dark">
                            <nav className="navbar navbar-expand-lg navbar-dark bg-dark header-sticky-single">
                                <div className="project-logo-main">
                                    <a className="navbar-brand ml-2" href="#">
                                        <img className="img-fluid" width={175}
                                             height={46}
                                             src={BASE_URL + "classified_assets/images/logo-light.svg"}
                                             alt="" loading="lazy"/>
                                    </a>
                                    <a className="navbar-brand mx-4" href="#">
                                        <img className="img-fluid" width={165}
                                             src={BASE_URL + "classified_assets/images/jo-projects/kings-scenic/kingsscenic_logo2.png"}
                                             alt="" loading="lazy"/>
                                    </a>
                                </div>
                                <button className="navbar-toggler" type="button" data-toggle="collapse"
                                        data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02"
                                        aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"/>
                                </button>
                                <div className="collapse navbar-collapse project-nav-jo" id="navbarTogglerDemo02">
                                    <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                                        <li className="nav-item">
                                            <a className="nav-link" href="#features">Features <span
                                                className="sr-only">(current)</span></a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#developer">About the Developer</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#location">Location</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#payment">Payment Plan</a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </header>
                    {/*Project Header*/}

                    {/*Banner Header Project*/}
                    <section className="banner-header-project"
                             style={{backgroundImage: 'url(../../../classified_assets/images/jo-projects/kings-scenic/kingsscenic_banner.jpg)'}}/>
                    {/*Banner Header Project*/}

                    {/*Project Features*/}
                    <section id="features" className="jp-features-section space-ptb">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center mb-5">
                                    <h1>Features</h1>
                                </div>
                            </div>
                            <div className="jp-features-main">
                                <div className="row">
                                    <div className="col-md-4 mb-5">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/kings-scenic/carparking.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Car Parking
                                                </h6>
                                                <p>Kings Scenic Mall has dedicated
                                                    car parking with over 300 cars
                                                    capacity.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-5">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/kings-scenic/centralair.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Centrally Air-Condition
                                                </h6>
                                                <p>Kings Scenic Mall is Sukkur’s first
                                                    centrally air conditioned mall.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-5">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/kings-scenic/events.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Atrium
                                                </h6>
                                                <p>Kings Scenic Mall has an Atrium
                                                    for all type of events and
                                                    celebrations.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-5">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/kings-scenic/playarea.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Children Play Area
                                                </h6>
                                                <p>Kings Scenic Mall has dedicated
                                                    children play area so visitors can
                                                    enjoy their weekends here</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-5">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/kings-scenic/foodcourt.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Food Court
                                                </h6>
                                                <p>Kings Scenic Mall has a food
                                                    court with all national and
                                                    international eateries</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-5">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/kings-scenic/canal.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Serene Environment
                                                </h6>
                                                <p>Kings Scenic Mall is located
                                                    opposite to three canals.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*Project Features*/}

                    {/*Project Developer*/}
                    <section id="developer" className="about-builder-section space-ptb">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-4">
                                    <div className="about-developer-img-main">
                                        <img className="about-developer-img img-fluid" alt="img"
                                             src={BASE_URL + "classified_assets/images/jo-projects/kings-scenic/kingsscenic-mid.jpg"}
                                             loading="lazy"/>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="about-developer-descp text-white">
                                        <h1>
                                            About <span>Kings Group</span>
                                        </h1>
                                        <p>
                                            Kings Group is one of the pioneers of private construction companies in the
                                            country. Kings
                                            Group
                                            started
                                            delivering projects from the 80’s and has delivered over 20 projects till
                                            now with the same
                                            more
                                            under construction.
                                        </p>
                                        <p>
                                            Kings Comforts is another landmark project of the builder located at the
                                            ideal location of
                                            Nazimabad block 3.
                                            Kings Group has make sure to equip this project with all the modern
                                            amenities which will
                                            give
                                            you the
                                            utmost comfort and better living standards everyone wish for
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*Project Developer*/}

                    {/*Project Location*/}
                    <section id="location" className="jo-proj-loc-main space-ptb">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    <div className="jo-proj-loc-main-inner mb-4">
                                        <div className="jo-location-heading">
                                            <span className="line-horizontal">Location <span>Highlights</span></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="jo-location-points">
                                        <div className="jo-location-points-route-km">
                                            1 KM FROM
                                        </div>
                                        <div className="jo-location-points-route">
                                            FROM NICVD
                                        </div>
                                    </div>
                                    <div className="jo-location-points">
                                        <div className="jo-location-points-route-km">
                                            2 KM FROM
                                        </div>
                                        <div className="jo-location-points-route">
                                            IBA SUKKUR
                                        </div>
                                    </div>
                                    <div className="jo-location-points">
                                        <div className="jo-location-points-route-km">
                                            4 KM FROM
                                        </div>
                                        <div className="jo-location-points-route">
                                            MILITARY ROAD
                                        </div>
                                    </div>
                                    <div className="jo-location-points d-flex align-items-center">
                                        <div className="jo-location-points-route">
                                            LOCATED ON BYPASS ROAD
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="jo-location-iframe">
                                        <iframe class="w-100"
                                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14130.222300628147!2d68.8370925!3d27.7001277!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x349dea402584dab4!2sKING%E2%80%99s%20SCENIC%20SHOPPING%20MALL%20Sukkur!5e0!3m2!1sen!2s!4v1649931779249!5m2!1sen!2s"
                                            height={450} style={{border: 0}} allowFullScreen loading="lazy"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*Project Location*/}

                    {/*Project Payment*/}
                    <section id="payment" className="inns-plans-section space-pt">
                        <div className="container">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-md-3">
                                    <div className="starting-from-main">
                                        <div className="starting-from-inner">
                                            <div className="black-head">Prices Starting From</div>
                                            <div className="read-head"><span>PKR</span> 2,864,400</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 text-center">
                                    <div className="starting-from-main">
                                        <div className="starting-from-img-logo">
                                            <img className="starting-from-img-lg img-fluid" alt="img"
                                                 src={BASE_URL + "classified_assets/images/jo-projects/kings-scenic/kingsscenic_logo.jpg"}
                                                 loading="lazy"/>
                                        </div>
                                        <div className="starting-from-main-inner-head mt-0">
                                            Shops
                                        </div>
                                        <div className="starting-from-img-main">
                                            <img className="starting-from-img-mn img-fluid" alt="img"
                                                 src={BASE_URL + "classified_assets/images/jo-projects/kings-scenic/kingsscenic_unit.jpg"}
                                                 loading="lazy"/>
                                        </div>
                                        <div className="read-head-blk"><span>3 Years</span> Possession Plan</div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="starting-from-main">
                                        <div className="starting-from-inner">
                                            <div className="black-head">Installments Starting From</div>
                                            <div className="read-head"><span>PKR</span> 18,897</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*Project Payment*/}
                </div>
            </BrowserView>
            {/*JO Project Browser View*/}

            {/*JO Project Mobile View*/}
            <MobileView>
                <div className="MobileView">
                    {/*Project Header*/}
                    <header className="header-stick">
                        <div className="container-fluid bg-dark">
                            <nav className="navbar navbar-expand-lg navbar-dark bg-dark header-sticky-single">
                                <div className="project-logo-main d-flex">
                                    <div className="col-md-6">
                                        <a className="navbar-brands" href="#">
                                            <img className="img-fluid w-100"
                                                 style={{marginTop: '10px'}}
                                                 src={BASE_URL + "classified_assets/images/logo-light.svg"}
                                                 alt="" loading="lazy"/>
                                        </a>
                                    </div>
                                    <div className="col-md-6">
                                        <a className="navbar-brands" href="#">
                                            <img className="img-fluid w-100"
                                                 style={{padding: '5px'}}
                                                 src={BASE_URL + "classified_assets/images/jo-projects/kings-scenic/kingsscenic_logo2.png"}
                                                 alt="" loading="lazy"/>
                                        </a>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </header>
                    {/*Project Header*/}

                    {/*Banner Header Project*/}
                    <section className="banner-header-project"
                             style={{backgroundImage: 'url(../../../classified_assets/images/jo-projects/kings-scenic/kingsscenic_banner.jpg)'}}/>
                    {/*Banner Header Project*/}

                    {/*Project Features*/}
                    <section id="features" className="jp-features-section space-ptb">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center mb-3">
                                    <h1>Features</h1>
                                </div>
                            </div>
                            <div className="jp-features-main">
                                <div className="row">
                                    <div className="col-md-4 mb-3">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/kings-scenic/carparking.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Car Parking
                                                </h6>
                                                <p>Kings Scenic Mall has dedicated
                                                    car parking with over 300 cars
                                                    capacity.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/kings-scenic/centralair.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Centrally Air-Condition
                                                </h6>
                                                <p>Kings Scenic Mall is Sukkur’s first
                                                    centrally air conditioned mall.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/kings-scenic/events.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Atrium
                                                </h6>
                                                <p>Kings Scenic Mall has an Atrium
                                                    for all type of events and
                                                    celebrations.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/kings-scenic/playarea.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Children Play Area
                                                </h6>
                                                <p>Kings Scenic Mall has dedicated
                                                    children play area so visitors can
                                                    enjoy their weekends here</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/kings-scenic/foodcourt.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Food Court
                                                </h6>
                                                <p>Kings Scenic Mall has a food
                                                    court with all national and
                                                    international eateries</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/kings-scenic/canal.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Serene Environment
                                                </h6>
                                                <p>Kings Scenic Mall is located
                                                    opposite to three canals.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*Project Features*/}

                    {/*Project Developer*/}
                    <section id="developer" className="about-builder-section space-ptb">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-5">
                                    <div className="about-developer-img-main">
                                        <img className="about-developer-img img-fluid" alt="img"
                                             src={BASE_URL + "classified_assets/images/jo-projects/kings-scenic/kingsscenic-mid.jpg"}
                                             loading="lazy"/>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <div className="about-developer-descp text-white">
                                        <h1>
                                            About <span>Kings Group</span>
                                        </h1>
                                        <p>
                                            Kings Group is one of the pioneers of private construction companies in the
                                            country. Kings
                                            Group
                                            started
                                            delivering projects from the 80’s and has delivered over 20 projects till
                                            now with the same
                                            more
                                            under construction.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="about-developer-descp text-white">
                                        <p className="mb-0">
                                            <span>Kings Comforts</span> is another landmark project of the builder
                                            located at the ideal
                                            location of
                                            Nazimabad block 3.
                                            Kings Group has make sure to equip this project with all the modern
                                            amenities which will
                                            give
                                            you the
                                            utmost comfort and better living standards everyone wish for
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*Project Developer*/}

                    {/*Project Location*/}
                    <section id="location" className="jo-proj-loc-main space-ptb">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    <div className="jo-proj-loc-main-inner mb-4">
                                        <div className="jo-location-heading">
                                            <span className="line-horizontal">Location <span>Highlights</span></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="jo-location-points">
                                        <div className="jo-location-points-route-km">
                                            1 KM FROM
                                        </div>
                                        <div className="jo-location-points-route">
                                            FROM NICVD
                                        </div>
                                    </div>
                                    <div className="jo-location-points">
                                        <div className="jo-location-points-route-km">
                                            2 KM FROM
                                        </div>
                                        <div className="jo-location-points-route">
                                            IBA SUKKUR
                                        </div>
                                    </div>
                                    <div className="jo-location-points">
                                        <div className="jo-location-points-route-km">
                                            4 KM FROM
                                        </div>
                                        <div className="jo-location-points-route">
                                            MILITARY ROAD
                                        </div>
                                    </div>
                                    <div className="jo-location-points d-flex align-items-center">
                                        <div className="jo-location-points-route">
                                            LOCATED ON BYPASS ROAD
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="jo-location-iframe">
                                        <iframe class="w-100"
                                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14130.222300628147!2d68.8370925!3d27.7001277!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x349dea402584dab4!2sKING%E2%80%99s%20SCENIC%20SHOPPING%20MALL%20Sukkur!5e0!3m2!1sen!2s!4v1649931779249!5m2!1sen!2s"
                                            height={450} style={{border: 0}} allowFullScreen loading="lazy"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*Project Location*/}

                    {/*Project Payment*/}
                    <section id="payment" className="inns-plans-section space-ptb">
                        <div className="container">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-md-3">
                                    <div className="starting-from-main">
                                        <div className="starting-from-main-inner-head mt-0">
                                            Shops
                                        </div>
                                        <div className="starting-from-inner">
                                            <div className="black-head">Prices Starting From</div>
                                            <div className="read-head"><span>PKR</span> 2,864,400</div>
                                            <div className="black-head">Installments Starting From</div>
                                            <div className="read-head"><span>PKR</span> 18,897</div>
                                        </div>
                                        <div className="read-head-blk"><span>3 Years</span> Possession Plan</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*Project Payment*/}
                </div>
            </MobileView>
            {/*JO Project Browser View*/}
        </main>
    )
}

export default KingsScenicMall