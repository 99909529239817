import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import * as Yup from "yup";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import services from "../../../../Redux/reducers/services";
import { getServices } from "../../../../Redux/actions/services";
import { useFormik, yupToFormErrors } from "formik";
import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  currentUserInformation,
  multiFormHeader,
} from "../../../../Constants/global";

const MainBanner = ({ getServices, services }) => {
  const [serviceArray, setServiceArray] = useState([]);
  const [selectedCity, setSelectedCity] = useState(0);
  const [initialValues, setInitialValues] = useState({
    city: 14,
    // services: "",
  });

  useEffect(() => {
    getServices();
    // alert(services[0].title);
    async function fetchSearchBy() {
      const response = await axios({
        method: "GET",
        url: API_URL + "api/searchby",
      });
      if (response.status === 200) {
        const cities = response.data?.data[2]["cities"];
        const nArray2 = [];
        cities.map((item) => {
          let roleData = {};
          if (
            item.id === 14 ||
            item.id === 3 ||
            item.id === 12 ||
            item.id === 62
          ) {
            roleData.value = item.id;
            roleData.label = item.city;
            nArray2.push(roleData);
          }
        });
        setCities(nArray2);
      }
    }
    fetchSearchBy();
  }, []);
  const [cities, setCities] = useState([]);
  const [inputError, setInputError] = useState("");
  const [inputErrorIcon, setInputErrorIcon] = useState("");
  const [dropDownValues, setDropDownValues] = useState({
    city_id: 0,
  });

  const handleSubmit = (values) => {
    // alert(values.services);
    console.log(values);
    if (
      values.services !== undefined &&
      values.services !== 0 &&
      values.city !== 0 &&
      inputError === ""
    ) {
      // setInputError("");
      window.location.href = "ustad-service/" + values.services;
    } else {
      setInputError("Required");
      setInputErrorIcon("*");
    }
  };

  // const validateSchema = Yup.object().shape({
  //   services: Yup.string().required("Required"),
  // });
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    // validationSchema: validateSchema,
    enableReinitialize: true,
  });
  useEffect(() => {
    setInputError("Required");
    setInputErrorIcon("*");
    // alert(formik.values.city);
    setSelectedCity(Number(formik.values.city));

    // alert(serviceArray);
    getServices(selectedCity);
    // setInputError("Required");
    // setInputErrorIcon("*");
    // setSelectedCity(Number(formik.values.city));
    // let tempArray = [];
    // services.map((item, index) => {
    //   // alert(typeof item.city_id + typeof selectedCity);
    //   if (item.city_id === selectedCity) {
    //     tempArray.push(item);
    //   }
    // });
    // setServiceArray(tempArray);
    // getServices(selectedCity);
  }, [formik.values.city, selectedCity]);

  useEffect(() => {
    if (formik.values.services === "0") {
      setInputError("Required");
      setInputErrorIcon("*");
    } else {
      setInputError("");
      setInputErrorIcon("");
    }
  }, [formik.values.services]);

  return (
    <section className="hman-header overflow-hidden">
      <div className="row">
        <div className="col-md-5 col-lg-5">
          <div className="hman-bg-gradient"></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-12 col-xl-12 text-left">
                <div className="banner px-md-5 px-1">
                  <h1 className="text-white mb-3 banner-bg-slider-title">
                    Handyman Services in Pakistan
                  </h1>
                  <span className="text-white banner-bg-slider-sub-title">
                    Don't worry. We've done this for millions of times.
                  </span>
                  {/*<div className="hman-saearch-main search mt-4">*/}
                  {/*  <div className="d-flex bg-white w-100">*/}
                  {/*    <form*/}
                  {/*      className="form-row w-100 align-items-center"*/}
                  {/*      onSubmit={formik.handleSubmit}*/}
                  {/*    >*/}
                  {/*      <div className="form-group m-0 col-lg col-md-6">*/}
                  {/*        <div className="hman-select-input m-2">*/}
                  {/*          <label className="mx-0">Select City</label>*/}

                  {/*          <select*/}
                  {/*            // form-control basic-select border-0*/}
                  {/*            className="form-control border-0 "*/}
                  {/*            onBlur={formik.handleBlur}*/}
                  {/*            onChange={formik.handleChange}*/}
                  {/*            name="city"*/}
                  {/*          >*/}
                  {/*            <option value={""}>{"All City"}</option>*/}
                  {/*            {cities.map((item, i) => {*/}
                  {/*              return (*/}
                  {/*                <option key={i} value={parseInt(item.value)}>*/}
                  {/*                  {item.label}*/}
                  {/*                </option>*/}
                  {/*              );*/}
                  {/*            }, this)}*/}
                  {/*          </select>*/}
                  {/*        </div>*/}
                  {/*      </div>*/}
                  {/*      <div className="form-group m-0 col-lg col-md-6 border-left">*/}
                  {/*        <div className="hman-select-input m-2">*/}
                  {/*          <label className="mx-0">Service Categories</label>*/}
                  {/*          <span class="red"> {inputErrorIcon}</span>*/}
                  {/*          <select*/}
                  {/*            className="form-control border-0 "*/}
                  {/*            onBlur={formik.handleBlur}*/}
                  {/*            onChange={formik.handleChange}*/}
                  {/*            name="services"*/}
                  {/*          >*/}
                  {/*            {" "}*/}
                  {/*            <option key={0} value={0}>*/}
                  {/*              Choose a type of service*/}
                  {/*            </option>*/}
                  {/*            {serviceArray !== [] ? (*/}
                  {/*              serviceArray.map((item, index) => {*/}
                  {/*                // alert(item.city_id);*/}

                  {/*                return (*/}
                  {/*                  <option key={index + 1} value={item.id}>*/}
                  {/*                    {item.title}*/}
                  {/*                  </option>*/}
                  {/*                );*/}
                  {/*              })*/}
                  {/*            ) : (*/}
                  {/*              <option key={0} value={0}>*/}
                  {/*                Choose a type of service*/}
                  {/*              </option>*/}
                  {/*            )}*/}
                  {/*          </select>*/}
                  {/*          /!* {inputError ? (*/}
                  {/*            <div className="error text-danger ml-4">*/}
                  {/*              {" "}*/}
                  {/*              {inputError}{" "}*/}
                  {/*            </div>*/}
                  {/*          ) : null} *!/*/}
                  {/*        </div>*/}
                  {/*      </div>*/}

                  {/*      <div className="form-group mb-0">*/}
                  {/*        <button*/}
                  {/*          className="btn btn-primary hman-search-btn"*/}
                  {/*          href="#"*/}
                  {/*          type="submit"*/}
                  {/*        >*/}
                  {/*          Search{" "}*/}
                  {/*        </button>*/}
                  {/*      </div>*/}
                  {/*    </form>*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  <div className="property-search-field bg-white mt-4 borders-radius-6">
                    <div className="property-search-item border-0">
                      <form
                        className="form-row basic-select-wrapper justify-content-center"
                        onSubmit={formik.handleSubmit}
                      >
                        <div className="form-group m-0 col-lg col-md-6 border-0">
                          <div className="hman-select-input m-2">
                            <label className="mx-0">Select City</label>

                            <select
                              // form-control basic-select border-0
                              className="form-control border-0 "
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              name="city"
                            >
                              <option value={""}>{"All City"}</option>
                              {cities.map((item, i) => {
                                return (
                                  <option key={i} value={parseInt(item.value)}>
                                    {item.label}
                                  </option>
                                );
                              }, this)}
                            </select>
                          </div>
                        </div>
                        <div className="form-group m-0 col-lg col-md-6 border-0">
                          <div className="hman-select-input m-2">
                            <label className="mx-0">Service Categories</label>
                            <span className="red"> {inputErrorIcon}</span>
                            <select
                              className="form-control border-0 "
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              name="services"
                            >
                              {" "}
                              <option key={0} value={0}>
                                Choose a type of service
                              </option>
                              {services !== [] ? (
                                services.map((item, index) => {
                                  // alert(item.city_id);

                                  return (
                                    <option key={index + 1} value={item.id}>
                                      {item.title}
                                    </option>
                                  );
                                })
                              ) : (
                                <option key={0} value={0}>
                                  Choose a type of service
                                </option>
                              )}
                            </select>
                            {/* {inputError ? (
                              <div className="error text-danger ml-4">
                                {" "}
                                {inputError}{" "}
                              </div>
                            ) : null} */}
                          </div>
                        </div>

                        <div className="d-flex justify-content-center align-items-center">
                          <div className="form-group mb-0 border-0">
                            <button
                              className="btn btn-primary hman-search-btn"
                              href="#"
                              type="submit"
                            >
                              Search{" "}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-7 col-lg-7">
          <div className="hero-header">
            <picture>
              {/*<source*/}
              {/*    type="image/webp"*/}
              {/*    srcSet="/classified_assets/images/handymen-images/handymenBanner.webp"*/}
              {/*/>*/}
              <img
                fetchpriority="high"
                className="hero-header-main-img ustad-header-img"
                alt="header"
                src="/classified_assets/images/handymen-images/handymenBanner.jpg"
              />
            </picture>
          </div>
        </div>
      </div>
    </section>
  );
};
MainBanner.propTypes = {
  getServices: PropTypes.func.isRequired,
  services: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  services: state.services,
});
export default connect(mapStateToProps, { getServices })(MainBanner);
