import React from "react";

export const SuccessDialogue = () => {
  return (
    <div
      class="modal fade"
      id="exampleModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body text-center ">
            <div>
              <i class="fas fa-check fa-4x my-3 text-success"></i>{" "}
            </div>
            <h6 className="py-4 px-3 text-transform-none">
              Your order has been placed, you will be contacted by customer
              support.
            </h6>

            <a href="/my-orders" type="button" className="btn btn-primary mt-0 mb-4">
              Track Orders
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
