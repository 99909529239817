// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider
} from 'firebase/auth'

// const firebaseConfig = {
//   apiKey: 'AIzaSyCIFIobj_gayGagB86L4KLsxPrAE5Rd8Hk',
//   authDomain: 'remvap-jotesting-com.firebaseapp.com',
//   projectId: 'remvap-jotesting-com',
//   storageBucket: 'remvap-jotesting-com.appspot.com',s
//   messagingSenderId: '304461723035',
//   appId: '1:304461723035:web:1cc7b9a99d59a65b4c1d3e',
//   measurementId: 'G-XKDTVPBVEL'
// }

const firebaseConfig = {
  apiKey: 'AIzaSyD5JmSwObeegvDDwB1MC9U5_E-1WsIGFOU',
  authDomain: 'jagahonline-9f3d3.firebaseapp.com',
  projectId: 'jagahonline-9f3d3',
  storageBucket: 'jagahonline-9f3d3.appspot.com',
  messagingSenderId: '403453135618',
  appId: '1:403453135618:web:21102e88c90f71a72b901b',
  measurementId: 'G-7X28FTET7W'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const provider = new GoogleAuthProvider()
const fprovider = new FacebookAuthProvider()

export { auth, provider, fprovider }
