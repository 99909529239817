import React, { Fragment, useState, useEffect } from 'react'
import UserProfileHeader from '../../../Components/Header/joclassified/UserProfileHeader'
import { multiFormHeader1 } from '../../../Constants/global'
import axios from 'axios'
import Pagination from 'react-js-pagination'
import PropertyListingColumn from '../../../Components/ClassifiedComponents/Properties/PropertyListingColumn'
import FavoritePropertyListingColumn from '../../../Components/ClassifiedComponents/Properties/FavoritePropertyListingColumn'
import { SpinnerCircular } from 'spinners-react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useParams,
  Switch
} from 'react-router-dom'
import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  multiFormHeader
} from '../../../Constants/global'
import 'bootstrap-less'
import { isWindows } from 'react-device-detect'
const MyFavorites = ({ prop_params }) => {
  const [propertyData, setPropertyData] = useState([])
  const [total_records, settotal_records] = useState(0)
  const [pageNumber, setpageNumber] = useState(0)
  const [recordNotfound, setRecordNotFound] = useState('')
  const [isLoading, setisLoading] = useState(false)
  var searchQueryGet = {}
  if (
    localStorage.getItem('searchQuery') !== undefined &&
    localStorage.getItem('searchQuery') !== ''
  ) {
    searchQueryGet = JSON.parse(localStorage.getItem('searchQuery')) || {
      property_purpose: 1,
      city_id: 14,
      area_id: 0,
      property_type: 1,
      min_area: 0,
      max_area: 0,
      price_min: 0,
      price_max: 0,
      beds: 0,
      keywords: ''
    }
  } else {
    searchQueryGet = {
      property_purpose: 1,
      city_id: 14,
      area_id: 0,
      property_type: 1,
      min_area: 0,
      max_area: 0,
      price_min: 0,
      price_max: 0,
      beds: 0,
      keywords: ''
    }
  }
  console.log(searchQueryGet)
  const property_thumbnail =
    'https://cdn.jagahonline.com/properties/275906/front_image-61b98b34e402a-thumb.jpeg'
  // console.log('Property Params' + prop_params.property_type)
  const user_id = localStorage.getItem('user_id')
    ? localStorage.getItem('user_id')
    : 0
  const data_m = {
    pageNumber: pageNumber,
    nPerPage: 10,
    isActive: 1,
    isDeleted: 0,
    showAll: false,
    id: 0,
    city_id: searchQueryGet.city_id,
    property_purpose: searchQueryGet.property_purpose,
    property_type: searchQueryGet.property_type,
    min_area: searchQueryGet.min_area,
    max_area: searchQueryGet.max_area,
    price_min: searchQueryGet.price_min,
    price_max: searchQueryGet.price_max,
    beds: searchQueryGet.beds,
    keywords: searchQueryGet.keywords,
    user_id: user_id
  }

  console.log('user_id : ' + localStorage.getItem('user_id'))

  useEffect(() => {
    async function fetchProperties () {
      setisLoading(true)
      axios({
        method: 'GET',
        url: API_URL + 'api/myfavorites',
        data: data_m,

        headers: multiFormHeader1
      }).then(response => {
        if (response.status === 200) {
          if (response.data.total_records === 0) {
            setisLoading(false)
            setRecordNotFound('Record not found. Try again')
          } else {
            settotal_records(response.data.total_records)
            // console.log('total records ' + response.data.total_records)
            setPropertyData(response.data?.data)
            setisLoading(false)
          }
        } else {
          setisLoading(false)
          setRecordNotFound(
            'Something went wrong with the requested data. Try again'
          )
        }
      })
    }
    fetchProperties()
  }, [pageNumber])
  function handlePageChange (pageNumber) {
    console.log(`active page is ${pageNumber}`)
    setpageNumber(pageNumber)
    this.setState({ activePage: pageNumber })
  }
  const resetSearchBox = () => {
    localStorage.setItem('searchQuery', '')
    window.location.reload()
  }
  return (
    <>
      <UserProfileHeader />
      <section class='space-ptb'>
        <div class='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='section-title mb-3 mb-lg-4'>
                <h2>
                  <span className='text-primary'>{total_records}</span> Results
                </h2>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='property-filter-tag'>
                <ul className='list-unstyled'>
                  <li>
                    <a
                      className='filter-clear'
                      href='javascript:void(0);'
                      onClick={resetSearchBox}
                    >
                      Reset Search <i className='fas fa-redo-alt' />{' '}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-lg-8'>
              <div className='property-filter d-sm-flex'>
                <ul className='property-short list-unstyled d-sm-flex mb-0'>
                  <li>
                    <form className='form-inline'>
                      <div className='form-group d-lg-flex d-block'>
                        <label className='justify-content-start'>
                          Sort by:
                        </label>
                        <div className='short-by'>
                          <select className='form-control basic-select'>
                            <option>Date new to old</option>
                            <option>Price Low to High</option>
                            <option>Price High to Low</option>
                            <option>Date Old to New</option>
                            <option>Date New to Old</option>
                          </select>
                        </div>
                      </div>
                    </form>
                  </li>
                </ul>
              </div>
              {/*Property Column Listing*/}
              <div className='row my-4 justify-content-center'>
                {isLoading ? (
                  <div className='col-md-6 text-center'>
                    <box className='shine property-box-shimmer'></box>

                    <div>
                      <lines className='my-1 shine property-box-shimmerLines d-block'></lines>
                      <lines className='shine property-box-shimmerLines d-block'></lines>
                      <lines className='shine property-box-shimmerLines d-block'></lines>
                      <lines className='my-1 shine property-box-shimmerLines d-block'></lines>
                    </div>
                  </div>
                ) : recordNotfound ? (
                  <div> {recordNotfound} </div>
                ) : (
                  <FavoritePropertyListingColumn propertyData={propertyData} />
                )}
              </div>
              {/*Property Column Listing*/}

              <div className='col-md-12'>
                <Pagination
                  activePage={pageNumber}
                  itemsCountPerPage={20}
                  totalItemsCount={total_records}
                  pageRangeDisplayed={3}
                  onChange={handlePageChange}
                  activeLinkClass='active'
                  innerClass='pagination'
                  itemClass='page-item'
                  linkClass='page-link'
                />
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='advertisment-slider'>
                <div className='advertisment-slider-item' data-ad-id>
                  <div className='advertisment-slider-inner compact'>
                    <a className='adds-sec' data-id href target='_blank'>
                      <img
                        src='https://cdn.jagahonline.com/advertisements/101/grand-xcito-2-jo-353x550-60a67a959b6b8.png'
                        className='img-fluid'
                        alt=''
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default MyFavorites
