import React from 'react'
import axios from 'axios'
// import Swiper core and required modules
import { Navigation, Pagination } from 'swiper'
import { connect } from 'react-redux'
import { handleFavorite } from '../../../Redux/actions/favorite'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import {
  API_URL,
  USER_IMAGE_NOT_FOUND_URL,
  USER_PROFILE_URL
} from '../../../Constants/global'
import { Link } from 'react-router-dom'

function PropertyListingRow ({ propertyData, handleFavorite }) {
  const property_thumbnail =
    'https://cdn.jagahonline.com/properties/275906/front_image-61b98b34e402a-thumb.jpeg'

  const add_to_favorite = e => {
    const response = axios({
      method: 'GET',
      url:
        API_URL +
        'generalservices/property_favorites_add/' +
        e.target.id +
        '/' +
        localStorage.getItem('user_id')
    }).then(response => {
      if (response.status === 200) {
        document.getElementById(e.target.id).className =
          document.getElementById(e.target.id).className === 'fa fa-heart'
            ? 'far fa-heart'
            : 'fa fa-heart'
        localStorage.setItem('total_favorites', response.data.total_favorites)
        handleFavorite(response.data.total_favorites)
        console.log(localStorage.getItem('total_favorites'))
      }
    })
  }
  if (propertyData.length === 0) {
    return (
      <section className='space-ptb'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-12'>
              <photo className='shine w-100 shimmerphoto'></photo>
            </div>
            <div className='col-md-12 mt-1'>
              <lines className='shine w-100 shimmerlines'></lines>
            </div>
          </div>
        </div>
      </section>
    )
  }

  return (
    <Swiper
      className='swiper-landing'
      // install Swiper modules
      modules={[Navigation, Pagination]}
      spaceBetween={10}
      slidesPerView={1}
      breakpoints={{
        768: { slidesPerView: 2 },
        1024: { slidesPerView: 3 }
      }}
      pagination={{ clickable: true }}
    >
      {/* Iterate over newly_listed_pproperties data */}
      {propertyData.map(function (data, key) {
        console.log('Current Data row ' + data)

        const detail_page_url =
          '/property/' +
          data.city_data[0].city.toLowerCase() +
          '-' +
          data.slug.toLowerCase() +
          '-' +
          data.id

        const userProfile = data.user_profile
        const areaUnit = data.area_unit_data

        var user_data_q = USER_IMAGE_NOT_FOUND_URL

        if (userProfile.length !== 0) {
          if (
            data.user_profile[0].profile_image === undefined ||
            data.user_profile[0].profile_image === null ||
            data.user_profile[0].profile_image === ''
          ) {
            user_data_q = USER_IMAGE_NOT_FOUND_URL
          } else {
            const mainRegExp = RegExp('https')

            if (mainRegExp.test(data.user_profile[0].profile_image)) {
              user_data_q = data.user_profile[0].profile_image
            } else {
              user_data_q =
                USER_PROFILE_URL +
                data.user_id +
                '/' +
                data.user_profile[0].profile_image
            }
          }
        }

        const profile_image_url = user_data_q

        var imageURL = property_thumbnail

        if (data.front_image !== '' && data.front_image !== 'null') {
          const mainRegExp = RegExp('https')

          if (mainRegExp.test(data.front_image)) {
            imageURL = data.front_image
          } else {
            imageURL =
              'https://cdn.jagahonline.com/properties/' +
              data.id +
              '/' +
              data.front_image.split('.')[0] +
              '-thumb.' +
              data.front_image.split('.')[1]
          }
        }

        var bath = 0
        var bed = 0

        if (data.beds_baths.length !== 0) {
          data.beds_baths.map(item => {
            try {
              if (item.bathroom_data === 'Bed Room') {
                bed = item.value
              }

              if (item.bathroom_data === 'Bath Room') {
                bath = item.value
              }
            } catch {}
          })
        }

        return (
          <SwiperSlide>
            <div key={key} className='swiper-main'>
              <div className='property-item'>
                <Link to={detail_page_url}>
                  <div className='property-image bg-overlay-gradient-04'>
                    <img
                      className='img-fluid property-image-main'
                      src={imageURL}
                      onError={property_thumbnail}
                      alt=''
                      width={350}
                      height={230}
                      maxWidth={350}
                      maxHeight={230}
                      minWidth={350}
                      minheight={230}
                    />
                    <div className='property-lable'>
                      <span className='badge badge-md badge-primary'>
                        {data.property_purpose_data.length > 0
                          ? data.property_purpose_data[0].title
                          : ''}
                      </span>
                      <span className='badge badge-md badge-info'>
                        {data.city_data[0].city}
                      </span>
                    </div>
                    <div className='property-agent'>
                      <div className='property-agent-image'>
                        <img
                          className='img-fluid'
                          src={profile_image_url}
                          minWidth={50}
                          maxHeight={50}
                          alt=''
                        />
                      </div>
                    </div>
                  </div>
                </Link>
                <div className='property-details'>
                  <Link to={detail_page_url}>
                    <div className='property-details-inner'>
                      <h5 className='property-title'>
                        <a href={detail_page_url}>{data.title}</a>
                      </h5>
                      <span className='property-address'>
                        <i className='fas fa-map-marker-alt fa-xs'></i>
                        {data.address}
                      </span>

                      <div className='property-price'>
                        <span className='font-sm text-primary font-weight-bolder'>
                          {data.currency}
                        </span>{' '}
                        {data.price_txt}
                        <span></span>
                      </div>
                      <ul className='property-info list-unstyled d-flex'>
                        <li className='flex-fill property-bed'>
                          <i className='fas fa-bed'></i>Bed
                          <span>{data.beds}</span>
                        </li>
                        <li className='flex-fill property-bath'>
                          <i className='fas fa-bath'></i>Bath
                          <span>{data.baths}</span>
                        </li>
                        <li className='flex-fill property-m-sqft'>
                          <i className='far fa-square'></i>
                          {data.area_unit_txt}
                          <span>{data.land_area}</span>
                        </li>
                      </ul>
                    </div>
                  </Link>
                  <div className='property-btn'>
                    <a className='property-link' href={detail_page_url}>
                      See Details
                    </a>
                    <ul className='property-listing-actions list-unstyled mb-0'>
                      <li className='property-favourites'>
                        {localStorage.getItem('token') !== '' ? (
                          <a href='javascript:void(0);' title='Favourite'>
                            <i
                              id={data.id}
                              key={data.id}
                              onClick={add_to_favorite}
                              className={
                                data.isFavorite === 0
                                  ? 'far fa-heart'
                                  : 'fa fa-heart'
                              }
                              name='favorite'
                            ></i>
                          </a>
                        ) : (
                          <a
                            data-toggle='modal'
                            data-target='#loginModal'
                            href='javascript:void(0);'
                            title='Favourite'
                          >
                            <i className='far fa-heart'></i>
                          </a>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        )
      })}
      <a className='btn btn-see-more p-1' href='/properties'>
        View More <i className='fas fa-angle-double-right pl-2 pr-0'></i>
      </a>
    </Swiper>
  )
}

export default connect(null, { handleFavorite })(PropertyListingRow)
