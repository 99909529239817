import { BROWSE_CATEGORY } from "./types";
import axios from "axios";
import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  currentUserInformation,
  multiFormHeader,
  USER_IMAGE_NOT_FOUND_URL,
} from "../../Constants/global";

export const setBrowseCategory = () => (dispatch) => {
  //   ------------------------------------
  // if (
  //   localStorage.getItem("browse_category") === "" ||
  //   localStorage.getItem("browse_category") === null ||
  //   localStorage.getItem("browse_category") === undefined
  // ) {
  if (localStorage.getItem("browse_category") !== null) {
    dispatch({
      type: BROWSE_CATEGORY,
      payload: JSON.parse(localStorage.getItem("browse_category")),
    });
  } else {
    axios({
      method: "GET",
      url: API_URL + "api/browsebycategory",
      jsonHeader,
    }).then((response) => {
      if (response.status === 200) {
        localStorage.setItem(
          "browse_category",
          JSON.stringify(response.data?.data)
        );
        // alert(localStorage.getItem("browse_category") === response.data?.data);
        // ------------------------------
        dispatch({
          type: BROWSE_CATEGORY,
          payload: JSON.parse(localStorage.getItem("browse_category")),
        });
      }
    });
  }

  // }

  // localStorage.setItem("my_colors", JSON.stringify(colors)); //store colors
  // var storedColors = JSON.parse(localStorage.getItem("my_colors"));
  // alert(localStorage.getItem("browse_category"));
};
