import React, { useEffect, useState } from 'react'
import ProjectBox from './ProjectBox'

import axios from 'axios'

import { API_URL, multiFormHeader } from '../../../Constants/global'

function NewlyListedProjects () {
  const [projectData, setProjectData] = useState([])
  const project_thumbnail =
    'https://cdn.jagahonline.com/projects/275906/front_image-61b98b34e402a-thumb.jpeg'

  useEffect(() => {
    function fetchProperties () {
      axios({
        method: 'GET',
        url: API_URL + 'api/latest_projects',
        headers: multiFormHeader
      }).then(response => {
        if (response.status === 200) {
          setProjectData(response.data?.data)
        }
        if (response.status === 400) {
          alert('Internet is not available, try again.')
        }
      })
    }

    fetchProperties()
  }, [])

  return (
    <>
      {/* <!-- Newly listed properties--> */}
      <section class='space-pb newlyListedProjectsSection'>
        <div class='container'>
          <div class='row justify-content-center'>
            <div class='col-lg-8'>
              <div class='section-title text-center'>
                <h2>Newly listed Projects</h2>
                <p>Find your dream Projects from our Newly added Projects</p>
              </div>
            </div>
          </div>

          <div class='row'>
            <div className='col-sm-12 col-md-12'>
              <ProjectBox project_Data={projectData} />
            </div>
          </div>
        </div>
      </section>
      {/* <!--Newly Listed Properties--> */}
    </>
  )
}

export default NewlyListedProjects
