import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import useFetchData from '../../../../Helpers/useFetchData'
import emailjs from '@emailjs/browser'
import 'yup-phone'
import {
  API_URL,
  BASE_URL,
  EMAIL_PUBLIC_KEY,
  EMAIL_SERVICE_ID,
  EMAIL_VERIFICATION_TEMPLATE_ID,
  multiFormHeader
} from '../../../../Constants/global'

import { setAuthUser } from '../../../../Redux/actions/authUser'
import { auth, provider, fprovider } from '../../../../socialauth/config'

import { signInWithPopup } from 'firebase/auth'

function RegistrationPage () {
  const [passwordType, setPasswordType] = useState({
    passwordType: 'password',
    eyeIconClass: 'fa fa-eye-slash eyeIcon',
    eyeColorClass: ''
  })

  function togglePassword () {
    if (passwordType.passwordType === 'password') {
      setPasswordType({
        passwordType: 'text',
        eyeIconClass: 'fa fa-eye eyeIcon',
        eyeColorClass: 'text-primary'
      })
    } else {
      setPasswordType({
        passwordType: 'password',
        eyeIconClass: 'fa fa-eye-slash eyeIcon',
        eyeColorClass: ''
      })
    }
  }

  const { data, loading, error } = useFetchData(API_URL + 'api/roles')

  const [isLoading, setIsLoading] = useState({
    isLoading: false,
    disabled: false
  })

  const [isLoadingVerify, setIsLoadingVerify] = useState({
    isLoading: false,
    disabled: false
  })

  const [apierror, setApierror] = useState('')
  const [errorclass, setErrorclass] = useState('error text-danger')

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    user_role_id: 3,
    password: '',
    confirm_password: ''
  }
  const phoneRegExp =
    /^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/

  const validateSchema = Yup.object().shape({
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email format').required('Required'),
    phone_number: Yup.string().matches(
      phoneRegExp,
      'Phone number is not valid'
    ),

    password: Yup.string().required('Required').min('8'),
    confirm_password: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match'
    )
  })

  const onSubmit = values => {
    setIsLoading({
      isLoading: true,
      disabled: true
    })
    console.log(values)

    var form = new FormData()
    form.append('first_name', values.first_name)
    form.append('last_name', values.last_name)
    form.append('email', values.email)
    form.append('phone_number', values.phone_number)
    form.append('role', values.user_role_id)
    form.append('password', values.password)

    axios({
      method: 'POST',
      url: API_URL + 'api/create_user',
      data: form,
      headers: multiFormHeader
    }).then(response => {
      if (response.data.status === 200) {
        setIsLoading({
          isLoading: false,
          disabled: false
        })
        setApierror(response.data.message)

        setErrorclass('error text-success')

        var templateParams = {
          name: values.first_name,
          to_email: values.email,
          remember_pin: response.data.code,
          email_verify_url: BASE_URL + '/verify/' + response.data?.user_id
        }
        localStorage.setItem('remember_pin', response.data.code)

        emailjs
          .send(
            EMAIL_SERVICE_ID,
            EMAIL_VERIFICATION_TEMPLATE_ID,
            templateParams,
            EMAIL_PUBLIC_KEY
          )
          .then(result => {})

        setTimeout(function () {
          window.location.href = '/verify/' + response.data?.user_id
        }, 3000)
      } else {
        setIsLoading({
          isLoading: false,
          disabled: false
        })
        setApierror(response.data.message)
        setErrorclass('error text-danger')

        if (response.data.message === 'Please verify your account.') {
          localStorage.setItem('user_id', response.data.user_id)
          localStorage.setItem('name', values.first_name)
          localStorage.setItem('to_email', values.email)
          localStorage.setItem('remember_pin', response.data.code)
        }
      }
    })
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validateSchema
  })

  function sendVerification () {
    setIsLoadingVerify({
      isLoading: true,
      disabled: true
    })
    axios({
      method: 'GET',
      url:
        API_URL +
        `api/resend_verification_code/${localStorage.getItem('user_id')}`,

      headers: multiFormHeader
    }).then(response => {
      if (response.data.status === 200) {
        setIsLoadingVerify({
          isLoading: false,
          disabled: false
        })
        // ------------------------------------------------
        var templateParams = {
          name: localStorage.getItem('name'),
          to_email: localStorage.getItem('to_email'),
          remember_pin: response.data.code,
          email_verify_url:
            BASE_URL + 'verify/' + localStorage.getItem('user_id')
        }
        // alert(localStorage.getItem("name"));
        // alert(localStorage.getItem("to_email"));
        // alert(templateParams.remember_pin);
        // alert(templateParams.email_verify_url);

        emailjs
          .send(
            EMAIL_SERVICE_ID,
            EMAIL_VERIFICATION_TEMPLATE_ID,
            templateParams,
            EMAIL_PUBLIC_KEY
          )
          .then(result => {})
        // ---------------------------------------------------------
        window.location.href = `/verify/${localStorage.getItem('user_id')}`
      } else {
        setIsLoadingVerify({
          isLoading: false,
          disabled: false
        })
      }
    })
  }

  //  Google Signup Button

  const [googleLogin, setGoogleLogin] = useState('')
  const handleSocialLogin = () => {
    signInWithPopup(auth, provider).then(data => {
      if (data) {
        console.log(data.user)
        var form = new FormData()
        form.append('email', data.user.email)
        form.append('first_name', data.user.displayName)
        form.append('last_name', '')
        form.append(
          'phone_number',
          data.user.phoneNumber ? data.user.phoneNumber : ''
        )
        form.append('role', 5)
        form.append('password', '')
        form.append('provider_id', data.user.uid)

        axios({
          method: 'POST',
          url: API_URL + 'auth/socialLogin',
          data: form,
          headers: multiFormHeader
        }).then(response => {
          if (response.data.status === 200) {
            setIsLoading({
              isLoading: false,
              disabled: false
            })
            // localStorage.setItem("token", response.data["token"]);
            localStorage.setItem('token', response.data.token)
            localStorage.setItem('user', response.data.data)
            localStorage.setItem('user_type', response.data?.data.user_type)
            if (response.data?.data.user_type === 'Agent') {
              localStorage.setItem(
                'agency_profile_id',
                response.data?.data.agency_profile.id
              )
            }
            // response.data.data.agency_profile.id

            setAuthUser()

            // localStorage.setItem("role_id", response.data.data["role"]);
            localStorage.setItem(
              'total_favorites',
              response.data.total_favorites
            )
            // localStorage.setItem("user_id", response.data.data["id"]);

            // alert(response.data.user_id);
            window.location.href = '/'
          } else {
            setIsLoading({
              isLoading: false,
              disabled: false
            })
            setApierror(response.data.message)
            localStorage.setItem('user_id', response.data.user_id)
          }
        })
      }
      // console.log(data.user)
      // setGoogleLogin(data.user.email)
      // console.log(googleLogin)
    })
  }

  return (
    <section className='auth-section space-ptb'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-md-6 order-md-1 order-2'>
            <div className='auth-column shadow p-4'>
              <div className='login-heading-section'>
                <h4 className='text-heading'>Create Account</h4>
                <p>It’s quick and easy.</p>
              </div>
              <form
                className='form-row mt-4 mb-2 align-items-center'
                onSubmit={formik.handleSubmit}
              >
                <div className='form-group col-sm-6'>
                  <input
                    type='first_name'
                    className='form-control'
                    id='first_name'
                    name='first_name'
                    placeholder='First Name'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.first_name}
                  />
                  {formik.touched.first_name && formik.errors.first_name ? (
                    <div className='error text-danger'>
                      {formik.errors.first_name}
                    </div>
                  ) : null}
                </div>
                <div className='form-group col-sm-6'>
                  <input
                    type='last_name'
                    className='form-control'
                    id='last_name'
                    name='last_name'
                    placeholder='Last Name'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.last_name}
                  />
                  {formik.touched.last_name && formik.errors.last_name ? (
                    <div className='error text-danger'>
                      {' '}
                      {formik.errors.last_name}{' '}
                    </div>
                  ) : null}
                </div>
                <div className='form-group col-sm-12'>
                  <input
                    type='email'
                    className='form-control'
                    aria-describedby='emailHelp'
                    id='email'
                    name='email'
                    placeholder='Email Address'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className='error text-danger'>
                      {' '}
                      {formik.errors.email}{' '}
                    </div>
                  ) : null}
                </div>

                <div className='form-group col-sm-12'>
                  <input
                    type='phone_num'
                    className='form-control'
                    id='phone_number'
                    name='phone_number'
                    placeholder='Phone Number'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.phone_number}
                  />
                  {formik.touched.phone_number && formik.errors.phone_number ? (
                    <div className='error text-danger'>
                      {' '}
                      {formik.errors.phone_number}{' '}
                    </div>
                  ) : null}
                </div>
                <div className='form-group col-sm-12'>
                  <select
                    type='user_type'
                    className='form-control'
                    id='user_role_id'
                    name='user_role_id'
                    placeholder='Select user type'
                    onChange={formik.handleChange}
                    value={formik.values.user_role_id}
                  >
                    {data?.data.map(item => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.title}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div className='form-group col-sm-12'>
                  <input
                    type={passwordType.passwordType}
                    className='form-control'
                    id='password'
                    name='password'
                    placeholder='Password'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className='error text-danger'>
                      {' '}
                      {formik.errors.password}{' '}
                    </div>
                  ) : null}
                </div>
                <div className='form-group col-sm-12 iconIn'>
                  <input
                    type={passwordType.passwordType}
                    className='form-control'
                    id='confirm_password'
                    name='confirm_password'
                    placeholder='Confirm Password'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.confirm_password}
                  />
                  <span
                    onClick={togglePassword}
                    type='button'
                    className={passwordType.eyeColorClass}
                  >
                    {' '}
                    <i class={passwordType.eyeIconClass}></i>
                  </span>
                  {formik.touched.confirm_password &&
                  formik.errors.confirm_password ? (
                    <div className='error text-danger'>
                      {' '}
                      {formik.errors.confirm_password}{' '}
                    </div>
                  ) : null}
                </div>
                <div className='col-sm-12'>
                  {apierror ? <p className={errorclass}> {apierror} </p> : null}
                  {apierror === 'Please verify your account.' ? (
                    <a
                      href='javascript:void(0);'
                      onClick={sendVerification}
                      disabled={isLoadingVerify.disabled}
                    >
                      <p>
                        {' '}
                        clich here to verify
                        <span
                          hidden={isLoadingVerify.disabled - 1}
                          className='spinner-border spinner-border-sm ml-2'
                          role='status'
                          aria-hidden='true'
                        ></span>
                      </p>
                    </a>
                  ) : null}
                </div>
                <div className='col-sm-12 mt-2 al text-center '>
                  <button
                    disabled={isLoading.disabled}
                    type='submit'
                    className='btn btn-primary button_size '
                  >
                    Sign up
                    <span
                      hidden={isLoading.disabled - 1}
                      className='spinner-border spinner-border-sm ml-2'
                      role='status'
                      aria-hidden='true'
                    ></span>
                  </button>
                </div>

                <div className='col-sm-12  mt-2 text-center'>OR</div>
                <div className='col-sm-12 mt-3 text-center cursor-pointer'>
                  <img
                    src='https://jocdn.sfo3.cdn.digitaloceanspaces.com/classified_images%2Fwebsite_assets%2Fimages%2Fgoogle_signup_button.png'
                    onClick={handleSocialLogin}
                    className='image_size '
                    alt='Google Signup'
                  />
                </div>

                <div className='col-sm-12 text-center mt-3'>
                  <ul className='list-unstyled mb-1 mt-sm-0 mt-3'>
                    <li className='mr-1'>
                      <a href='/login'>
                        <b>Already Registered User? Login</b>
                      </a>
                    </li>
                  </ul>
                </div>
              </form>
            </div>
          </div>
          <div className='col-md-6 order-md-2 order-1 mb-md-0 mb-3'>
            <img
              className='img-fluid w-100 text-center'
              src={
                BASE_URL +
                'classified_assets/images/handymen-images/auth/signup-vector.svg'
              }
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default RegistrationPage
