import React, { useState, useEffect } from 'react'
import axios from 'axios'
import PropertyListingRow from './Properties/PropertyListingRow'

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useParams,
  Switch
} from 'react-router-dom'

import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  multiFormHeader
} from '../../Constants/global'

import 'bootstrap-less'

const NewlyListedProperties = () => {
  const [propertyData, setPropertyData] = useState([])

  const property_thumbnail =
    'https://cdn.jagahonline.com/properties/275906/front_image-61b98b34e402a-thumb.jpeg'

  useEffect(() => {
    function fetchProperties () {
      axios({
        method: 'GET',
        url: localStorage.getItem('token')
          ? API_URL + 'api/latest_properties/' + localStorage.getItem('user_id')
          : API_URL + 'api/latest_properties/0',
        headers: multiFormHeader
      }).then(response => {
        if (response.status === 200) {
          console.log('total records ' + response.data.total_records)
          setPropertyData(response.data?.data)
        }
        if (response.status === 400) {
          alert('Internet is not available, try again.')
        }
      })
    }

    fetchProperties()
  }, [])

  return (
    <>
      <section class='space-ptb propertyListingRowSection'>
        <div class='container'>
          <div class='row justify-content-center'>
            <div class='col-lg-8'>
              <div class='section-title text-center'>
                <h2>Newly listed properties</h2>
                <p>Find your dream home from our Newly added properties</p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12 col-md-12'>
              <PropertyListingRow propertyData={propertyData} />
            </div>
          </div>
        </div>
      </section>
      {/* <!--Newly Listed Properties--> */}
    </>
  )
}

export default NewlyListedProperties
