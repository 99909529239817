import React from 'react'

function NotFound() {
  return (
      <section className="space-ptb bg-holder">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-6">
              <div className="error-404">
                <h1>404</h1>
                <strong>Oops – no one seems to be home.</strong>
                <span>In the meantime try a <a href="/"> search for homes </a> or <a href="/properties">check more properties</a></span>
              </div>
            </div>
            <div className="col-md-6 text-center mt-5 mt-md-0 position-relative overflow-hidden">
              <img className="img-fluid house-animation" src="classified_assets/images/error/01.png" alt="" />
              <img className="img-fluid cloud cloud-01" src="classified_assets/images/error/cloud-01.png" alt="" />
              <img className="img-fluid cloud cloud-02" src="classified_assets/images/error/cloud-02.png" alt="" />
              <img className="img-fluid cloud cloud-03" src="classified_assets/images/error/cloud-03.png" alt="" />
              <img className="img-fluid cloud cloud-04" src="classified_assets/images/error/cloud-04.png" alt="" />
              <img className="img-fluid mt-5" src="classified_assets/images/error/02.png" alt="" />
            </div>
          </div>
        </div>
      </section>
  )
}

export default NotFound