import React, { useState } from 'react'

import {
  API_URL,
  BASE_URL,
  EMAIL_PUBLIC_KEY,
  EMAIL_SERVICE_ID,
  EMAIL_VERIFICATION_TEMPLATE_ID,
  multiFormHeader
} from '../../Constants/global'

import CryptoJS from 'crypto-js'
import crypto from 'crypto-js'
import hmacSHA256 from 'crypto-js/hmac-sha256'
import hmacSHA512 from 'crypto-js/hmac-sha512'
import Base64 from 'crypto-js/enc-base64'
import enc_utf8 from 'crypto-js/enc-utf8'
import Utf8 from 'crypto-js/enc-utf8'
import { sha256 } from 'js-sha256'
import { Component } from 'react'

const JAZZCASH_HTTP_POST_URL =
  'https://sandbox.jazzcash.com.pk/ApplicationAPI/API/2.0/Purchase/DoMWalletTransaction'
const INTEGRITY_KEY = '7uxz91ax8x'
var arr = []
var hashString = ''

function JazzCash () {
  // state = {
  //   pp_Language: 'EN',
  //   pp_MerchantID: 'MC51963',
  //   pp_SubMerchantID: '',
  //   pp_Password: '7601818w99',
  //   pp_TxnRefNo: '',
  //   pp_MobileNumber: '03232430139',
  //   pp_CNIC: '345678',
  //   pp_Amount: '10000',
  //   pp_TxnType: 'MWALLET',
  //   pp_DiscountedAmount: '',
  //   pp_TxnCurrency: 'PKR',
  //   pp_TxnDateTime: '',
  //   pp_BillReference: 'BillRef',
  //   pp_Description: 'Hello',
  //   pp_TxnExpiryDateTime: '',
  //   pp_SecureHash: '',
  //   ppmpf_1: '',
  //   ppmpf_2: '',
  //   ppmpf_3: '',
  //   ppmpf_4: '',
  //   ppmpf_5: ''
  // }

  // secureHash = data => {
  //   // console.log(JSON.stringify(data));
  //   // → '{"b":"foo","c":"bar","a":"baz"}'

  //   const ordered = Object.keys(data)
  //     .sort()
  //     .reduce((obj, key) => {
  //       obj[key] = data[key]
  //       return obj
  //     }, {})

  //   // console.log(JSON.stringify(ordered));

  //   var hash = ''
  //   Object.entries(ordered).forEach(([key, value]) => {
  //     if (value !=== '') {
  //       hash += '&' + value
  //     }
  //   })
  //   //  console.log(hash);
  //   return hash
  // }

  // convertToSHA = async string => {
  //   await sha256(string).then(hash => {
  //     console.log(hash)
  //   })
  // }

  function submitForm () {
    CalculateHash()
    var IntegritySalt = document.getElementsByName('salt')[0].value
    var hash = CryptoJS.HmacSHA256(
      document.getElementById('hashValuesString').value,
      IntegritySalt
    )
    document.getElementsByName('pp_SecureHash')[0].value = hash + ''

    console.log('string: ' + hashString)
    console.log('hash: ' + document.getElementsByName('pp_SecureHash')[0].value)

    document.jsform.submit()
  }

  function CalculateHash () {
    var IntegritySalt = document.getElementsByName('salt')[0].value

    hashString += IntegritySalt + '&'

    if (document.getElementsByName('pp_Amount')[0].value !== '') {
      hashString += document.getElementsByName('pp_Amount')[0].value + '&'
    }

    if (document.getElementsByName('pp_BillReference')[0].value !== '') {
      hashString +=
        document.getElementsByName('pp_BillReference')[0].value + '&'
    }

    //    For Card Tokenization 2.0.Uncomment below 5 commented fields pp_IsRegisteredCustomer, pp_TokenizedCardNumber, pp_CustomerID, pp_CustomerEmail, pp_CustomerMobile

    //if (document.getElementsByName("pp_CustomerEmail")[0].value !== '') {
    //    hashString += document.getElementsByName("pp_CustomerEmail")[0].value + '&';
    //}
    //if (document.getElementsByName("pp_CustomerID")[0].value !== '') {
    //    hashString += document.getElementsByName("pp_CustomerID")[0].value + '&';
    //}
    //if (document.getElementsByName("pp_CustomerMobile")[0].value !== '') {
    //    hashString += document.getElementsByName("pp_CustomerMobile")[0].value + '&';
    //}

    if (document.getElementsByName('pp_Description')[0].value !== '') {
      hashString += document.getElementsByName('pp_Description')[0].value + '&'
    }

    //                    if (document.getElementsByName("pp_IsRegisteredCustomer")[0].value !== '') {
    //    hashString += document.getElementsByName("pp_IsRegisteredCustomer")[0].value + '&';
    //}

    if (document.getElementsByName('pp_Language')[0].value !== '') {
      hashString += document.getElementsByName('pp_Language')[0].value + '&'
    }
    if (document.getElementsByName('pp_MerchantID')[0].value !== '') {
      hashString += document.getElementsByName('pp_MerchantID')[0].value + '&'
    }
    if (document.getElementsByName('pp_Password')[0].value !== '') {
      hashString += document.getElementsByName('pp_Password')[0].value + '&'
    }
    if (document.getElementsByName('pp_ReturnURL')[0].value !== '') {
      hashString += document.getElementsByName('pp_ReturnURL')[0].value + '&'
    }
    if (document.getElementsByName('pp_SubMerchantID')[0].value !== '') {
      hashString +=
        document.getElementsByName('pp_SubMerchantID')[0].value + '&'
    }

    //                    if (document.getElementsByName("pp_TokenizedCardNumber")[0].value !== '') {
    //    hashString += document.getElementsByName("pp_TokenizedCardNumber")[0].value + '&';
    //}

    if (document.getElementsByName('pp_TxnCurrency')[0].value !== '') {
      hashString += document.getElementsByName('pp_TxnCurrency')[0].value + '&'
    }
    if (document.getElementsByName('pp_TxnDateTime')[0].value !== '') {
      hashString += document.getElementsByName('pp_TxnDateTime')[0].value + '&'
    }
    if (document.getElementsByName('pp_TxnExpiryDateTime')[0].value !== '') {
      hashString +=
        document.getElementsByName('pp_TxnExpiryDateTime')[0].value + '&'
    }
    if (document.getElementsByName('pp_TxnRefNo')[0].value !== '') {
      hashString += document.getElementsByName('pp_TxnRefNo')[0].value + '&'
    }

    if (document.getElementsByName('pp_TxnType')[0].value !== '') {
      hashString += document.getElementsByName('pp_TxnType')[0].value + '&'
    }

    if (document.getElementsByName('pp_Version')[0].value !== '') {
      hashString += document.getElementsByName('pp_Version')[0].value + '&'
    }
    if (document.getElementsByName('ppmpf_1')[0].value !== '') {
      hashString += document.getElementsByName('ppmpf_1')[0].value + '&'
    }
    if (document.getElementsByName('ppmpf_2')[0].value !== '') {
      hashString += document.getElementsByName('ppmpf_2')[0].value + '&'
    }
    if (document.getElementsByName('ppmpf_3')[0].value !== '') {
      hashString += document.getElementsByName('ppmpf_3')[0].value + '&'
    }
    if (document.getElementsByName('ppmpf_4')[0].value !== '') {
      hashString += document.getElementsByName('ppmpf_4')[0].value + '&'
    }
    if (document.getElementsByName('ppmpf_5')[0].value !== '') {
      hashString += document.getElementsByName('ppmpf_5')[0].value + '&'
    }

    hashString = hashString.slice(0, -1)
    document.getElementById('hashValuesString').value = hashString
  }

  // componentDidMount = async () => {
  //   var date = new Date()
  //   date =
  //     date.getFullYear() +
  //     ('0' + date.getMonth()).slice(-2) +
  //     ('0' + date.getDate()).slice(-2) +
  //     ('0' + date.getHours()).slice(-2) +
  //     ('0' + date.getMinutes()).slice(-2) +
  //     ('0' + date.getSeconds()).slice(-2)
  //   var date1 = new Date()
  //   date1.setHours(date1.getHours() + 1)
  //   date1 =
  //     date1.getFullYear() +
  //     ('0' + date1.getMonth()).slice(-2) +
  //     ('0' + date1.getDate()).slice(-2) +
  //     ('0' + date1.getHours()).slice(-2) +
  //     ('0' + date1.getMinutes()).slice(-2) +
  //     ('0' + date1.getSeconds()).slice(-2)
  //   var tXID = 'T' + date

  //   this.setState({
  //     pp_TxnDateTime: date,
  //     pp_TxnExpiryDateTime: date1,
  //     pp_TxnRefNo: 'T' + date
  //     // pp_BillReference: 'T' + date,
  //   })

  //   var hash = this.secureHash(this.state)
  //   hash = INTEGRITY_KEY + hash //Integritykey + hashString
  //   console.log('HASH======> ', hash)

  //   const hmacDigest = hmacSHA256(hash, INTEGRITY_KEY).toString()
  //   console.log('SecureHash======> ', hmacDigest)

  //   // const hmacDigest11 = Utf8.stringify(hmacSHA256(hash, INTEGRITY_KEY))
  //   // let encData = CryptoJS.enc.Utf8.stringify(hash, INTEGRITY_KEY)
  //   // console.log('SecureHash11======> ', encData);

  //   // console.log("HASH ,", hash);
  //   this.setState({
  //     pp_SecureHash: hmacDigest
  //   })

  //   console.log(this.state.pp_SecureHash)

  //   // await console.log("SecureHash1 ", this.state);

  //   fetch(
  //     'https://sandbox.jazzcash.com.pk/ApplicationAPI/API/2.0/Purchase/DoMWalletTransaction',
  //     {
  //       method: 'POST',
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify(this.state)
  //     }
  //   )
  //     .then(function (response) {
  //       console.log(response.data)
  //       return response.json()
  //     })
  //     .then(function (data) {
  //       console.log(data.pp_ResponseMessage)
  //     })
  //   function submitform () {
  //     console.log('submit')
  //   }
  // }

  return (
    <div className='jsformWrapper'>
      <form
        name='jsform'
        method='post'
        action='https://sandbox.jazzcash.com.pk/CustomerPortal/transactionmanagement/merchantform/'
      >
        {/* For Card Tokenization Version should be 2.0 */}
        <div className='formFielWrapper'>
          <label className='active'>pp_Version: </label>
          <input type='text' name='pp_Version' defaultValue='1.1' />
        </div>
        <div className='formFielWrapper'>
          <label className>pp_TxnType: </label>
          <input type='text' name='pp_TxnType' defaultValue='MWALLET' />
        </div>
        {/* For Card Tokenization 2.0. Uncomment below 5 fields pp_IsRegisteredCustomer, pp_TokenizedCardNumber, pp_CustomerID, pp_CustomerEmail, pp_CustomerMobile */}

        <div>
          <div className='formFielWrapper'>
            <label className='active'>pp_IsRegisteredCustomer: </label>
            <input
              type='text'
              name='pp_IsRegisteredCustomer'
              defaultValue='No'
            />
          </div>
          <div className='formFielWrapper'>
            <label className>pp_TokenizedCardNumber: </label>
            <input type='text' name='pp_TokenizedCardNumber' defaultValue />
          </div>
          <div className='formFielWrapper'>
            <label className>pp_CustomerID: </label>
            <input type='text' name='pp_CustomerID' defaultValue />
          </div>
          <div className='formFielWrapper'>
            <label className>pp_CustomerEmail: </label>
            <input type='text' name='pp_CustomerEmail' defaultValue />
          </div>
          <div className='formFielWrapper'>
            <label className>pp_CustomerMobile: </label>
            <input type='text' name='pp_CustomerMobile' defaultValue />
          </div>
        </div>

        <div className='formFielWrapper'>
          <label className='active'>pp_MerchantID: </label>
          <input type='text' name='pp_MerchantID' defaultValue='MC51963' />
        </div>
        <div className='formFielWrapper'>
          <label className='active'>pp_Language: </label>
          <input type='text' name='pp_Language' defaultValue='EN' />
        </div>
        <div className='formFielWrapper'>
          <label className>pp_SubMerchantID: </label>
          <input type='text' name='pp_SubMerchantID' defaultValue />
        </div>
        <div className='formFielWrapper'>
          <label className='active'>pp_Password: </label>
          <input type='text' name='pp_Password' defaultValue='7601818w99' />
        </div>
        <div className='formFielWrapper'>
          <label className='active'>pp_TxnRefNo: </label>
          <input
            type='text'
            name='pp_TxnRefNo'
            id='pp_TxnRefNo'
            defaultValue='T20230103101717'
          />
        </div>
        <div className='formFielWrapper'>
          <label className='active'>pp_Amount: </label>
          <input type='text' name='pp_Amount' defaultValue={500} />
        </div>
        <div className='formFielWrapper'>
          <label className>pp_DiscountedAmount: </label>
          <input type='text' name='pp_DiscountedAmount' defaultValue />
        </div>
        <div className='formFielWrapper'>
          <label className>pp_DiscountBank: </label>
          <input type='text' name='pp_DiscountBank' defaultValue />
        </div>
        <div className='formFielWrapper'>
          <label className='active'>pp_TxnCurrency: </label>
          <input type='text' name='pp_TxnCurrency' defaultValue='PKR' />
        </div>
        <div className='formFielWrapper'>
          <label className='active'>pp_TxnDateTime: </label>
          <input
            type='text'
            name='pp_TxnDateTime'
            id='pp_TxnDateTime'
            defaultValue={20230103101717}
          />
        </div>
        <div className='formFielWrapper'>
          <label className='active'>pp_TxnExpiryDateTime: </label>
          <input
            type='text'
            name='pp_TxnExpiryDateTime'
            id='pp_TxnExpiryDateTime'
            defaultValue={20230104101717}
          />
        </div>
        <div className='formFielWrapper'>
          <label className='active'>pp_BillReference: </label>
          <input type='text' name='pp_BillReference' defaultValue='billRef' />
        </div>
        <div className='formFielWrapper'>
          <label className='active'>pp_Description: </label>
          <input
            type='text'
            name='pp_Description'
            defaultValue='Description of transaction'
          />
        </div>
        <div className='formFielWrapper'>
          <label className='active'>pp_ReturnURL: </label>
          <input
            type='text'
            name='pp_ReturnURL'
            defaultValue='https://revamp.jotesting.com/callback_payment'
          />
        </div>
        <div className='formFielWrapper'>
          <label className='active'>pp_SecureHash: </label>
          <input type='text' name='pp_SecureHash' defaultValue={hashString} />
        </div>
        <div className='formFielWrapper'>
          <label className='active'>ppmpf 1: </label>
          <input type='text' name='ppmpf_1' defaultValue={1} />
        </div>
        <div className='formFielWrapper'>
          <label className='active'>ppmpf 2: </label>
          <input type='text' name='ppmpf_2' defaultValue={2} />
        </div>
        <div className='formFielWrapper'>
          <label className='active'>ppmpf 3: </label>
          <input type='text' name='ppmpf_3' defaultValue={3} />
        </div>
        <div className='formFielWrapper'>
          <label className='active'>ppmpf 4: </label>
          <input type='text' name='ppmpf_4' defaultValue={4} />
        </div>
        <div className='formFielWrapper'>
          <label className='active'>ppmpf 5: </label>
          <input type='text' name='ppmpf_5' defaultValue={5} />
        </div>
        <button type='button' onClick={submitForm}>
          Submit
        </button>
      </form>
      <input type='hidden' name='salt' defaultValue='7uxz91ax8x' />
      <br />
      <br />
      <div className='formFielWrapper' style={{ marginBottom: '2rem' }}>
        <label className>Hash values string: </label>
        <input type='text' id='hashValuesString' defaultValue />
        <br />
        <br />
      </div>
    </div>
  )
}

export default JazzCash
