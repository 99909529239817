import React from 'react'
import {Jaga360} from './components/Jaga360';
import {CellNoForm} from './components/CellNoForm';
import "../css/tourJO.css";

function TourJo() {
  return (
    <>
      <main className="font-weight tourJoMain">
        <div id="PageContainer">
          <main className="pro-seller-main">
            <div className="top-header-heading text-center bg-white-f2 py-3">
              <h1 className="jo-main-heading top-header-heading-inner">Jagah360</h1>
              <h6 className="mb-0">Banaye Apni Zindagi Asaan! </h6>
            </div>
            <section className="homepage-hero space-pb">
              <div className="grid grid--equal-height grid--vertically-centered grid-three-quarter">
                <div className="grid__item grid__item--desktop-up-half homepage-hero__content">
                  <div className="section-heading section-heading--mobile-up-align-left gutter-bottom--reset">
              <h1 className="section-heading__heading gutter-bottom">
                Stand out with high definition pictures,<br /> accurate videography and a 360 virtual
                tour!
              </h1>
             {/* ----------------CellNoFormComponent---------------------- */}
             {/*<CellNoForm />*/}
            </div>
          </div>
        </div>
        <div className="picture--cover homepage-hero__image">
          <div className="tour-header-video">
            <video src='classified_assets/images/tour-jo/Mobile_360.mp4' autoPlay playsInline frameBorder={0} muted loop className="homepage-hero__image-tag w-100">
              <source data-src="classified_assets/images/tour-jo/Mobile_360.mp4" type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
          </div>
        </div>
      </section>
    </main>
  </div>
  <section className="target-audience space-pb">
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-lg-12 col-sm-12">
          <div className="target-audience-headings text-center">
            <h2 className="sub-heading-target-audience">What is Jagah360?</h2>
            <h6 className="heading-target-audience tour-hed">We provide a 360 walk-through experience to<br />
              represent the real overview of properties put up for<br /> <strong className>RENT</strong> or <strong>SALE!</strong>
            </h6>
            <a href="/contact-us" className="btn btn-shadow mb-4 mt-2">Take a Tour!</a>
            <picture>
              <img src="classified_assets/images/tour-jo/360-JO.png" alt="360 tour" title="360 tour" className="img-fluid w-100 tour-360" />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="variations-section-one space-pb">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-6 col-lg-6 col-sm-12 position-relative">
          <div className="variations-video-globe">
            <picture>
              <img src="classified_assets/images/tour-jo/JagahTours_3.jpg" alt="360 tour" title="360 tour" className="img-fluid w-100" />
            </picture>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-sm-12">
          <div className="digital-businesss-headings text-left">
            <h2 className="sub-heading-target-audience">Hum Banaye Apka Kaam Asaan!
            </h2>
            <p>We give your property an edge with Jagah360, to help you sell and rent in no time, with
              greater profits!
            </p>
            <a href="/contact-us" className="btn target-audience-plans" tabIndex={0}>Go 360 Now ! <i className="fas fa-long-arrow-alt-right" /></a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="variations-section-second space-pb">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-6 col-lg-6 col-sm-12">
          <div className="digital-businesss-headings text-left">
            <h2 className="sub-heading-target-audience">Roz ke Property Visits se Churayen Jaan!
            </h2>
            <p>Let Jagah360 take your worries away. We give you a complete property viewing
              experience, from the comfort of your homes. </p>
            <a href="/contact-us" className="btn target-audience-plans" tabIndex={0}>Go 360 Now ! <i className="fas fa-long-arrow-alt-right" /></a>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-sm-12 position-relative">
          <picture>
            <img src="classified_assets/images/tour-jo/JagahTours_4.jpg" alt="360 tour" title="360 tour" className="img-fluid w-100" />
          </picture>
        </div>
      </div>
    </div>
  </section>
  <section className="variations-section-third space-pb">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-7 col-lg-7 col-sm-12 position-relative">
          <picture>
            <img src="classified_assets/images/tour-jo/JagahTours_5.jpg" alt="360 tour" title="360 tour" className="img-fluid w-100" />
          </picture>
        </div>
        <div className="col-md-5 col-lg-5 col-sm-12">
          <div className="digital-businesss-headings text-left">
            <h2 className="sub-heading-target-audience">Apni Listings Karen Upgrade!
            </h2>
            <p className="text-left">Get noticed amongst hundreds of listings with Jagah360. Make
              your property seem more appealing, reach a wider audience, to sell or rent faster than ever
              before!</p>
            <a href="/contact-us" className="btn target-audience-plans" tabIndex={0}>Go 360 Now ! <i className="fas fa-long-arrow-alt-right" /></a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="cta-section space-pb">
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-lg-12 col-sm-12">
          <div className="target-audience-headings text-center">
            <h2 className="sub-heading-target-audience">Dhunden Properties, Baghair Shehar Ghoomay!</h2>
            <p>Whether you’re dealing in apartments, houses, shops, or offices, you can now
              make your
              properties stand out – with ease! </p>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-4 text-center">
          <div className="heading-tour">
            <h6 className="heading-target-audience">Apartment/House</h6>
          </div>
          <div className="heading-img mb-2 easyzoom easyzoom--overlay">
            <a className href="classified_assets/images/tour-jo/jagahtours_house.jpg">
              <picture>
                <img src="classified_assets/images/tour-jo/jagahtours_house.jpg" alt="360 tour" title="360 tour" className="img-fluid w-100 mainimage" />
              </picture>
            </a>
          </div>
        </div>
        <div className="col-md-4 text-center">
          <div className="heading-tour">
            <h6 className="heading-target-audience">Shops</h6>
          </div>
          <div className="heading-img mb-2 easyzoom easyzoom--overlay">
            <a className href="classified_assets/images/tour-jo/jagahtours_shop.jpg">
              <picture>
                <img src="classified_assets/images/tour-jo/jagahtours_shop.jpg" alt="360 tour" title="360 tour" className="img-fluid w-100 mainimage" />
              </picture>
            </a>
          </div>
        </div>
        <div className="col-md-4 text-center">
          <div className="heading-tour">
            <h6 className="heading-target-audience">Offices</h6>
          </div>
          <div className="heading-img mb-2 easyzoom easyzoom--overlay">
            <a className href="classified_assets/images/tour-jo/jagahtours_office.jpg">
              <picture>
                <img src="classified_assets/images/tour-jo/jagahtours_office.jpg" alt="360 tour" title="360 tour" className="img-fluid w-100 mainimage" />
              </picture>
            </a>
          </div>
        </div>
        <a href="/contact-us" className="btn target-audience-plans" tabIndex={0}>Go 360 Now ! <i className="fas fa-long-arrow-alt-right" /></a>
      </div>
    </div>
  </section>
  <section className="cta-section space-ptb bg-white-f2">
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-lg-12 col-sm-12">
          <div className="target-audience-headings text-center">
            <h2 className="sub-heading-target-audience">Properties Bechen, ya Kiraye par Den! </h2>
            <p>Sell your properties faster using the Jagah360 feature, <br />to generate greater viewership
              and better leads. With us, deal with serious buyers and tenants only!</p>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-4 text-center">
          <div className="heading-img mb-2">
            <picture>
              <img src="classified_assets/images/tour-jo/JagahOnline_Vector_Clock.png" alt="360 tour" title="360 tour" className="img-fluid" />
            </picture>
          </div>
          <div className="heading-tour">
            <h6 className="heading-target-audience">Property Biken Aur Tezi Se! </h6>
            <p>Properties with the option of a virtual 360 tour, on average, sell 25% faster than, with less
              property visits! </p>
          </div>
        </div>
        <div className="col-md-4 text-center">
          <div className="heading-img mb-2">
            <picture>
              <img src="classified_assets/images/tour-jo//JagahOnline_Vector_Eye.png" alt="360 tour" title="360 tour" className="img-fluid" />
            </picture>
          </div>
          <div className="heading-tour">
            <h6 className="heading-target-audience">Barhayen Apne Property Views! </h6>
            <p>Add a 360 virtual tour to your listings, and witness improvement in your views and
              traffic by 30%! </p>
          </div>
        </div>
        <div className="col-md-4 text-center">
          <div className="heading-img mb-2">
            <picture>
              <img src="classified_assets/images/tour-jo/JagahOnline_Vector_360.png" alt="360 tour" title="360 tour" className="img-fluid" />
            </picture>
          </div>
          <div className="heading-tour">
            <h6 className="heading-target-audience">Banayen Apni Properties Mukhtalif! </h6>
            <p>Step up your game with a 360 virtual tour to improve the customer experience, giving you more
              revenue, and brilliant reviews! </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="variations-section-fifth space-ptb">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-7 col-lg-7 col-sm-12 position-relative">
          <picture>
            <img src="classified_assets/images/tour-jo/JagahTours_7.jpg" alt="360 tour" title="360 tour" className="img-fluid w-100" />
          </picture>
        </div>
        <div className="col-md-5 col-lg-5 col-sm-12">
          <div className="digital-businesss-headings text-left">
            <h2 className="sub-heading-target-audience">Jagah360: Travel Less, Experience More! </h2>
            <p>Download our app today, and find the home of your dreams! </p>
            <a href="/contact-us" className="btn target-audience-plans" tabIndex={0}>Get it Now ! <i className="fas fa-long-arrow-alt-right" /></a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="variations-section-fourth space-pb">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-5 col-lg-5 col-sm-12">
          <div className="digital-businesss-headings text-left">
            <h2 className="sub-heading-target-audience">Trusted by Millions of Buyers and Sellers Across
              PAKISTAN! </h2>
            <p>Don’t believe us? Hear what they have to say for our services! </p>
            <a href="/contact-us" className="btn target-audience-plans" tabIndex={0}>Get it Now ! <i className="fas fa-long-arrow-alt-right" /></a>
          </div>
        </div>
        <div className="col-md-7 col-lg-7 col-sm-12 position-relative">
          <div className="variations-video-globe">
            <video src='classified_assets/images/tour-jo/Revised-Testimonial.mp4' autoPlay playsInline frameBorder={0} muted loop className>
              <source data-src="classified_assets/images/tour-jo/Revised-Testimonial.mp4" type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="variations-section-fourth space-pb">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-6 col-lg-6 col-sm-12 position-relative text-center">
          <picture>
            <img src="classified_assets/images/tour-jo/JagahOnline_Vector.jpg" alt="360 tour" title="360 tour" className="img-fluid w-50" />
          </picture>
        </div>
        <div className="col-md-5 col-lg-5 col-sm-12">
          <div className="digital-businesss-headings text-left">
            <h2 className="sub-heading-target-audience">Still confused?
            </h2>
            <p>Reach out to our support team, and let us help you out! </p>
            <a href="/contact-us" className="btn target-audience-plans" tabIndex={0}>Call Now! <i className="fas fa-long-arrow-alt-right" /></a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="cta-section space-pb">
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-lg-12 col-sm-12">
          <div className="target-audience-headings text-center">
            <h2 className="sub-heading-target-audience">Bhag Dor ki Pareshaniyon se Jaan Churayen!
            </h2>
            <p>Digitalize your entire property viewing experience with Jagah360.
            </p>
          </div>
        </div>
      </div>
      {/* ----------Jaga360Component--------- */}
      <Jaga360 />
    </div>
  </section>
</main>
</>
  )
}

export default TourJo;