import React from 'react'

export const Banner = () => {
  return (
    <>
    <section className="contact-banner section-sticky banner bg-holder bg-overlay-black-30">
  <div className="hero-header">
    <picture>
      <img className="hero-header-main-img" alt="header" src="https://cdn.jagahonline.com/assets/frontend-assets/images/careers/cover.png" />
    </picture>
  </div>
  <div className="container">
    <div className="row">
      <div className="col-12 mob-center margin-search-pfy updated-img-pfy">
        <h1 className="text-white text-center mb-2 header-heading global-padding">
          Get in Touch<span>  With Us</span>
        </h1>
      </div>
    </div>
  </div>
</section>

    </>
  )
}
