import React, { Fragment } from "react";
import UserProfileHeader from "../../Components/Header/joclassified/UserProfileHeader";
import MyOrderTabs from "./components/myOrder/MyOrderTabs";
import OrderDetail from "./components/myOrder/OrderDetail";

function MyOrderLanding() {
  return (
    <Fragment>
      <UserProfileHeader />

      <section className="my-order-section space-ptb">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <MyOrderTabs />
            </div>
            {/* <div className="col-md-8">
              <OrderDetail />
            </div> */}
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default MyOrderLanding;
