import { React, useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import axios from "axios";
import { API_URL, BASE_URL } from "../../../Constants/global";

function ListingBannerVertical() {
  const [bannerArr, setBannerArr] = useState([]);

  useEffect(() => {

    async function getBanner() {
      const response = await axios({
        method: "GET",
        url: API_URL + "banner/1",
      });
      if (response.status === 200) {
        setBannerArr(response.data.data.images);
        //   console.log(response.data.data.images[0].fileurl);
      } else {
        setBannerArr([]);
      }
    }

    getBanner();
  }, []);
  
  const options = {
    nav: true,
    navText: [
      "<div className='nav-btn prev-slide'></div>",
      "<div className='nav-btn next-slide'></div>",
    ],
    dots: true,
  };

  return (
    <div className="col-lg-4">

      {bannerArr ?

      <OwlCarousel
        className="advertisment-slider owl-theme"
        items={1}
        margin={10}
        stagePadding={10}
      >
        {/* ----------------------------------------------------- */}
        {bannerArr.map((item) => {
          const bannerURL = item.fileurl;
          return (
            <div className="advertisment-slider-item">
              <div className="advertisment-slider-inner compact">
                <a className="adds-sec" href="" target="_blank">
                  <img src={bannerURL} className="img-fluid" alt="" />
                </a>
              </div>
            </div>
          );
        })}

        {/* //----------------------------------------- */}
      </OwlCarousel>

      : 

      <div className="col-lg-4">
            <OwlCarousel className='advertisment-slider owl-theme' items={1} margin={10} stagePadding={10}>
                <div className="advertisment-slider-item">
                    <div className="advertisment-slider-inner compact">
                        <a className="adds-sec" href="" target="_blank">
                            <img
                                src="https://cdn.jagahonline.com/advertisements/101/grand-xcito-2-jo-353x550-60a67a959b6b8.png"
                                className="img-fluid" alt=""/>
                        </a>
                    </div>
                </div>
            </OwlCarousel>
        </div>
     

}
    </div>
  );
}

export default ListingBannerVertical;
