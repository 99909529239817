import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { API_URL, jsonHeader } from '../../Constants/global'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import browseCategory from '../../Redux/reducers/browseCategory'
import { setBrowseCategory } from '../../Redux/actions/browseCategory'
// import useLocalStorage from '@/hooks/useLocalStorage'

const BrowseByCategory = ({ setBrowseCategory, browseCategory }) => {
  // const [browse_category, setBrowseCategory] = useState([]);

  var searchQueryGet = {}

  if (
    localStorage.getItem('searchQuery') !== undefined &&
    localStorage.getItem('searchQuery') !== ''
  ) {
    searchQueryGet = JSON.parse(localStorage.getItem('searchQuery')) || {
      property_purpose: 1,
      city_id: 0,
      area_id: 0,
      property_type: 1,
      min_area: 0,
      max_area: 0,
      price_min: 0,
      price_max: 0,
      beds: 0,
      keywords: ''
    }
  } else {
    searchQueryGet = {
      property_purpose: 1,
      city_id: 0,
      area_id: 0,
      property_type: 1,
      min_area: 0,
      max_area: 0,
      price_min: 0,
      price_max: 0,
      beds: 0,
      keywords: ''
    }
  }

  const [searchQuery, setSearchQuery] = useState(searchQueryGet)

  useEffect(() => {
    async function fetchSearchBy () {
      // const response = await axios({
      //   method: "GET",
      //   url: API_URL + "api/browsebycategory",
      //   jsonHeader,
      // });
      // if (response.status === 200) {
      //   setBrowseCategory(response.data?.data);
      // }
      setBrowseCategory()
    }

    fetchSearchBy()
  }, [])

  if (browseCategory.length === 0) {
    return (
      <section className='space-ptb'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-12'>
              <photo className='shine w-100 shimmerphoto'></photo>
            </div>
            <div className='col-md-12 mt-1'>
              <lines className='shine w-100 shimmerlines'></lines>
            </div>
          </div>
        </div>
      </section>
    )
  }

  const propertTypeHandler = e => {
    console.log(e.target.getAttribute('data-propertytype').split('_')[0])

    setSearchQuery({
      ...searchQuery,
      property_type: parseInt(
        e.target.getAttribute('data-propertytype').split('_')[1]
      )
    })

    console.log(searchQuery)

    const searchQuery1 = {
      property_type: e.target.getAttribute('data-propertytype').split('_')[1],
      city_id: 0,
      property_purpose: 0
    }

    localStorage.setItem('searchQuery', JSON.stringify(searchQuery1))

    window.location =
      '/' +
      e.target.getAttribute('data-propertytype').split('_')[0] +
      '/Any-Purpose/All-Cities'
  }

  return (
    <>
      {/* <!-- Browse properties --> */}
      <section class='space-ptb'>
        <div class='container'>
          <div class='row justify-content-center'>
            <div class='col-lg-8'>
              <div class='section-title text-center'>
                <h2>Browse by category</h2>
                <p>
                  To browse and buy in your areas of interest, look for
                  properties by category.
                </p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='unified-listing-categories-main'>
              <div className='bg-unified-categories'>
                {browseCategory.map(item => {
                  // {item.icon}  for image icon
                  return (
                    <div className='unified-listing-categories-item'>
                      <a
                        className='unified-listing-categories-item-href category-icon'
                        href='javascript:void(0);'
                        data-propertytype={item.title + '_' + item.id}
                        onClick={propertTypeHandler}
                      >
                        <div className='main-category-item'>
                          <img
                            className='img-fluid sub_categories-svg-icon'
                            data-propertytype={item.title + '_' + item.id}
                            src={item.icon}
                            loading='lazy'
                          />
                        </div>
                        <h6 className='mb-0'>{item.title}</h6>
                        {/* <span>(457)</span> */}
                      </a>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--Browse properties --> */}
    </>
  )
}

BrowseByCategory.propTypes = {
  setBrowseCategory: PropTypes.func.isRequired,
  browseCategory: PropTypes.array.isRequired
}
const mapStateToProps = state => ({
  browseCategory: state.browseCategory
})
export default connect(mapStateToProps, { setBrowseCategory })(BrowseByCategory)
