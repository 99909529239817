import React, { Fragment, useEffect, useState } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { handleFavorite } from '../../../Redux/actions/favorite'
import ProjectListingCard from '../Projects/ProjectListingCard'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FeaturedProjectCard from './FeaturedProjectCard'
import { useParams } from 'react-router-dom'

import {
  API_URL,
  multiFormHeader,
  BASE_URL,
  jsonHeader,
  USER_IMAGE_NOT_FOUND_URL,
  USER_PROFILE_URL
} from '../../../Constants/global'
import { PropertyCard } from './PropertyCard'
import InquiryModal from './InquiryModal'

const PropertyListingColumn = ({ propertyData, handleFavorite }) => {
  const [projectData, setProjectData] = useState([])
  useEffect(() => {
    axios({
      method: 'GET',
      url: API_URL + 'api/featured_projects'
    }).then(response => {
      setProjectData(response.data.data)
    })
  }, [])

  const property_thumbnail =
    BASE_URL + 'classified_assets/images/placeholder/jo-placeholder.png'

  const add_to_favorite = e => {
    const response = axios({
      method: 'GET',
      url:
        API_URL +
        'generalservices/property_favorites_add/' +
        e.target.id +
        '/' +
        localStorage.getItem('user_id')
    }).then(response => {
      if (response.status === 200) {
        document.getElementById(e.target.id).className =
          document.getElementById(e.target.id).className === 'fa fa-heart'
            ? 'far fa-heart'
            : 'fa fa-heart'
        localStorage.setItem('total_favorites', response.data.total_favorites)
        handleFavorite(response.data.total_favorites)
        console.log(localStorage.getItem('total_favorites'))
      }
    })
  }

  try {
    let flag = 0

    return (
      <>
        {/* Iterate over newly_listed_pproperties data */}

        {propertyData.map((data, key) => {
          flag = key + 1
          console.log('Current Data row ' + data['city'])

          const detail_page_url = '/property/' + data.slug + '-' + data.id
          const property_detail_URL =
            '/property/' +
            data['city'].toLowerCase() +
            '-' +
            data.slug +
            '-' +
            data.id

          const userProfile = data.user_profile
          const areaUnit = data.area_unit_data

          var user_data_q = USER_IMAGE_NOT_FOUND_URL

          if (userProfile.length !== 0) {
            if (
              data.user_profile[0].profile_image === undefined ||
              data.user_profile[0].profile_image === null ||
              data.user_profile[0].profile_image === ''
            ) {
              user_data_q = USER_IMAGE_NOT_FOUND_URL
            } else {
              const mainRegExp = RegExp('https')

              if (mainRegExp.test(data.user_profile[0].profile_image)) {
                user_data_q = data.user_profile[0].profile_image
              } else {
                user_data_q =
                  USER_PROFILE_URL +
                  data.user_id +
                  '/' +
                  data.user_profile[0].profile_image
              }
            }
          }

          const profile_image_url = user_data_q

          var imageURL = property_thumbnail

          if (data.front_image !== '' && data.front_image !== 'null') {
            const mainRegExp = RegExp('https')

            if (mainRegExp.test(data.front_image)) {
              imageURL = data.front_image
            } else {
              imageURL =
                'https://cdn.jagahonline.com/properties/' +
                data.id +
                '/' +
                data.front_image.split('.')[0] +
                '-thumb.' +
                data.front_image.split('.')[1]
            }
          }

          var bath = 0
          var bed = 0

          if (data.beds_baths.length !== 0) {
            data.beds_baths.map(item => {
              try {
                if (item.bathroom_data === 'Bed Room') {
                  bed = item.value
                }

                if (item.bathroom_data === 'Bath Room') {
                  bath = item.value
                }
              } catch {}
            })
          }
          if (flag % 2 === 0 && key !== 0) {
            const index = Math.floor(Math.random() * projectData.length)
            return (
              <Fragment>
                <PropertyCard
                  data={data}
                  key={key}
                  imageURL={imageURL}
                  property_thumbnail={property_thumbnail}
                  profile_image_url={profile_image_url}
                  property_detail_URL={property_detail_URL}
                  bed={data.beds}
                  bath={data.baths}
                  areaUnit={areaUnit}
                  add_to_favorite={add_to_favorite}
                />
                <FeaturedProjectCard
                  projectData={projectData}
                  key={key}
                  index={index}
                  // index={index}
                />
              </Fragment>
            )
          } else {
            return (
              <PropertyCard
                data={data}
                key={key}
                imageURL={imageURL}
                property_thumbnail={property_thumbnail}
                profile_image_url={profile_image_url}
                property_detail_URL={property_detail_URL}
                bed={data.beds}
                bath={data.baths}
                areaUnit={areaUnit}
                add_to_favorite={add_to_favorite}
              />
            )
          }
        })}
        <InquiryModal />
      </>
    )
  } catch (err) {
    console.log('Error' + err.message)
    return (
      <>
        <div className='container'>
          Server error, Please try again later - listing column.
        </div>
      </>
    )
  }
}

export default connect(null, { handleFavorite })(PropertyListingColumn)
