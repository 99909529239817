import React from "react";
import { Navigate } from "react-router-dom";

// import {UserContext} from '../UserContext';

// const [setIsAutherized] = UserContext(UserContext)

function Logout() {
  localStorage.setItem("editSiteID", "");
  localStorage.setItem("editUserID", "");
  localStorage.setItem("editProjectID", "");
  // setIsAutherized(false);
  localStorage.setItem("token", "");
  localStorage.setItem("user_id", "");
  localStorage.setItem("email", "");
  localStorage.setItem("user_id", "");
  localStorage.setItem("profile_image", "");
  localStorage.setItem("user_name", "");
  localStorage.setItem("user_type", "");

  localStorage.setItem("first_name", "");
  localStorage.setItem("last_name", "");
  localStorage.setItem("phone_number", "");
  localStorage.setItem("role_id", "");
  localStorage.setItem("agency_profile_id", "");

  window.location.href = "/";
}

export default Logout;
