import React, { Fragment, useEffect, useState } from 'react'
import axios from 'axios'
import ReactPlayer from 'react-player'

// import ProjectOtherImages from './ProjectOtherImages'
import { API_URL, jsonHeader } from '../../../Constants/global'

import { useParams } from 'react-router-dom'
import ProjectOtherImages from './ProjectOtherImages'

function PropertyInfoCard ({ projectDetails }) {
  const project_params = useParams()
  console.log(project_params.searchquery.split('-').pop())
  const project_id = project_params.searchquery.split('-').pop()

  const [amnData, setAmnData] = useState([])

  useEffect(() => {
    function fetchProperties () {
      axios({
        method: 'GET',
        url: API_URL + 'api/fetchAmenitiesBy_Project_id/' + project_id,

        headers: jsonHeader
      }).then(response => {
        console.log(response.data)
        setAmnData(response.data?.data)
      })
    }
    fetchProperties()
  }, [])

  var appartments,
    plots,
    villas,
    shops,
    offices,
    industrial_plot = []

  const projectOffers = projectDetails.offers

  if (projectOffers !== undefined && projectOffers.length !== 0) {
    projectOffers.map((item, index) => {
      if (projectOffers[index][1] !== undefined) {
        appartments = projectOffers[index][1]
      }
      if (projectOffers[index][2] !== undefined) {
        plots = projectOffers[index][2]
      }
      if (projectOffers[index][3] !== undefined) {
        villas = projectOffers[index][3]
      }

      if (projectOffers[index][4] !== undefined) {
        shops = projectOffers[index][4]
      }

      if (projectOffers[index][5] !== undefined) {
        offices = projectOffers[index][5]
      }

      if (projectOffers[index][6] !== undefined) {
        industrial_plot = projectOffers[index][6]
      }
    })
  }

  var mapURL = ''
  const areaData = projectDetails.area
  if (areaData !== undefined && areaData.length > 0) {
    mapURL =
      'https://maps.google.com/maps?q=' + encodeURI(areaData) + '&output=embed'
  }

  return (
    <div>
      {/* ------------------- Property Info Card-------------------*/}
      <div className='card property-details-inner text-left px-3'>
        <div className='property-info mt-4'>
          <div className='row'>
            <div className='col-sm-3 mb-3 mb-sm-0'>
              <h5>Project details</h5>
            </div>
            <div className='col-sm-9'>
              <div className='row mb-3'>
                <div className='col-sm-6'>
                  <ul className='property-list list-unstyled'>
                    <li>
                      <b>Project ID:</b> {projectDetails.id}
                    </li>
                  </ul>
                </div>
                <div className='col-sm-6'>
                  <ul className='property-list list-unstyled'>
                    <li>
                      <b>Price:</b> {projectDetails.price_txt}
                    </li>
                  </ul>
                </div>
              </div>
              {/* <h6 className="text-primary">Additional details</h6>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <ul className="property-list list-unstyled mb-0">
                                            <li><b>Deposit:</b> 30%</li>
                                            <li><b>Pool Size:</b> 457 Sqft</li>
                                            <li><b>Last remodel year:</b> 1956</li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-6">
                                        <ul className="property-list list-unstyled mb-0">
                                            <li><b>Amenities:</b> Clubhouse</li>
                                            <li><b>Additional Rooms:</b> Guest Bat</li>
                                            <li><b>Equipment:</b> Grill - Gas - light</li>
                                        </ul>
                                    </div>
                                </div> */}
            </div>
          </div>
        </div>
        <hr className='mt-4 mb-4 mb-sm-5 mt-sm-5' />
        <div className='property-description'>
          <div className='row'>
            <div className='col-sm-3 mb-3 mb-sm-0'>
              <h5>Description</h5>
            </div>
            <div className='col-sm-9'>
              <div
                dangerouslySetInnerHTML={{ __html: projectDetails.description }}
              ></div>
            </div>
          </div>
        </div>
        <hr className='mt-4 mb-4 mb-sm-5 mt-sm-5' />
        <div className='property-features'>
          <div className='row'>
            <div className='col-sm-3 mb-3 mb-sm-0'>
              <h5>Features</h5>
            </div>
            <div className='col-sm-9'>
              <div className='row'>
                <div className='property-list-style-2 list-unstyled mb-0 w-100'>
                  {amnData.length
                    ? amnData.map(item => {
                        return (
                          <div className='amenity-box-feature-main mb-3'>
                            <h6 className='property-main-features-name'>
                              {item.amenity_group}
                            </h6>
                            <div className='amenity-group d-flex'>
                              {item.amenties.map(child => {
                                console.log(child.icon)
                                return (
                                  <div className='amenityInfo mr-3'>
                                    {child.icon !== null &&
                                    child.icon !== 'null' &&
                                    child.icon !== undefined &&
                                    child.icon !== '' ? (
                                      <img
                                        class='img-fluid'
                                        src={child.icon}
                                        alt={child.amenity}
                                      ></img>
                                    ) : (
                                      // <i className="fas fa-circle"></i>
                                      <img
                                        class='img-fluid'
                                        src='../../../../classified_assets/svg/components/otherempty.svg'
                                        alt={child.amenity}
                                      ></img>
                                    )}

                                    {child.amenity}
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        )
                      })
                    : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className='mt-4 mb-4 mb-sm-5 mt-sm-5' />
        <div className='property-address'>
          <div className='row'>
            <div className='col-sm-3 mb-3 mb-sm-0'>
              <h5>Address</h5>
            </div>
            <div className='col-sm-9'>
              <div className='row'>
                <div className='col-sm-6'>
                  <ul className='property-list list-unstyled mb-0'>
                    <li>
                      <b>Address:</b>
                      {/* {projectDetails.address} */}
                      {projectDetails.area + ', ' + projectDetails.city}
                    </li>

                    <li>
                      <b>Area:</b> {projectDetails.area}
                    </li>
                  </ul>
                </div>
                <div className='col-sm-6'>
                  <ul className='property-list list-unstyled mb-0'>
                    <li>
                      <b>City:</b> {projectDetails.city}
                    </li>
                    <li>
                      <b>Zip:</b> 4848494
                    </li>
                    <li>
                      <b>Country:</b> {projectDetails.country}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className='mt-4 mb-4 mb-sm-5 mt-sm-5' />
        <div className='property-floor-plans'>
          <div className='row'>
            <div className='col-sm-12 mb-3 mb-sm-0'>
              <h5>Offers</h5>
            </div>
            <div className='col-sm-12'>
              <div className='accordion-style-2' id='accordion'>
                {/* Appartments */}

                {appartments !== undefined && appartments.length !== 0 ? (
                  <div className='card'>
                    {/*Appartments Accordian*/}
                    <div className='card-header' id='headingOne'>
                      <h5 className='accordion-title mb-0'>
                        <button
                          className='btn btn-link d-flex ml-auto align-items-center'
                          data-toggle='collapse'
                          data-target='#collapseOne'
                          aria-expanded='true'
                          aria-controls='collapseOne'
                        >
                          Apartments <i className='fas fa-chevron-down fa-xs' />
                        </button>
                      </h5>
                    </div>
                    <div
                      id='collapseOne'
                      className='collapse show accordion-content'
                      aria-labelledby='headingOne'
                      data-parent='#accordion'
                    >
                      <div className='card-body pb-0'>
                        <div className='row'>
                          {appartments !== undefined
                            ? appartments.map(item => {
                                return (
                                  <div className='col-md-6 mb-3'>
                                    <ul className='property-list-style-2 list-unstyled mb-0 offering-list'>
                                      <h6 className='offering-heading'>
                                        {item.title}
                                      </h6>
                                      <li>
                                        <span className='offering-title'>
                                          Price:
                                        </span>
                                        <span className='offering-item'>
                                          {item.price}
                                        </span>
                                      </li>
                                      <li>
                                        <span className='offering-title'>
                                          Covered Area:
                                        </span>
                                        <span className='offering-item'>
                                          {item.covered_area}
                                          {item.area_unit}
                                        </span>
                                      </li>
                                      {item.baths ? (
                                        <li>
                                          <span className='offering-title'>
                                            Baths:
                                          </span>
                                          <span className='offering-item'>
                                            {item.baths}
                                          </span>
                                        </li>
                                      ) : (
                                        ''
                                      )}

                                      {item.beds ? (
                                        <li>
                                          <span className='offering-title'>
                                            Beds:
                                          </span>
                                          <span className='offering-item'>
                                            {item.beds}
                                          </span>
                                        </li>
                                      ) : (
                                        ''
                                      )}
                                    </ul>
                                  </div>
                                )
                              })
                            : ''}
                        </div>
                      </div>
                    </div>
                    {/*Appartments Accordian*/}
                  </div>
                ) : (
                  ''
                )}
                {/* Appartments */}
                {/* Plots */}
                {plots !== undefined && plots.length !== 0 ? (
                  <div className='card'>
                    {/*Plots Accordian*/}

                    <div className='card-header' id='headingTwo'>
                      <h5 className='accordion-title mb-0'>
                        <button
                          className='btn btn-link d-flex ml-auto align-items-center'
                          data-toggle='collapse'
                          data-target='#collapseTwo'
                          aria-expanded='true'
                          aria-controls='collapseTwo'
                        >
                          Plots <i className='fas fa-chevron-down fa-xs' />
                        </button>
                      </h5>
                    </div>
                    <div
                      id='collapseTwo'
                      className='collapse accordion-content'
                      aria-labelledby='headingTwo'
                      data-parent='#accordion'
                    >
                      <div className='card-body pb-0'>
                        <div className='row'>
                          {plots !== undefined
                            ? plots.map(item => {
                                return (
                                  <div className='col-md-6 mb-3'>
                                    <ul className='property-list-style-2 list-unstyled mb-0 offering-list'>
                                      <h6 className='offering-heading'>
                                        {item.title}
                                      </h6>
                                      <li>
                                        <span className='offering-title'>
                                          Price:
                                        </span>
                                        <span className='offering-item'>
                                          {item.price}
                                        </span>
                                      </li>
                                      <li>
                                        <span className='offering-title'>
                                          Covered Area:
                                        </span>
                                        <span className='offering-item'>
                                          {item.covered_area}
                                          {item.area_unit}
                                        </span>
                                      </li>
                                      {item.baths ? (
                                        <li>
                                          <span className='offering-title'>
                                            Baths:
                                          </span>
                                          <span className='offering-item'>
                                            {item.baths}
                                          </span>
                                        </li>
                                      ) : (
                                        ''
                                      )}

                                      {item.beds ? (
                                        <li>
                                          <span className='offering-title'>
                                            Beds:
                                          </span>
                                          <span className='offering-item'>
                                            {item.beds}
                                          </span>
                                        </li>
                                      ) : (
                                        ''
                                      )}
                                    </ul>
                                  </div>
                                )
                              })
                            : ''}
                        </div>
                      </div>
                    </div>
                    {/*Plots Accordian*/}
                  </div>
                ) : (
                  ''
                )}
                {/* Plots */}
                {/* Villas */}
                {villas !== undefined && villas.length !== 0 ? (
                  <div className='card'>
                    <div className='card-header' id='headingThree'>
                      <h5 className='accordion-title mb-0'>
                        <button
                          className='btn btn-link d-flex ml-auto align-items-center'
                          data-toggle='collapse'
                          data-target='#collapseThree'
                          aria-expanded='true'
                          aria-controls='collapseThree'
                        >
                          Villas <i className='fas fa-chevron-down fa-xs' />
                        </button>
                      </h5>
                    </div>
                    <div
                      id='collapseThree'
                      className='collapse accordion-content'
                      aria-labelledby='headingThree'
                      data-parent='#accordion'
                    >
                      <div className='card-body pb-0'>
                        <div className='row'>
                          {villas !== undefined
                            ? villas.map(item => {
                                return (
                                  <div className='col-md-6 mb-3'>
                                    <ul className='property-list-style-2 list-unstyled mb-0 offering-list'>
                                      <h6 className='offering-heading'>
                                        {item.title}
                                      </h6>
                                      <li>
                                        <span className='offering-title'>
                                          Price:
                                        </span>
                                        <span className='offering-item'>
                                          {item.price}
                                        </span>
                                      </li>
                                      <li>
                                        <span className='offering-title'>
                                          Covered Area:
                                        </span>
                                        <span className='offering-item'>
                                          {item.covered_area}
                                          {item.area_unit}
                                        </span>
                                      </li>
                                      {item.baths ? (
                                        <li>
                                          <span className='offering-title'>
                                            Baths:
                                          </span>
                                          <span className='offering-item'>
                                            {item.baths}
                                          </span>
                                        </li>
                                      ) : (
                                        ''
                                      )}

                                      {item.beds ? (
                                        <li>
                                          <span className='offering-title'>
                                            Beds:
                                          </span>
                                          <span className='offering-item'>
                                            {item.beds}
                                          </span>
                                        </li>
                                      ) : (
                                        ''
                                      )}
                                    </ul>
                                  </div>
                                )
                              })
                            : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {/* Villas */}

                {/* shops */}
                {shops !== undefined && shops.length !== 0 ? (
                  <div className='card'>
                    <div className='card-header' id='headingFour'>
                      <h5 className='accordion-title mb-0'>
                        <button
                          className='btn btn-link d-flex ml-auto align-items-center'
                          data-toggle='collapse'
                          data-target='#collapseFour'
                          aria-expanded='true'
                          aria-controls='collapseFour'
                        >
                          Shops <i className='fas fa-chevron-down fa-xs' />
                        </button>
                      </h5>
                    </div>
                    <div
                      id='collapseFour'
                      className='collapse accordion-content'
                      aria-labelledby='headingFour'
                      data-parent='#accordion'
                    >
                      <div className='card-body pb-0'>
                        <div className='row'>
                          {shops !== undefined
                            ? shops.map(item => {
                                return (
                                  <div className='col-md-6 mb-3'>
                                    <ul className='property-list-style-2 list-unstyled mb-0 offering-list'>
                                      <h6 className='offering-heading'>
                                        {item.title}
                                      </h6>
                                      <li>
                                        <span className='offering-title'>
                                          Price:
                                        </span>
                                        <span className='offering-item'>
                                          {item.price}
                                        </span>
                                      </li>
                                      <li>
                                        <span className='offering-title'>
                                          Covered Area:
                                        </span>
                                        <span className='offering-item'>
                                          {item.shop_size_from}-{' '}
                                          {item.shop_size_to} {item.area_unit}
                                        </span>
                                      </li>

                                      {item.baths ? (
                                        <li>
                                          <span className='offering-title'>
                                            Baths:
                                          </span>
                                          <span className='offering-item'>
                                            {item.baths}
                                          </span>
                                        </li>
                                      ) : (
                                        ''
                                      )}

                                      {item.beds ? (
                                        <li>
                                          <span className='offering-title'>
                                            Beds:
                                          </span>
                                          <span className='offering-item'>
                                            {item.beds}
                                          </span>
                                        </li>
                                      ) : (
                                        ''
                                      )}
                                    </ul>
                                  </div>
                                )
                              })
                            : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {/* shops */}
                {/* offices */}
                {offices !== undefined && offices.length !== 0 ? (
                  <div className='card'>
                    <div className='card-header' id='headingFive'>
                      <h5 className='accordion-title mb-0'>
                        <button
                          className='btn btn-link d-flex ml-auto align-items-center'
                          data-toggle='collapse'
                          data-target='#collapseFive'
                          aria-expanded='true'
                          aria-controls='collapseFive'
                        >
                          Offices <i className='fas fa-chevron-down fa-xs' />
                        </button>
                      </h5>
                    </div>
                    <div
                      id='collapseFive'
                      className='collapse accordion-content'
                      aria-labelledby='headingFive'
                      data-parent='#accordion'
                    >
                      <div className='card-body pb-0'>
                        <div className='row'>
                          {offices !== undefined
                            ? offices.map(item => {
                                return (
                                  <div className='col-md-6 mb-3'>
                                    <ul className='property-list-style-2 list-unstyled mb-0 offering-list'>
                                      <h6 className='offering-heading'>
                                        {item.title}
                                      </h6>
                                      <li>
                                        <span className='offering-title'>
                                          Price:
                                        </span>
                                        <span className='offering-item'>
                                          {item.price}
                                        </span>
                                      </li>
                                      <li>
                                        <span className='offering-title'>
                                          Covered Area:
                                        </span>
                                        <span className='offering-item'>
                                          {item.covered_area}
                                          {item.area_unit}
                                        </span>
                                      </li>
                                      {item.baths ? (
                                        <li>
                                          <span className='offering-title'>
                                            Baths:
                                          </span>
                                          <span className='offering-item'>
                                            {item.baths}
                                          </span>
                                        </li>
                                      ) : (
                                        ''
                                      )}

                                      {item.beds ? (
                                        <li>
                                          <span className='offering-title'>
                                            Beds:
                                          </span>
                                          <span className='offering-item'>
                                            {item.beds}
                                          </span>
                                        </li>
                                      ) : (
                                        ''
                                      )}
                                    </ul>
                                  </div>
                                )
                              })
                            : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {/* offices */}
                {/* industrial_plot */}
                {industrial_plot !== undefined &&
                industrial_plot.length !== 0 ? (
                  <div className='card'>
                    <div className='card-header' id='headingSix'>
                      <h5 className='accordion-title mb-0'>
                        <button
                          className='btn btn-link d-flex ml-auto align-items-center'
                          data-toggle='collapse'
                          data-target='#collapseSix'
                          aria-expanded='true'
                          aria-controls='collapseSix'
                        >
                          Industrial Plot{' '}
                          <i className='fas fa-chevron-down fa-xs' />
                        </button>
                      </h5>
                    </div>
                    <div
                      id='collapseSix'
                      className='collapse accordion-content'
                      aria-labelledby='headingSix'
                      data-parent='#accordion'
                    >
                      <div className='card-body pb-0'>
                        <div className='row'>
                          {industrial_plot !== undefined
                            ? industrial_plot.map(item => {
                                return (
                                  <div className='col-md-6 mb-3'>
                                    <ul className='property-list-style-2 list-unstyled mb-0 offering-list'>
                                      <h6 className='offering-heading'>
                                        {item.title}
                                      </h6>
                                      <li>
                                        <span className='offering-title'>
                                          Price:
                                        </span>
                                        <span className='offering-item'>
                                          {item.price}
                                        </span>
                                      </li>
                                      <li>
                                        <span className='offering-title'>
                                          Covered Area:
                                        </span>
                                        <span className='offering-item'>
                                          {item.covered_area}
                                          {item.area_unit}
                                        </span>
                                      </li>
                                      {item.baths ? (
                                        <li>
                                          <span className='offering-title'>
                                            Baths:
                                          </span>
                                          <span className='offering-item'>
                                            {item.baths}
                                          </span>
                                        </li>
                                      ) : (
                                        ''
                                      )}

                                      {item.beds ? (
                                        <li>
                                          <span className='offering-title'>
                                            Beds:
                                          </span>
                                          <span className='offering-item'>
                                            {item.beds}
                                          </span>
                                        </li>
                                      ) : (
                                        ''
                                      )}
                                    </ul>
                                  </div>
                                )
                              })
                            : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {/* industrial_plot */}
              </div>
            </div>
          </div>
        </div>
        <ProjectOtherImages />

        {projectDetails.front_video === 'null' ||
        projectDetails.front_video === '' ||
        projectDetails.front_video === undefined ? (
          ''
        ) : (
          <Fragment>
            <hr className='mt-4 mb-4 mb-sm-5 mt-sm-5' />
            <div className='property-video'>
              <div className='row'>
                <div className='col-sm-3 mb-3 mb-sm-0'>
                  <h5>Project Video</h5>
                </div>
                <div className='col-sm-9'>
                  <div className='ReactPlayerMain w-100'>
                    <ReactPlayer
                      className='w-100'
                      url={projectDetails.front_video}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}

        <hr className='mt-4 mb-4 mb-sm-5 mt-sm-5' />
        {mapURL !== undefined || mapURL !== '' ? (
          <div className='property-map mb-4 mb-sm-5'>
            <div className='row'>
              <div className='col-sm-3 mb-3 mb-sm-0'>
                <h5>Location</h5>
              </div>
              <div className='col-sm-9'>
                <div className='embed-responsive embed-responsive-16by9'>
                  <iframe
                    title={projectDetails.id}
                    src={mapURL}
                    style={{ border: 0, width: '100%', height: '100%' }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {/* ------------------- Property Info Card-------------------*/}
    </div>
  )
}

export default PropertyInfoCard
