import React, { useEffect, useState } from 'react'

import axios from 'axios'

import { API_URL, jsonHeader } from '../../Constants/global'

function PopularCities () {
  const [quickSearch, setquickSearch] = useState([])

  const searchSubmit = (
    searchQuery,
    url,
    searchContent,
    qsearch_seo_title,
    qsearch_seo_desc
  ) => {
    localStorage.setItem('searchQuery', '')
    localStorage.setItem('searchQuery', JSON.stringify(searchQuery))
    localStorage.setItem('searchContent', searchContent)
    localStorage.setItem('qsearch_seo_title', qsearch_seo_title)
    localStorage.setItem('qsearch_seo_desc', qsearch_seo_desc)

    window.open(url, '_blank')
  }
  useEffect(() => {
    axios({
      method: 'GET',
      url: API_URL + 'seoapi/get_all_search_contents_bycategory',

      headers: jsonHeader
    }).then(response => {
      if (response.status === 200) {
        setquickSearch(response.data?.data)
        console.log(response.data?.data)
      }
    })
  }, [])

  // function handleCityHeight () {
  //   if (localStorage.getItem('pop_city_height') === 150) {
  //     localStorage.setItem('pop_city_height', 900)
  //   } else {
  //     localStorage.setItem('pop_city_height', 150)
  //   }
  // }

  return (
    <section className='space-pb'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='section-title text-center bold-lite'>
              <h2>
                Popular <span>Cities</span>
              </h2>
            </div>
          </div>
          <div className='col-md-12 no-padding-left no-padding-right'>
            <div className='style-1 popular-locations-style'>
              <div className='box_border content hideContent'>
                <div className='row  '>
                  <div className='col-md-12 '>
                    <div className='main-container-homes zb i  '>
                      {quickSearch.length
                        ? quickSearch.map(item => {
                            return (
                              <div className='location-container-homes zb-conatiner-inner '>
                                <h3 className='location-for-home-name mt-0 '>
                                  {item.title}
                                </h3>
                                <ul className='home-ul'>
                                  {item.search_contents_child.map(child => {
                                    return (
                                      <li className='home-li'>
                                        <a
                                          target='_blank'
                                          onClick={() =>
                                            searchSubmit(
                                              {
                                                property_purpose:
                                                  child.property_purpose_id,
                                                city_id: child.city_id,
                                                property_type:
                                                  child.property_type_id
                                              },
                                              child.url,
                                              child.listing_content,
                                              child.seo_title,
                                              child.seo_description
                                            )
                                          }
                                          href='javascript:void(0);'
                                          title={child.content_label}
                                        >
                                          <i className='las la-plus' />
                                          {child.content_label}
                                        </a>
                                      </li>
                                    )
                                  })}
                                </ul>
                                <hr />
                              </div>
                            )
                          })
                        : ''}
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
              <div className='show-more show-more-zb '>
                <i className='fas fa-chevron-down animate-btn-height' />
                <img
                  className
                  alt='img'
                  src='classified_assets/images/load_more.gif'
                  loading='lazy'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PopularCities
