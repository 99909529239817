import React, { useEffect, useState } from 'react'

import Select from 'react-select'
import axios from 'axios'
import {
  API_URL,
  BASE_URL,
  jsonHeader,
  multiFormHeader
} from '../../../Constants/global'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
//fetch AmenitiesSelected
var AmenitiesSelected = []

const AddPropertyV2 = ({ propertyData }) => {
  //update--
  const [selectedAmenities, setSelectedAmenities] = useState([])
  // ----------------------------------------Step All--------------------------------
  const [isLoading, setIsLoading] = useState({
    isLoading: false,
    disabled: false
  })

  const [front_Image_file, setfront_Image_file] = useState()

  // const [title_error, setTitle_error] = useState("");

  const [isLoadingImages, setIsLoadingImages] = useState({
    isLoading: false,
    disabled: false
  })

  const project_params = useParams()

  // ADD PROPERTY CONST

  const [details, setDetails] = useState({
    id: 0,
    city_id: 0,
    area_id: 0,
    property_purpose: 1,
    property_group_type: 0,
    property_type: 0
  })

  const [details2, setDetails2] = useState({
    title: '',
    description: '',
    zipcode: '',
    address: '',
    landarea: '',
    baths: 0,
    beds: 0,
    depth: 0,
    width: 0,
    dining_lounge: 0,
    areaunit: 0,
    price: 0,
    currency: 'PKR',
    old_file: '',
    front_image: '',
    front_video: ''
  })

  const [cityList, setcityList] = useState([])
  const [areaList, setareaList] = useState([])
  const [propertyType, setpropertyType] = useState([])
  const [propertyTypeGroup, setpropertyTypeGroup] = useState([])
  const [AmenitiesList, setAmenitiesList] = useState([])
  const [ImagesArray, setImagesArray] = useState([])
  const [areaUnitList, setareaUnitList] = useState([])
  const [propertyID, setpropertyID] = useState(0)
  const [imageLoadFlag, setImageLoadFlag] = useState('')

  //  form Errors

  const [property_type_error, setProperty_type_error] = useState('')
  const [property_group_type, setProperty_group_type] = useState('')
  const [city_list_error, setCity_list_error] = useState('')
  const [area_list_error, setArea_list_error] = useState('')

  async function fetchPropertyType () {
    const response = await axios({
      method: 'GET',
      url:
        API_URL +
        'api/property_type_by_groupid/' +
        details2.property_group_type,
      headers: jsonHeader
    })

    if (response.status === 200) {
      const nArray = []
      response.data?.data.map(item => {
        let roleData = {}
        roleData.value = item.id
        roleData.label = item.title
        nArray.push(roleData)
      })
      setpropertyType(nArray)
    }
  }

  useEffect(() => {
    if (project_params.id !== undefined) {
      axios({
        method: 'GET',
        url: API_URL + 'api/properties/' + project_params.id,

        headers: jsonHeader
      }).then(response => {
        if (response.status === 200) {
          setDetails({
            id: parseInt(response.data?.data.id),
            city_id: parseInt(response.data?.data.city_id),
            area_id: parseInt(response.data?.data.area_id),
            property_purpose: parseInt(response.data?.data.property_purpose_id),
            property_group_type: parseInt(
              response.data?.data.property_type_data[0].group_id
            ),
            property_type: response.data?.data.property_type_id
          })
          setDetails2({
            title: response.data?.data.title,
            description: response.data?.data.description,
            zipcode: response.data?.data.zipcode,
            address: response.data?.data.address,
            landarea: parseInt(response.data?.data.land_area),
            areaunit: response.data?.data.area_unit,
            price: parseInt(response.data?.data.price),
            currency: response.data?.data.currency,
            front_image: response.data?.data.front_image,
            front_video: response.data?.data.front_video,
            old_file: response.data?.data.front_image,
            beds: response.data?.data.beds,
            baths: response.data?.data.baths,
            dining_lounge: response.data?.data.dining_lounge
          })

          console.log(details)
          AmenitiesSelected = response.data.data.amenities
          setSelectedAmenities(AmenitiesSelected)

          console.log(`-----------------------------${details}`)
          console.log('amunities  ' + response.data.data.amenities)
          fetchPropertyType()
          fetch(
            API_URL +
              'generalservices/areabycityid/' +
              response.data?.data.city_id
          )
            .then(response => response.json())
            .then(data => {
              if (data.status === 200) {
                const nArray = []
                data.data.map(item => {
                  let roleData = {}
                  roleData.value = item.id
                  roleData.label = item.area
                  nArray.push(roleData)
                })
                setareaList(nArray)
              }
            })
        }
      })
    }

    async function fetchCountries () {
      const response = await axios({
        method: 'GET',
        url: API_URL + 'generalservices/citybycountryid/1',
        headers: jsonHeader
      })

      if (response.status === 200) {
        const nArray = []
        response.data?.data.map(item => {
          let roleData = {}
          roleData.value = item.id
          roleData.label = item.city
          nArray.push(roleData)
        })
        setcityList(nArray)
      }

      const response_type_group = await axios({
        method: 'GET',
        url: API_URL + 'api/property_type_by_group',
        headers: jsonHeader
      })

      const nArray_group = []
      const nArray = []
      response_type_group.data?.data.map(item => {
        let roleData = {}
        roleData.label = item.title
        roleData.value = item.id
        nArray_group.push(roleData)
      })

      setpropertyTypeGroup(nArray_group)

      let id = project_params.id
      if (project_params.id === undefined) {
        id = 0
      }

      const amenitiesList = await axios({
        method: 'GET',
        url: API_URL + 'api/fetchAmenitiesBy_Property_id/' + id,
        headers: jsonHeader
      })

      setAmenitiesList(amenitiesList.data?.data)

      if (project_params.id !== undefined) {
        const imagesList = await axios({
          method: 'GET',
          url: API_URL + 'api/get_property_images/' + project_params.id,
          headers: jsonHeader
        })

        setImagesArray(imagesList.data?.data)
        if (ImagesArray.length === 0) {
          setImageLoadFlag('no Images')
        }
      }

      const fetchAreaUnits = await axios({
        method: 'POST',
        url: API_URL + 'metadata/property_area_units',
        data: { id: 0, showAll: true, isActive: 0, isDeleted: 0 },
        headers: jsonHeader
      })

      const areaunitArray = []

      fetchAreaUnits.data?.data.map(item => {
        let roleData = {}
        roleData.value = item.id
        roleData.label = item.title
        areaunitArray.push(roleData)
      })
      setareaUnitList(areaunitArray)
      console.log(areaUnitList)
    }

    fetchCountries()

    // fecth property type

    async function fetchPropertyType () {
      const response = await axios({
        method: 'GET',
        url:
          API_URL + 'api/property_type_by_groupid/' + details.property_purpose,
        headers: jsonHeader
      })

      if (response.status === 200) {
        const nArray = []
        response.data?.data.map(item => {
          let roleData = {}
          roleData.value = item.id
          roleData.label = item.title
          nArray.push(roleData)
        })
        setpropertyType(nArray)
      }
    }

    // fetchPropertyType()
  }, [])

  async function fetchAreasbycityID (value) {
    setArea_list_error('Please select area')
    setCity_list_error('')

    // alert(details.city_id);
    const response = await axios({
      method: 'GET',
      url: API_URL + 'generalservices/areabycityid/' + value.value,
      headers: jsonHeader
    })

    if (response.status === 200) {
      const nArray = []
      response.data?.data.map(item => {
        let roleData = {}
        roleData.value = item.id
        roleData.label = item.area
        nArray.push(roleData)
      })
      setareaList(nArray)
    }
    setDetails({ ...details, city_id: value.value, area_id: 0 })
  }

  function onAreaChange (value) {
    if (details.area_id !== '0') {
      setArea_list_error('')
    }
    setDetails({ ...details, area_id: value.value })
  }

  async function onPropertyType (value) {
    setProperty_type_error('Please select property type.')

    console.log(value)
    const response = await axios({
      method: 'GET',
      url: API_URL + 'api/property_type_by_groupid/' + value.value,
      headers: jsonHeader
    })

    if (response.status === 200) {
      const nArray = []
      response.data?.data.map(item => {
        let roleData = {}
        roleData.value = item.id
        roleData.label = item.title
        nArray.push(roleData)
      })
      setpropertyType(nArray)
      setProperty_group_type('')
    }

    setDetails({
      ...details,
      property_type: 0,
      property_group_type: value.value
    })
  }

  function update_setuserAmenitiesList (e) {
    console.log(e.target.alt)

    var curarray = AmenitiesSelected.find(
      x => x.amenity_id === parseInt(e.target.id)
    )

    if (curarray) {
      if (e.target.alt === 'Boolean') {
        replaceData(e.target.checked ? true : false, parseInt(e.target.id))
      }
      if (e.target.alt === 'text') {
        replaceData(e.target.value, parseInt(e.target.id))
      }

      if (e.target.alt === 'number') {
        replaceData(parseInt(e.target.value), parseInt(e.target.id))
      }
    } else {
      if (e.target.alt === 'text') {
        const userData = {
          property_id: propertyID,
          amenity_id: parseInt(e.target.id),

          amenity_value: e.target.value
        }

        AmenitiesSelected.push(userData)
      }

      if (e.target.alt === 'number') {
        const userData = {
          property_id: propertyID,
          amenity_id: parseInt(e.target.id),
          amenity_value: parseInt(e.target.value)
        }
        AmenitiesSelected.push(userData)
      }

      if (e.target.alt === 'Boolean') {
        const userData = {
          property_id: propertyID,
          amenity_id: parseInt(e.target.id),
          amenity_value: e.target.checked ? true : false
        }
        AmenitiesSelected.push(userData)
      }
    }

    setSelectedAmenities(AmenitiesSelected)
    console.log(selectedAmenities)
    console.log(AmenitiesSelected)
  }

  function replaceData (value, amenity_id) {
    AmenitiesSelected.forEach(element => {
      if (element.amenity_id === amenity_id) {
        element.amenity_value = value
      }
    })
  }

  // --------------------------step1------------------------------------

  const propertyPurposeHandler = event => {
    // console.log(event.target.value);
    setDetails({
      ...details,
      property_purpose: parseInt(event.target.value)
    })
  }

  const checkFormData1 = values => {
    if (details.property_group_type === 0) {
      setProperty_group_type('Please select property group.')
    } else {
      setProperty_group_type('')
    }

    if (details.property_type === 0) {
      setProperty_type_error('Please select property type.')
    } else {
      setProperty_type_error('')
    }

    if (details.city_id === 0) {
      setCity_list_error('Please select city.')
    } else {
      setCity_list_error('')
    }

    if (
      property_type_error !== '' ||
      property_group_type !== '' ||
      city_list_error !== ''
    ) {
      return false
    } else {
      return true
    }
  }
  // -------------------------------------------step2-------------------------
  const [img, setImg] = useState()
  const [imageError, setImageError] = useState('')
  const [selectedfile, setSelectedFile] = useState([])
  const [totalImages, settotalImages] = useState(0)
  const [selectedfileArray, setselectedfileArray] = useState([])
  const [fileSizes, setFileSizes] = useState([])
  const [areaunit, setAreaunit] = useState(0)
  const [frontImagefile, setfrontImagefile] = useState()
  const [frontImageError, setfrontImageError] = useState('')

  const onSubmitProperty = values => {
    if (project_params.id !== undefined) {
      if (details2.old_file === undefined || details2.old_file === '') {
        setfrontImageError('Front image is required.')
      }
    } else {
      if (frontImagefile === undefined || frontImagefile === '') {
        setfrontImageError('Front image is required.')
      }
    }

    // alert(details.property_type + details.city_id + details.area_id)
    if (checkFormData1() === true) {
      // if (project_params.id === undefined) {

      // }

      if (imageError === '') {
        setIsLoading({
          isLoading: true,
          disabled: true
        })

        var form = new FormData()
        form.append('id', details.id)
        form.append('city_id', details.city_id)
        form.append('area_id', details.area_id)
        form.append('property_purpose_id', details.property_purpose)
        form.append('property_type_id', details.property_type)
        form.append('title', values.title)
        form.append('description', values.description)
        form.append('zipcode', values.zipcode)
        form.append('address', values.address)
        form.append('land_area', values.landarea)
        form.append('area_unit', areaunit)
        form.append('price', values.price)
        form.append('beds', values.beds !== undefined ? values.beds : 0)
        form.append('baths', values.baths !== undefined ? values.baths : 0)
        form.append('depth', values.depth !== undefined ? values.depth : 0)
        form.append('width', values.width !== undefined ? values.width : 0)
        form.append(
          'dining_lounge',
          values.dining_lounge !== undefined ? values.dining_lounge : 0
        )
        form.append('currency', values.currency)
        form.append('rent_frequency', '')
        form.append('country_id', 1)
        form.append('agency_id', 0)
        form.append('active', 0)
        form.append('is_wanted', 0)
        form.append('is_hot', 0)
        form.append('is_featured', 0)
        form.append('is_promo', 0)
        form.append('is_privacy', 0)
        form.append('video_360', '')
        form.append('property_status_id', 0)
        form.append('is_popout', 0)
        form.append('front_video', values.front_video)
        form.append('is_scrap', 0)

        if (frontImagefile !== undefined && frontImagefile.length !== 0) {
          form.append('front_image', frontImagefile.target.files[0])
        } else {
          form.append(
            'old_file',
            details2.old_file !== undefined || details2.old_file !== ''
              ? details2.old_file
              : ''
          )
          form.append('front_image', '')
        }

        form.append('location', values.address)

        if (selectedfile !== undefined && selectedfile.length !== 0) {
          for (var i = 0, element; (element = selectedfile.target.files[i]); ) {
            console.log(element)
            form.append('images-' + i, element)
            i++
            settotalImages(i)

            settotalImages(selectedfile.target.files.length)
            form.append('images_qty', selectedfile.target.files.length)
          }
        } else {
          form.append('images_qty', 0)
        }

        form.append('amenities', JSON.stringify(selectedAmenities, null, 2))
        form.append('user_id', parseInt(localStorage.getItem('user_id')))
        form.append('video_360', '')

        if (frontImagefile !== undefined) {
          setfront_Image_file(frontImagefile.target.files[0])
        }

        axios({
          method: 'POST',
          url: API_URL + 'api/property_add',
          data: form,
          headers: multiFormHeader
        })
          .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.details)
              setIsLoading({
                isLoading: false,
                disabled: false
              })
            }
          })
          .then(response => {
            console.log(response)

            if (response.data.status === 200) {
              setIsLoading({
                isLoading: false,
                disabled: false
              })

              setpropertyID(response.data?.property_id)
              console.log(propertyID)
              window.location.href = '/my-properties'
              // checkFormData1(values);
              // onSubmitImages()
            } else {
              setIsLoading({
                isLoading: false,
                disabled: false
              })

              // setTitle_error("Title already exists");
            }
          })
      } else {
        setIsLoading({
          isLoading: false,
          disabled: false
        })
      }
    } else {
      setIsLoading({
        isLoading: false,
        disabled: false
      })
    }
  }

  const validateSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    zipcode: Yup.string()
      // .required("Required")
      .max(8, 'Must not be more than 8 characters'),
    address: Yup.string().required('Required'),
    landarea: Yup.number()

      .required('Required')
      .min(1, 'Min value 1.')
      .max(10000000, 'Max value 10000000.'),
    price: Yup.number()
      .required('Required')
      .min(1, 'Min value 1.')
      .max(100000000000000, 'price should be less than 15 digits.'),
    front_video: Yup.string().url('Please enter a valid URL')
  })

  const formik = useFormik({
    initialValues: details2,
    onSubmit: onSubmitProperty,
    validationSchema: validateSchema,
    enableReinitialize: true
  })

  const handleFrontImageChange = e => {
    if (e.target.files[0].size < 500000) {
      setfrontImagefile(e)
      const [file] = e.target.files
      setImg(URL.createObjectURL(file))
      // setImageError("");
      setfrontImageError('')
    } else {
      setImageError('File size should be less than 500 kb')
      setImg('')
    }
  }

  // --------------------------------------------Step 3

  // --------------------------------------------Step 4
  const [imageError_step4, setImageError_step4] = useState('')

  const fileObj = []
  const fileArray = []
  const fileSizesArray = []

  const handleChange = e => {
    if (e.target.files.length < 11) {
      setselectedfileArray([])
      setFileSizes([])
      setImageError_step4('')
      for (let i = 0; i < e.target.files.length; i++) {
        if (e.target.files[i].size > 500000) {
          // alert("");
          setImageError_step4('File size should be less than 500 kb')
        }
      }
      console.log(e.target.files)
      if (imageError_step4 === '') {
        // setImageError("");
        setSelectedFile(e)
        fileObj.push(e.target.files)
        for (let i = 0; i < fileObj[0].length; i++) {
          fileArray.push(URL.createObjectURL(fileObj[0][i]))
          fileSizesArray.push(fileObj[0][i].size)
          // console.log(fileObj);
        }
        setselectedfileArray(fileArray)
        setFileSizes(fileSizesArray)
        console.log(fileSizesArray)
      }
    } else {
      setImageError_step4('only 10 images can be selected in total')
    }
  }

  function onSubmitImages () {
    if (imageError_step4 === '') {
      //   window.location.replace("/my-properties");

      if (selectedfile.length === 0) {
        setIsLoadingImages({
          isLoading: false,
          disabled: false
        })

        return
      }

      setIsLoadingImages({
        isLoading: true,
        disabled: true
      })

      var form = new FormData()
      //if front image is provided
      if (front_Image_file !== '') {
        form.append('images-' + 0, front_Image_file)
        for (var i = 1, element; (element = selectedfile.target.files[i]); ) {
          console.log(element)
          form.append('images-' + i, element)
          i++
          settotalImages(i)
        }

        settotalImages(selectedfile.target.files.length + 1)
        form.append('images_qty', selectedfile.target.files.length + 1)
      } else {
        for (var i = 0, element; (element = selectedfile.target.files[i]); ) {
          console.log(element)
          form.append('images-' + i, element)
          i++
          settotalImages(i)
        }

        settotalImages(selectedfile.target.files.length)
        form.append('images_qty', selectedfile.target.files.length)
      }

      form.append('id', details.id)
      form.append('property_id', propertyID)

      console.log(form.values)

      axios({
        method: 'POST',
        url: API_URL + 'api/property_images',
        data: form,
        headers: multiFormHeader
      }).then(response => {
        if (response.status === 200) {
          setIsLoadingImages({
            isLoading: false,
            disabled: false
          })

          window.location.href = '/my-properties'
        } else {
          setIsLoadingImages({
            isLoading: false,
            disabled: false
          })
        }
      })
    }
  }

  const deleteArray = e => {
    const file_url = e.target.name
    const type = e.target.id

    if (type === 'remote_images') {
      console.log(file_url)

      if (file_url !== undefined) {
        const response = axios({
          method: 'POST',
          url: API_URL + 'generalservices/delete_record_str',
          data: {
            table_name: 'joproperty_images',
            field_id: 'image_url',
            field_value: file_url
          },
          headers: jsonHeader
        })
        if (response.status === 200) {
          for (var i = 0; i < ImagesArray.length; i++) {
            if (ImagesArray[i].image_url === file_url) {
              ImagesArray.splice(i, 1)
              break
            }
          }
        }
      }
    } else {
      for (var z = 0; z < selectedfileArray.length; z++) {
        if (selectedfileArray[z] === file_url) {
          selectedfileArray.splice(z, 1)
          break
        }
      }
    }
  }

  return (
    <>
      {/* -----------------------------------------------------------Return Step 1 */}
      <section className='add-listing-section space-ptb'>
        <div className='container'>
          <div className='add-property-header-left d-md-none d-lg-none d-block mb-3'>
            <h4>Sell or Rent out your Property</h4>
          </div>

          <div className='add-property-header d-none d-md-block mb-4'>
            <div className='add-property-header-inner'>
              <div className='add-property-header-left'>
                <h4>Sell or Rent out your Property</h4>
                <p className='mb-0'>
                  Enter your property details below to get it listed on our
                  portal and receive leads on your property
                </p>
              </div>
              <div className='add-property-header-right'>
                <img
                  className='img-fluid no-sticky-logo'
                  src={BASE_URL + 'classified_assets/images/sellrent-svg.svg'}
                  alt='logo'
                />
              </div>
            </div>
          </div>

          <div className='add-listing-main'>
            <form>
              <h6>Property Purpose</h6>
              <div className='col-md-12 p-0'>
                <div className='form-group'>
                  <label className='mb-2'>Property Purpose :</label>
                  <div class='switch-radio'>
                    <div className='switch-field'>
                      <div className='switch-field-inner'>
                        <input
                          type='radio'
                          name='property_purpose'
                          value={1}
                          id='property_purpose-0'
                          defaultChecked
                          checked={details.property_purpose === 1}
                          onChange={propertyPurposeHandler.bind(this)}
                        />
                        <label htmlFor='property_purpose-0'>For Sale</label>
                        <input
                          type='radio'
                          name='property_purpose'
                          value={2}
                          id='property_purpose-1'
                          checked={details.property_purpose === 2}
                          onChange={propertyPurposeHandler.bind(this)}
                        />
                        <label htmlFor='property_purpose-1'>For Rent</label>
                      </div>
                    </div>
                  </div>

                  {/*<div className="kt-radio-inline radio-inline--v2">*/}
                  {/*  <label className="kt-radio radio--v2">*/}
                  {/*    <input*/}
                  {/*      type="radio"*/}
                  {/*      name="property_purpose"*/}
                  {/*      value={1}*/}
                  {/*      id="property_purpose-0"*/}
                  {/*      defaultChecked*/}
                  {/*      checked={details.property_purpose === 1}*/}
                  {/*      onChange={propertyPurposeHandler.bind(this)}*/}
                  {/*    />*/}
                  {/*    For Sale*/}
                  {/*    <span />*/}
                  {/*  </label>*/}

                  {/*  <label className="kt-radio radio--v2">*/}
                  {/*    <input*/}
                  {/*      type="radio"*/}
                  {/*      name="property_purpose"*/}
                  {/*      value={2}*/}
                  {/*      id="property_purpose-1"*/}
                  {/*      checked={details.property_purpose === 2}*/}
                  {/*      onChange={propertyPurposeHandler.bind(this)}*/}
                  {/*    />*/}
                  {/*    For Rent*/}
                  {/*    <span />*/}
                  {/*  </label>*/}
                  {/*</div>*/}
                </div>
              </div>
              <div className='label-main'>
                <label>Property Category *</label>
                <div className='row '>
                  <div className='form-group col-md-4 select-border'>
                    <Select
                      className='form-control '
                      //   isLoading={isloading}
                      options={propertyTypeGroup}
                      placeholder='Property Group Type'
                      isMulti={false}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      onChange={onPropertyType}
                      value={propertyTypeGroup.find(
                        c => c.value === parseInt(details.property_group_type)
                      )}
                    />
                    <div>
                      {property_group_type !== '' &&
                      property_group_type !== undefined ? (
                        <div className='error text-danger'>
                          {property_group_type}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='form-group col-md-4 select-border'>
                    <Select
                      id='property_type'
                      className='form-control '
                      //   isLoading={isloading}
                      options={propertyType}
                      placeholder='Property Type'
                      isMulti={false}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      onChange={e => {
                        setDetails({
                          ...details,
                          property_type: parseInt(e.value)
                        })
                        setProperty_type_error('')
                      }}
                      value={
                        details.property_type === 0
                          ? 0
                          : propertyType.find(
                              c => c.value === parseInt(details.property_type)
                            )
                      }
                    />
                    <div>
                      {property_type_error !== '' &&
                      property_type_error !== undefined ? (
                        <div className='error text-danger'>
                          {property_type_error}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <hr className='mt-3 mb-4'></hr>
              <h6>Where's your Property located?</h6>
              <div className='row '>
                <div className='form-group col-md-4'>
                  <label>City *</label>

                  <Select
                    className='form-control '
                    //   isLoading={isloading}
                    options={cityList}
                    name='city'
                    isMulti={false}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    onChange={fetchAreasbycityID}
                    value={cityList.find(
                      c => c.value === parseInt(details.city_id)
                    )}
                  />
                  {city_list_error ? (
                    <div className='error text-danger'> {city_list_error} </div>
                  ) : null}
                </div>

                <div className='form-group col-md-4 select-border'>
                  <label>Area *</label>

                  <Select
                    className='form-control '
                    //   isLoading={isloading}
                    name='area'
                    options={areaList}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    onChange={onAreaChange}
                    value={
                      details.area_id === 0
                        ? 0
                        : areaList.find(
                            c => c.value === parseInt(details.area_id)
                          )
                    }
                  />
                  {area_list_error ? (
                    <div className='error text-danger'> {area_list_error} </div>
                  ) : null}
                </div>
              </div>
            </form>
          </div>

          {/* -----------------------------------------------------------Return Step 2 */}
          <div className='add-listing-main'>
            <form
              onSubmit={formik.handleSubmit}
              autoComplete='off'
              id='formToSubmit'
            >
              <div className='add-listing-main'>
                <hr className='mt-3 mb-4'></hr>
                <h6>Add Your Property Details</h6>
                <div className='row'>
                  <div className='form-group col-md-6 select-border'>
                    <label>Title *</label>

                    <input
                      type='text'
                      id='title'
                      name='title'
                      className='form-control'
                      placeholder='Title'
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.title}
                    />
                    {formik.touched.title && formik.errors.title ? (
                      <div className='error text-danger'>
                        {' '}
                        {formik.errors.title}{' '}
                      </div>
                    ) : null}
                    {/* {<div className="error text-danger"> {title_error} </div>} */}
                  </div>
                </div>
                <div className='row'>
                  <div className='form-group col-md-6 field-description'>
                    <textarea
                      className='form-control floating-textarea '
                      name='description'
                      rows={4}
                      placeholder='Description *'
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.description}
                    />
                    {formik.touched.description && formik.errors.description ? (
                      <div className='error text-danger'>
                        {' '}
                        {formik.errors.description}{' '}
                      </div>
                    ) : null}
                  </div>
                </div>

                <hr className='mt-3 mb-4'></hr>
                <h6>Add Property Info for Better Results</h6>

                <div className='row'>
                  <div className='form-group col-md-3 select-border'>
                    <label>Zip Code</label>
                    <input
                      name='zipcode'
                      type='text'
                      className='form-control'
                      placeholder='Zip Code'
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.zipcode}
                    />
                    {formik.touched.zipcode && formik.errors.zipcode ? (
                      <div className='error text-danger'>
                        {' '}
                        {formik.errors.zipcode}{' '}
                      </div>
                    ) : null}
                  </div>
                  <div className='form-group col-md-3 select-border'>
                    <label>Address</label>
                    <input
                      type='text'
                      name='address'
                      className='form-control'
                      placeholder='Address'
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.address}
                    />
                    {formik.touched.address && formik.errors.address ? (
                      <div className='error text-danger'>
                        {' '}
                        {formik.errors.address}{' '}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='row'>
                  <div className='form-group col-md-3 select-border'>
                    <label>Land Area</label>
                    <input
                      type='text'
                      name='landarea'
                      className='form-control'
                      placeholder='Land Area'
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.landarea}
                    />
                    {formik.touched.landarea && formik.errors.landarea ? (
                      <div className='error text-danger'>
                        {' '}
                        {formik.errors.landarea}{' '}
                      </div>
                    ) : null}
                  </div>
                  <div className='form-group col-md-3 select-border'>
                    <label>Area Unit</label>
                    <Select
                      className='form-control '
                      //   isLoading={isloading}
                      options={areaUnitList}
                      isMulti={false}
                      name='areaunit'
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      placeholder='sq feet'
                      onChange={e => setAreaunit(parseInt(e.value))}
                      value={areaUnitList.find(
                        c => c.value === parseInt(areaunit)
                      )}
                    />
                  </div>
                </div>

                {details.property_type === 7 ||
                details.property_type === 8 ||
                details.property_type === 10 ||
                details.property_type === 11 ||
                details.property_type === 13 ||
                details.property_type === 14 ||
                details.property_type === 15 ? (
                  // Properties Plot type

                  <div className='row'>
                    <div className='form-group col-md-3 select-border'>
                      <label>Depth</label>
                      <input
                        type='text'
                        name='depth'
                        className='form-control'
                        placeholder='Depth'
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.depth}
                      />
                      {formik.touched.depth && formik.errors.depth ? (
                        <div className='error text-danger'>
                          {' '}
                          {formik.errors.depth}{' '}
                        </div>
                      ) : null}
                    </div>
                    <div className='form-group col-md-3 select-border'>
                      <label>Width</label>
                      <input
                        type='text'
                        name='width'
                        className='form-control'
                        placeholder='Width'
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.width}
                      />
                      {formik.touched.width && formik.errors.width ? (
                        <div className='error text-danger'>
                          {' '}
                          {formik.errors.width}{' '}
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {details.property_type === 9 ? (
                  <div className='row'>
                    <div className='form-group col-md-3 select-border'>
                      <label>No of Bath(s)</label>
                      <input
                        type='text'
                        name='baths'
                        className='form-control'
                        placeholder='No of Baths'
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.baths}
                      />
                      {formik.touched.baths && formik.errors.baths ? (
                        <div className='error text-danger'>
                          {' '}
                          {formik.errors.baths}{' '}
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                {details.property_type === 1 ||
                details.property_type === 2 ||
                details.property_type === 3 ||
                details.property_type === 4 ||
                details.property_type === 5 ? (
                  // Properties Apartments

                  <div className='row'>
                    <div className='form-group col-md-3 select-border'>
                      <label>No of Bed(s)</label>
                      <input
                        type='text'
                        name='beds'
                        className='form-control'
                        placeholder='No of Beds'
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.beds}
                      />
                      {formik.touched.beds && formik.errors.beds ? (
                        <div className='error text-danger'>
                          {' '}
                          {formik.errors.beds}{' '}
                        </div>
                      ) : null}
                    </div>
                    <div className='form-group col-md-3 select-border'>
                      <label>No of Bath(s)</label>
                      <input
                        type='text'
                        name='baths'
                        className='form-control'
                        placeholder='No of Baths'
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.baths}
                      />
                      {formik.touched.baths && formik.errors.baths ? (
                        <div className='error text-danger'>
                          {' '}
                          {formik.errors.baths}{' '}
                        </div>
                      ) : null}
                    </div>
                    <div className='form-group col-md-3 select-border'>
                      <label>Dinning / Lounge</label>
                      <input
                        type='text'
                        name='dining_lounge'
                        className='form-control'
                        placeholder='Dinning / Lounge'
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.dining_lounge}
                      />
                      {formik.touched.dining_lounge &&
                      formik.errors.dining_lounge ? (
                        <div className='error text-danger'>
                          {' '}
                          {formik.errors.dining_lounge}{' '}
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className='row'>
                  <div className='form-group col-md-3 select-border'>
                    <label>Price</label>
                    <input
                      type='text'
                      name='price'
                      className='form-control'
                      placeholder='Price'
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.price}
                    />
                    {formik.touched.price && formik.errors.price ? (
                      <div className='error text-danger'>
                        {' '}
                        {formik.errors.price}{' '}
                      </div>
                    ) : null}
                  </div>
                  <div className='form-group col-md-3 select-border'>
                    <label>Currency</label>
                    <select className='form-control basic-select'>
                      <option value='value 01' selected='selected'>
                        PKR
                      </option>
                    </select>
                  </div>
                </div>
                {/* --------------video--------------- */}
                <hr className='mt-3 mb-4'></hr>
                <h6>Add Your Property Video URL</h6>
                <div className='col-md-12 p-0 image-tag-heading'>
                  <h4>
                    Add Your Property Video URL
                    {/* <span className="red">*</span> */}
                  </h4>
                  <input
                    type='text'
                    name='front_video'
                    className='form-control'
                    placeholder='video URL'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.front_video}
                  />
                  {/*<h6>Image Size: (400*300)</h6>*/}
                  {formik.touched.front_video && formik.errors.front_video ? (
                    <div className='error text-danger'>
                      {' '}
                      {formik.errors.front_video}{' '}
                    </div>
                  ) : null}
                </div>
                <div className='col-md-12 p-0 image-tag-heading mt-3'>
                  <h6>Please enter a valid video url for your property.</h6>
                </div>
                {/* ---------------------------------- */}
                <hr className='mt-3 mb-4'></hr>
                <h6>Add Your Property Image</h6>
                <div className='col-md-12 p-0 image-tag-heading'>
                  <h4>
                    Front Image <span className='red'>*</span>
                  </h4>
                  {/*<h6>Image Size: (400*300)</h6>*/}
                </div>
                <div className='col-md-12 p-0 mb-3'>
                  <div className='upload-property-imgs-outer'>
                    <div className='upload-single-img-listing field-front_image'>
                      <input
                        type='file'
                        accept='image/*'
                        onChange={handleFrontImageChange}
                        id='front_image'
                        className=''
                        name='front_image'
                      />
                      <label className='btn' for='front_image'>
                        <i className='font-lg fas fa-image'></i> Choose Image
                      </label>
                    </div>
                  </div>
                  {details2.front_image !== '' ? (
                    <div className='multi-previewMain mt-2'>
                      <img src={details2.front_image} alt='' />{' '}
                    </div>
                  ) : (
                    ''
                  )}
                  <div className='col-md-12 p-0 image-tag-heading'>
                    <h6>Allowed file types: png, jpg, jpeg.</h6>
                  </div>
                  <span className='text-danger'>{frontImageError}</span>
                  <p className='text-danger'>{imageError}</p>
                </div>
                {img ? (
                  <div className='multi-previewMain'>
                    <img src={img} alt='' />{' '}
                  </div>
                ) : (
                  ''
                )}
                <hr className='mt-3 mb-4'></hr>
              </div>
            </form>
          </div>
          {/* ---------------------------------------------------------------Return Step 3 */}

          <div className='add-listing-main'>
            <div className='row'>
              <div className='col-md-12'>
                <h6 className='mb-3'>Add Your Property Amenities</h6>
                <div className='accordion-style-2' id='accordion-02'>
                  {AmenitiesList.length === 0 ? (
                    <section className='space-ptb'>
                      <div className='container'>
                        <div className='row justify-content-center'>
                          <div className='col-md-12'>
                            <photo className='shine w-100 shimmerphoto'></photo>
                          </div>
                          <div className='col-md-12 mt-1'>
                            <lines className='shine w-100 shimmerlines'></lines>
                          </div>
                        </div>
                      </div>
                    </section>
                  ) : (
                    ''
                  )}
                  {AmenitiesList
                    ? AmenitiesList.map((item, index) => {
                        return (
                          <div className='card'>
                            <div className='card-header' id>
                              <h5 className='accordion-title mb-0'>
                                <button
                                  type='button'
                                  className='btn btn-link d-flex ml-auto align-items-center collapsed'
                                  data-toggle='collapse'
                                  data-target={`#collapse-${index}`}
                                  aria-expanded='false'
                                  aria-controls='collapsefour'
                                >
                                  {item.amenity_group}
                                  <i className='fas fa-chevron-down fa-xs' />
                                </button>
                              </h5>
                            </div>
                            <div
                              id={`collapse-${index}`}
                              className='accordion-content collapse'
                              aria-labelledby='headingfour'
                              data-parent='#accordion-02'
                              style={{}}
                            >
                              <div className='card-body d-flex flex-wrap align-items-center'>
                                {item.amenities_childs.map(child => {
                                  if (child.input_type === 'text') {
                                    if (child.amenity_value !== '') {
                                      const userData = {
                                        property_id: propertyID,
                                        amenity_id: parseInt(child.id),
                                        amenity_value: child.amenity_value
                                      }
                                      // AmenitiesSelected.push(userData);

                                      return (
                                        <div className='form-group col-md-4 select-border'>
                                          <label>{child.amenity}</label>

                                          <input
                                            type='text'
                                            className='form-control'
                                            name={child.amenity}
                                            placeholder={child.amenity}
                                            id={child.id}
                                            alt={child.input_type}
                                            onChange={
                                              update_setuserAmenitiesList
                                            }
                                            value={child.amenity_value}
                                          />
                                        </div>
                                      )
                                    } else {
                                      return (
                                        <div className='form-group col-md-4 select-border'>
                                          <label>{child.amenity}</label>

                                          <input
                                            type='text'
                                            className='form-control'
                                            name={child.amenity}
                                            placeholder={child.amenity}
                                            id={child.id}
                                            alt={child.input_type}
                                            onChange={
                                              update_setuserAmenitiesList
                                            }
                                          />
                                        </div>
                                      )
                                    }
                                  }

                                  if (child.input_type === 'Boolean') {
                                    if (child.amenity_value) {
                                      const userData = {
                                        property_id: propertyID,
                                        amenity_id: parseInt(child.id),
                                        amenity_value: child.amenity_value
                                      }
                                      // AmenitiesSelected.push(userData);
                                      return (
                                        <div className='form-group col-md-4 select-border'>
                                          <div className='custom-control custom-checkbox d-flex'>
                                            <input
                                              className='custom-control-input'
                                              type='checkbox'
                                              name={child.amenity}
                                              alt={child.input_type}
                                              id={child.id}
                                              onClick={
                                                update_setuserAmenitiesList
                                              }
                                              defaultChecked={
                                                child.amenity_value
                                              }
                                            />
                                            <label
                                              className='custom-control-label'
                                              htmlFor={child.id}
                                            >
                                              {child.amenity}
                                            </label>
                                          </div>
                                        </div>
                                      )
                                    } else {
                                      return (
                                        <div className='form-group col-md-4 select-border'>
                                          <div className='custom-control custom-checkbox d-flex'>
                                            <input
                                              className='custom-control-input'
                                              type='checkbox'
                                              name={child.amenity}
                                              alt={child.input_type}
                                              id={child.id}
                                              onClick={
                                                update_setuserAmenitiesList
                                              }
                                            />
                                            <label
                                              className='custom-control-label'
                                              htmlFor={child.id}
                                            >
                                              {child.amenity}
                                            </label>
                                          </div>
                                        </div>
                                      )
                                    }
                                  }

                                  if (child.input_type === 'number') {
                                    if (child.amenity_value !== '') {
                                      const userData = {
                                        property_id: propertyID,
                                        amenity_id: parseInt(child.id),
                                        amenity_value: child.amenity_value
                                      }
                                      // AmenitiesSelected.push(userData);
                                      return (
                                        <div className='form-group col-md-4 select-border'>
                                          <label>{child.amenity}</label>
                                          <input
                                            type='text'
                                            name={child.amenity}
                                            className='form-control'
                                            placeholder={child.amenity}
                                            id={child.id}
                                            alt={child.input_type}
                                            onChange={
                                              update_setuserAmenitiesList
                                            }
                                            value={child.amenity_value}
                                          />
                                        </div>
                                      )
                                    } else {
                                      return (
                                        <div className='form-group col-md-4 select-border'>
                                          <label>{child.amenity}</label>
                                          <input
                                            type='text'
                                            className='form-control'
                                            name={child.amenity}
                                            placeholder={child.amenity}
                                            id={child.id}
                                            alt={child.input_type}
                                            onChange={
                                              update_setuserAmenitiesList
                                            }
                                          />
                                        </div>
                                      )
                                    }
                                  }
                                })}
                              </div>
                            </div>
                          </div>
                        )
                      })
                    : ''}
                </div>
              </div>
            </div>
          </div>

          {/* ---------------------------------------------------------------Return Step 4 */}

          <div className='add-listing-main'>
            <form>
              <div className='submit-property--outer-part'>
                <hr className='mt-4 mb-4'></hr>
                <h6>Add Your Property Additional Image</h6>
                <div className='col-md-12 p-0 image-tag-heading'>
                  <h4>Additional Images</h4>
                  {/*<h6>Image Size: (400*300)</h6>*/}
                </div>
                <div className='col-md-12 p-0 mb-3'>
                  <div className='upload-property-imgs-outer'>
                    <div className='upload-single-img-listing field-front_image'>
                      <input
                        type='file'
                        multiple
                        onChange={handleChange}
                        id='aditional_images'
                        className=''
                        accept='image/*'
                        name='aditional_images'
                      />
                      <label className='btn' htmlFor='aditional_images'>
                        <i className='font-lg fas fa-images'></i> Additional
                        Images
                      </label>
                      <div className='image-tag-heading'>
                        <h6>Allowed file types: png, jpg, jpeg.</h6>
                      </div>
                    </div>
                  </div>
                </div>
                {ImagesArray.length === 0 &&
                project_params.id !== undefined &&
                imageLoadFlag === '' ? (
                  <section className='space-ptb'>
                    <div className='container'>
                      <div className='row justify-content-center'>
                        <div className='col-md-12'>
                          <photo className='shine w-100 shimmerphoto'></photo>
                        </div>
                        <div className='col-md-12 mt-1'>
                          <lines className='shine w-100 shimmerlines'></lines>
                        </div>
                      </div>
                    </div>
                  </section>
                ) : (
                  ''
                )}
                <div className='form-group multi-preview'>
                  <div className='multi-preview-inner'>
                    {selectedfileArray.map((url, index) => {
                      return (
                        <div
                          className={`multi-previewMain ${
                            Math.round(parseInt(fileSizes[index]) * 0.001) > 500
                              ? 'bg-danger'
                              : ''
                          }`}
                        >
                          <img src={url} alt='...' />
                          <p
                            className={
                              Math.round(parseInt(fileSizes[index]) * 0.001) >
                              500
                                ? 'text-danger'
                                : ''
                            }
                          >
                            {Math.round(parseInt(fileSizes[index]) * 0.001)} kb
                          </p>
                          {/* <button type="button" className="fa fa-trash" name={url} id="local_images" onClick={deleteArray}></button> */}
                        </div>
                      )
                    })}
                    {(ImagesArray || []).map(url => {
                      return (
                        <div className='multi-previewMain-del'>
                          <img src={url.image_url} alt='...' />
                          <button
                            type='button'
                            className='btn btn-danger px-3 p-2 my-2'
                            name={url.image_url}
                            id='remote_images'
                            onClick={deleteArray}
                          >
                            <i className='fas fa-trash p-0'></i>
                          </button>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <p className='text-danger'>{imageError_step4}</p>
              </div>

              <hr className='mt-4 mb-4'></hr>

              <div className='w-100 d-flex justify-content-center align-items-center'>
                <button
                  form='formToSubmit'
                  disabled={isLoadingImages.disabled}
                  type='submit'
                  className='btn btn-primary btn-md font-lg btn-submit-property'
                  // onClick={onSubmitImages}
                >
                  Submit your Property
                  <span
                    hidden={isLoading.disabled - 1}
                    className='spinner-border spinner-border-sm ml-2'
                    role='status'
                    aria-hidden='true'
                  ></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

export default AddPropertyV2
