import React, { Fragment } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { setCart } from '../../Redux/actions/cart';
import axios from 'axios';
import * as Yup from 'yup';
import searchBar from '../../Redux/reducers/searchBar';
import { setSearchBar } from '../../Redux/actions/searchBar';
import { useFormik, yupToFormErrors } from 'formik';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Stack from '@mui/material/Stack';
import { SuccessDialogue } from './SuccessDialogue';

import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  currentUserInformation,
  multiFormHeader,
  multiFormHeader2,
} from '../../Constants/global';

const OrderCheckout = ({ setCart, setSearchBar, searchBar }) => {
  function formatDate(date, skip) {
    //remove sec.mili_sec
    const segments = date.split(':');
    return segments.slice(0, segments.length - skip).join(':');
  }
  const [cart_loading, setCartLoading] = useState(false);
  const [totalImages, settotalImages] = useState(0);
  const [ImagesArray, setImagesArray] = useState([]);
  const [selectedfile, setSelectedFile] = useState([]);
  const [selectedfileArray, setselectedfileArray] = useState([]);
  const [fileSizes, setFileSizes] = useState([]);
  const [isLoading, setIsLoading] = useState({
    isLoading: false,
    disabled: false,
  });
  const [isLoading_placeOrder, setIsLoading_placeOrder] = useState({
    isLoading: false,
    disabled: false,
  });
  const params = useParams();
  const [date, setDate] = useState(dayjs(new Date()));
  var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  var service_date = new Date(date - tzoffset).toISOString().slice(0, -1);

  useEffect(() => {
    const dateStr = date.$d.toDateString().split(' ');
    var dateStrFormat = dateStr[2] + ' ' + dateStr[1] + ' ' + dateStr[3];
    setDateString(dateStrFormat);
  }, [date]);

  const [dateString, setDateString] = useState('');
  const [selectedAddress, setSelectedAddress] = useState('');

  var bill = 0;
  const [address_list, setAddress_list] = useState([]);
  const [selectedCity, setSelectedCity] = useState(0);
  const [locations, setLocations] = useState([]);
  function addToCart(id, title, charges, cat_id, city_id, quantity) {
    if (quantity !== '0') {
      setCart(charges, quantity, cat_id, city_id, id, id, 1);
    }
  }
  const [cartData, setCartData] = useState([]);
  const [cities, setCities] = useState([]);
  const [error, setError] = useState('');
  function place_order() {
    if (cartData.length !== 0) {
      setIsLoading_placeOrder({
        isLoading: true,
        disabled: true,
      });
      setError('');

      var form = new FormData();

      var form = new FormData();

      form.append('address', selectedAddress);
      form.append('service_date', formatDate(service_date, 1));
      form.append('total_price', bill);
      form.append('cart_items', JSON.stringify(cartData));
      form.append('payment_method', 'COD');
      form.append('additional_info', additional_info);
      form.append('user_id', localStorage.getItem('user_id'));

      if (selectedfile.target) {
        setError('');
        for (var i = 0, element; (element = selectedfile.target.files[i]); ) {
          form.append('images-' + i, element);
          i++;
          settotalImages(i);
        }
        settotalImages(selectedfile.target.files.length);

        form.append('images_qty', selectedfile.target.files.length);
      } else {
      }

      axios({
        method: 'POST',
        url: API_URL + 'api/handymen_myorders_add',
        data: form,
        headers: multiFormHeader,
      }).then((response) => {
        if (response.data.status === 200) {
          setIsLoading_placeOrder({
            isLoading: false,
            disabled: false,
          });
          document.getElementById('SuccessDialogue').click();
          // -----------------------Reset Cart---------------------
          localStorage.setItem('cart_total', '0');
          localStorage.setItem('cart_qty', '0');
          // -----------------------Reset Cart---------------------
          console.log('order placed');
        } else {
          setIsLoading_placeOrder({
            isLoading: false,
            disabled: false,
          });
          setError('Something went wrong. Your order was not placed');
          console.log('order failed to place');
        }
      });

      setAdditional_info('');
      console.log(form);
    } else {
      if (cartData.length === 0) {
        setError('Please add services to your cart.');
      }
    }
  }
  var source;
  const get_cart = () => {
    setCartLoading(true);
    if (source) {
      source.cancel();
    }
    const CancelToken = axios.CancelToken;
    source = CancelToken.source();
    axios({
      method: 'GET',
      url: API_URL + 'api/handymen_cart/' + localStorage.getItem('user_id'),
      headers: multiFormHeader,
      cancelToken: source.token,
    }).then((response) => {
      if (response.data.status === 200) {
        setCartLoading(false);
        console.log(response.data.data);
        setCartData(response.data.data);
      } else {
        setCartLoading(false);

        console.log('cart data fetch fail');
      }
    });
  };
  useEffect(() => {
    if (address_list) {
      if (address_list[0]) {
        setSelectedAddress(
          address_list[address_list.length - 1].address +
            ', ' +
            address_list[address_list.length - 1].area +
            ', ' +
            address_list[address_list.length - 1].city
        );
      }
    }
  }, [address_list]);
  useEffect(() => {
    function fetchAddress() {
      axios({
        method: 'GET',
        url:
          API_URL +
          'api/handymen_user_addres/' +
          localStorage.getItem('user_id'),
        headers: multiFormHeader,
      }).then((response) => {
        if (response.data.status === 200) {
          console.log(response.data.data);
          setAddress_list(response.data.data);
        } else {
          console.log('address fetch fail');
        }
      });
    }
    fetchAddress();
    async function fetchSearchBy() {
      const response = await axios({
        method: 'GET',
        url: API_URL + 'api/searchby',
      });
      if (response.status === 200) {
        const cities = response.data?.data[2]['cities'];
        const nArray2 = [];
        cities.map((item) => {
          let roleData = {};
          if (
            item.id === 14 ||
            item.id === 3 ||
            item.id === 12 ||
            item.id === 62
          ) {
            roleData.value = item.id;
            roleData.label = item.city;
            nArray2.push(roleData);
          }
        });
        setCities(nArray2);
      }
    }
    fetchSearchBy();

    get_cart();
  }, []);
  useEffect(() => {
    // ----------------------resetCart
    axios({
      method: 'GET',
      url: API_URL + 'api/handymen_cart/' + localStorage.getItem('user_id'),
      headers: multiFormHeader,
    }).then((response) => {
      if (response.data.status === 200) {
        console.log(response.data.data);

        localStorage.setItem('cart_total', '0');
        localStorage.setItem('cart_qty', '0');
        response.data.data.map((item) => {
          localStorage.setItem(
            'cart_total',
            parseInt(localStorage.getItem('cart_total')) +
              parseInt(item.service_charges) * parseInt(item.service_qty)
          );
          localStorage.setItem(
            'cart_qty',
            parseInt(localStorage.getItem('cart_qty')) +
              parseInt(item.service_qty)
          );
        });
      } else {
        console.log('cart data fetch fail');
      }
    });
    // ----------------------resetCart
  }, []);
  // -----------------------------add Address-------------------------
  const [initialValues, setInitialValues] = useState({
    city: 0,
    area: '',
    address: '',
  });
  const handleSubmit = (values) => {
    setIsLoading({
      isLoading: true,
      disabled: true,
    });

    console.log(values);
    var form = new FormData();
    form.append('city_id', values.city);
    form.append('area_id', values.area);
    form.append('address', values.address);
    form.append('user_id', localStorage.getItem('user_id'));
    form.append('id', 0);

    axios({
      method: 'POST',
      url: API_URL + 'api/handymen_user_address_add',
      data: form,
      headers: multiFormHeader,
    }).then((response) => {
      if (response.data.status === 200) {
        setIsLoading({
          isLoading: false,
          disabled: false,
        });
        console.log('Adress Added successfully');
        window.location.href = '/check-out';
      } else {
        setIsLoading({
          isLoading: false,
          disabled: false,
        });
        console.log('Add address Fail');
      }
    });
  };

  const validateSchema = Yup.object().shape({});
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: validateSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    setSelectedCity(Number(formik.values.city));

    let tempArray = [];

    axios({
      method: 'GET',
      url: API_URL + 'generalservices/areabycityid/' + selectedCity,
    }).then((response) => {
      if (response.status === 200) {
        const nArray = [];
        response.data?.data.map((item) => {
          if (item.city_id === selectedCity) {
            let roleData = {};
            roleData.value = item.id;
            roleData.label = item.area;

            nArray.push(roleData);
          }
        });
        setLocations(nArray);
      }
    });
  }, [formik.values.city, selectedCity]);

  // -----------------------------add Address-------------------------
  function removeRecord(table_name, id) {
    const form = {
      table_name: table_name,
      field_id: 'id',
      field_value: parseInt(id),
    };

    axios({
      method: 'POST',
      url: API_URL + 'generalservices/delete_record',
      data: JSON.stringify(form),
      headers: multiFormHeader2,
    }).then((response) => {
      if (response.data.status === 200) {
        console.log('Record Deleted successfully');
        window.location.href = '/check-out';
      } else {
        console.log('Delete Record Fail');
      }
    });
  }
  // ------------------------------delete Address-----------------------------------
  function delete_address(e) {
    console.log(e.target.id);
    const id = e.target.id;
    removeRecord('johandymen_useraddress', id);
  }
  function delete_cart_item(e) {
    console.log(e.target.id);
    const id = e.target.id;
    removeRecord('johandymen_cart', id);
  }

  function select_address(e) {
    console.log(e.target.value);
    setSelectedAddress(e.target.value);
  }

  // ------------------------------delete Address-----------------------------------
  const [additional_info, setAdditional_info] = useState('');
  const handleChange = (event) => {
    setAdditional_info(event.target.value);
  };
  // -----------------------------screenshots--------------------------------------
  const [imageError_step4, setImageError_step4] = useState('');

  const fileObj = [];
  const fileArray = [];
  const fileSizesArray = [];
  const handleImageChange = (e) => {
    if (e.target.files.length < 11) {
      setselectedfileArray([]);
      setFileSizes([]);
      setImageError_step4('');
      for (let i = 0; i < e.target.files.length; i++) {
        if (e.target.files[i].size > 500000) {
          setImageError_step4('File size should be less than 500 kb');
        }
      }
      console.log(e.target.files);
      if (imageError_step4 === '') {
        setSelectedFile(e);
        fileObj.push(e.target.files);
        for (let i = 0; i < fileObj[0].length; i++) {
          fileArray.push(URL.createObjectURL(fileObj[0][i]));
          fileSizesArray.push(fileObj[0][i].size);
        }
        setselectedfileArray(fileArray);
        setFileSizes(fileSizesArray);
        console.log(fileSizesArray);
      }
    } else {
      setImageError_step4('only 10 images can be selected in total');
    }
    console.log(selectedfileArray);
  };
  const deleteArray = (e) => {
    const file_url = e.target.name;
    const type = e.target.id;

    if (type === 'remote_images') {
      console.log(file_url);

      if (file_url !== undefined) {
        const response = axios({
          method: 'POST',
          url: API_URL + 'generalservices/delete_record_str',
          data: {
            table_name: 'joproperty_images',
            field_id: 'image_url',
            field_value: file_url,
          },
          headers: jsonHeader,
        });
        if (response.status === 200) {
          for (var i = 0; i < ImagesArray.length; i++) {
            if (ImagesArray[i].image_url === file_url) {
              ImagesArray.splice(i, 1);
              break;
            }
          }
        }
      }
    } else {
      for (var z = 0; z < selectedfileArray.length; z++) {
        if (selectedfileArray[z] === file_url) {
          selectedfileArray.splice(z, 1);
          break;
        }
      }
    }
  };
  return (
    <Fragment>
      <section className='book-now-section space-ptb'>
        <div>
          <div>
            <div>
              <div></div>
              <div className='modal-body space-ptb'>
                <div className='container'>
                  <div className='row mt-5'>
                    <div className='col-md-8'>
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='myOrderPayment d-flex justify-content-between align-items-center'>
                            <div>
                              <h3>Select Date</h3>
                            </div>
                            <div className='select-book-date-show'>
                              <i className='fas fa-calendar-day'></i>{' '}
                              {dateString}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='mt-3'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3}>
                            <DatePicker
                              label='Booking Date'
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              value={date}
                              onChange={(newValue) => {
                                setDate(newValue);
                                console.log(date);
                                console.log(date.$d);
                              }}
                              minDate={dayjs(new Date())}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>
                      <div className='myOrderItem w-100 mt-5'>
                        <h3 className='mb-3'>Items</h3>
                        <div className='col-md-6 p-0'>
                          {/* ------------------------------------------ */}
                          {cartData.map((item) => {
                            return (
                              <div className='all-services-box'>
                                <div className='all-service-box-img'>
                                  <img
                                    className='img-fluid'
                                    alt='icons'
                                    src='/classified_assets/images/handymen-images/service-img/airconditioning.jpg'
                                  />
                                </div>
                                <div className='all-service-detail'>
                                  <div className='all-service-title'>
                                    <h3>{item.service}</h3>
                                  </div>
                                  <div className='all-service-price'>
                                    Rs. {item.service_charges}
                                  </div>
                                  <div className='all-service-inspecrtion'>
                                    (Very After Inspection)
                                  </div>

                                  <ul className='serviceCounter p-0 d-flex align-items-center justify-content-end mb-0'>
                                    <li
                                      className='list-style-none'
                                      data-btn-type='decrement'
                                      onClick={() => {
                                        if (
                                          document.getElementById(item.id)
                                            .value !== '0' &&
                                          document.getElementById(item.id)
                                            .value !== 0
                                        ) {
                                          document.getElementById(
                                            item.id
                                          ).value =
                                            parseInt(
                                              document.getElementById(item.id)
                                                .value
                                            ) - 1;

                                          if (localStorage.getItem('token')) {
                                            addToCart(
                                              item.service_id,
                                              item.service,
                                              item.service_charges,
                                              item.category_id,
                                              item.city_id,
                                              document.getElementById(item.id)
                                                .value
                                            );
                                          }
                                        }
                                        setTimeout(get_cart, 2000);
                                      }}
                                    >
                                      <span className='btn btn-primary increBtn px-2 py-1'>
                                        <i className='fa fa-minus p-0' />
                                      </span>
                                    </li>
                                    <li className='list-style-none counterText'>
                                      {cart_loading ? (
                                        <span
                                          hidden={false}
                                          className='spinner-border spinner-border-sm ml-1'
                                          role='status'
                                          aria-hidden='true'
                                        ></span>
                                      ) : (
                                        <input
                                          type='text'
                                          name='guestCount'
                                          id={item.id}
                                          value={item.service_qty}
                                          disabled
                                        />
                                      )}
                                    </li>
                                    <li
                                      className='list-style-none'
                                      data-btn-type='increment'
                                      onClick={() => {
                                        if (
                                          document.getElementById(item.id)
                                            .value !== '100' &&
                                          document.getElementById(item.id)
                                            .value !== 100
                                        ) {
                                          document.getElementById(
                                            item.id
                                          ).value =
                                            parseInt(
                                              document.getElementById(item.id)
                                                .value
                                            ) + 1;

                                          if (localStorage.getItem('token')) {
                                            addToCart(
                                              item.service_id,
                                              item.service,
                                              item.service_charges,
                                              item.category_id,
                                              item.city_id,
                                              parseInt(
                                                document.getElementById(item.id)
                                                  .value
                                              )
                                            );
                                          }
                                          setTimeout(get_cart, 2000);
                                        }
                                      }}
                                    >
                                      <span className='btn btn-primary increBtn px-2 py-1'>
                                        <i className='fa fa-plus p-0'></i>
                                      </span>
                                    </li>

                                    <li
                                      className='list-style-none'
                                      id={item.id}
                                      key={item.id}
                                      onClick={delete_cart_item}
                                    >
                                      <span
                                        className='btn btn-primary increBtn ml-2 px-2 py-1'
                                        id={item.id}
                                        key={item.id}
                                        onClick={delete_cart_item}
                                      >
                                        <i
                                          className='fas fa-trash  p-0'
                                          id={item.id}
                                          key={item.id}
                                          onClick={delete_cart_item}
                                        ></i>
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            );
                          })}

                          {/* ------------------------------------------ */}
                        </div>
                      </div>

                      <div className='row mt-5'>
                        <div className='col-md-6'>
                          <div className='myOrderPayment'>
                            <h3>Payment Method</h3>
                          </div>
                          <label className='offwhite-box d-flex justify-content-between'>
                            <div className='pm-left'>
                              <img
                                className='img-fluid'
                                alt='icons'
                                width='20'
                                src='/classified_assets/images/handymen-images/payment-icon.svg'
                              />
                              <span className='pl-2'>COD</span>
                            </div>
                            <div className='pm-right'>
                              <div className='form-check'>
                                <input
                                  className
                                  type='radio'
                                  name='paymentMethod'
                                  id='flexRadioDefault2'
                                  checked={true}
                                />
                              </div>
                            </div>
                          </label>
                        </div>
                        <div className='col-md-6'>
                          <div className='myOrderPayment'>
                            <h3>Add Screenshot</h3>
                          </div>
                          <label className='service-problem-imgs'>
                            <div className='service-problem-imgs-inner'>
                              <div className='service-problem-add'>
                                <div>
                                  <label
                                    className='btn btn-primary px-2 py-1'
                                    for='aditional_images'
                                  >
                                    <i className='fas fa-plus p-0'></i>
                                  </label>
                                  <p>
                                    {selectedfileArray.length
                                      ? `${selectedfileArray.length} files`
                                      : ''}
                                  </p>
                                </div>
                                <div visbility='hidden'>
                                  <input
                                    type='file'
                                    style={{
                                      border: 0,
                                      height: '1px',
                                      margin: '-1px',
                                      overflow: 'hidden',
                                      padding: 0,
                                      position: 'absolute',
                                      width: '1px',
                                    }}
                                    multiple
                                    onChange={handleImageChange}
                                    id='aditional_images'
                                    accept='image/*'
                                    name='aditional_images'
                                  />
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>

                      <div className='form-group multi-preview'>
                        <div className='multi-preview-inner'>
                          {selectedfileArray.map((url, index) => {
                            return (
                              <div
                                className={`multi-previewMain ${
                                  Math.round(
                                    parseInt(fileSizes[index]) * 0.001
                                  ) > 500
                                    ? 'bg-danger'
                                    : ''
                                }`}
                              >
                                <img src={url} alt='...' />
                                <p
                                  className={
                                    Math.round(
                                      parseInt(fileSizes[index]) * 0.001
                                    ) > 500
                                      ? 'text-danger'
                                      : ''
                                  }
                                >
                                  {Math.round(
                                    parseInt(fileSizes[index]) * 0.001
                                  )}{' '}
                                  kb
                                </p>
                                {/* <button type="button" className="fa fa-trash" name={url} id="local_images" onClick={selectedfileArray.delete}></button> */}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className='row mb-md-0 mb-5 mt-5'>
                        <div className='col-md-12'>
                          <div className='myOrderPayment'>
                            <h3>Additional Information</h3>
                            <div className='additional-info-txt'>
                              <form>
                                <textarea
                                  id='additional_info'
                                  name='additional_info'
                                  onChange={handleChange}
                                  value={additional_info}
                                  height='120'
                                  className='form-control'
                                ></textarea>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='delivering-status'>
                        <div className='delivering-status-inner'>
                          <div className='delivering-to'>Delivering To:</div>
                          <div className='add-new-address d-flex align-items-center justify-content-between'>
                            <div class='add-new-btn'>Add New</div>
                            <div className='dropdown ml-2 mr-0'>
                              <button
                                className='order-modal-dd-btn btn btn-primary dropdown-toggle text-black p-0'
                                type='button'
                                data-toggle='modal'
                                data-target='#AddNewAddressModal'
                              >
                                <i className='fas fa-chevron-down p-0' />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ------------------Address_map-------------------- */}

                      <div className='delivering-status my-3 pri-address'>
                        <div className='delivering-status-inner'>
                          <div className='delivering-to'>Primary Address</div>
                        </div>
                        <div className='address-specific'>
                          <p className='m-0'>{selectedAddress}</p>
                        </div>
                      </div>
                      <div className='delivering-status my-3  deliver-overflow'>
                        <div className='delivering-status-inner'>
                          <div className='delivering-to'>Other Addresses</div>
                        </div>
                        <div className='address-specific'>
                          <p className='m-0'>
                            {address_list !== []
                              ? address_list
                                  .slice(0)
                                  .reverse()
                                  .map((item, index) => {
                                    return (
                                      <div
                                        className='delivering-status my-3 bg-white'
                                        key={index}
                                      >
                                        <div className='address-specific'>
                                          <p className='m-0'>
                                            <input
                                              className='mr-2'
                                              type='radio'
                                              name='address_radio'
                                              id={item.id}
                                              value={
                                                item.address +
                                                ', ' +
                                                item.area +
                                                ', ' +
                                                item.city
                                              }
                                              defaultChecked={index === 0}
                                              onClick={select_address}
                                            />
                                            {item.address}
                                            {item.area}, {item.city}
                                            <i
                                              id={item.id}
                                              key={item.id}
                                              onClick={delete_address}
                                              className='fa fa-trash float-right'
                                              name='delete_address'
                                            ></i>
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  })
                              : ''}
                          </p>
                        </div>
                      </div>

                      {/* --------------------------------------- */}
                      <div className='delivering-status-promo mt-4'>
                        <div className='delivering-status-promo-in'>
                          <div className='delivering-promo'>
                            Apply Promo Code
                          </div>
                          <div className='input-promo position-relative my-2'>
                            <button className='btn btn-primary apply-promo'>
                              Apply
                            </button>
                            <input
                              type='text'
                              className='form-control border-0'
                              placeholder='Promo Code'
                            />
                          </div>
                        </div>
                        <div className='billing-specific mt-3'>
                          <div className='billing-heading mb-2'>Address</div>
                          {/* ------------------------------------- */}

                          <div className='billing-detail'>
                            <div className='billing-left'>
                              {selectedAddress}
                            </div>
                            {/* <div className="billing-right">Rs:</div> */}
                          </div>

                          {/* ------------------------------------- */}

                          <hr className='my-1' />
                        </div>
                        <div className='billing-specific mt-3'>
                          <div className='billing-heading mb-2'>Billing</div>
                          {/* ------------------------------------- */}
                          {cartData.map((item) => {
                            bill =
                              bill + item.service_charges * item.service_qty;

                            return (
                              <div className='billing-detail'>
                                <div className='billing-left'>
                                  {item.service}{' '}
                                  <span>
                                    (
                                    {cart_loading ? (
                                      <span
                                        hidden={false}
                                        className='spinner-border spinner-border-sm '
                                        role='status'
                                        aria-hidden='true'
                                      ></span>
                                    ) : (
                                      item.service_qty
                                    )}
                                    )
                                  </span>
                                </div>
                                <div className='billing-right'>
                                  Rs: {item.service_charges} x{' '}
                                  {cart_loading ? (
                                    <span
                                      hidden={false}
                                      className='spinner-border spinner-border-sm '
                                      role='status'
                                      aria-hidden='true'
                                    ></span>
                                  ) : (
                                    item.service_qty
                                  )}
                                </div>
                              </div>
                            );
                          })}

                          {/* ------------------------------------- */}
                          <hr className='my-1' />
                          <div className='billing-detail'>
                            <div className='billing-left'>Amount</div>
                            <div className='billing-right'>
                              Rs:{' '}
                              {cart_loading ? (
                                <span
                                  hidden={false}
                                  className='spinner-border spinner-border-sm '
                                  role='status'
                                  aria-hidden='true'
                                ></span>
                              ) : (
                                bill
                              )}
                            </div>
                          </div>
                          <hr className='my-1' />
                          <div className='billing-detail total-price'>
                            <div className='billing-left'>Total Price</div>
                            <div className='billing-right'>
                              Rs:{' '}
                              {cart_loading ? (
                                <span
                                  hidden={false}
                                  className='spinner-border spinner-border-sm '
                                  role='status'
                                  aria-hidden='true'
                                ></span>
                              ) : (
                                bill
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='total-place-order mt-3'>
                          <button
                            disabled={isLoading_placeOrder.disabled}
                            className='btn btn-primary w-100 p-2'
                            onClick={place_order}
                          >
                            Place Order
                            <span
                              hidden={isLoading_placeOrder.disabled - 1}
                              className='spinner-border spinner-border-sm ml-2'
                              role='status'
                              aria-hidden='true'
                            ></span>
                          </button>
                          <button
                            style={{ display: 'none' }}
                            type='button'
                            class='btn btn-primary'
                            data-toggle='modal'
                            data-target='#exampleModalCenter'
                            id='SuccessDialogue'
                          >
                            Launch demo modal
                          </button>
                        </div>
                      </div>
                      {error ? (
                        <div className='error text-danger'> {error} </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className='modal fade'
        id='AddNewAddressModal'
        tabIndex={-1}
        aria-labelledby='AddNewAddressModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='AddNewAddressModalLabel'>
                Add New Address
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>×</span>
              </button>
            </div>
            <div className='modal-body'>
              <form onSubmit={formik.handleSubmit}>
                <div className='form-row'>
                  <div className='form-group col-md-12 select-border'>
                    <label>Please Select City</label>
                    <select
                      className='form-control border-0 '
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      name='city'
                    >
                      <option value={''}>{'All City'}</option>
                      {cities.map((item, i) => {
                        return (
                          <option key={i} value={parseInt(item.value)}>
                            {item.label}
                          </option>
                        );
                      }, this)}
                    </select>
                  </div>
                  <div className='form-group col-md-12 select-border'>
                    <label>Please Select Area</label>
                    <select
                      className='form-control border-0 '
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      name='area'
                    >
                      {' '}
                      <option key={0} value={''}>
                        Choose Area
                      </option>
                      {locations !== [] ? (
                        locations.map((item, index) => {
                          return (
                            <option key={index + 1} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })
                      ) : (
                        <option key={0} value={''}>
                          Choose Area
                        </option>
                      )}
                    </select>
                  </div>

                  <div className='form-group col-md-12'>
                    <label>Please Type Your Address </label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Please Type Your Address'
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      name='address'
                    />
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    disabled={isLoading.disabled}
                    className='btn btn-primary'
                  >
                    Done
                    <span
                      hidden={isLoading.disabled - 1}
                      className='spinner-border spinner-border-sm ml-2'
                      role='status'
                      aria-hidden='true'
                    ></span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <SuccessDialogue />
    </Fragment>
  );
};

OrderCheckout.propTypes = {
  setCart: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, { setCart })(OrderCheckout);
