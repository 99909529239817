import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import * as Yup from "yup";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import services from "../../../../Redux/reducers/services";
import { getServices } from "../../../../Redux/actions/services";
import { useFormik, yupToFormErrors } from "formik";
import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  currentUserInformation,
  multiFormHeader,
} from "../../../../Constants/global";

const HomeServices = ({ getServices, services }) => {
  if (services.length === 0 && services[0] !== "x") {
    return (
      <section className="space-ptb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <photo className="shine w-100 shimmerphoto"></photo>
            </div>
            <div className="col-md-12 mt-1">
              <lines className="shine w-100 shimmerlines"></lines>
            </div>
          </div>
        </div>
      </section>
    );
  }
  if (services[0] === "x") {
    return (
      <section className="home-services-section space-ptb">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="text-center mb-3">
                <div className="section-title text-center">
                  <h3 className="mb-3 banner-bg-slider-title text-center">
                    Service Categories
                  </h3>
                  <span className="banner-bg-slider-sub-title text-center">
                    Choose from wide range of JO Ustad Services
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12">
              <ul className="ustad-img-cards list-unstyled">
                <p>We don't have services in your area yet.</p>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="home-services-section space-ptb">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="text-center mb-3">
              <div className="section-title text-center">
                <h3 className="mb-3 banner-bg-slider-title text-center">
                  Service Categories
                </h3>
                <span className="banner-bg-slider-sub-title text-center">
                  Choose from wide range of JO Ustad Services
                </span>
              </div>
            </div>
          </div>

          <div className="col-12">
            <ul className="ustad-img-cards list-unstyled">
              {/* <li>
                <a href="ac-services">
                  <img
                    loading="lazy"
                    alt="AC Services"
                    src="/classified_assets/images/handymen-images/ac-servicesicon.png"
                  />
                  <div className="serviceTitle mt-3">
                    <h6>AC Services</h6>
                  </div>
                </a>
              </li> */}
              {services.map((item) => {
                return (
                  <li>
                    <a href={`ustad-service/${item.id}`}>
                      <img
                        loading="lazy"
                        alt="AC Services"
                        src={item.icon}
                        style={{
                          height: "60px",
                          width: "60px",
                        }}
                      />
                      <div className="serviceTitle mt-3">
                        <h6>{item.title}</h6>
                      </div>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
HomeServices.propTypes = {
  getServices: PropTypes.func.isRequired,
  services: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  services: state.services,
});
export default connect(mapStateToProps, { getServices })(HomeServices);
