import { SEARCH_BAR } from './types'
import axios from 'axios'
import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  currentUserInformation,
  multiFormHeader,
  USER_IMAGE_NOT_FOUND_URL
} from '../../Constants/global'

export const setSearchBar = () => dispatch => {
  //   ------------------------------------
  axios({
    method: 'GET',
    url: API_URL + 'api/searchby'
  }).then(response => {
    if (response.status === 200) {
      const propert_purpose = response.data?.data[0]['propert_purpose']

      const array_property_purpose = []
      let obj_property_purpose = {}
      obj_property_purpose.value = 0
      obj_property_purpose.label = 'All Purposes'
      array_property_purpose.push(obj_property_purpose)
      propert_purpose.map(item => {
        let property_purpose = {}
        property_purpose.value = item.id
        property_purpose.label = item.title
        array_property_purpose.push(property_purpose)
      })
      // setpropertyPupose(array_property_purpose);

      const propert_type = response.data?.data[1]['propert_type']

      const array_property_type = []
      let obj_property_type = {}
      obj_property_type.value = 0
      obj_property_type.label = 'All Categories'
      array_property_type.push(obj_property_type)
      propert_type.map(item => {
        let property_type = {}
        property_type.value = item.id
        property_type.label = item.title
        array_property_type.push(property_type)
      })
      // setpropertyType(array_property_type);

      const cities = response.data?.data[2]['cities']

      const array_all_cities = []
      let obj_all_cities = {}
      obj_all_cities.value = 0
      obj_all_cities.label = 'All Cities'
      array_all_cities.push(obj_all_cities)
      cities.map(item => {
        let all_cities = {}
        all_cities.value = item.id
        all_cities.label = item.city
        array_all_cities.push(all_cities)
      })
      // setCities(array_all_cities);

      // ------------------------------
      dispatch({
        type: SEARCH_BAR,
        payload: {
          array_all_cities,
          array_property_purpose,
          array_property_type
        }
      })
    }
  })
}
