import React, { Fragment, useEffect, useState } from 'react'
import PropertyInfoCard from './PropertyInfoCard'
import PropertyDetailSlider from './PropertyDetailSlider'
import PropertyDetailContact from './PropertyDetailContact'
import { useParams } from 'react-router-dom'
import { BrowserView, MobileView } from 'react-device-detect'
import axios from 'axios'

import SEO from '../../Seo'

import { API_URL, jsonHeader } from '../../../Constants/global'
import SimilarProperties from '../SimilarProperties'
import PropertyDetailSliderMob from './PropertyDetailSliderMob'

function PropertyDetails () {
  const [propertyData, setPropertyData] = useState([])

  const property_params = useParams()
  console.log(property_params.searchquery.split('-').pop())
  const property_id = property_params.searchquery.split('-').pop()

  const [property_details, setProperty_details] = useState({})
  const [userProfile, setUserProfile] = useState({})

  useEffect(() => {
    let cancelToken
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel('cancelling req')
    }
    cancelToken = axios.CancelToken.source()

    const cur_user_ID = localStorage.getItem('user_id')
      ? localStorage.getItem('user_id')
      : 0

    axios({
      method: 'GET',
      url:
        API_URL +
        'api/properties_final_data/' +
        property_id +
        '/' +
        cur_user_ID,

      headers: jsonHeader
    }).then(response => {
      if (response.status === 200) {
        console.log(response.data)
        setProperty_details(response.data?.data)
        setUserProfile(response.data?.data)
        console.log(userProfile)
      }
    })
  }, [])

  console.log(property_details)

  const add_to_favorite = e => {
    const response = axios({
      method: 'GET',
      url:
        API_URL +
        'generalservices/property_favorites_add/' +
        e.target.id +
        '/' +
        localStorage.getItem('user_id')
    }).then(response => {
      if (response.status === 200) {
        document.getElementById(e.target.id).className =
          document.getElementById(e.target.id).className === 'fa fa-heart'
            ? 'far fa-heart'
            : 'fa fa-heart'
        localStorage.setItem('total_favorites', response.data.total_favorites)
        console.log(localStorage.getItem('total_favorites'))
      }
    })
  }

  return (
    <>
      <SEO
        title={property_details.title}
        description={property_details.description}
        name='JagahOnline'
        type='property'
        url={window.location.href}
        image_url={property_details.front_image}
        keywords='sale house, rent house'
      />
      <div className='bg-light'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <ol className='breadcrumb mb-0'>
                <li className='breadcrumb-item'>
                  <a href='/'>
                    {' '}
                    <i className='fas fa-home' />
                  </a>
                </li>
                <li className='breadcrumb-item'>
                  <i className='fas fa-chevron-right' />{' '}
                  <a href='/properties'>Properties</a>
                </li>
                <li className='breadcrumb-item active'>
                  <i className='fas fa-chevron-right' />{' '}
                  <span> {property_details.title}</span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {/*=================================
Breadcrumb */}
      <div class='wrapper'>
        {/*=================================
                    Property Detail */}
        <section className='py-4'>
          <div className='container'>
            <div className='row'>
              {/* ------------------- Property Detail Title -------------------*/}
              <div className='col-lg-12 col-sm-12 col-md-12 text-left mb-3'>
                <h1>{property_details.title}</h1>
                <span className='d-block mb-3'>
                  <i className='fas fa-map-marker-alt fa-xs pr-2' />
                  {property_details.address}
                </span>
              </div>
              {/* ------------------- Property Detail Title -------------------*/}

              <div className='col-lg-4 mb-5 mb-lg-0 order-2'>
                <div className='sticky-top'>
                  {/* ------------------- Property Detail Contact Card -------------------*/}
                  <PropertyDetailContact
                    userProfile={userProfile}
                    agency_profile_id={property_details.agency_profile_id}
                    property_details={property_details}
                    id={'customCheck1'}
                  />
                  {/* ------------------- Property Detail Contact Card -------------------*/}
                </div>
              </div>
              <div className='col-lg-8 order-1'>
                {/* ------------------- Property Detail Slider -------------------*/}
                <Fragment>
                  <BrowserView>
                    <PropertyDetailSlider
                      property_details={property_details}
                      userProfile={userProfile}
                    />
                  </BrowserView>
                  <MobileView>
                    <PropertyDetailSliderMob
                      property_details={property_details}
                      userProfile={userProfile}
                    />
                  </MobileView>
                </Fragment>
                {/* ------------------- Property Detail Slider -------------------*/}

                {/* ------------------- Property Price and Sharing-------------------*/}
                <div className='row'>
                  <div className='col-lg-12 text-left d-flex justify-content-between align-items-center mt-3'>
                    {/*<span className="price font-xll text-primary d-block mt-3">{property_details.price_txt}</span>*/}
                    <div className='d-flex justify-content-start d-md-flex align-items-center mb-2'>
                      <span className='price font-xll text-primary'>
                        <span className='font-sm'>PKR</span>{' '}
                        {property_details.price_txt}
                      </span>
                      {/* <span className="sub-price font-lg text-dark"><b>$6,500/Sqft </b> </span> */}
                    </div>
                    <ul className='property-detail-meta list-unstyled mt-1 mb-3 d-flex justify-content-end'>
                      <li className='share-box'>
                        <a href='javascript:void(0);'>
                          {' '}
                          <i className='fas fa-share-alt' />{' '}
                        </a>
                        <ul className='list-unstyled share-box-social'>
                          <li>
                            <a
                              target='_blank'
                              href={
                                'https://www.facebook.com/sharer/sharer.php?u=' +
                                window.location.href
                              }
                            >
                              <i className='fab fa-facebook-f' />
                            </a>
                          </li>
                          <li>
                            <a
                              target='_blank'
                              href={
                                'https://wa.me/?text=' +
                                property_details.title +
                                ' ' +
                                window.location.href
                              }
                            >
                              <i className='fab fa-whatsapp' />
                            </a>
                          </li>
                          <li>
                            <a
                              target='_blank'
                              href={
                                'mailto:?subject=' +
                                property_details.title +
                                '&body=' +
                                window.location.href
                              }
                            >
                              <i className='fas fa-envelope' />
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className='property-favourites'>
                        {localStorage.getItem('token') !== '' ? (
                          <a href='javascript:void(0);' title='Favourite'>
                            <i
                              id={property_details.id}
                              key={property_details.id}
                              onClick={add_to_favorite}
                              className={
                                property_details.isFavorite === 0
                                  ? 'far fa-heart'
                                  : 'fa fa-heart'
                              }
                              name='favorite'
                            ></i>
                          </a>
                        ) : (
                          <a
                            data-toggle='modal'
                            data-target='#loginModal'
                            href='javascript:void(0);'
                            title='Favourite'
                          >
                            <i className='far fa-heart'></i>
                          </a>
                        )}
                      </li>
                    </ul>
                  </div>
                  {/*<div className="col-lg-6">*/}
                  {/*    <ul className="property-detail-meta list-unstyled ">*/}
                  {/*        <li className="share-box">*/}
                  {/*            <a href="#"> <i className="fas fa-share-alt"/> </a>*/}
                  {/*            <ul className="list-unstyled share-box-social">*/}
                  {/*                <li><a href="#"><i className="fab fa-facebook-f"/></a></li>*/}
                  {/*                <li><a href="#"><i className="fab fa-twitter"/></a></li>*/}
                  {/*                <li><a href="#"><i className="fab fa-linkedin"/></a></li>*/}
                  {/*                <li><a href="#"><i className="fab fa-instagram"/></a></li>*/}
                  {/*                <li><a href="#"><i className="fab fa-pinterest"/></a></li>*/}
                  {/*            </ul>*/}
                  {/*        </li>*/}
                  {/*        <li><a href="#"> <i className="fas fa-heart"/> </a></li>*/}

                  {/*    </ul>*/}
                  {/*</div>*/}
                </div>
                {/* ------------------- Property Price and Sharing-------------------*/}

                {/* ------------------- Property Info Card-------------------*/}
                <PropertyInfoCard property_details={property_details} />
                {/* ------------------- Property Info Card-------------------*/}
              </div>
            </div>
          </div>
        </section>

        <section className='space-ptb similarPropertiesSection'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-lg-8'>
                <div className='section-title text-center mb-0'>
                  <h2>Similar Properties</h2>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12 col-md-12'>
                <SimilarProperties />
              </div>
            </div>
          </div>
        </section>
        {/*=================================
                    Property Detail */}
      </div>
    </>
  )
}

export default PropertyDetails
