import React from 'react'
import { IEView } from 'react-device-detect'
import { BASE_URL } from '../../Constants/global'

function BrowseByCities () {
  const browseBycity = [
    {
      id: 14,
      title: 'Karachi',
      icon: 'karachi_icon.svg'
    },
    {
      id: 3,
      title: 'Lahore',
      icon: 'lahore_icon.svg'
    },
    {
      id: 12,
      title: 'Islamabad',
      icon: 'islamabad_icon.svg'
    },
    {
      id: 10,
      title: 'Gwadar',
      icon: 'gwadar_icon.svg'
    },
    {
      id: 170,
      title: 'Murree',
      icon: 'murree_icon.svg'
    },
    {
      id: 62,
      title: 'Rawalpindi',
      icon: 'rawalpindi_icon.svg'
    },
    {
      id: 11,
      title: 'Hyderabad',
      icon: 'hyderabad_icon.svg'
    },
    {
      id: 18,
      title: 'Multan',
      icon: 'multan_icon.svg'
    }
    // {
    //     "id": 28,
    //     "title": "Sialkot",
    //     "icon": "sialkot_icon.svg"
    // },
    // {
    //     "id": 42,
    //     "title": "Faisalabad",
    //     "icon": "faisalabad_icon.svg"
    // },
    // {
    //     "id": 54,
    //     "title": "Peshawar",
    //     "icon": "peshawar_icon.svg"
    // },
    // {
    //     "id": 0,
    //     "title": "Other Cities",
    //     "icon": "othercities.svg"
    // },
  ]

  var searchQueryGet = {}
  if (
    localStorage.getItem('searchQueryProject') !== undefined &&
    localStorage.getItem('searchQueryProject') !== ''
  ) {
    searchQueryGet = JSON.parse(localStorage.getItem('searchQueryProject')) || {
      property_purpose: 0,
      city_id: 0,
      area_id: 0,
      property_type: 1,
      min_area: 0,
      max_area: 0,
      price_min: 0,
      price_max: 0,
      beds: 0,
      keywords: ''
    }
  } else {
    searchQueryGet = {
      property_purpose: 0,
      city_id: 0,
      area_id: 0,
      property_type: 1,
      min_area: 0,
      max_area: 0,
      price_min: 0,
      price_max: 0,
      beds: 0,
      keywords: ''
    }
  }

  console.log(browseBycity)

  const citiesHandler = e => {
    searchQueryGet.city_id = e.target.id
    localStorage.setItem('searchQueryProject', JSON.stringify(searchQueryGet))

    console.log(e.target.name)
    // const allcities =
    //   e.target.name === "Other Cities" ? "All-Cities" : e.target.name;
    window.location.href = '/projects/Residential/Sale/' + e.target.name
  }

  if (browseBycity.length === 0) {
    return (
      <section className='space-ptb'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-12'>
              <photo className='shine w-100 shimmerphoto'></photo>
            </div>
            <div className='col-md-12 mt-1'>
              <lines className='shine w-100 shimmerlines'></lines>
            </div>
          </div>
        </div>
      </section>
    )
  }

  return (
    <>
      {/* <!-- Browse properties --> */}
      <section class='space-ptb'>
        <div class='container-fluid'>
          <div class='row justify-content-center'>
            <div class='col-lg-8'>
              <div class='section-title text-center mb-4'>
                <h2>Browse by City</h2>
                <p className='m-0'>
                  To browse and buy in your areas of interest, look for projects
                  by category.
                </p>
              </div>
            </div>
          </div>
          <div class='row'>
            <div className='unified-listing-categories-main'>
              <div className='bg-unified-categories'>
                {browseBycity.map(item => {
                  // alert(item.id);
                  return (
                    <div className='unified-listing-categories-item'>
                      <a
                        className='unified-listing-categories-item-href'
                        key={item.id}
                        id={item.id}
                        name={item.title}
                        data-category='Residential-Apartment'
                        tabIndex={0}
                        onClick={citiesHandler}
                      >
                        <div className='main-category-item'>
                          <img
                            key={item.id}
                            id={item.id}
                            name={item.title}
                            className='img-fluid sub_categories-svg-icon'
                            src={
                              '/classified_assets/images/projectCity/' +
                              item.icon
                            }
                            loading='lazy'
                          />
                        </div>
                        <h6 className='mb-0'>{item.title}</h6>
                      </a>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--Browse properties --> */}
    </>
  )
}

export default BrowseByCities
