import React, { Fragment, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import emailjs from '@emailjs/browser'
import { connect } from 'react-redux'
import { setAuthUser } from '../../../../Redux/actions/authUser'
import PropTypes from 'prop-types'

import { auth, provider, fprovider } from '../../../../socialauth/config'

import { signInWithPopup } from 'firebase/auth'

import {
  API_URL,
  BASE_URL,
  EMAIL_PUBLIC_KEY,
  EMAIL_SERVICE_ID,
  EMAIL_VERIFICATION_TEMPLATE_ID,
  multiFormHeader
} from '../../../../Constants/global'
import { width } from '@mui/system'

const LoginPage = ({ setAuthUser }) => {
  const [passwordType, setPasswordType] = useState({
    passwordType: 'password',
    eyeIconClass: 'fa fa-eye-slash eyeIcon',
    eyeColorClass: ''
  })

  function togglePassword () {
    if (passwordType.passwordType === 'password') {
      setPasswordType({
        passwordType: 'text',
        eyeIconClass: 'fa fa-eye eyeIcon',
        eyeColorClass: 'text-primary'
      })
    } else {
      setPasswordType({
        passwordType: 'password',
        eyeIconClass: 'fa fa-eye-slash eyeIcon',
        eyeColorClass: ''
      })
    }
  }

  // console.log("APIERROR:::" + apierror);
  const [userDetails, setUserDetails] = useState({
    first_name: '',
    to_email: '',
    remember_pin: ''
  })
  const [isLoadingVerify, setIsLoadingVerify] = useState({
    isLoading: false,
    disabled: false
  })
  const [apierror, setApierror] = useState('')

  const [isLoading, setIsLoading] = useState({
    isLoading: false,
    disabled: false
  })

  const initialValues = {
    email: '',
    password: ''
  }

  const onSubmit = values => {
    const { email, password } = values
    setIsLoading({
      isLoading: true,
      disabled: true
    })

    setUserDetails({ to_email: values.email })
    console.log(values)

    // auth({ email, password });
    var form = new FormData()
    form.append('email', email)
    form.append('password', password)

    axios({
      method: 'POST',
      url: API_URL + 'auth/login',
      data: form,
      headers: multiFormHeader
    }).then(response => {
      if (response.data.status === 200) {
        setIsLoading({
          isLoading: false,
          disabled: false
        })
        // localStorage.setItem("token", response.data["token"]);
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('user', response.data.data)
        localStorage.setItem('user_type', response.data?.data.user_type)
        if (response.data?.data.user_type === 'Agent') {
          localStorage.setItem(
            'agency_profile_id',
            response.data?.data.agency_profile.id
          )
        }
        // response.data.data.agency_profile.id

        setAuthUser()

        // localStorage.setItem("role_id", response.data.data["role"]);
        localStorage.setItem('total_favorites', response.data.total_favorites)
        // localStorage.setItem("user_id", response.data.data["id"]);

        // alert(response.data.user_id);
        window.location.href = '/'
      } else {
        setIsLoading({
          isLoading: false,
          disabled: false
        })
        setApierror(response.data.message)
        localStorage.setItem('user_id', response.data.user_id)
      }
    })
  }

  const validateSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email format').required('Required'),
    password: Yup.string().required('Required')
  })

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validateSchema
  })

  function sendVerification () {
    setIsLoadingVerify({
      isLoading: true,
      disabled: true
    })
    axios({
      method: 'GET',
      url:
        API_URL +
        `api/resend_verification_code/${localStorage.getItem('user_id')}`,

      headers: multiFormHeader
    }).then(response => {
      if (response.data.status === 200) {
        setIsLoadingVerify({
          isLoading: false,
          disabled: false
        })
        var templateParams = {
          name: response.data.user.first_name,
          to_email: response.data.user.email,
          remember_pin: response.data.code
          // email_verify_url: BASE_URL + "/verify/" + response.data?.user_id,
        }
        localStorage.setItem('remember_pin', response.data.code)
        emailjs
          .send(
            EMAIL_SERVICE_ID,
            EMAIL_VERIFICATION_TEMPLATE_ID,
            templateParams,
            EMAIL_PUBLIC_KEY
          )
          .then(result => {})

        window.location.href = `/verify/${localStorage.getItem('user_id')}`
      } else {
        setIsLoadingVerify({
          isLoading: false,
          disabled: false
        })
      }
    })
  }

  const [googleLogin, setGoogleLogin] = useState('')
  const handleSocialLogin = () => {
    signInWithPopup(auth, provider).then(data => {
      if (data) {
        console.log(data.user)
        var form = new FormData()
        form.append('email', data.user.email)
        form.append('first_name', data.user.displayName)
        form.append('last_name', '')
        form.append(
          'phone_number',
          data.user.phoneNumber ? data.user.phoneNumber : ''
        )
        form.append('role', 5)
        form.append('password', '')
        form.append('provider_id', data.user.uid)

        axios({
          method: 'POST',
          url: API_URL + 'auth/socialLogin',
          data: form,
          headers: multiFormHeader
        }).then(response => {
          if (response.data.status === 200) {
            setIsLoading({
              isLoading: false,
              disabled: false
            })
            // localStorage.setItem("token", response.data["token"]);
            localStorage.setItem('token', response.data.token)
            localStorage.setItem('user', response.data.data)
            localStorage.setItem('user_type', response.data?.data.user_type)
            if (response.data?.data.user_type === 'Agent') {
              localStorage.setItem(
                'agency_profile_id',
                response.data?.data.agency_profile.id
              )
            }
            // response.data.data.agency_profile.id

            setAuthUser()

            // localStorage.setItem("role_id", response.data.data["role"]);
            localStorage.setItem(
              'total_favorites',
              response.data.total_favorites
            )
            // localStorage.setItem("user_id", response.data.data["id"]);

            // alert(response.data.user_id);
            window.location.href = '/'
          } else {
            setIsLoading({
              isLoading: false,
              disabled: false
            })
            setApierror(response.data.message)
            localStorage.setItem('user_id', response.data.user_id)
          }
        })
      }
      // console.log(data.user)
      // setGoogleLogin(data.user.email)
      // console.log(googleLogin)
    })
  }

  const [facebookLogin, setFacebookLogin] = useState('')
  const handleFacebookLogin = () => {
    signInWithPopup(auth, fprovider).then(data => {
      console.log(data)
      setFacebookLogin(data.user.email)
      console.log(facebookLogin)
    })
  }

  return (
    <Fragment>
      <section className='auth-section space-ptb'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-md-6 order-md-1 order-2'>
              <div className='auth-column shadow p-4'>
                <div className='login-heading-section'>
                  <h4 className='text-heading'>Hey! Welcome</h4>
                  <p>Login to reach out hundreds of verified buyers daily.</p>
                </div>
                <form
                  className='form-row mt-4 align-items-center'
                  onSubmit={formik.handleSubmit}
                >
                  <div className='form-group col-sm-12'>
                    <input
                      type='text'
                      className='form-control'
                      name='email'
                      id='email'
                      placeholder='Email'
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className='error text-danger'>
                        {' '}
                        {formik.errors.email}{' '}
                      </div>
                    ) : null}
                  </div>
                  {/* {error ? <span style={{ fontWeight: 'bold', color: 'red' }}>{email_error}</span> : <span> </span>} */}

                  <div className='form-group col-sm-12 iconIn'>
                    <input
                      type={passwordType.passwordType}
                      className='form-control'
                      name='password'
                      id='password'
                      placeholder='Password'
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                    <span
                      onClick={togglePassword}
                      type='button'
                      className={passwordType.eyeColorClass}
                    >
                      {' '}
                      <i className={passwordType.eyeIconClass}></i>
                    </span>

                    {formik.touched.password && formik.errors.password ? (
                      <div className='error text-danger'>
                        {' '}
                        {formik.errors.password}{' '}
                      </div>
                    ) : null}
                  </div>
                  <div className='col-sm-12'>
                    {apierror ? (
                      <p className='error text-danger'> {apierror} </p>
                    ) : null}
                    {apierror === 'Email is not verified.' ? (
                      <a
                        href='javascript:void(0);'
                        onClick={sendVerification}
                        disabled={isLoadingVerify.disabled}
                      >
                        <p>
                          {' '}
                          Click here to verify
                          <span
                            hidden={isLoadingVerify.disabled - 1}
                            className='spinner-border spinner-border-sm ml-2'
                            role='status'
                            aria-hidden='true'
                          ></span>
                        </p>
                      </a>
                    ) : null}
                  </div>

                  <div className='col-sm-6'>
                    <button
                      disabled={isLoading.disabled}
                      type='submit'
                      className='btn btn-primary btn-block'
                    >
                      Login
                      <span
                        hidden={isLoading.disabled - 1}
                        className='spinner-border spinner-border-sm ml-2'
                        role='status'
                        aria-hidden='true'
                      ></span>
                    </button>
                  </div>

                  <div className='col-sm-6 text-right'>
                    <ul className='list-unstyled mb-1 mt-sm-0 mt-3'>
                      <li className='mr-1'>
                        <a href='forgotpassword'>
                          <b>Forget Password?</b>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='col-sm-12 mt-3'>
                    <hr />
                    <div className='text-center'>
                      Don't have an account yet? It's absolutely free
                      <br />
                      <a href='/register' className='register-now'>
                        <span className='text-center mb-0'>
                          <b>Register Now</b>
                        </span>
                      </a>
                    </div>
                  </div>
                </form>
                <div className='col-sm-12 mt-3 text-center cursor-pointer'>
                  <img
                    src='https://jocdn.sfo3.cdn.digitaloceanspaces.com/classified_images%2Fwebsite_assets%2Fimages%2Fgoogle-signin-button.png'
                    onClick={handleSocialLogin}
                    className='image_size '
                  />
                </div>

                {/* <div className='col-sm-12 mt-3'>
                  <button onClick={handleFacebookLogin}>
                    Signin with Facebook
                  </button>
                  <p>{facebookLogin}</p>
                </div>
                    */}
              </div>
            </div>
            <div className='col-md-6 order-md-2 order-1 mb-md-0 mb-3'>
              <img
                className='img-fluid w-100 text-center'
                src={
                  BASE_URL +
                  'classified_assets/images/handymen-images/auth/login-vector.svg'
                }
              />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}

LoginPage.propTypes = {
  // user_name: PropTypes.object.isRequired,
  setAuthUser: PropTypes.func.isRequired
}
const mapStateToProps = state => ({
  // user_name: state.user_profile,
})
export default connect(mapStateToProps, { setAuthUser })(LoginPage)
