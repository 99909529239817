import { useEffect, useState } from 'react'
import axios from 'axios'

import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  multiFormHeader
} from '../Constants/global'

function SeoFetchData (url) {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    axios
      .get(url, { headers: jsonHeader })
      .then(response => {
        console.log(response)
        setData(response.data)
      })
      .catch(err => {
        setError(err)
      })
      .finally(() => {})
  }, [url])

  return { data, loading, error }
}

export default SeoFetchData
