import { SET_AUTH_USER } from './types'
import axios from 'axios'
import {
  API_URL,
  BASE_URL,
  themeDefault,
  namesOfModes,
  jsonHeader,
  currentUserInformation,
  multiFormHeader,
  USER_IMAGE_NOT_FOUND_URL
} from '../../Constants/global'

export const setAuthUser = () => dispatch => {
  if (localStorage.getItem('token') !== '') {
    axios({
      method: 'GET',
      url: API_URL + 'api/user_profile',
      headers: multiFormHeader
    }).then(response => {
      var user_name =
        response.data.data.first_name + ' ' + response.data.data.last_name
      var profile_image = response.data.data.profile_image
      // -----------------------------------------------
      var user_id = response.data.data.id
      var email = response.data.data.email
      var first_name = response.data.data.first_name
      var last_name = response.data.data.last_name
      var phone_number = response.data.data.phone_number
      var role_id = response.data.data.role

      // ----------------------------------------------
      if (profile_image === null || profile_image === '') {
        profile_image = USER_IMAGE_NOT_FOUND_URL
      }
      localStorage.setItem('user_name', user_name)
      localStorage.setItem('profile_image', profile_image)
      localStorage.setItem('user_id', user_id)
      localStorage.setItem('email', email)
      localStorage.setItem('first_name', first_name)
      localStorage.setItem('last_name', last_name)
      localStorage.setItem('phone_number', phone_number)
      localStorage.setItem('role_id', role_id)

      dispatch({
        type: SET_AUTH_USER,
        payload: {
          user_name,
          profile_image,
          user_id,
          email,
          first_name,
          last_name,
          phone_number,
          role_id
        }
      })
    })
  }
}
