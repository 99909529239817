import React, { Fragment, useEffect } from 'react'
import { BASE_URL } from '../../../Constants/global'
import { connect } from 'react-redux'
import { handleFavorite } from '../../../Redux/actions/favorite'
import { setAuthUser } from '../../../Redux/actions/authUser'
import PropTypes from 'prop-types'
import authUser from '../../../Redux/reducers/authUser'
function resetFavorites () {
  localStorage.setItem('total_favorites', 0)
}

const TopHeader = ({
  total_favorites,
  handleFavorite,
  setAuthUser,
  // user_name,
  // profile_image,
  authUser
}) => {
  useEffect(() => {
    if (authUser.user_name === '') {
      setAuthUser()
    }
  }, [authUser])
  // useEffect(() => {
  //   handleFavorite();
  //   console.log(total_favorites);
  // }, [handleFavorite]);
  console.log(authUser.user_name + ' ' + authUser.profile_image)
  return (
    <>
      <div className='topbar'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <div className='d-flex d-md-flex align-items-center text-left justify-content-between'>
                <div className='left-panel'>
                  <div className='mr-3 d-inline-block'>
                    <a
                      href='tel:080014786'
                      className='d-flex align-items-center'
                    >
                      <span className='call-icon-main pr-1'>
                        <img
                          className='img-fluid callIcon'
                          src={
                            BASE_URL + 'classified_assets/images/phone_icon.svg'
                          }
                          alt='logo'
                        />
                      </span>
                      <span className='number-id'>0800-14-786</span>
                    </a>
                  </div>
                  <div className='mr-3 d-inline-block'>
                    <a
                      href='tel:03302244786'
                      className='d-flex align-items-center'
                    >
                      <span className='call-icon-main pr-1'>
                        <img
                          className='img-fluid callIcon'
                          src={
                            BASE_URL +
                            'classified_assets/images/whatsapp_icon.svg'
                          }
                          alt='logo'
                        />
                      </span>
                      <span className='number-id'>0330-22-44-786</span>
                    </a>
                  </div>
                </div>
                {/* <div className="d-inline-block">
                  <a href="/buy" className="nav-link">
                    Buy
                  </a>

                </div>

                <div className="d-inline-block">
                  <a href="/rent" className="nav-link">
                    Rent
                  </a>
                </div>  */}
                {/*<div className="d-inline-block">*/}
                {/*  <a href="/shop" className="nav-link" >*/}
                {/*    Jo Mall*/}
                {/*  </a>*/}
                {/*</div>*/}

                {/* <div className="mr-auto d-inline-block">
                 <a href="/ustad" className="nav-link pr-1" >
                   Jo Ustad
                 </a>
                </div> */}

                <div className='right-panel d-flex align-items-center'>
                  {localStorage.getItem('token') !== null &&
                  localStorage.getItem('token') !== '' ? (
                    <Fragment>
                      <div className='login d-inline-block save-to-fav-nav position-relative mr-2'>
                        <a
                          href='/my-favorites'
                          className='dropdown-toggle userbox-toggle'
                        >
                          {' '}
                          <span className='fav-icon-main'>
                            <img
                              className='img-fluid favIcon'
                              src={
                                BASE_URL +
                                'classified_assets/images/favorite_icon.svg'
                              }
                              alt='logo'
                            />
                            <span className='fav-count'>
                              {total_favorites[0]}
                            </span>
                          </span>
                        </a>
                      </div>

                      <div className='dropdown mr-0'>
                        <button
                          className='btn dropdown-toggle text-white p-0'
                          type='button'
                          id='dropdownMenuButton'
                          data-toggle='dropdown'
                          aria-haspopup='true'
                          aria-expanded='false'
                        >
                          <span className='user-icon-main'>
                            {authUser.profile_image ? (
                              <img
                                className='img-fluid userIcon profileImg'
                                src={authUser.profile_image}
                                alt='logo'
                              />
                            ) : (
                              <img
                                className='img-fluid userIcon'
                                src='https://revamp.jotesting.com/classified_assets/images/user_icon.svg'
                                alt='logo'
                              />
                            )}
                          </span>
                          <i className='fas fa-chevron-down pl-1 p-0' />
                        </button>
                        <div
                          className='dropdown-menu'
                          aria-labelledby='dropdownMenuButton'
                        >
                          <div className='userNameMain'>
                            {authUser.user_name}{' '}
                          </div>
                          <hr className='m-1' />
                          <a
                            className='dropdown-item px-1 font-weight-normal'
                            href='/my-properties'
                          >
                            <i className='fas fa-building pr-1'></i> My
                            Properties
                          </a>
                          <a
                            className='dropdown-item px-1 font-weight-normal'
                            href='/profile'
                          >
                            <i className='fas fa-id-card pr-1'></i> Profile
                          </a>
                          <a
                            className='dropdown-item px-1 font-weight-normal'
                            onClick={resetFavorites}
                            href='/logout'
                          >
                            <i className='fas fa-door-open pr-1'></i> Logout
                          </a>
                        </div>
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className='login d-inline-block save-to-fav-nav position-relative mr-2'>
                        <a href='/login'>
                          <span className='fav-icon-main'>
                            <img
                              className='img-fluid favIcon'
                              src={
                                BASE_URL +
                                'classified_assets/images/favorite_icon.svg'
                              }
                              alt='logo'
                            />
                            <span className='fav-count'>0</span>
                          </span>
                        </a>
                      </div>
                      <div class='login d-inline-block'>
                        <a href='/login'>
                          <span className='user-icon-main'>
                            <img
                              className='img-fluid userIcon'
                              src={
                                BASE_URL +
                                'classified_assets/images/user_icon.svg'
                              }
                              alt='logo'
                            />
                          </span>
                        </a>
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
TopHeader.propTypes = {
  total_favorites: PropTypes.array.isRequired,
  handleFavorite: PropTypes.func.isRequired,
  setAuthUser: PropTypes.func.isRequired,
  // user_name: PropTypes.string.isRequired,
  // profile_image: PropTypes.string.isRequired,
  authUser: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  total_favorites: state.favorite,
  // user_name: state.authUser,
  // profile_image: state.authUser,
  authUser: state.authUser
})
export default connect(mapStateToProps, { handleFavorite, setAuthUser })(
  TopHeader
)
