import React, { Fragment, useEffect, useState } from 'react'
import ProjectDetailContact from './ProjectDetailContact'
import ProjectInfoCard from './ProjectInfoCard'
import { BrowserView, MobileView } from 'react-device-detect'

import { useParams } from 'react-router-dom'
import axios from 'axios'

import { API_URL, jsonHeader } from '../../../Constants/global'
import ProjectDetailSliderV2 from './ProjectDetailSliderV2'
import ProjectDetailSliderMob from './ProjectDetailSliderMob'

function ProjectDetail () {
  const project_params = useParams()
  console.log(project_params.searchquery.split('-').pop())
  const project_id = project_params.searchquery.split('-').pop()

  const [project_details, setProject_details] = useState({})
  const [imagesData, setimagesData] = useState([])
  const [userProfile, setUserProfile] = useState({})

  useEffect(() => {
    async function fetchProject () {
      axios({
        method: 'GET',
        url: API_URL + 'api/project/' + project_id,

        headers: jsonHeader
      }).then(response => {
        if (response.status === 200) {
          console.log(response.data)
          setProject_details(response.data?.data)
          setUserProfile(response.data?.data.user_profile)
          // setUserProfile(response.data?.data.user_profile)
          // console.log(userProfile)
        }
      })
    }

    fetchProject()

    async function fetchProjectImages () {
      axios({
        method: 'GET',
        url: API_URL + 'api/project_images_byid/' + project_id,

        headers: jsonHeader
      }).then(response => {
        console.log(response.data)
        setimagesData(response.data?.data)
      })
    }

    fetchProjectImages()
  }, [])

  console.log(project_details)

  return (
    <>
      <div className='bg-light'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <ol className='breadcrumb mb-0'>
                <li className='breadcrumb-item'>
                  <a href='/'>
                    {' '}
                    <i className='fas fa-home' />{' '}
                  </a>
                </li>
                <li className='breadcrumb-item'>
                  {' '}
                  <i className='fas fa-chevron-right' />{' '}
                  <a href='/project-list'>Projects</a>
                </li>
                <li className='breadcrumb-item active'>
                  {' '}
                  <i className='fas fa-chevron-right' />{' '}
                  <span> {project_details.title}</span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className='wrapper'>
        {/*=================================
                    Property Detail */}
        <section className='py-4'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-8 col-md-6'>
                <div className='property-detail-title'>
                  <h1 className='mb-3'>{project_details.title}</h1>
                  <span className='d-block mb-2'>
                    <i className='fas fa-map-marker-alt fa-xs pr-2' />
                    {project_details.area}
                  </span>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='price-meta'>
                  <div className='d-flex justify-content-start d-md-flex justify-content-md-end mb-2'>
                    <span className='price font-xl text-primary font-weight-bold'>
                      <span className='font-md text-secondary'>
                        Starting From:{' '}
                      </span>
                      <span className='font-md'>PKR</span>{' '}
                      {project_details.price_txt}
                    </span>
                    {/* <span className="sub-price font-lg text-dark"><b>$6,500/Sqft </b> </span> */}
                  </div>
                  <ul className='property-detail-meta list-unstyled mt-1 mb-3 d-flex justify-content-start d-md-flex justify-content-md-end'>
                    <li className='share-box'>
                      <a href='javascript:void(0);'>
                        {' '}
                        <i className='fas fa-share-alt' />{' '}
                      </a>
                      <ul className='list-unstyled share-box-social'>
                        <li>
                          <a
                            target='_blank'
                            href={
                              'https://www.facebook.com/sharer/sharer.php?u=' +
                              window.location.href
                            }
                          >
                            <i className='fab fa-facebook-f' />
                          </a>
                        </li>
                        <li>
                          <a
                            target='_blank'
                            href={
                              'https://wa.me/?text=' +
                              project_details.title +
                              ' ' +
                              window.location.href
                            }
                          >
                            <i className='fab fa-whatsapp' />
                          </a>
                        </li>
                        <li>
                          <a
                            target='_blank'
                            href={
                              'mailto:?subject=' +
                              project_details.title +
                              '&body=' +
                              window.location.href
                            }
                          >
                            <i className='fas fa-envelope' />
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <ProjectDetailSlider /> */}

            <Fragment>
              <BrowserView>
                <div className='row align-items-center'>
                  <div className='col-md-12'>
                    <ProjectDetailSliderV2
                      project_details={project_details}
                      userProfile={userProfile}
                    />
                  </div>
                </div>
              </BrowserView>
              <MobileView>
                <div className='row align-items-center'>
                  <div className='col-md-12 p-0'>
                    <ProjectDetailSliderMob
                      property_details={project_details}
                      userProfile={userProfile}
                    />
                  </div>
                </div>
              </MobileView>
            </Fragment>

            <div className='row mt-4'>
              <div className='col-lg-4 mb-5 mb-lg-0 order-2'>
                <div className='sticky-top project-sticky-top'>
                  {/* ------------------- Property Detail Contact Card -------------------*/}
                  <ProjectDetailContact projectDetails={project_details} />
                  {/* ------------------- Property Detail Contact Card -------------------*/}
                </div>
              </div>

              <div className='col-lg-8 order-1'>
                {/* ------------------- Property Info Card-------------------*/}
                <ProjectInfoCard projectDetails={project_details} />
                {/* ------------------- Property Info Card-------------------*/}
              </div>
            </div>
          </div>
        </section>
        {/*=================================
                    Property Detail */}
      </div>
    </>
  )
}

export default ProjectDetail
