import React from 'react'
import {BrowserView, MobileView} from 'react-device-detect';
import "../../css/jo-projects.css";

import {BASE_URL,} from '../../../../Constants/global'

function QaswaaResidency() {

    return (
        <main className="joProject">
            {/*JO Project Browser View*/}
            <BrowserView>
                <div className="BrowserView">
                    {/*Project Header*/}
                    <header className="header-stick pj-is-sticky">
                        <div className="container-fluid bg-dark">
                            <nav className="navbar navbar-expand-lg navbar-dark bg-dark header-sticky-single">
                                <div className="project-logo-main">
                                    <a className="navbar-brand ml-2" href="#">
                                        <img className="img-fluid" width={175}
                                             height={46}
                                             src={BASE_URL + "classified_assets/images/logo-light.svg"}
                                             alt="" loading="lazy"/>
                                    </a>
                                    <a className="navbar-brand mx-4" href="#">
                                        <img className="img-fluid" width={165}
                                             src={BASE_URL + "classified_assets/images/jo-projects/qaswaa/qaswaa_logo.png"}
                                             alt="" loading="lazy"/>
                                    </a>
                                </div>
                                <button className="navbar-toggler" type="button" data-toggle="collapse"
                                        data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02"
                                        aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"/>
                                </button>
                                <div className="collapse navbar-collapse project-nav-jo" id="navbarTogglerDemo02">
                                    <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                                        <li className="nav-item">
                                            <a className="nav-link" href="#features">Features <span
                                                className="sr-only">(current)</span></a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#developer">About the Developer</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#location">Location</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#payment">Payment Plan</a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </header>
                    {/*Project Header*/}

                    {/*Banner Header Project*/}
                    <section className="banner-header-project"
                             style={{backgroundImage: 'url(../../../classified_assets/images/jo-projects/qaswaa/qaswaa_banner.jpg)'}}/>
                    {/*Banner Header Project*/}

                    {/*Project Features*/}
                    <section id="features" className="jp-features-section space-ptb">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center mb-5">
                                    <h1>Features</h1>
                                </div>
                            </div>
                            <div className="jp-features-main">
                                <div className="row">
                                    <div className="col-md-4 mb-5">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/al-ahmed/boundarywalled.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Boundary Walled
                                                </h6>
                                                <p>Qaswaa Residency Phase 3 is
                                                    one of the very few boundary
                                                    walled residential project of
                                                    Larkana.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-5">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/al-ahmed/residentialunits.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Residential Units
                                                </h6>
                                                <p>Qaswaa Residency Phase 3
                                                    offers 150, 166, 200, 266 and 400
                                                    Sqyd residential plots.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-5">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/king-comfort-v2/approvals.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Approvals</h6>
                                                <p>Qaswaa Residency Phase 3 is
                                                    approved from all the concerned
                                                    authorities.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-5">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/al-ahmed/planning.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Planning</h6>
                                                <p>Qaswaa Residency Phase 3 is
                                                    planned on international
                                                    standards with world class
                                                    amenities.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-5">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/qaswaa/modernwiring.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Modern Wiring System
                                                </h6>
                                                <p>Qaswaa Residency Phase 3
                                                    offers modern wiring system
                                                    which is underground.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-5">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/qaswaa/turkishmosque.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Turkish Design Mosque
                                                </h6>
                                                <p>Qaswaa Residency Phase 3
                                                    offers Larkana’s first Turkish
                                                    Design Grand Mosque.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*Project Features*/}

                    {/*Project Developer*/}
                    <section id="developer" className="about-builder-section space-ptb">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-4">
                                    <div className="about-developer-img-main">
                                        <img className="about-developer-img img-fluid" alt="img"
                                             src={BASE_URL + "classified_assets/images/jo-projects/kings-scenic/kingsscenic-mid.jpg"}
                                             loading="lazy"/>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="about-developer-descp text-white">
                                        <h1>
                                            About <span>About Qaswaa Builders & Developers</span>
                                        </h1>
                                        <p>Qaswaa Builders & Developers is Larkana’s top builder and developer who has
                                            given numerous projects in Larkana.
                                            Qaswaa Residency is one of the most successful and renowned project of
                                            Larkana which is defining new standards
                                            of living in Larkana</p>
                                        <p>Qaswaa Residency Phase 3 is another project of Qaswaa Builders & Developers
                                            after successfully delivering 2 Phases
                                            of Qaswaa Residency. Here Qaswaa offering numerous sizes residential plot
                                            with one unit and double storey villas.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*Project Developer*/}

                    {/*Project Location*/}
                    <section id="location" className="jo-proj-loc-main space-ptb">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    <div className="jo-proj-loc-main-inner mb-4">
                                        <div className="jo-location-heading">
                                            <span className="line-horizontal">Location <span>Highlights</span></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="jo-location-points">
                                        <div className="jo-location-points-route-km">
                                            4 KM FROM
                                        </div>
                                        <div className="jo-location-points-route">
                                            POLICE STATION TALUKA LARKANA
                                        </div>
                                    </div>
                                    <div className="jo-location-points">
                                        <div className="jo-location-points-route-km">
                                            6 KM FROM
                                        </div>
                                        <div className="jo-location-points-route">
                                            POLICE TRAINING SCHOOL
                                        </div>
                                    </div>
                                    <div className="jo-location-points">
                                        <div className="jo-location-points-route-km">
                                            8 KM FROM
                                        </div>
                                        <div className="jo-location-points-route">
                                            SZABIST LARKANA
                                        </div>
                                    </div>
                                    <div className="jo-location-points d-flex align-items-center">
                                        <div className="jo-location-points-route">
                                            LOCATED ON INDUS HIGHWAY
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="jo-location-iframe">
                                        <iframe className="w-100"
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3536.942632285422!2d68.15466591548012!3d27.56429083837015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3935ab2c4c4f7c4b%3A0xc69a85800a6c2c52!2sQaswaa%20Residency%20(III)!5e0!3m2!1sen!2s!4v1653139464415!5m2!1sen!2s"
                                                height={450} style={{border: 0}} allowFullScreen loading="lazy"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*Project Location*/}

                    {/*Project Payment*/}
                    <section id="payment" className="inns-plans-section space-pt">
                        <div className="container">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-md-3">
                                    <div className="starting-from-main">
                                        <div className="starting-from-inner">
                                            <div className="black-head">Prices Starting From</div>
                                            <div className="read-head"><span>PKR</span> 6,075,000</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 text-center">
                                    <div className="starting-from-main">
                                        <div className="starting-from-img-logo">
                                            <img className="starting-from-img-lg img-fluid" alt="img"
                                                 src={BASE_URL + "classified_assets/images/jo-projects/qaswaa/qaswaa_logo2.jpg"}
                                                 loading="lazy"/>
                                        </div>
                                        <div className="starting-from-main-inner-head mt-0">
                                            <span className="line-horizontal">Residential <span>Plots</span></span>
                                        </div>
                                        <div className="starting-from-img-main">
                                            <img className="starting-from-img-mn img-fluid" alt="img"
                                                 src={BASE_URL + "classified_assets/images/jo-projects/qaswaa/qaswaa_unit.jpg"}
                                                 loading="lazy"/>
                                        </div>
                                        <div className="read-head-blk"><span>30 Months</span> Easy Installment Plan</div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="starting-from-main">
                                        <div className="starting-from-inner">
                                            <div className="black-head">Installments Starting From</div>
                                            <div className="read-head"><span>PKR</span> 121,500</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*Project Payment*/}
                </div>
            </BrowserView>
            {/*JO Project Browser View*/}

            {/*JO Project Mobile View*/}
            <MobileView>
                <div className="MobileView">
                    {/*Project Header*/}
                    <header className="header-stick">
                        <div className="container-fluid bg-dark">
                            <nav className="navbar navbar-expand-lg navbar-dark bg-dark header-sticky-single">
                                <div className="project-logo-main d-flex">
                                    <div className="col-md-6">
                                        <a className="navbar-brands" href="#">
                                            <img className="img-fluid w-100"
                                                 style={{marginTop: '10px'}}
                                                 src={BASE_URL + "classified_assets/images/logo-light.svg"}
                                                 alt="" loading="lazy"/>
                                        </a>
                                    </div>
                                    <div className="col-md-6">
                                        <a className="navbar-brands" href="#">
                                            <img className="img-fluid w-100"
                                                 style={{padding: '5px'}}
                                                 src={BASE_URL + "classified_assets/images/jo-projects/qaswaa/qaswaa_logo.png"}
                                                 alt="" loading="lazy"/>
                                        </a>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </header>
                    {/*Project Header*/}

                    {/*Banner Header Project*/}
                    <section className="banner-header-project"
                             style={{backgroundImage: 'url(../../../classified_assets/images/jo-projects/qaswaa/qaswaa_banner.jpg)'}}/>
                    {/*Banner Header Project*/}

                    {/*Project Features*/}
                    <section id="features" className="jp-features-section space-ptb">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center mb-3">
                                    <h1>Features</h1>
                                </div>
                            </div>
                            <div className="jp-features-main">
                                <div className="row">
                                    <div className="col-md-4 mb-3">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/al-ahmed/boundarywalled.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Boundary Walled
                                                </h6>
                                                <p>Qaswaa Residency Phase 3 is
                                                    one of the very few boundary
                                                    walled residential project of
                                                    Larkana.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/al-ahmed/residentialunits.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Residential Units
                                                </h6>
                                                <p>Qaswaa Residency Phase 3
                                                    offers 150, 166, 200, 266 and 400
                                                    Sqyd residential plots.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/king-comfort-v2/approvals.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Approvals</h6>
                                                <p>Qaswaa Residency Phase 3 is
                                                    approved from all the concerned
                                                    authorities.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/al-ahmed/planning.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Planning</h6>
                                                <p>Qaswaa Residency Phase 3 is
                                                    planned on international
                                                    standards with world class
                                                    amenities.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/qaswaa/modernwiring.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Modern Wiring System
                                                </h6>
                                                <p>Qaswaa Residency Phase 3
                                                    offers modern wiring system
                                                    which is underground.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <div className="jo-features-details-box text-left">
                                            <div className="jo-features-box-icon">
                                                <img className="jo-features-us-social img-fluid" alt="img"
                                                     src={BASE_URL + "classified_assets/images/jo-projects/qaswaa/turkishmosque.svg"}
                                                     loading="lazy"/>
                                            </div>
                                            <div className="jo-features-box-detail-inner">
                                                <h6>Turkish Design Mosque
                                                </h6>
                                                <p>Qaswaa Residency Phase 3
                                                    offers Larkana’s first Turkish
                                                    Design Grand Mosque.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*Project Features*/}

                    {/*Project Developer*/}
                    <section id="developer" className="about-builder-section space-ptb">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-5">
                                    <div className="about-developer-img-main">
                                        <img className="about-developer-img img-fluid" alt="img"
                                             src={BASE_URL + "classified_assets/images/jo-projects/kings-scenic/kingsscenic-mid.jpg"}
                                             loading="lazy"/>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <div className="about-developer-descp text-white">
                                        <h1>
                                            About <span>About Qaswaa Builders & Developers</span>
                                        </h1>
                                        <p>Qaswaa Builders & Developers is Larkana’s top builder and developer who has given numerous projects in Larkana.
                                            Qaswaa Residency is one of the most successful and renowned project of Larkana which is defining new standards
                                            of living in Larkana.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="about-developer-descp text-white">
                                        <p className="mb-0">
                                            Qaswaa Residency Phase 3 is another project of Qaswaa Builders & Developers after successfully delivering 2 Phases
                                            of Qaswaa Residency. Here Qaswaa offering numerous sizes residential plot with one unit and double storey villas.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*Project Developer*/}

                    {/*Project Location*/}
                    <section id="location" className="jo-proj-loc-main space-ptb">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    <div className="jo-proj-loc-main-inner mb-4">
                                        <div className="jo-location-heading">
                                            <span className="line-horizontal">Location <span>Highlights</span></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="jo-location-points">
                                        <div className="jo-location-points-route-km">
                                            4 KM FROM
                                        </div>
                                        <div className="jo-location-points-route">
                                            POLICE STATION TALUKA LARKANA
                                        </div>
                                    </div>
                                    <div className="jo-location-points">
                                        <div className="jo-location-points-route-km">
                                            6 KM FROM
                                        </div>
                                        <div className="jo-location-points-route">
                                            POLICE TRAINING SCHOOL
                                        </div>
                                    </div>
                                    <div className="jo-location-points">
                                        <div className="jo-location-points-route-km">
                                            8 KM FROM
                                        </div>
                                        <div className="jo-location-points-route">
                                            SZABIST LARKANA
                                        </div>
                                    </div>
                                    <div className="jo-location-points d-flex align-items-center">
                                        <div className="jo-location-points-route">
                                            LOCATED ON INDUS HIGHWAY
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="jo-location-iframe">
                                        <iframe class="w-100"
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3536.942632285422!2d68.15466591548012!3d27.56429083837015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3935ab2c4c4f7c4b%3A0xc69a85800a6c2c52!2sQaswaa%20Residency%20(III)!5e0!3m2!1sen!2s!4v1653139464415!5m2!1sen!2s"
                                                height={450} style={{border: 0}} allowFullScreen loading="lazy"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*Project Location*/}

                    {/*Project Payment*/}
                    <section id="payment" className="inns-plans-section space-ptb">
                        <div className="container">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-md-3">
                                    <div className="starting-from-main">
                                        <div className="starting-from-main-inner-head mt-0">
                                            <span className="line-horizontal">Residential <span>Plots</span></span>
                                        </div>
                                        <div className="starting-from-inner">
                                            <div className="black-head">Prices Starting From</div>
                                            <div className="read-head"><span>PKR</span> 6,075,000</div>
                                            <div className="black-head">Installments Starting From</div>
                                            <div className="read-head"><span>PKR</span> 121,500</div>
                                        </div>
                                        <div className="read-head-blk"><span>30 Months</span> Easy Installment Plan</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*Project Payment*/}
                </div>
            </MobileView>
            {/*JO Project Browser View*/}
        </main>
    )
}

export default QaswaaResidency