import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { BASE_URL } from "../../../Constants/global";

function ProjectListingCard({ propertyData }) {
  const project_thumbnail =
    BASE_URL + "classified_assets/images/placeholder/jo-placeholder.png";

  try {
    return (
      <Fragment>
        {propertyData.map(function (data, key) {
          const detail_page_url =
            "/project/" +
            data.city_data[0].city.toLowerCase() +
            "-" +
            data.slug.toLowerCase() +
            "-" +
            data.id;

          return (
            <div className="pp-main-horizontal">
              <div className="property-item property-col-list mt-4">
                <div className="row no-gutters">
                  <div className="col-lg-4 col-md-5">
                    <Link to={detail_page_url}>
                      <div className="property-image bg-overlay-gradient-04">
                        <img
                          className="img-fluid"
                          src={data.front_image}
                          alt=""
                        />
                        <div className="property-lable">
                          <span className="badge badge-md badge-info">
                            {data.city_data[0].city}{" "}
                          </span>
                        </div>
                        <div className="property-agent">
                          <div className="property-agent-image">
                            <img
                              className="img-fluid"
                              src={data.builder_profile[0].profile_image}
                              alt=""
                            />
                          </div>
                          <div className="property-agent-info">
                            <a className="property-agent-name" href="#">
                              {data.builder_profile[0].name}
                            </a>
                            <span className="d-block">Company Agent</span>
                            <ul className="property-agent-contact list-unstyled">
                              <li>
                                <a href="#">
                                  <i className="fas fa-mobile-alt" />{" "}
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fas fa-envelope" />{" "}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-8 col-md-7">
                    <div className="property-details">
                      <Link to={detail_page_url}>
                        <div className="property-details-inner">
                          <div className="property-details-inner-box">
                            <div className="property-details-inner-box-left">
                              <h5 className="property-title">
                                <a href={detail_page_url}>{data.title} </a>
                              </h5>
                              <span className="property-address">
                                <i className="fas fa-map-marker-alt fa-xs" />
                                {data.area_data[0].area}
                              </span>
                            </div>
                            <div className="property-price">
                              <span className="font-md text-black-50 font-weight-bold">
                                Starting From:
                              </span>{" "}
                              <span className="font-sm text-primary font-weight-bolder">
                                PKR
                              </span>{" "}
                              {data.price_txt}
                            </div>
                          </div>
                          <ul className="property-info list-unstyled d-flex project-amenities-card">
                            <li className="flex-fill property-bed">
                              <i className="fas fa-bed" />
                            </li>
                            <li className="flex-fill property-bath">
                              <i className="fas fa-bath" />
                            </li>
                            <li className="flex-fill property-m-sqft">
                              <i className="far fa-square" />
                            </li>
                          </ul>
                          {/*<p className="mb-0 d-none d-block mt-3 project-descp">{parse(data.description)}</p>*/}
                        </div>
                      </Link>
                      <div className="property-btn">
                        <a className="property-link" href={detail_page_url}>
                          See Details
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Fragment>
    );
  } catch (err) {
    console.log(err.message);
    return (
      <>
        <div className="container">Server error, Please try again later.</div>
      </>
    );
  }
}

export default ProjectListingCard;
