import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { handleFavorite } from "../../../Redux/actions/favorite";
import ProjectListingCard from "../Projects/ProjectListingCard";
import { useFormik } from "formik";
import * as Yup from "yup";
import { bindActionCreators } from "redux";
import { handleInquiry } from "../../../Redux/actions/inquiry";
import { Link, useParams } from "react-router-dom";
import PropTypes from "prop-types";

import {
  API_URL,
  multiFormHeader,
  BASE_URL,
  jsonHeader,
  USER_IMAGE_NOT_FOUND_URL,
  USER_PROFILE_URL,
} from "../../../Constants/global";
// { handleInquiry, projectData, index }
const FeaturedProjectCard = ({ handleInquiry, projectData, key, index }) => {
  const [project_data, set_project_data] = useState([]);
  // ------------------------------------------------------------------
  const inquiry_modal_func = (e) => {
    let inquiry_data = e.target.id.split("+");

    handleInquiry({
      id: inquiry_data[0],
      title: inquiry_data[1],
      url: inquiry_data[2],
    });
  };

  const [Statusmessage, setMessage] = useState({
    message: "",
    class: "",
  });
  const [isLoading, setIsLoading] = useState({
    isLoading: false,
    disabled: false,
  });
  function setSuccessMessage(msg) {
    setMessage({
      message: msg,
      class: "alert alert-success contactUsAlert",
    });
  }
  function setErrorMessage(msg) {
    setMessage({
      message: msg,
      class: "alert alert-danger contactUsAlert",
    });
  }
  function removeMessage(msg) {
    setMessage({
      message: "",
      class: "",
    });
  }

  const [apierror, setApierror] = useState("");
  const initialValues = {
    name: "",
    email: "",
    contact_number: "",
    message: "",
  };

  const validateSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email format").required("Required"),
    contact_number: Yup.number().required("Required"),
    message: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
  });
  const onSubmit = (values, { resetForm }) => {
    const data_arr = project_data;

    const id = data_arr[0];
    const title = data_arr[1];
    const href = "https://jagahonline.com" + data_arr[2];

    setIsLoading({
      isLoading: true,
      disabled: true,
    });
    console.log(values);
    var form = new FormData();
    form.append("name", values.name);
    form.append("email", values.email);
    form.append("contact_number", values.contact_number);
    form.append("message", values.message);
    form.append("type", "project");
    form.append("ref_id", id);
    form.append("title", title);
    //get current date

    const current = new Date();
    const date = `${current.getDate()}-${
      current.getMonth() + 1
    }-${current.getFullYear()} - ${current.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })}`;

    form.append("inquiry_date_time", date);

    form.append("url", href);

    console.log(form);

    axios({
      method: "POST",
      url: API_URL + "generalservices/inquiry",
      data: form,
      headers: multiFormHeader,
    }).then((response) => {
      if (response.data.status === 200) {
        setIsLoading({
          isLoading: false,
          disabled: false,
        });
        setSuccessMessage("Your request has been sent");
        setTimeout(removeMessage, 3000);
        resetForm({ values: "" });
      } else {
        setIsLoading({
          isLoading: false,
          disabled: false,
        });
        setApierror(response.data.message);
        setErrorMessage(response.data.message);
      }
    });
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validateSchema,
  });

  // --------------------------------------------------------------
  return projectData.map((data, innerKey) => {
    if (index === innerKey) {
      const detail_page_url =
        "/project/" +
        data.city_data[0].city.toLowerCase() +
        "-" +
        data.slug.toLowerCase() +
        "-" +
        data.id;

      return (
        <Fragment>
          <div className="col-md-12">
            <div className="property-item property-col-list mt-3 mb-5">
              <div className="row no-gutters">
                <div className="col-lg-4 col-md-5">
                  <Link to={detail_page_url}>
                    <div className="property-image bg-overlay-gradient-04">
                      <img
                        className="img-fluid"
                        src={data.front_image}
                        alt=""
                        loading="lazy"
                      />

                      <div className="property-lable">
                        <span className="badge badge-md badge-info">
                          {data.city_data[0].city}{" "}
                        </span>
                      </div>
                      <div className="property-agent">
                        <div className="property-agent-image">
                          <img
                            className="img-fluid"
                            src={data.builder_profile[0].profile_image}
                            alt=""
                            loading="lazy"
                          />
                        </div>
                        <div className="property-agent-info">
                          <a className="property-agent-name" href="#">
                            {data.builder_profile[0].name}
                          </a>
                          <span className="d-block">Company Agent</span>
                          <ul className="property-agent-contact list-unstyled">
                            <li>
                              <a href="#">
                                <i className="fas fa-mobile-alt" />{" "}
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-envelope" />{" "}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-lg-8 col-md-7">
                  <div className="property-details">
                    <Link to={detail_page_url}>
                      <div className="property-details-inner">
                        <div className="property-details-inner-box">
                          <div className="property-details-inner-box-left">
                            <h5 className="property-title">
                              <a href={detail_page_url}>{data.title} </a>
                            </h5>
                            <span className="property-address">
                              <i className="fas fa-map-marker-alt fa-xs" />
                              {data.area_data[0].area}
                            </span>
                          </div>
                          <div className="property-price">
                            <span className="font-md text-black-50 font-weight-bold">
                              Starting From:
                            </span>{" "}
                            <span className="font-sm text-primary font-weight-bolder">
                              PKR
                            </span>{" "}
                            {data.price_txt}
                          </div>
                        </div>
                        <ul className="property-info list-unstyled d-flex project-amenities-card">
                          <li className="flex-fill property-bed">
                            <i className="fas fa-bed" />
                          </li>
                          <li className="flex-fill property-bath">
                            <i className="fas fa-bath" />
                          </li>
                          <li className="flex-fill property-m-sqft">
                            <i className="far fa-square" />
                          </li>
                        </ul>
                        {/*<p className="mb-0 d-none d-block mt-3 project-descp">{parse(data.description)}</p>*/}
                      </div>
                    </Link>
                    <div className="property-btn">
                      <a className="property-link" href={detail_page_url}>
                        See Details
                      </a>
                      <ul className="property-listing-actions list-unstyled mb-0">
                        <li className="property-favourites">
                          <a
                            title="Inquiry"
                            data-toggle="modal"
                            data-target="#ProjectInquiryModal"
                            href="#"
                            id={
                              data.id + "+" + data.title + "+" + detail_page_url
                            }
                            key={data.title}
                            onClick={inquiry_modal_func}
                          >
                            <i
                              className="fas fa-comment"
                              id={
                                data.id +
                                "+" +
                                data.title +
                                "+" +
                                detail_page_url
                              }
                              key={data.title}
                              onClick={inquiry_modal_func}
                            ></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
  });
};
FeaturedProjectCard.propTypes = {
  handleInquiry: PropTypes.func.isRequired,
};
export default connect(null, { handleInquiry })(FeaturedProjectCard);
